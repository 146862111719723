import React, { useContext, useEffect, useMemo } from 'react'
import Box from '@ui/layout/Box'
import { StudioContext, useApp } from '../context/app-context'
import { useProjectProp } from '@ui/hooks/useProjectProp'
import HotKeyPanel, { HotKey } from '@ui/components/HotKeyPanel'
import { MediaContext } from '../context/media-context'
import useMediaData from '@ui/hooks/useMediaData'
import { isEqual } from 'lodash'

const defaultBackground = {
  id: '570d9bb3-5b73-4ec8-ab23-30d222b4d6a5',
  src: ' https://studio.golightstream.com/images/polygons.jpg',
}

export interface ActiveLayers {
  videoOverlay: string
  imageOverlay: string
  background: string
  activeBanner: string
  logo: string
}

export const HotKeyManager = () => {
  const { track, projectCommands } = useApp()
  const {
    logo,
    setLogo,
    activeBanner,
    setActiveBanner,
    videoOverlay,
    setVideoOverlay,
    imageOverlay,
    setImageOverlay,
    background,
    setBackground,
    remainingOverlayTime,
    remainingVideoLoopTime,
    setRemainingOverlayTime,
  } = useContext(MediaContext)

  const { studio } = React.useContext(StudioContext)
  const [hotkeys, setHotKeys] = useProjectProp<HotKey[]>('hotkeys')
  const [activeHotkey, setActiveHotKey] = useProjectProp<string>('activeHotkey')
  const [getMediaData] = useMediaData()
  const media = getMediaData(['image', 'video', 'custom'])

  const banners = useMemo(
    () => studio.compositor.getSources('Banner'),
    [hotkeys],
  )

  const updateRemaningTime = [
    remainingOverlayTime,
    remainingVideoLoopTime,
  ].filter(Boolean)

  React.useEffect(() => {
    if (media && hotkeys) {
      const filterdHotKeys = hotkeys?.filter((hotkey) => {
        const doesExistis =
          hotkey.type === 'banner'
            ? banners.find((b) => b.id === hotkey.hotKeyId)
            : (media.find((r) => r.id === hotkey.hotKeyId) as any)
        if (doesExistis?.id) {
          return hotkey.hotKeyId
        }
      })
      if (!isEqual(hotkeys, filterdHotKeys)) {
        setHotKeys([...filterdHotKeys])
      }
    }
  }, [media, hotkeys])

  // Delete a hotkey
  const onDeleteHotKey = React.useCallback(
    (id: string) => {
      if (activeHotkey === id) {
        setActiveHotKey(null)
      }
      const filterdHotKeys = hotkeys.filter((hotkey) => hotkey.hotKeyId !== id)
      setHotKeys([...filterdHotKeys])
    },
    [activeHotkey, hotkeys],
  )

  // Update a hotkey
  const onUpdateHotKey = React.useCallback(
    async (hotkey: HotKey) => {
      const updateHotKeyIndex = hotkeys.findIndex(
        (h) => h.hotKeyId === hotkey.hotKeyId,
      )
      if (updateHotKeyIndex > -1) {
        const shallowHotKey = JSON.parse(JSON.stringify(hotkeys))
        const newHotKey = { ...hotkeys[updateHotKeyIndex], loop: !hotkey.loop }
        shallowHotKey.splice(updateHotKeyIndex, 1, newHotKey)
        setHotKeys([...shallowHotKey])
      }
    },
    [hotkeys],
  )

  const onHotKeyReorder = React.useCallback(
    async (from: number, to: number) => {
      const shallowHotKeys = JSON.parse(JSON.stringify(hotkeys))
      shallowHotKeys.splice(to, 0, shallowHotKeys.splice(from, 1)[0])
      setHotKeys([...shallowHotKeys])
    },
    [hotkeys],
  )

  // Handle the active hotkey being changed.
  const onActiveHotKey = React.useCallback(
    async (hotkey: HotKey, state: boolean) => {
      track('SetHotkey')

      const hotKeyData =
        hotkey.type === 'banner'
          ? banners.find((b) => b.id === hotkey.hotKeyId)
          : (media.find((r) => r.id === hotkey.hotKeyId) as any)
      const doDelete = state

      if (hotKeyData?.type?.toLowerCase() === 'banner') {
        setActiveBanner(doDelete ? null : hotKeyData.id)
        projectCommands.setActiveBanner(doDelete ? null : hotKeyData.id)
      } else {
        switch (hotKeyData.usageType) {
          case 'intro':
          case 'outro':
          case 'intermission':
            if (doDelete) {
              setVideoOverlay(null)
              projectCommands.removeVideoOverlay2(hotKeyData.id)
            } else {
              setVideoOverlay(hotKeyData.id)
              setRemainingOverlayTime({
                id: hotKeyData.id,
                time: Math.floor(hotKeyData.duration),
              })
              projectCommands.addVideoOverlay2(hotKeyData.id, {
                src: hotKeyData.url,
                loop: hotkey?.loop,
              })
            }
            break
          case 'background':
            if (doDelete) {
              setBackground(defaultBackground.id)
              projectCommands.setBackgroundImage2(defaultBackground.id, {
                src: defaultBackground.src,
              })
            } else {
              setBackground(hotKeyData.id)
              hotKeyData.type === 'image'
                ? projectCommands.setBackgroundImage2(hotKeyData.id, {
                    src: hotKeyData.url,
                  })
                : projectCommands.setBackgroundVideo2(hotKeyData.id, {
                    src: hotKeyData.url,
                    loop: true,
                  })
            }
            break
          case 'logo':
            if (doDelete) {
              setLogo(null)
              projectCommands.removeLogo(hotKeyData.id)
            } else {
              setLogo(hotKeyData.id)
              projectCommands.addLogo(hotKeyData.id, {
                src: hotKeyData.url,
              })
            }
            break
          case 'overlay':
            if (doDelete) {
              setImageOverlay(null)
              hotKeyData.resourceType === 'custom'
                ? projectCommands.removeCustomOverlay(hotKeyData.id)
                : projectCommands.removeImageOverlay2(hotKeyData.id)
            } else {
              setImageOverlay(hotKeyData.id)
              hotKeyData.resourceType === 'custom'
                ? projectCommands.addCustomOverlay(hotKeyData.id, {
                    src: hotKeyData.url,
                    width: hotKeyData?.width ?? null,
                    height: hotKeyData?.height ?? null,
                  })
                : projectCommands.addImageOverlay2(hotKeyData.id, {
                    src: hotKeyData.url,
                  })
            }
            break
        }
      }
    },
    [hotkeys, media, banners],
  )

  return (
    <Box>
      {media && (
        <HotKeyPanel
          media={media}
          banners={banners}
          hotkeys={hotkeys}
          onDelete={onDeleteHotKey}
          onActive={onActiveHotKey}
          onUpdate={onUpdateHotKey}
          onReorder={onHotKeyReorder}
          updateRemaningTime={updateRemaningTime}
          activeLayers={{
            logo,
            videoOverlay,
            imageOverlay,
            background,
            activeBanner,
          }}
        />
      )}
    </Box>
  )
}
