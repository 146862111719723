import React from 'react'
import Icon, { IconName } from './Icon'
import { Flex } from '../../layout/Box'
import * as Colors from '../../helpers/colors'
import { style } from 'typestyle'

type Props = {
  /** image url */
  src?: string
  /** height/width */
  size: number
  icon?: IconName
  iconHeight?: number
  iconWidth?: number
  background?: string
  borderColor?: string
  name?: string
}

const avatarContainer = style({
  display: 'inline-block',
  verticalAlign: 'middle',
  position: 'relative',
  borderRadius: '50%',
})

const avatarLetter = style({
  left: '50%',
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
})

export const Avatar = (props: Props) => {
  const avatarStyle = {
    height: `${props.size}px`,
    width: `${props.size}px`,
    borderRadius: `${props.size / 2}px`,
    background: props.background || 'transparent',
    ...(Boolean(props.borderColor) && {
      border: `1px solid ${props.borderColor}`,
    }),
  }
  if (props.icon) {
    return (
      <Flex style={avatarStyle} align="center" justify="center">
        <Icon
          name={props.icon}
          // Twitch icon is off-center
          nudgeLeft={props.icon === 'Twitch' ? 1 : 0}
          height={props.iconHeight}
          width={props.iconWidth}
        />
      </Flex>
    )
  }
  return <img style={avatarStyle} src={props.src} />
}

export const LetterAvatar = (props: Props) => {
  const avatarStyle = {
    height: `${props.size}px`,
    width: `${props.size}px`,
    background: props.background || 'transparent',
  }
  return (
    <div className={avatarContainer} style={avatarStyle}>
      <div className={avatarLetter}>
        {props.name.substring(0,2).toUpperCase()}
      </div>
    </div>
  )
}
