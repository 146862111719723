import * as React from 'react'
import { style } from 'typestyle'
import { IconButton } from '../../atoms/buttons/IconButton'
import Text from '../../atoms/text/Text'
import Box, { Flex } from '../../layout/Box'
import * as Colors from '../../helpers/colors'
import { Modal } from '../../atoms/FloatingMenu/FloatingMenu'
import { subscribeToEscape } from '../../helpers/escape-context'
import bowser from 'bowser'

const browser = bowser.getParser(navigator.userAgent)

const shadowBackgroundClass = style({
  position: 'absolute',
  height: '100%',
  width: '100%',
  ...(browser.isBrowser('chrome')
    ? {
        background: Colors.color(Colors.neutral(1000)).fade(0.7).toString(),
        backdropFilter: 'blur(20px)',
      }
    : {
        background: Colors.color(Colors.neutral(900)).fade(0.9).toString(),
      }),
})

const modalNoBackground = style({
  pointerEvents: 'none',
})

export const modalHeading = style({
  padding: '36px 60px 20px 20px',
  background:
    'url("https://studio.golightstream.com/images/polygons.jpg") #000',
  backgroundSize: 'cover',
  backgroundPosition: 'bottom',
  color: Colors.neutral(0),
})
export const modalContent = style({
  fontWeight: 400,
  fontSize: 14,
})

const closeButton = style({
  position: 'absolute',
  top: 14,
  right: 14,
})

const modal = style({
  position: 'relative',
  background: Colors.neutral(0),
  color: Colors.neutral(1000),
  borderRadius: 12,
  overflow: 'hidden',
})

const MODAL_GUTTER_SIZE = 13

const topLeft = style({
  position: 'fixed',
  background: Colors.neutral(0),
  color: Colors.neutral(1000),
  borderRadius: 12,
  overflow: 'hidden',
  top: MODAL_GUTTER_SIZE,
  left: MODAL_GUTTER_SIZE,
})

const bottomLeft = style({
  position: 'fixed',
  background: Colors.neutral(0),
  color: Colors.neutral(1000),
  borderRadius: 12,
  overflow: 'hidden',
  bottom: MODAL_GUTTER_SIZE,
  left: MODAL_GUTTER_SIZE,
})

const topRight = style({
  position: 'fixed',
  background: Colors.neutral(0),
  color: Colors.neutral(1000),
  borderRadius: 12,
  overflow: 'hidden',
  top: MODAL_GUTTER_SIZE,
  right: MODAL_GUTTER_SIZE,
})

const bottomRight = style({
  position: 'fixed',
  background: Colors.neutral(0),
  color: Colors.neutral(1000),
  borderRadius: 12,
  overflow: 'hidden',
  bottom: MODAL_GUTTER_SIZE,
  right: MODAL_GUTTER_SIZE,
})

const bottomCenter = style({
  position: 'fixed',
  background: Colors.neutral(0),
  color: Colors.neutral(1000),
  borderRadius: 12,
  overflow: 'hidden',
  bottom: MODAL_GUTTER_SIZE,
})

const topCenter = style({
  position: 'fixed',
  background: Colors.neutral(0),
  color: Colors.neutral(1000),
  borderRadius: 12,
  overflow: 'hidden',
  top: MODAL_GUTTER_SIZE,
})

export const enum Position {
  TopCenter = 'top-center',
  BottomCenter = 'bottom-center',
  TopLeft = 'top-left',
  BottomLeft = 'bottom-left',
  TopRight = 'top-right',
  BottomRight = 'bottom-right',
}

const modalPosition = (corner?: Position) => {
  switch (corner) {
    case Position.TopCenter:
      return topCenter
    case Position.BottomCenter:
      return bottomCenter
    case Position.TopLeft:
      return topLeft
    case Position.BottomLeft:
      return bottomLeft
    case Position.TopRight:
      return topRight
    case Position.BottomRight:
      return bottomRight
    default:
      return modal
  }
}

export const BasicModal = React.memo(
  (
    props: React.PropsWithChildren<{
      headline?: string
      /** probably just going to be setIsOpen(false) */
      onClose(): void
      maxWidth?: number
      isOpen: boolean
      /**
       * Modals with the shadow background close when clicked outside of modal or when pressing escape key.
       * @default true
       */
      shadowBackground?: boolean
      /**
       * If left undefined, modal will render in center of page.
       * @default undefined
       */
      position?: Position
      closeable?: boolean
      backgroundColor?: string
      textColor?: string
    }>,
  ) => {
    const {
      shadowBackground = true,
      closeable = true,
      backgroundColor = 'transparent',
      textColor = Colors.neutral(1000),
    } = props
    React.useEffect(() => {
      if (closeable && props.isOpen && shadowBackground) {
        return subscribeToEscape(() => {
          props.onClose()
        })
      }
    }, [props.isOpen])
    return (
      <Modal
        top="0%"
        isOpen={props.isOpen}
        onClose={() => {
          props.onClose()
        }}
      >
        <Flex
          align="center"
          justify="center"
          {...(shadowBackground && {
            height: '100vh',
            width: '100vw',
          })}
        >
          {shadowBackground && (
            <Flex
              className={shadowBackgroundClass}
              onClick={(e) => {
                e.stopPropagation()
                props.onClose()
              }}
            />
          )}
          <Box
            onClick={(e) => {
              e.stopPropagation()
            }}
            className={modalPosition(props.position)}
            maxWidth={props.maxWidth}
          >
            {closeable && (
              <IconButton
                className={closeButton}
                appearance="text"
                color="neutral"
                colorWeight={0}
                fontSize={24}
                icon="Close"
                onClick={props.onClose}
              />
            )}
            {props.headline && (
              <Box className={modalHeading}>
                <Text.Heading1 text={props.headline} />
              </Box>
            )}
            <Box
              className={modalContent}
              paddingX={20}
              paddingY={14}
              style={{ backgroundColor, color: textColor }}
            >
              {props.children}
            </Box>
          </Box>
        </Flex>
      </Modal>
    )
  },
)
