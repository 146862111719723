import { ChatAggregator } from './ChatAggregator'
import { TwitchParser } from '@streamjar/chatparser'
import { BaseChat } from './BaseChat'
export class TwitchChat extends BaseChat {
  private reconnectTimer: NodeJS.Timer;
  private readonly endpoint = 'wss://irc-ws.chat.twitch.tv'
  private ws: WebSocket
  private accessToken: string

  private parser = new TwitchParser({
    bots: [],
    developers: [],
  })

  constructor(
    private readonly aggregator: ChatAggregator,
    private username: string,
    accessToken?: string,
  ) {
    super();
    this.accessToken = accessToken
  }

  public setToken(token: string): void {
    this.accessToken = token;
  }

  public connect(): void {
    if (this.ws) {
      return
    }

    this.ws = new WebSocket(this.endpoint)

    this.ws.addEventListener('open', () => {
      this.send('CAP REQ :twitch.tv/membership')
      this.send('CAP REQ :twitch.tv/commands')
      this.send('CAP REQ :twitch.tv/tags')

      if (this.accessToken) {
        this.send(`PASS oauth:${this.accessToken}`)
      }

      // Twitch allows anonymous users via a justinfan**** usernames.
      this.send(`NICK ${this.accessToken ? this.username : `justinfan1234`}`)
    })

    this.ws.addEventListener('message', (message) => {
      for (const line of message.data.toString().trim().split('\r\n')) {
        const args = line.trim().split(' ')

        if (args[1] === '001') {
          this.send(`JOIN #${this.username.toLowerCase()}`)
          this.aggregator.emit('health-changed');
        }

        if (args[0] === 'PING') {
          this.send(`PONG ${args[1]}`)
        }

        if (args[1] === 'PART') {
          // We've left that channel, we should reconnect.
          this.handleDisconnect()
        }

        if (args[2] === 'PRIVMSG') {
          this.parser
            .parseMessage(
              { raw: line },
              {
                platformId: '',
                platformUserId: this.username,
              },
            )
            .then((message) => {
              this.aggregator.emit('message', {
                destination: 'twitch',
                message,
              })
            })
            .catch((err) => {
              console.warn('Unable to parse twitch message', err)
            })
        }
      }
    })

    this.ws.addEventListener('close', this.handleDisconnect);
  }

  public disconnect() {
    try {
      // make sure it's really dead.
      this.ws.removeEventListener('close', this.handleDisconnect)
      this.ws.close()
    } catch (e) {}

 
    this.ws = null
    this.aggregator.emit('health-changed');
    clearTimeout(this.reconnectTimer);
  }

  // intentionally a local function.
  private handleDisconnect = () => {
    this.disconnect()

    this.reconnectTimer = setTimeout(() => {
      this.connect()
    }, 5000)
  }

  public sendMessage(message: string) {
    this.send(
      `@send-ts=${new Date().getTime()} PRIVMSG #${this.username.toLowerCase()} :${message}`,
    )
  }

  private send(message: string): void {
    if (this.ws) {
      this.ws.send(`${message}\r\n`)
    }
  }

  public canChat(): boolean {
    return !!this.ws;
  }
}
