import { MediaTileData } from '@ui/atoms/media/Mediatile'
import { HotKey } from '@ui/components/HotKeyPanel'
import { toMediaItem, toMediaType } from '@ui/helpers/common'
import { date2str } from '@ui/helpers/date-formatter'
import { useCallback, useContext } from 'react'
import { UserContext } from '../../src/context/user-context'
import { UsageType } from 'studio-types/models/resource'
import { useProjectProp } from './useProjectProp'

const useMediaData = () => {
  const userState = useContext(UserContext)
  const [hotkeys] = useProjectProp<HotKey[]>('hotkeys')

  const getMediaData = useCallback(
    (predicates: string[], usageType?: UsageType): MediaTileData[] => {
      const userResources = [
        ...userState?.resources,
        ...userState?.globalResources,
      ]
      return userResources
        .filter((r) => (usageType ? toMediaType(r, usageType) : r))
        .filter((r) => toMediaItem(r, [...predicates]))
        .map((r) => {
          return {
            url: r.resourceUrl,
            id: r.id,
            type: r.resourceType,
            resourceType: r.resourceType,
            usageType: r.usageType,
            isGlobal: r.isGlobal,
            isFavorite: hotkeys?.some((h) => h?.hotKeyId === r.id),
            uploadedAt: date2str(r.createdAt, 'yyyy.MM.dd'),
            authorName: r.authorName ?? userState.displayName,
            authorWebsite: r.authorWebsite,
            themeName: r.themeName,
            title: r.title,
            snapshot: r.snapshot,
            duration: r.duration,
            authorAvatar: r.authorAvatar,
            width: r.naturalWidth,
            height: r.naturalHeight,
          }
        })
        .filter(Boolean)
    },
    [userState.resources, hotkeys],
  )

  return [getMediaData]
}

export default useMediaData
