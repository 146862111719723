import * as React from 'react'
import { style } from 'typestyle'
import { IconButton } from '@ui/atoms/buttons/IconButton'
import { IconName } from '@ui/atoms/icons/Icon'
import { DesignShelf } from './design/DesignShelf'
import { GuestChatShelf } from './guest-chat/GuestChatShelf'
import { WithInfo } from '@ui/atoms/FloatingMenu/FloatingMenu'
import { PlatformChatShelf } from './platform-chat/PlatformChatShelf'
import Box, { Column } from '@ui/layout/Box'
import * as Colors from '@ui/helpers/colors'
import { ProduceShelf } from './produce/ProduceShelf'
import { GuestChatContext } from '../../context/guest-chat-context'
import { PlatformChatContext } from '../../context/platform-chat-context'
import { MediaContext } from '../../context/media-context'
import { Helpers } from '@api.stream/studio-kit'
import { AppContext } from '../../context/app-context'
import { UserType } from '../../types'
const { StudioContext } = Helpers.React

enum ActiveTab {
  Design = 'Design',
  Produce = 'Produce',
  GuestChat = 'Guest Chat',
  PlatformChat = 'Platform Chat',
}

const availableComponents = (userType: UserType) => {
  if (userType !== UserType.GUEST) {
    return [
      ActiveTab.Design,
      ActiveTab.Produce,
      ActiveTab.GuestChat,
      ActiveTab.PlatformChat,
    ]
  }
  return [ActiveTab.PlatformChat, ActiveTab.GuestChat]
}

const NotPermitted = () => {
  return (
    <Box style={{ display: 'block' }} width="100%">
      <Box style={{ textAlign: 'center', padding: '10px' }}>Not permitted</Box>
    </Box>
  )
}

const TabComponents: { [key in ActiveTab]: React.FC } = {
  [ActiveTab.Design]: DesignShelf,
  [ActiveTab.PlatformChat]: PlatformChatShelf,
  [ActiveTab.GuestChat]: GuestChatShelf,
  [ActiveTab.Produce]: ProduceShelf,
}

const sidebarStyle = style({
  background: Colors.neutral(900),
  padding: '12px 5px 5px 5px',
  borderLeft: `1px solid ${Colors.neutral(1000)}`,
})

const shelfStyle = style({
  width: 400,
  position: 'relative',
  background: Colors.neutral(900),
  borderLeft: `1px solid ${Colors.neutral(1000)}`,
})

const indicator = style({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: Colors.secondary(400),
  outline: `2px solid ${Colors.neutral(900)}`,
  position: 'absolute',
  top: 6,
  right: 4,
})

export const SidebarButton = (props: {
  type: ActiveTab
  active: ActiveTab
  icon: IconName
  onClick(type: ActiveTab | null): void
  indicator?: boolean
}) => {
  const isActive = props.active === props.type

  const onButtonClick = React.useCallback(() => {
    if (props.type === props.active) {
      props.onClick(null)
    } else {
      props.onClick(props.type)
    }
  }, [props.type, props.active, props.onClick])

  return (
    <WithInfo
      message={props.type}
      position="left"
      node={
        <div style={{ position: 'relative' }}>
          {props.indicator && <div className={indicator} />}
          <IconButton
            width={36}
            height={36}
            iconSize={24}
            style={{ marginBottom: 4 }}
            color={isActive ? 'primary' : 'neutral'}
            colorWeight={isActive ? 500 : 900}
            icon={props.icon}
            onClick={onButtonClick}
          />
        </div>
      }
    />
  )
}

export const Sidebar = () => {
  const { unread } = React.useContext(GuestChatContext)
  const { userType, projectCommands } = React.useContext(AppContext)
  const {
    setVideoOverlay,
    setRemainingOverlayTime,
    setRemainingVideoLoopTime,
  } = React.useContext(MediaContext)

  const { studio } = React.useContext(StudioContext)

  React.useEffect(() => {
    const unsubscribe = studio.subscribe((events, payload) => {
      switch (events) {
        case 'VideoTimeUpdate':
          switch (payload.category) {
            case 'video-overlay':
              setRemainingOverlayTime({
                id: payload.id,
                time: payload.time,
              })
              break
            case 'video-background':
              setRemainingVideoLoopTime({
                id: payload.id,
                time: payload.time,
              })
              break
          }
          break
        case 'VideoEnded':
          if (payload.id) {
            projectCommands.removeVideoOverlay2(payload.id)
            setVideoOverlay(null)
            setRemainingOverlayTime(null)
          }
          break
      }
    })

    return () => {
      unsubscribe()
    }
  }, [studio])

  const { isUsable } = React.useContext(PlatformChatContext)
  const [activeItem, setActiveItem] = React.useState<ActiveTab | null>(null)
  const isComponentAvailable = availableComponents(userType).find(
    (item) => item === activeItem,
  )
  const ShelfComponent = isComponentAvailable
    ? TabComponents[activeItem]
    : NotPermitted

  const Shelf = activeItem ? (
    <Column className={shelfStyle}>{activeItem && <ShelfComponent />}</Column>
  ) : null

  return (
    <>
      {Shelf}

      <Column className={sidebarStyle} align="center">
        {userType !== UserType.GUEST && (
          <>
            <SidebarButton
              type={ActiveTab.Design}
              active={activeItem}
              icon="Palette"
              onClick={setActiveItem}
            />
            <SidebarButton
              type={ActiveTab.Produce}
              active={activeItem}
              icon="ClapBoard"
              onClick={setActiveItem}
            />
          </>
        )}
        {isUsable && (
          <SidebarButton
            type={ActiveTab.PlatformChat}
            active={activeItem}
            icon="Chat"
            onClick={setActiveItem}
          />
        )}
        <SidebarButton
          type={ActiveTab.GuestChat}
          active={activeItem}
          icon="ChatPrivate"
          onClick={setActiveItem}
          indicator={unread}
        />
        <div className="InfoSpace InfoSidebar" style={{ marginTop: 8 }} />
        <span style={{ flex: 1 }} />
      </Column>
    </>
  )
}
