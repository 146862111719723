import * as React from 'react'
import { WelcomeModal } from '../../ui/components/modals/modals'
import Box from '../../ui/layout/Box'
import { AppContext } from '../context/app-context'
import { UserContext } from '../context/user-context'
import * as Types from '../types'
import { userCanStream } from '../utils/subscription'
import { UserType } from '../types'

const welcomeState = (user: Types.User) => {
  if (userCanStream(user)) {
    return 'creator'
  }
  const hasSubscription = Boolean(
    user.subscription && user.subscription.status !== 'cancelled',
  )
  if (user.hasDoneTrial || hasSubscription) {
    return 'notrial'
  }
  return 'trial'
}

export const Welcome = () => {
  const { userType } = React.useContext(AppContext)
  const user = React.useContext(UserContext)

  const [visible, setVisible] = React.useState(
    localStorage.getItem('dismissedWelcomeModal') !== 'true',
  )

  const onClose = React.useCallback(() => {
    setVisible(false)
    localStorage.setItem('dismissedWelcomeModal', 'true')
  }, [])

  if (!visible || userType !== UserType.HOST) {
    return <></>
  }

  const state = welcomeState(user)

  return (
    <>
      <Box style={{ position: 'fixed', zIndex: 10000, bottom: 14, left: 14 }}>
        <WelcomeModal
          isOpen={visible}
          state={state}
          onClose={onClose}
          onAction={() => {
            // @ts-ignore
            window.location = `${window.location.origin}/welcome?account=true`
          }}
        />
      </Box>
    </>
  )
}
