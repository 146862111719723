import XHRUpload from '@uppy/xhr-upload'
import React from 'react'
import Uppy, { UppyFile, SuccessResponse } from '@uppy/core'
import { Resource, trigger } from '../utils/events'
import { UsageType } from '../../types/models/resource'

const Mb = (mb: number) => mb * 1024 * 1024

interface AllowedFiles {
  image?: string[]
  video?: string[]
  media?: string[]
}
let allowedFileTypes: AllowedFiles = {
  image: ['image/gif', 'image/jpeg', 'image/png'],
  video: ['video/mp4', 'video/webm'],
}

allowedFileTypes.media = [...allowedFileTypes.image, ...allowedFileTypes.video]

type Props = {
  children: JSX.Element[] | JSX.Element
}

type UploadContext = {
  uppy: Uppy
  setFileType: (fileType: 'image' | 'video' | 'media') => void
  setUsageType: (usageType: UsageType) => void
}

const UploadContext = React.createContext<UploadContext>(null)

const UploadProvider: React.FC<Props> = ({ children }) => {
  const [fileType, setFileType] = React.useState<'image' | 'video' | 'media'>(
    'image',
  )
  const [usageType, setUsageType] = React.useState<UsageType>(
    UsageType.Background,
  )

  const uppy = React.useMemo(() => {
    return new Uppy({
      debug: false,
      autoProceed: false,
      restrictions: {
        maxFileSize: Mb(500),
        maxNumberOfFiles: 12,
      },
    }).use(XHRUpload, {
      timeout: 20 * 60 * 1000,
      endpoint: `/api/resources/studio2/uppy`,
    })
  }, [])

  const onUploadSuccess = React.useCallback(
    async (file: UppyFile, response: SuccessResponse) => {
      if (usageType) {
        const { body } = response
        const fileType = file.type.split('/')[0] as 'image' | 'video'

        // Create image resource
        const naturalSize = {
          width: 100,
          height: 100,
        }

        // hit studio-server to create resource
        const postBody = {
          ...body,
          naturalHeight: naturalSize.height,
          naturalWidth: naturalSize.width,
          type: fileType,
          title: file.name,
          mimetype: file.type,
          usageType: usageType,
        }

        const result = await fetch(`/api/resources/studio2/resource`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postBody),
        })

        // Add resource to user state
        const resource = await result.json()
        trigger(Resource.Added, { resource })
      }
    },
    [usageType, fileType],
  )

  React.useEffect(() => {
    if (!usageType) return

    uppy.setOptions({
      restrictions: {
        allowedFileTypes: allowedFileTypes[fileType],
      },
    })

    uppy.on('upload-success', onUploadSuccess)

    return () => {
      uppy.off('upload-success', onUploadSuccess)
    }
  }, [fileType, usageType, uppy, onUploadSuccess])

  React.useEffect(() => {
    return () => uppy.close()
  }, [uppy])

  return (
    <UploadContext.Provider
      value={{
        uppy,
        setFileType,
        setUsageType,
      }}
    >
      {children}
    </UploadContext.Provider>
  )
}

export { UploadContext, UploadProvider }
