import { WithInfo } from '@ui/atoms/FloatingMenu/FloatingMenu'
import Icon from '@ui/atoms/icons/Icon'
import { MediaTileData, MediaTileProps } from '@ui/atoms/media/Mediatile'
import Box, { DragAndDrop, Flex, Row } from '@ui/layout/Box'
import {
  getShortcut,
  HotKeyFooter,
  HotKeyItems,
  HotKeyType,
} from '../../src/utils/hotkey-layouts'
import { cssRaw, style } from 'typestyle'
import Text, { TextProps } from '@ui/atoms/text/Text'
import { MediaTileContext } from '@ui/atoms/media/MediatileContext'
import {
  CSSProperties,
  DragEvent,
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import classNames from 'classnames'
import { Banner } from './TextBanner'
import { debounce } from 'lodash'
import { ActiveLayers } from '../../src/components/HotKeyManager'
import { RemaningTime } from '../../src/context/media-context'
import { UserContext } from '../../src/context/user-context'
import useKeyPress from '@ui/hooks/useKeyPress'
import { range } from '@ui/helpers/common'
import TooltipTypes from '@ui/atoms/FloatingMenu/TooltipTypes'
import { flexLayout } from '@ui/layout/Flex'
import * as Colors from '@ui/helpers/colors'
import { UsageType } from 'studio-types/models/resource'
const MaxHotKeys = 4

interface StyleProps {
  borderColor?: TextProps['color']
  borderRadius?: CSSProperties['borderRadius']
  borderColorWeight?: TextProps['colorWeight']
}

export type HotKey = {
  loop?: boolean
  hotKeyId: string
  type: string
}

export interface HotKeyMediaProps extends MediaTileProps {}

type HotkeyPanelProps = {
  hotkeys: HotKey[]
  banners: Banner[]
  media: MediaTileData[]
  onActive: (hotkey: HotKey, state: boolean) => void
  onDelete: (id: string) => void
  onUpdate: (hotkey: HotKey) => void
  onReorder: (from: number, to: number) => void
  activeLayers: ActiveLayers
  updateRemaningTime: RemaningTime[]
}

cssRaw(`
  .placeholder {
    background: rgb(255,240,120);
    &:before {
      content: "Drop here";
      color: rgb(225,210,90);
    }
  }`)

const MediaTileLayer = style({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: '28px',
  height: '16px',
  background: 'rgba(20, 20, 20, 0.7)',
  borderRadius: '6px',
  fontSize: '10px',
})

const MediaTileChildren = style({
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  bottom: 0,
  padding: '2px',
  maxWidth: '120px',
  width: '100%',
})

const MediaTileContainer = style({
  position: 'relative',
  cursor: 'pointer',
  borderRadius: '6px',
  width: '100%',
  height: '100%',
})

const HotKeyTextElement = memo(({ text }: { text: string }) => {
  const firstLetters = text?.match(/\b(\w)/g).join('')
  return (
    <Text.Custom
      fontSize={24}
      fontWeight={900}
      lineHeight={1}
      text={firstLetters?.substring(
        0,
        firstLetters?.length >= 3 ? 3 : firstLetters?.length,
      )}
      color="neutral"
    />
  )
})

const HotKeyMediaElement = memo(
  ({
    data,
    showDuration = true,
    hideControls = false,
    remaningDuration = null,
    dimmed = false,
    resizeMode = 'cover',
  }: HotKeyMediaProps & StyleProps) => {
    const secondsToMintues = (s: number) => {
      return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s
    }

    const nodeRef = useRef(null)
    const [remainingTime, setRemainingTime] = useState<number>(0)

    useEffect(() => {
      if (remaningDuration) {
        setRemainingTime(remaningDuration.time)
      } else {
        setRemainingTime(Math.floor(data.duration))
      }
    }, [remaningDuration])

    /** @returns {JSX.Element} */
    const GetResource = (): JSX.Element => {
      return (
        <Box className={MediaTileContainer}>
          {!hideControls && (
            <Box className={MediaTileChildren}>
              {showDuration && data.duration > 0 && (
                <Box
                  style={{ opacity: dimmed ? 0.5 : 1, fontSize: 10 }}
                  className={classNames(MediaTileLayer)}
                >
                  {remainingTime
                    ? secondsToMintues(remainingTime)
                    : secondsToMintues(Math.floor(data.duration))}
                </Box>
              )}
            </Box>
          )}

          <MediaElement
            style={{
              opacity: dimmed ? 0.5 : 1,
              pointerEvents: 'none',
              objectFit: resizeMode,
            }}
          />
        </Box>
      )
    }

    const MediaElement = useCallback(
      ({ style }: { style: CSSProperties }) => {
        return (
          <>
            <img
              key={data.id}
              ref={nodeRef}
              src={data?.snapshot?.medium}
              style={{
                display: 'block',
                borderRadius: '6px',
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                ...style,
              }}
            />
          </>
        )
      },
      [data?.snapshot?.small],
    )

    const resource = GetResource()
    return <>{resource}</>
  },
)

const ElementFooter = memo(
  ({
    loop,
    index,
    type,
    onUpdate,
    onDelete,
  }: {
    isFavorite?: boolean
    loop: boolean
    index: number
    type: keyof typeof HotKeyFooter
    onUpdate: () => void
    onDelete: () => void
  }) => {
    const footerIcon = HotKeyFooter[type]
    return (
      <Flex
        align="center"
        justify="center"
        width={85}
        style={{ position: 'absolute', fontWeight: 500 }}
        gap={8}
        height={20}
      >
        {footerIcon?.loop && (
          <Box style={{ cursor: 'pointer' }} onClick={onUpdate}>
            <Icon
              name={footerIcon?.loop}
              color={loop ? 'primary' : 'neutral'}
              colorWeight={500}
              height={20}
              width={20}
            />
          </Box>
        )}
        {footerIcon?.icon && (
          <Icon
            name={footerIcon.icon}
            color="neutral"
            colorWeight={500}
            height={20}
            width={20}
          />
        )}
        <Text.Body
          text={index}
          color="neutral"
          colorWeight={500}
          fontWeight={500}
          fontSize={13}
          marginTop={1}
          lineHeight={0}
        />
        <Box style={{ cursor: 'pointer' }} onClick={onDelete}>
          <Icon name="StarFilled" colorWeight={500} height={20} width={20} />
        </Box>
      </Flex>
    )
  },
)

const BannerToopTip = memo(
  ({ text, index }: { text: string; index: number }) => {
    return (
      <Box padding={5} marginBottom={5} minWidth={150}>
        <Flex marginBottom={5}>
          <Text.Body
            color="neutral"
            colorWeight={400}
            fontSize={10}
            fontWeight={600}
            style={{
              textTransform: 'uppercase',
              margin: '0 0 0 auto',
            }}
            text={`SHIFT + ${index}`}
          />
        </Flex>
        <Text.Body marginLeft={5} fontWeight={500} text={text} />
      </Box>
    )
  },
)

const CustomOverlayToolTip = memo(
  ({ title, url, index }: { title: string; url: string; index: number }) => {
    return (
      <Box padding={5} marginBottom={5} minWidth={150} maxWidth={300}>
        <Flex>
          <Text.Body
            colorWeight={400}
            color="neutral"
            fontWeight={700}
            fontSize={16}
            text={title}
            style={{
              marginBottom: '1px',
            }}
          />
          <Text.Body
            color="neutral"
            colorWeight={400}
            fontSize={10}
            fontWeight={600}
            style={{
              textTransform: 'uppercase',
              marginBottom: '1px',
              margin: '0 0 0 auto',
            }}
            text={`SHIFT + ${index}`}
          />
        </Flex>
        <Text.Body
          fontWeight={400}
          fontSize={14}
          color="neutral"
          colorWeight={400}
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
          text={url}
        />
      </Box>
    )
  },
)

const TooltipComponent = memo(
  ({ hotKeyData, index }: { hotKeyData: any; index: number }) => {
    switch (hotKeyData?.type?.toLowerCase()) {
      case 'banner':
        return (
          <BannerToopTip text={hotKeyData?.props?.bodyText} index={index} />
        )
      case 'custom':
        return (
          <CustomOverlayToolTip
            title={hotKeyData?.title}
            index={index}
            url={hotKeyData?.url}
          />
        )
      default:
        return (
          <MediaTileContext data={hotKeyData} type="hotkey" index={index} />
        )
    }
  },
)

export const HotKeyPanel = ({
  onUpdate,
  hotkeys,
  banners,
  media,
  activeLayers,
  updateRemaningTime,
  onActive,
  onDelete,
  onReorder,
}: HotkeyPanelProps) => {
  const user = useContext(UserContext)
  const emptyHotkeyStartIndex =
    hotkeys && hotkeys.length ? MaxHotKeys - hotkeys.length : MaxHotKeys
  const [isKeyPressed, pressesKeys] = useKeyPress(
    Object.keys(HotKeyItems).map((key) => getShortcut(parseInt(key))),
  )

  const [hoverdIndex, setHoverdIndex] = useState(-1)
  const dragged = useRef(null)
  const over = useRef(null)

  useEffect(() => {
    if (isKeyPressed) {
      const pressedKey = Array.from(pressesKeys).join('+')
      const index = Object.keys(HotKeyItems).findIndex(
        (key) => getShortcut(parseInt(key)) === pressedKey,
      )
      const hotKey = hotkeys?.[index]
      if (hotKey) {
        const hotKeyState = Object.keys(activeLayers).find(
          (key: keyof typeof activeLayers) =>
            hotKey?.hotKeyId === activeLayers[key],
        )
          ? true
          : false
        debounceOnActive(hotKey, hotKeyState)
      }
    }
  }, [isKeyPressed, pressesKeys])

  const debounceOnActive = useCallback(
    debounce((hotkey: HotKey, state: boolean) => {
      onActive(hotkey, state)
    }, 800),
    [media, banners],
  )

  const dragStart = useCallback((e: DragEvent) => {
    dragged.current = e.currentTarget
    e.dataTransfer.effectAllowed = 'move'
    e.dataTransfer.setData('text/html', dragged?.current)
  }, [])

  const dragEnd = useCallback(
    (e: DragEvent) => {
      setHoverdIndex(-1)
      var from = Number(dragged.current.dataset.id)
      var to = Number(over.current.dataset.id)
      onReorder(from, to)
    },
    [onReorder],
  )

  const dragOver = useCallback(
    (e: DragEvent) => {
      e.preventDefault()
      if (e.currentTarget.className === 'placeholder') return
      over.current = e.currentTarget
      setHoverdIndex(Number(over.current.dataset.id))
    },
    [setHoverdIndex],
  )

  return (
    <Row layout={flexLayout} style={{ gap: '8px', marginRight : 3 }}>
      {hotkeys &&
        hotkeys.map((hotkey, i) => {
          const hotKeyState = Object.keys(activeLayers).find(
            (key: keyof typeof activeLayers) =>
              hotkey.hotKeyId === activeLayers[key],
          )
            ? 'Active'
            : 'Filled'
          const hotKeyData =
            hotkey.type === 'banner'
              ? banners.find((b) => b.id === hotkey.hotKeyId)
              : (media.find((r) => r.id === hotkey.hotKeyId) as any)

          const updateTime = updateRemaningTime.find(
            (item) => item.id === hotkey.hotKeyId,
          )

          return (
            <Box key={hotkey.hotKeyId}>
              {hotKeyData && (
                <WithInfo
                  showCaret={false}
                  textColor="neutral"
                  textColorWeight={100}
                  backgroundColor="neutral"
                  backgroundColorWeight={800}
                  borderColor="neutral"
                  borderColorWeight={800}
                  width={300}
                  delay={0}
                  message={
                    <TooltipComponent hotKeyData={hotKeyData} index={i + 1} />
                  }
                  node={
                    <>
                      <DragAndDrop
                        className={style({
                          width: '84px',
                          height: '48px',
                          cursor: 'pointer',
                          borderRadius: 6,
                          display: 'flex',
                          backgroundColor: 'transparent',
                          border: HotKeyType[hotKeyState].border,
                          $nest: {
                            '&:hover': {
                              border: '1px solid white',
                            },
                          },
                        })}
                        dataId={i}
                        key={i}
                        draggable="true"
                        onDragEnd={dragEnd}
                        onDragStart={dragStart}
                        onDragOver={dragOver}
                      >
                        {i === hoverdIndex &&
                          Number(dragged.current.dataset.id) > i && (
                            <Box
                              tag="span"
                              className={style({
                                position: 'absolute',
                                marginLeft: '-4px',
                                height: '46px',
                                border: `1px solid  ${Colors['primary'](500)}`,
                              })}
                            />
                          )}
                        <Flex
                          dataId={i}
                          className={style({
                            width: '100%',
                          })}
                          onClick={() => {
                            debounceOnActive(
                              hotkey,
                              hotKeyState === 'Active' ? true : false,
                            )
                          }}
                        >
                          {hotKeyData?.type?.toLowerCase() === 'banner' ||
                          hotKeyData?.type === 'custom' ? (
                            <Flex
                              align="center"
                              justify="center"
                              className={style({
                                width: '100%',
                                height: '100%',
                              })}
                            >
                              <HotKeyTextElement
                                text={
                                  hotKeyData?.type?.toLowerCase() === 'banner'
                                    ? hotKeyData?.props?.bodyText
                                    : hotKeyData?.title
                                }
                              />
                            </Flex>
                          ) : (
                            <HotKeyMediaElement
                              resizeMode={
                                hotKeyData?.usageType === 'logo'
                                  ? 'contain'
                                  : 'cover'
                              }
                              data={hotKeyData}
                              remaningDuration={
                                hotKeyState === 'Active' && updateTime
                              }
                            />
                          )}
                        </Flex>
                        {i === hoverdIndex &&
                          Number(dragged.current.dataset.id) < i && (
                            <Box
                              tag="span"
                              className={style({
                                position: 'absolute',
                                marginLeft: '84px',
                                height: '46px',
                                border: `1px solid  ${Colors['primary'](500)}`,
                              })}
                            />
                          )}
                      </DragAndDrop>

                      <ElementFooter
                        loop={hotkey?.loop}
                        onUpdate={() => onUpdate(hotkey)}
                        onDelete={() => onDelete(hotkey.hotKeyId)}
                        index={i + 1}
                        type={
                          (['video', 'custom'].some(
                            (value) =>
                              value === hotKeyData?.resourceType &&
                              hotKeyData?.usageType !== UsageType.Background,
                          )
                            ? hotKeyData?.resourceType
                            : hotKeyData?.usageType?.toLowerCase() ||
                              'banner') as keyof typeof HotKeyFooter
                        }
                      />
                    </>
                  }
                />
              )}
            </Box>
          )
        })}
      {emptyHotkeyStartIndex
        ? range(MaxHotKeys - emptyHotkeyStartIndex + 1, MaxHotKeys, 1).map(
            (item, i) => (
              <Box key={item}>
                <WithInfo
                  lingerMS={150}
                  padding="0px"
                  delay={180}
                  message={
                    <TooltipTypes.Default
                      mediaLink={user?.tooltipHelpers?.hotkey}
                      title="Favorites"
                      linkTitle="Learn more"
                      link="https://kb.golightstream.com/en_us/how-do-i-display-my-frequently-used-media-and-favorite-overlays-on-stream-S1dhzLDj"
                      description="Click the star icon to add elements from the Design and Produce panels for quick access. Keyboard shortcut: Shift+1-4"
                    />
                  }
                  node={
                    <>
                      <Flex
                        className={style({
                          width: '84px',
                          height: '48px',
                          cursor: 'pointer',
                          borderRadius: 6,
                          border: HotKeyType['Empty'].border,
                          color: HotKeyType['Empty'].color,
                        })}
                      ></Flex>
                      <Flex
                        align="center"
                        justify="center"
                        width={84}
                        style={{ position: 'absolute', fontWeight: 500 }}
                        gap={2}
                        height={20}
                      >
                        <Text.Body
                          text={item}
                          color="neutral"
                          colorWeight={500}
                          fontWeight={500}
                          fontSize={13}
                          lineHeight={0}
                        />
                      </Flex>
                    </>
                  }
                />
              </Box>
            ),
          )
        : null}
    </Row>
  )
}

export default HotKeyPanel
