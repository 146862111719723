import * as React from 'react'
import { style } from 'typestyle'
import Text from '../atoms/text/Text'
import { color } from 'csx'
import * as Colors from '../helpers/colors'

export enum VideoBannerStyle {
  DEFAULT = 'default',
  MINIMAL = 'minimal',
  BUBBLE = 'bubble',
}

export interface IVideoBannerProps {
  type: VideoBannerStyle
  color: string
}

const styles: { [key in VideoBannerStyle]: string } = {
  [VideoBannerStyle.DEFAULT]: style({
    display: 'inline-block',
    padding: '10px 22px',
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    textTransform: 'capitalize',
    border: '1px solid transparent',
  }),
  [VideoBannerStyle.MINIMAL]: style({
    display: 'inline-block',
    padding: '10px 8px',
    border: '1px solid transparent',
    borderLeft: '9px solid',
    textTransform: 'capitalize',
    position: 'relative',

    $nest: {
      '&::before': {
        zIndex: 1,
        content: '""',
        position: 'absolute',
        left: 0,
        top: -1,
        bottom: -1,
        right: -1,
        background: 'rgba(0,0,0,0.6)',
        transition: '300ms ease all',
      },
    },
  }),
  [VideoBannerStyle.BUBBLE]: style({
    display: 'inline-block',
    padding: '10px 12px',
    border: '1px solid',
    borderRadius: 80,
    textTransform: 'capitalize',
  }),
}

// todo: ensure color adjustments match the studio-kit implementation of each!
function getTheme(
  type: VideoBannerStyle,
  customColor: string,
): React.CSSProperties {
  const textColor = color(customColor).lightness() < 0.6 ? '#FFF' : '#000'

  if (type === VideoBannerStyle.MINIMAL) {
    const backgroundColor = color(customColor)
      .fade(color(customColor).alpha() * 0.7)
      .toString()

    return {
      borderLeftColor: customColor,
      color: 'white',
      backgroundColor: backgroundColor,
    }
  }

  if (type === VideoBannerStyle.DEFAULT) {
    return {
      borderColor: 'transparent',
      color: textColor,
      backgroundColor: customColor,
    }
  }

  return {
    borderColor: textColor,
    color: textColor,
    backgroundColor: customColor,
  }
}

export const VideoBanner = React.memo((props: IVideoBannerProps) => {
  return (
    <div
      className={styles[props.type]}
      style={getTheme(props.type, props.color)}
    >
      <Text.Label
        text={props.type}
        fontWeight={700}
        style={{
          color: getTheme(props.type, props.color)?.color,
          position: 'relative',
          zIndex: 2,
        }}
      />
    </div>
  )
})

const bannerItemButton = style({
  background: 'transparent',
  borderRadius: 6,
  border: `1.5px solid ${Colors.neutral(700)}`,
  padding: '12px 0px',
  width: '100%',
  cursor: 'pointer',
})

export const VideoBannerButton = React.memo(
  (
    props: IVideoBannerProps & {
      active: boolean
      onSelect(type: VideoBannerStyle): void
    },
  ) => {
    return (
      <button
        className={bannerItemButton}
        style={props.active ? { borderColor: Colors.primary(500) } : {}}
        onClick={() => {
          props.onSelect(props.type)
        }}
      >
        <VideoBanner {...props} />
      </button>
    )
  },
)
