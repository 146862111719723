import { ChatAggregator } from './ChatAggregator'
import { FacebookParser } from '@streamjar/chatparser'
import { BaseChat } from './BaseChat'

export class FacebookChat extends BaseChat {
  private source: EventSource
  private accessToken: string
  private timer: NodeJS.Timeout
  private apiVersion : string
  private parser = new FacebookParser({
    bots: [],
    developers: [],
  })

  constructor(
    private readonly aggregator: ChatAggregator,
    private username: string,
    private platformId: string,
    accessToken?: string,
  ) {
    super()
    this.accessToken = accessToken
    this.apiVersion = "v15.0"
  }

  public setToken(token: string): void {
    this.accessToken = token
  }

  private getVideoId() {
    return fetch(
      `https://graph.facebook.com/${this.apiVersion}/${this.platformId}/live_videos?source=owner&broadcast_status[]=LIVE&access_token=${this.accessToken}`,
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (!resp.data.length) {
          return null
        }

        return resp.data[0].id
      })
      .catch((err) => {
        console.warn('Unable to lookup livestream', err)

        return null
      })
  }

  private getProfilePicture() {
    return fetch(
      `https://graph.facebook.com/${this.apiVersion}/${this.platformId}/picture?height=450&width=450&redirect=false&access_token=${this.accessToken}`,
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (!resp.data) {
          return null
        }

        return resp.data
      })
      .catch((err) => {
        console.warn('Unable to lookup livestream', err)

        return null
      })
  }

  public async connect(): Promise<void> {
    if (this.source) {
      return
    }

    const videoId = await this.getVideoId()

    if (!videoId) {
      this.handleDisconnect(25000)

      return
    }

    this.source = new EventSource(
      `https://streaming-graph.facebook.com/${videoId}/live_comments?access_token=${this.accessToken}&comment_rate=one_hundred_per_second&fields=${FacebookParser.REQUIRED_FIELDS}`,
    )

    this.source.addEventListener('message', async (message) => {
      try {
        if (message.type === 'message') {
          const msg = JSON.parse(message.data)
          const highResPictureResponse = await this.getProfilePicture()
          const updatedMessage = {
            ...msg,
            from: {
              ...msg.from,
              picture: {
                ...msg.from.picture,
                data: {
                  ...msg.from.picture.data,
                  ...highResPictureResponse,
                },
              },
            },
          }
          this.parser
            .parseMessage(updatedMessage, {
              platformId: 'platform-id',
              platformUserId: this.username,
            })
            .then((message) => {
              this.aggregator.emit('message', {
                destination: 'facebook',
                message,
              })
            })
            .catch((err) => {
              // uh oh
              console.warn('Unable to parse twitch message', err)
            })
        }
      } catch (e) {
        console.log(e)
      }
    })

    this.source.addEventListener('error', () => {
      this.handleDisconnect(30000)
    })
  }

  public disconnect() {
    try {
      // make sure it's really dead.
      this.source.close()
    } catch (e) {}

    this.source = null
    clearTimeout(this.timer)
  }

  private handleDisconnect(retry: number = 1000) {
    this.disconnect()

    this.timer = setTimeout(() => {
      this.connect()
    }, retry)
  }

  public sendMessage(message: string) {
    // no-op
  }
}
