import * as React from 'react';
import { Helpers } from '@api.stream/studio-kit';
import { LiveApiModel } from '@api.stream/sdk';
const { StudioContext } = Helpers.React;

/**
 * This React hook enables you to identify a primary host. This is useful where
 * changes need to be applied from a single participant.
 * 
 * This uses the oldest participant active with role `HOST`..
 */
export function usePrimaryHost() {
  const { room } = React.useContext(StudioContext)
  const [isPrimary, setPrimary] = React.useState(false);

  React.useEffect(() => {
    if (!room) {
      return
    }

    return room.useParticipants(() => {
      const me = room.participantId
      const participants = room.getParticipants()
        .filter(role => role.role === LiveApiModel.Role.ROLE_HOST)
        .sort((a, b) => {
          return +a.joinedAt - +b.joinedAt
        })
  
        setPrimary(participants?.[0]?.id === me)
    });
  }, [room, room?.participantId])

  return isPrimary
}