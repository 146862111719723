import * as React from 'react'
import { MediaContext } from '../../../src/context/media-context'
import { AppContext } from '../../../src/context/app-context'
import * as Colors from '../../helpers/colors'
import Box, { Column, Row } from '../../layout/Box'
import Text from '../text/Text'
import { ChatMessageBubble, ChatMessageBubbleProps } from './ChatMessageBubble'
import {
  iconStyles,
  IPlatformVariation,
  PlatformButton,
  PlatformFilterIcon,
} from './PlatformFilter'
import { useProjectProp } from '@ui/hooks/useProjectProp'
import { VideoBannerStyle } from '@ui/components/VideoBannerStyle'
import Icon from '../icons/Icon'
import { style } from 'typestyle'
import { WithInfo } from '../FloatingMenu/FloatingMenu'
import { OnStreamChatMessage } from './OnStreamMessage'
import { UserType } from '../../../src/types'
import { DISPLAY_NAME_KEY } from '@ui/components/CameraSettings'
import { IMessageSegment } from '@streamjar/chatparser'



/* Creating a new style that can be used in the component. */
const MessageSelector = style({
  cursor: 'pointer',
  opacity: 0,
})

/* Creating a style object that is used to style the MessageContainer component. */
const MessageContainer = style({
  marginTop: 2,
  marginBottom: 2,
  flexDirection: 'row',
  alignItems: 'flex-end',
  $nest: {
    [`&:hover .${MessageSelector}`]: {
      cursor: 'pointer',
      transition: 'opacity 0.5s ease',
      opacity: 1,
    },
  },
})

const tryGetItem = (key: string) => {
  try {
    return localStorage.getItem(key)
  } catch (e) {
    return null
  }
}

export type ChatTarget =
  | ({
      type: 'destination' | 'user'
    } & IPlatformVariation)
  | { type: 'everyone' }



export type ChatMessageProps = {
  // Unique identifier for the chat message.
  id: string

  // Unique identifier for the user.
  /**
   * *NOTE:* this is a misnomer. It is being set to the destination id.
   * TODO: Refactor to change this to destinationId, add a `senderId` which is actually set to platformId of the sender.
   */
  userId: string

  // Whether the chat message was sent by the current user.
  isSender: boolean

  // Display name of the chat messages
  displayName: string

  // Users avatar
  avatar?: string

  // The target of the message
  target: ChatTarget[]

  // The time the first message in the group was sent.
  timestamp: Date

  // Messages sent by this user
  messages: ChatMessageBubbleProps['message'][]

  chatType?: 'platform' | 'guest'
}

const ChatMessageTime = (props: { time: Date }) => {
  return (
    <span
      style={{
        color: Colors.neutral(500),
        fontWeight: 700,
        fontSize: 10,
        padding: '0px 10px',
        flexShrink: 0,
      }}
    >
      {props.time.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
      })}
    </span>
  )
}

// Displays a list of chat messages.
const ChatMessageList = (
  props: ChatMessageProps & {
    activeChatOverlay: any
    setActiveChatOverlay: (activeChatOverlay: any) => void
  },
) => {
  const {
    avatar,
    displayName,
    messages,
    isSender,
    target,
    activeChatOverlay,
    setActiveChatOverlay,
  } = props

  const { projectCommands, userType } = React.useContext(AppContext)
  const [style, setStyle] = useProjectProp<VideoBannerStyle>('bannerStyle')

  const destinationPlatform: {
    type: 'destination' | 'user'
    username: string
    platform: keyof typeof iconStyles
    variant: number
  } = React.useMemo(
    () => target.find((i) => i.type === 'destination')! as any,
    [],
  )

  const onSelectMessage = React.useCallback(
    (index: number, message: IMessageSegment[]) => {
      const { platform, variant } = destinationPlatform || {}
      const { id, isSender, displayName, chatType } = props
      const chatOverlayDetails = {
        metadata: {
          platform,
          variant,
          avatar,
          index,
          bannerStyle: style,
          chatType,
        },
        username: isSender ? tryGetItem(DISPLAY_NAME_KEY) : displayName,
        message,
        id,
      } as OnStreamChatMessage

      projectCommands.addChatOverlay(props.id, chatOverlayDetails as any)
      setActiveChatOverlay({
        chatOverlayId: id,
        ...chatOverlayDetails,
      })
    },
    [],
  )

  const onRemoveMessage = React.useCallback((id: string) => {
    projectCommands.removeChatOverlay(id)
    setActiveChatOverlay(null)
  }, [])

  const isSelectedMesssage = React.useCallback(
    (index: number) => {
      return (
        activeChatOverlay?.id === props.id &&
        activeChatOverlay?.metadata?.index === index
      )
    },
    [activeChatOverlay],
  )
  return (
    <div style={{ flexDirection: 'row', width: '100%' }}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {!props.isSender && avatar && (
          <span>
            <img
              src={avatar}
              alt={`${displayName}'s Avatar`}
              style={{
                width: 37,
                height: 37,
                borderRadius: '50%',
                margin: '2px 7.5px 0px',
              }}
            />
          </span>
        )}
        <div
          style={{
            flexDirection: 'column',
            display: 'flex',
            width: '100%',
            margin: props.isSender && '2px 7.5px 0px',
          }}
        >
          {messages.map((msg, id) => (
            <div className={MessageContainer} key={id}>
              <div>
                {props.isSender && (
                  <Box
                    style={{
                      display: 'inline-flex',
                      placeItems: 'center',
                      verticalAlign: 'middle',
                    }}
                  >
                    {userType === UserType.HOST && (
                      <WithInfo
                        position="left"
                        message={
                          isSelectedMesssage(id)
                            ? 'Remove message from stream'
                            : 'Display message on stream'
                        }
                        node={
                          <Box
                            height={24}
                            width={24}
                            marginRight={5}
                            className={MessageSelector}
                            onClick={() =>
                              isSelectedMesssage(id)
                                ? onRemoveMessage(props.id)
                                : onSelectMessage(id, msg)
                            }
                          >
                            <Icon
                              name={
                                isSelectedMesssage(id)
                                  ? 'CommentDeSelect'
                                  : 'CommentSelect'
                              }
                              height={24}
                              width={24}
                            />
                          </Box>
                        }
                      />
                    )}

                    {id === 0 && <ChatMessageTime time={props.timestamp} />}
                  </Box>
                )}
                <ChatMessageBubble
                  isSelected={isSelectedMesssage(id)}
                  isSender={isSender}
                  message={msg}
                />
                {!props.isSender && (
                  <Box
                    style={{
                      display: 'inline-flex',
                      placeItems: 'center',
                      verticalAlign: 'middle',
                    }}
                  >
                    {id === 0 && <ChatMessageTime time={props.timestamp} />}
                    {userType === UserType.HOST && (
                      <WithInfo
                        position="left"
                        message={
                          isSelectedMesssage(id)
                            ? 'Remove message from stream'
                            : 'Display message on stream'
                        }
                        node={
                          <Box
                            height={24}
                            width={24}
                            marginLeft={5}
                            className={MessageSelector}
                            onClick={() =>
                              isSelectedMesssage(id)
                                ? onRemoveMessage(props.id)
                                : onSelectMessage(id, msg)
                            }
                          >
                            <Icon
                              name={
                                isSelectedMesssage(id)
                                  ? 'CommentDeSelect'
                                  : 'CommentSelect'
                              }
                              height={24}
                              width={24}
                            />
                          </Box>
                        }
                      />
                    )}
                  </Box>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

// Displays a chat message for private chat
export const ChatMessage = (props: ChatMessageProps) => {
  const { isSender, displayName, avatar } = props
  const { setActiveChatOverlay, activeChatOverlay } =
    React.useContext(MediaContext)
  const sender = isSender ? 'Me' : displayName
  const destinations = props.target.map((i) =>
    i.type === 'destination' ? `${i.platform}: ${i.username}` : 'Everyone',
  )

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: isSender ? 'end' : 'start',
        textAlign: isSender ? 'right' : 'left',
      }}
    >
      <div
        style={{
          paddingLeft: avatar ? 55 : 0,
          paddingTop: 6,
          paddingBottom: 6,
        }}
      >
        {sender} to <span style={{ color: '#26AD80' }}>{destinations}</span>
      </div>

      <ChatMessageList
        {...props}
        setActiveChatOverlay={setActiveChatOverlay}
        activeChatOverlay={activeChatOverlay}
      />
    </div>
  )
}

// Displays a chat message for platform chat.
export const PlatformChatMessage = (
  props: ChatMessageProps & {
    duplicatedPlatforms: string[]
    onTargetClick?(click: ChatTarget): void
  },
) => {
  const { isSender, displayName, target } = props
  const { setActiveChatOverlay, activeChatOverlay } =
    React.useContext(MediaContext)

  const destinationPlatform: {
    type: 'destination' | 'user'
    username: string
    platform: keyof typeof iconStyles
    variant: number
  } = target.find((i) => i.type === 'destination')! as any
  const toEveryone = props.target[0].type === 'everyone'

  return (
    <Column
      marginBottom={5}
      style={{
        alignItems: isSender ? 'end' : 'start',
        textAlign: isSender ? 'right' : 'left',
      }}
    >
      <div
        style={{
          marginLeft: 53,
          marginBottom: -2,
          flexDirection: 'row',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {!isSender && (
          <PlatformButton
            onClick={() => props.onTargetClick?.(target[0])}
            platform={destinationPlatform.platform}
            username={displayName}
            variant={destinationPlatform.variant}
          />
        )}
        {!isSender &&
          props.duplicatedPlatforms.includes(destinationPlatform.platform) && (
            <Text.Caption2
              fontWeight={500}
              color="neutral"
              colorWeight={300}
              style={{ paddingLeft: 10 }}
              text={destinationPlatform.username}
            />
          )}
        {isSender && (
          <Row paddingY={6}>
            <Text.Caption2 text="To: " fontWeight={500} />
            {toEveryone && (
              <Text.Caption2
                text="All"
                letterSpacing={0.08}
                marginLeft={8}
                fontWeight={500}
                textTransform="uppercase"
              />
            )}
            {!toEveryone && (
              <Row style={{ marginLeft: 8 }}>
                {props.target.map((target) => {
                  if (target.type !== 'destination') {
                    return <></>
                  }

                  return (
                    <PlatformFilterIcon
                      key={`${target.platform}-${target.variant}`}
                      active={true}
                      size={20}
                      iconSize={16}
                      platform={target.platform}
                      username={target.username}
                      variant={target.variant}
                    />
                  )
                })}
              </Row>
            )}
          </Row>
        )}
      </div>
      <ChatMessageList
        {...props}
        setActiveChatOverlay={setActiveChatOverlay}
        activeChatOverlay={activeChatOverlay}
      />
    </Column>
  )
}
