import { IconButton } from '@ui/atoms/buttons/IconButton'
import { WithDropdown } from '@ui/atoms/FloatingMenu/FloatingMenu'
import { useState } from 'react'

type UserDopdownProps = {
  children: JSX.Element
}

export const UserDropdown = ({ children }: UserDopdownProps) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <WithDropdown
      isOpen={isOpen}
      onClick={() => {
        setIsOpen(!isOpen)
      }}
      onClose={() => {
        setIsOpen(false)
      }}
      node={
        <IconButton
          icon="Lightstream"
          iconColor="primary"
          iconColorWeight={500}
          color="neutral"
          colorWeight={isOpen ? 900 : 800}
          height={48}
          width={48}
          iconSize={27}
        />
      }
    >
      {children}
    </WithDropdown>
  )
}

export default UserDropdown
