import { useCallback, useEffect, useState } from 'react'
import { useApp } from '../../src/context/app-context'

// TODO: Type of project props

export const useProjectProp = <T extends any>(
  propName: string,
): [T, (val: T) => void] => {
  const { projectCommands } = useApp()
  const [prop, setProp] = useState<T>(projectCommands.getProp(propName))

  const set = useCallback((val: T) => {
    projectCommands.setProp(propName, val)
    // TODO: Set prop locally for immediate feedback
  }, [])

  useEffect(() => {
    return projectCommands.useProp(propName, setProp)
  }, [])

  return [prop, set]
}
