import Box, { Flex } from '@ui/layout/Box'
import Text from '@ui/atoms/text/Text'
import { SVG } from '../icons/Icon'
import LoadingDots from '../animations/LoadingDots'
import { useState } from 'react'

interface DefaultProps {
  title: string
  description: string
  link?: string
  linkTitle?: string
  mediaLink?: string
}

/**
 *
 * @returns {JSX.Element}
 */
const LoadingSpinner = (): JSX.Element => {
  return (
    <Flex align="center" justify="center" width="100%" style={{top : "25%" , position : "absolute"}}>
      <SVG color="primary" colorWeight={400} width={64} svg={LoadingDots} />
    </Flex>
  )
}

const Default = (props: DefaultProps) => {
  const [loading, setLoading] = useState(true)
  return (
    <Box minWidth={250}>
      {props?.mediaLink && (
        <Box width="100%" marginBottom={5}>
          {loading && <LoadingSpinner />}
          <video
              src={props.mediaLink}
            muted
            loop
            autoPlay
            preload="true"
            style={{
              clipPath: 'inset(1px 1px)',
            }}
            onLoadedData={() => {
              setLoading(false)
            }}
          />
        </Box>
      )}

      <Box style={{ padding: '8px 12px' }}>
        <Box marginBottom={5}>
          <Text.Body
            color="neutral"
            colorWeight={1000}
            fontSize={16}
            fontWeight={700}
            style={{
              margin: '0 0 0 auto',
            }}
            text={props.title}
          />
        </Box>
        <Text.Body
          color="neutral"
          colorWeight={1000}
          fontSize={12}
          fontWeight={400}
          text={props.description}
        />
        {props.link && (
          <Text.Link
            fontSize={12}
            marginTop={5}
            text={props.linkTitle}
            href={props.link}
            color="primary"
            style={{ textDecoration: 'none', cursor: 'pointer' }}
            fontWeight={700}
            colorWeight={500}
          />
        )}
      </Box>
    </Box>
  )
}

export default {
  Default,
}
