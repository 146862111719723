import Text, { CustomText } from '../atoms/text/Text'
import { TextInput } from '../atoms/inputs/TextInput'
import { IconButton } from '../atoms/buttons/IconButton'
import { SignalBars, SignalProps } from '../atoms/icons/SignalBars'
import Box, { Column, Flex } from '../layout/Box'
import Button from '../atoms/buttons/Button'
import Icon from '../atoms/icons/Icon'
import React, { useState, useEffect, useRef } from 'react'
import * as Color from '../helpers/colors'
import { WithInfo } from '@ui/atoms/FloatingMenu/FloatingMenu'
import { Helpers } from '@api.stream/studio-kit'
import { classes, style } from 'typestyle'
import Bowser from 'bowser'
import { Camera, trigger } from '../../src/utils/events'

const browser = Bowser.getParser(window.navigator.userAgent)

const isSafari14 =
  browser.getBrowserName(true) === 'safari' &&
  browser.getBrowserVersion().split('.')[0] === '14'

type ParticipantProps = Helpers.ScenelessProject.ParticipantProps

const borderRadius = 6

export type ParticipantCardProps = {
  name?: string
  canClose?: boolean
  canHide?: boolean
  canKick?: boolean
  canMute?: boolean
  canAdd?: boolean
  canRename?: boolean
  canMirror?: boolean
  canOpenSettings?: boolean
  // There is an active video track
  hasVideo?: boolean
  // Video track intentionally hidden, even if it exists
  isHidden?: boolean
  isMuted?: boolean
  isOnStage?: boolean
  // Temporarily disabled add
  isAddDisabled?: boolean
  isMirrored?: boolean
  // Is an external camera
  isExternal?: boolean
  signal?: SignalProps
  label?: string
  onAddToStage?: (props?: Partial<ParticipantProps>) => void
  onRemoveFromStage?: () => void
  onNameChange?: (name: string) => void
  onMuteChange?: (isMuted: boolean) => void
  onHiddenChange?: (isHidden: boolean) => void
  onOpenSettings?: () => void
  onKick?: () => void
  video: React.ReactNode
}
export const ParticipantCard = (props: ParticipantCardProps) => {
  const {
    isExternal = false,
    isOnStage = false,
    isMuted = false,
    isHidden = false,
    isAddDisabled = false,
    hasVideo = false,
    isMirrored = true,
    onNameChange = () => {},
    onMuteChange = () => {},
    onHiddenChange = () => {},
    onKick = () => {},
    onAddToStage = () => {},
    onRemoveFromStage = () => {},
    onOpenSettings,
    signal = {},
    name = '',
    label = '',
    video,
  } = props
  const [isKicking, setIsKicking] = useState(false)
  const [isRenaming, setIsRenaming] = useState(false)
  const [isAddHovered, setIsAddHovered] = useState(false)

  // Local state (for responsive UI)
  const [isLocallyMuted, setIsLocallyMuted] = useState(isMuted)
  useEffect(() => {
    setIsLocallyMuted(isMuted)
  }, [isMuted])

  // Local state
  const [isLocallyHidden, setIsLocallyHidden] = useState(isHidden)
  useEffect(() => {
    setIsLocallyHidden(isHidden)
  }, [isHidden])

  // Local state
  const [isLocallyOnStage, setIsLocallyOnStage] = useState(isOnStage)
  useEffect(() => {
    setIsLocallyOnStage(isOnStage)
  }, [isOnStage])

  // Local state
  const [localName, setLocalName] = useState(name)
  useEffect(() => {
    setLocalName(name)
  }, [name])

  const adding = isLocallyOnStage && !isOnStage

  return (
    <Flex
      style={{
        width: '100%',
        ...(isSafari14 && {
          height: 157,
        }),
        overflow: 'hidden',
        position: 'relative',
        transition: '100ms ease all',
        cursor: props.canAdd && !isAddDisabled ? 'pointer' : 'default',
        background: Color.neutral(800),
        ...(isAddDisabled && {
          opacity: 0.7,
          pointerEvents: 'none',
        }),
      }}
      onClick={() => {
        if (!props.canAdd || isAddDisabled) return
        if (isLocallyOnStage) {
          onRemoveFromStage()
          setIsLocallyOnStage(false)
        } else {
          onAddToStage({
            isMuted: isLocallyMuted,
            isHidden: isLocallyHidden,
          })
          setIsLocallyOnStage(true)
        }
      }}
    >
      <Flex
        height="100%"
        width="100%"
        align="center"
        justify="center"
        direction="column"
        style={{
          aspectRatio: '16/9',
        }}
      >
        {/* Video container */}
        <Flex
          width="100%"
          height="100%"
          opacity={
            hasVideo && !isLocallyHidden && !(isLocallyOnStage && props.canAdd)
              ? 1
              : 0
          }
          style={{
            position: 'absolute',
            transition: 'opacity 300ms ease',
            top: 0,
            left: 0,
            ...(Boolean(isMirrored) && { transform: 'scaleX(-1)' }),
          }}
        >
          {video}
        </Flex>

        {/* No video */}
        {(!hasVideo || isLocallyHidden) && !isLocallyOnStage && (
          <Flex
            style={{
              height: '64px',
              width: '64px',
              padding: '14px',
              marginBottom: '16px',
            }}
          >
            <Icon name="User" height="100%" width="100%"></Icon>
          </Flex>
        )}

        {/* On stage - only show for users who canAdd */}
        {isLocallyOnStage && props.canAdd && (
          <Flex
            direction="column"
            align="center"
            style={{
              height: '54px',
              width: '95px',
              padding: '4px',
              position: 'absolute',
              marginBottom: '16px',
            }}
          >
            <Icon name="MaximizeWindow" height={21} width={24}></Icon>
            <CustomText
              text="On Stage"
              fontSize={14}
              fontWeight={700}
              marginTop={4}
            />
          </Flex>
        )}
        {/* Kick dialog */}
        {isKicking && (
          <Flex
            direction="column"
            align="center"
            justify="center"
            onClick={(e) => e.stopPropagation()}
            style={{
              backgroundColor: Color.neutral(900),
              borderColor: Color.neutral(800),
              borderWidth: '1px',
              borderStyle: 'solid',
              borderRadius: '4px',
              position: 'absolute',
              width: '212px',
              height: '142px',
              padding: '16px',
              textAlign: 'center',
              zIndex: 1,
            }}
          >
            <CustomText
              text={`Are you sure you want to ${isExternal ? 'remove this camera from Green Room' : 'kick this user'}?`}
              fontSize={14}
              fontWeight={500}
            ></CustomText>
            <CustomText
              text={name}
              fontSize={20}
              fontWeight={700}
              style={{ marginTop: '16px', marginBottom: '16px' }}
            ></CustomText>
            <Flex direction="row" align="center" fontSize={16}>
              <Button
                text="No"
                color="neutral"
                height={30}
                colorWeight={1000}
                onClick={(e) => {
                  e.stopPropagation()
                  setIsKicking(false)
                }}
              />
              <Button
                text="Yes"
                color="primary"
                height={30}
                marginLeft={10}
                onClick={(e) => {
                  e.stopPropagation()
                  setIsKicking(false)
                  onKick()
                }}
              />
            </Flex>
          </Flex>
        )}
      </Flex>
      {/* Background gradient */}
      <Flex
        style={{
          pointerEvents: 'none',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 60%, #000000cc 100%)`,
        }}
      />
      {/* Default hover-handling container */}
      {props.canAdd && (
        <div
          className={style({
            $nest: {
              '&:hover ~ .participant-card-hover': {
                boxShadow: `0 0 0 1px white inset`,
              },
            },
          })}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            pointerEvents: 'all',
            borderRadius,
          }}
        />
      )}
      {/* Child button hover-handling container */}
      <div
        className={style({
          $nest: {
            '&:hover ~ .participant-card-hover': {
              boxShadow: 'none',
            },
          },
        })}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          pointerEvents: 'none',
          borderRadius,
        }}
      >
        {/* Bottom */}
        {!isKicking && (
          <Column
            style={{
              position: 'absolute',
              bottom: 0,
              padding: '8px 10px',
              width: '100%',
            }}
          >
            {!isRenaming && <Text.Caption2 text={label} marginLeft={4} />}
            <Flex
              justify="space-between"
              style={{
                alignItems: 'center',
                width: '100%',
              }}
            >
              {/* Rename dialog */}
              {isRenaming && (
                <Flex
                  align="center"
                  style={{ pointerEvents: 'all' }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <form
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                    }}
                    tabIndex={1}
                    onBlur={() => {
                      // TODO: Blur occurs before checkmark onClick handler, so this does not work
                      // if (isRenaming) {
                      //   setIsRenaming(false)
                      //   setLocalName(name)
                      // }
                    }}
                    onSubmit={(e) => {
                      e.preventDefault()
                      onNameChange(localName)
                      setIsRenaming(false)
                    }}
                  >
                    <TextInput
                      autoFocus={true}
                      selectOnFocus={true}
                      defaultValue={localName}
                      paddingX={3}
                      paddingY={3}
                      fontSize={13}
                      style={{ fontWeight: 700, maxWidth: '200px' }}
                      onChange={(e) => {
                        setLocalName(e.target.value)
                      }}
                    />
                    <IconButton
                      color="primary"
                      colorWeight={500}
                      height={25}
                      icon="Checkmark"
                      iconColor="neutral"
                      iconSize={16}
                      marginLeft={4}
                      width={25}
                      onClick={(e) => {
                        onNameChange(localName)
                        setIsRenaming(false)
                      }}
                    />
                    <IconButton
                      color="neutral"
                      height={25}
                      icon="Close"
                      iconColor="neutral"
                      iconSize={16}
                      marginLeft={4}
                      width={25}
                      onClick={(e) => {
                        setIsRenaming(false)
                        setLocalName(name)
                      }}
                    />
                  </form>
                </Flex>
              )}
              {/* Controls bottom left */}
              {!isRenaming && (
                <Flex
                  align="center"
                  direction="row"
                  height="100%"
                  overflow="hidden"
                  grow={0}
                  shrink={1}
                  marginRight={4}
                  style={{ pointerEvents: 'all' }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Flex overflow="hidden" marginRight={6} shrink={1}>
                    <WithInfo
                      message={localName || 'Guest'}
                      delay={0}
                      node={
                        <Button
                          text={localName || 'Guest'}
                          className={style({
                            $nest: {
                              '&:hover': {
                                boxShadow: props.canRename
                                  ? '0 0 0 1px white inset'
                                  : 'none',
                              },
                            },
                          })}
                          appearance="text"
                          color="neutral"
                          colorWeight={0}
                          style={{
                            padding: '4px',
                            marginTop: '4px',
                          }}
                          fontSize={13}
                          fontWeight={700}
                          onClick={(e) => {
                            e.stopPropagation()
                            props.canRename && setIsRenaming(true)
                          }}
                        />
                      }
                    />
                  </Flex>
                  {signal && <SignalBars {...props.signal} size={16} />}
                </Flex>
              )}
              {/* Controls bottom right */}
              {!isRenaming && (
                <Flex align="center" onClick={(e) => e.stopPropagation()}>
                  {props.canMute && (
                    <WithInfo
                      message={isLocallyMuted ? 'Unmute' : 'Mute'}
                      node={
                        <IconButton
                          color={isLocallyMuted ? 'secondary' : 'neutral'}
                          colorWeight={isLocallyMuted ? 500 : 1000}
                          onClick={(e) => {
                            if (!isExternal) {
                              e.stopPropagation()
                              setIsLocallyMuted(!isLocallyMuted)
                              onMuteChange(!isLocallyMuted)
                            }
                          }}
                          height={32}
                          disabled={adding}
                          icon={isLocallyMuted ? 'MicrophoneOff' : 'Microphone'}
                          iconColor="neutral"
                          iconSize={20}
                          marginLeft={4}
                          width={32}
                        />
                      }
                    />
                  )}
                  {props.canHide && (
                    <WithInfo
                      message={isLocallyHidden ? 'Show camera' : 'Hide camera'}
                      node={
                        <IconButton
                          color={isLocallyHidden ? 'secondary' : 'neutral'}
                          colorWeight={isLocallyHidden ? 500 : 1000}
                          onClick={(e) => {
                            e.stopPropagation()
                            setIsLocallyHidden(!isLocallyHidden)
                            onHiddenChange(!isLocallyHidden)
                          }}
                          height={32}
                          disabled={adding}
                          icon={isLocallyHidden ? 'VideoOff' : 'Video'}
                          iconColor="neutral"
                          iconSize={20}
                          marginLeft={4}
                          width={32}
                        />
                      }
                    />
                  )}
                  {props.canOpenSettings && (
                    <WithInfo
                      message="Settings"
                      node={
                        <IconButton
                          color="neutral"
                          colorWeight={1000}
                          height={32}
                          icon="Gear"
                          iconColor="neutral"
                          iconSize={20}
                          marginLeft={4}
                          width={32}
                          onClick={(e) => {
                            e.stopPropagation()
                            onOpenSettings()
                          }}
                        />
                      }
                    />
                  )}
                  {props.canKick && (
                    <WithInfo
                      message={`${isExternal ? 'Remove from Green Room' : 'Kick user'}`}
                      node={
                        <IconButton
                          color="neutral"
                          colorWeight={1000}
                          height={32}
                          icon="UserRemove"
                          iconColor="neutral"
                          iconSize={20}
                          marginLeft={4}
                          width={32}
                          onClick={(e) => {
                            e.stopPropagation()
                            setIsKicking(true)
                          }}
                        />
                      }
                    />
                  )}
                </Flex>
              )}
            </Flex>
          </Column>
        )}
      </div>
      {/* Hover effect */}
      {props.canAdd && (
        <div
          className="participant-card-hover"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            pointerEvents: 'none',
            borderRadius,
            ...(isLocallyOnStage
              ? {
                  boxShadow: `0 0 0 1px ${Color.primary(500)} inset`,
                }
              : {}),
          }}
        />
      )}
    </Flex>
  )
}
