import React, { useContext } from 'react'
import { Helpers } from '@api.stream/studio-kit'
import { Uploader } from './Uploader'
import { Column, Flex, Row } from '@ui/layout/Box'
import MediaTile, { MediaTileData } from '@ui/atoms/media/Mediatile'
import { UsageType } from '../../types/models/resource'
import { UploadContext } from '../context/upload-context'
import { MediaContext } from '../context/media-context'
import { SVG } from '@ui/atoms/icons/Icon'
import LoadingDots from '@ui/atoms/animations/LoadingDots'
import { Resource } from '../types'
import { useApp } from '../context/app-context'
import { CustomOverlayManager } from './CustomOverlayManager'
const { StudioContext } = Helpers.React

interface MeidaDataProps {
  type: UsageType
  data: MediaTileData[]
  onResourceUpdate(key: string, resource: Partial<Resource>): void
  onResourceCreate(resource: Partial<Resource>): void
  onHotKeyAdded(data: MediaTileData, state: boolean): void
  onResourceDelete(key: string): void
}

export const Overlay = ({
  type,
  data,
  onResourceDelete,
  onResourceUpdate,
  onHotKeyAdded,
  onResourceCreate,
}: MeidaDataProps) => {
  const { setImageOverlay, imageOverlay } = useContext(MediaContext)
  const { uppy, setUsageType, setFileType } = useContext(UploadContext)
  const { room } = useContext(StudioContext)
  const { projectCommands, track } = useApp()
  const [selectedContext, setSelectedContext] = React.useState(null)
  const onUploaderOpen = () => {
    setFileType('image')
    setUsageType(type)
  }

  if (!room) {
    return (
      <Flex align="center" justify="center" width="100%" height={100}>
        <SVG color="primary" colorWeight={400} width={32} svg={LoadingDots} />
      </Flex>
    )
  }

  return (
    <Column width="100%" grow={1} shrink={1}>
      <Row
        style={{
          width: '100%',
          alignItems: 'center',
          display: 'grid',
          gridGap: '5px',
          gridTemplateColumns: 'repeat(auto-fill, minmax(115px, 1fr))',
        }}
      >
        {data
          ?.filter((d: MediaTileData) => d.resourceType === 'image')?.map((d: MediaTileData) => {
            return (
              <MediaTile
                key={d.id}
                data={d}
                active={imageOverlay === d.id}
                dimmed={imageOverlay && imageOverlay !== d.id}
                isOpen={selectedContext === d.id}
                setSelectedContext={setSelectedContext}
                showContext={true}
                showFavorite={true}
                onFavorite={async (key: string, state: boolean) => {
                  onHotKeyAdded(d, state)
                }}
                onClick={(key: string) => {
                  track('SetOverlay', { type: 'image' })
                  if (key === imageOverlay) {
                    projectCommands.removeImageOverlay2(key)
                    setImageOverlay(null)
                  } else {
                    const image = data.find(
                      (image: MediaTileData) => image.id === key,
                    )
                    projectCommands.addImageOverlay2(key, { src: image.url })
                    setImageOverlay(key)
                  }
                }}
                onDelete={async (key: string) => {
                  onResourceDelete(key)
                }}
              />
            )
          })}

        <Uploader usageType={type} uppy={uppy} onOpen={onUploaderOpen} />
      </Row>
      <CustomOverlayManager
        onResourceCreate={onResourceCreate}
        onResourceDelete={onResourceDelete}
        onHotKeyAdded={onHotKeyAdded}
        onResourceUpdate={onResourceUpdate}
        data={data.filter((d: MediaTileData) => d.resourceType === 'custom')}
      />
    </Column>
  )
}
