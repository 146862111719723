import { IconName } from '../icons/Icon'
import { Button, ButtonProps } from './Button'

type IconButtonProps = {
  icon: IconName
  iconSize?: number
} & Partial<ButtonProps>

export const IconButton = (props: IconButtonProps) => {
  const {
    width = 32,
    height = 32,
    iconSize,
    icon = 'Star',
    iconColor,
    iconColorWeight = 0,
    color = 'neutral',
    colorWeight = 700,
    ...passthroughProps
  } = props

  return (
    <Button
      icon={icon}
      color={color}
      colorWeight={colorWeight}
      iconSize={iconSize || Math.ceil(height / 1.5)}
      iconColor={iconColor}
      iconColorWeight={iconColorWeight}
      height={height}
      width={width}
      {...passthroughProps}
    />
  )
}

type ToggleButtonProps = {
  isOn: boolean
  onChange: (isOn: boolean) => void
}
export const ToggleIconButton = ({ isOn, onChange }: ToggleButtonProps) => {
  return (
    <IconButton
      icon="Microphone"
      color={isOn ? 'primary' : 'secondary'}
      colorWeight={500}
      onClick={() => {
        onChange(!isOn)
      }}
    />
  )
}
