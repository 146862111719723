import * as React from 'react'
import { Helpers } from '@api.stream/studio-kit'
import { ChatObject } from '@api.stream/studio-kit/types/src/core/types'
import { ChatMessageProps } from '@ui/atoms/chat-message/ChatMessage'
import { IMessageSegment, SegmentType } from '@streamjar/chatparser'
import { usePrimaryHost } from '../utils/is-primary-host'

const url = new RegExp(
  /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
)

// Formats private chat into a format similar to chatparser.
// This enables the ChatMessage to displays things like links
// in a consumable format.
function formatMessage(message: string): IMessageSegment[] {
  const parts: IMessageSegment[] = []

  for (const word of message.split(' ')) {
    const lastPart = parts[parts.length - 1]

    if (url.test(word)) {
      parts.push({ type: SegmentType.Link, text: word, data: { url: word } })
    } else {
      if (lastPart?.type == SegmentType.Text) {
        lastPart.text += ` ${word}`
      } else {
        parts.push({ type: SegmentType.Text, text: word })
      }
    }
  }

  return parts
}

// Groups multiple messages from a single sender together
function groupMessagesBySender(
  messages: ChatObject[],
  currentParticipant: string,
): ChatMessageProps[] {
  const mapped: ChatMessageProps[] = []

  for (const msg of messages) {
    const recentMessage = mapped[mapped.length - 1]

    if (recentMessage && recentMessage.userId === msg.sender) {
      recentMessage.messages.push(formatMessage(msg.content))
    } else {
      mapped.push({
        id: `${+msg.timestamp}-${msg.sender}`,
        userId: msg.sender,
        displayName: msg.displayName,
        isSender: currentParticipant === msg.sender,
        messages: [formatMessage(msg.content)],
        target: [{ type: 'everyone' }],
        timestamp: new Date(msg.timestamp), // Use the first message at the group timestamp.
      })
    }
  }

  return mapped
}

export interface IGuestChatContext {
  // All the current messages in guest chat.
  messages: ChatMessageProps[]

  // Send a message to guest chat.
  send(text: string): void

  // Hook to set whether the user currently has guest chat open.
  setViewingChat(viewing: boolean): void

  // Whether the user can send messages. Typically this will be
  // false if we're disconnected from the room.
  canSend: boolean

  // Whether the active user has missed chat messages
  unread: boolean
}

export const GuestChatContext = React.createContext<IGuestChatContext>({
  messages: [],
  unread: false,
  canSend: false,
  send() {},
  setViewingChat() {},
})
const { StudioContext } = Helpers.React

export const GuestChatProvider = React.memo(
  (props: React.PropsWithChildren<{}>) => {
    const { room } = React.useContext(StudioContext)
    const rawMessages = React.useRef<ChatObject[]>([])
    const [messageHistory, setMessageHistory] = React.useState([])
    const [messages, setMessages] = React.useState<ChatMessageProps[]>([])
    const [unread, setUnread] = React.useState(false)
    const [viewing, setViewing] = React.useState(false)
    const lastViewedAt = React.useRef(new Date())
    const isPrimaryHost = usePrimaryHost()

    React.useEffect(() => {
      if (room) {
        return room.useChatHistory((chat) => {
          chat = chat.map((x) => ({
            ...x,
            displayName: room.getParticipant(x.sender).displayName,
          }))

          if (viewing) {
            setUnread(false)
          } else {
            // Identify any chat messages that haven't been seen.
            const msgs = chat.filter(
              (i) => new Date(i.timestamp) > lastViewedAt.current,
            )

            if (msgs.length) {
              setUnread(true)
            }
          }

          // todo: consider optimizing this by not re-parsing and just append new messages.
          setMessages(
            groupMessagesBySender(
              [...messageHistory, ...chat],
              room.participantId,
            ),
          )

          // Cache raw chat for future requests
          rawMessages.current = [...messageHistory, ...chat]
        })
      }
    }, [room, unread, viewing, messageHistory])

    React.useEffect(() => {
      if (room && !isPrimaryHost) {
        room.sendData({ type: 'Studio2ChatHistoryRequest' })
      }
    }, [room, isPrimaryHost])

    React.useEffect(() => {
      return room?.onData((event, senderId) => {
        // Handle request for chat history, only sending public chat.
        if (event.type === 'Studio2ChatHistoryRequest' && isPrimaryHost) {
          setTimeout(() => {
            room.sendData(
              {
                type: 'Studio2ChatHistory',
                messages: rawMessages.current.filter(
                  (chat) => !chat.recipients || !chat.recipients.length,
                ),
              },
              [senderId],
            )
          }, 100)
        }

        // Apply chat history.
        if (event.type === 'Studio2ChatHistory') {
          setMessageHistory(event.messages)
          // When we get chat history, we'll consider it to be the current state of chat.
          setMessages(groupMessagesBySender(event.messages, room.participantId))
        }
      })
    }, [isPrimaryHost, room])

    const onSend = React.useCallback(
      (message: string) => {
        if (room) {
          room.sendChatMessage({
            message,
          })
        }
      },
      [room],
    )

    const setViewingChat = React.useCallback((viewing: boolean) => {
      setViewing(viewing)

      if (!viewing) {
        lastViewedAt.current = new Date()
      }
    }, [])

    const value: IGuestChatContext = React.useMemo(
      () => ({
        messages,
        send: onSend,
        setViewingChat,
        unread,
        canSend: !!room,
      }),
      [!!room, unread, setViewingChat, onSend, messages],
    )

    return (
      <GuestChatContext.Provider value={value}>
        {props.children}
      </GuestChatContext.Provider>
    )
  },
)
