import * as React from 'react'
import { classes, style } from 'typestyle'
import Button from '../../atoms/buttons/Button'
import { PlatformFilterIcon } from '../../atoms/chat-message/PlatformFilter'
import Text, { TextProps } from '../../atoms/text/Text'
import Box, { Flex } from '../../layout/Box'
import { BasicModal, Position } from './BasicModal'
import * as Colors from '../../helpers/colors'
import { DestinationType } from '../../../types/models/destination'

const inlineLink = style({
  color: Colors.primary(500),
  textDecoration: 'none',
  fontWeight: '700',
  lineHeight: '16.8px',
  $nest: {
    '&:hover': {
      color: Colors.primary(600),
    },
  },
})
const HelpCenter = () => (
  <InlineLink href="https://kb.golightstream.com/" target="_blank">
    Visit our Help Center.
  </InlineLink>
)
const Rule = () => (
  <hr style={{ borderTop: '1px solid #D9D9D9', margin: '14px 0px' }} />
)
const textStyle = style({
  lineHeight: '16.8px',
  padding: '6px 0px 6px 0px',
})

const Title = (props: TextProps) => (
  <Box paddingY={6}>
    <Text.Heading3 {...props} fontWeight={700} />
  </Box>
)
const Paragraph = (props: React.AnchorHTMLAttributes<HTMLParagraphElement>) => (
  <p {...props} className={classes(textStyle, props.className)} />
)
const InlineLink = (props: React.AnchorHTMLAttributes<HTMLAnchorElement>) => (
  <a {...props} className={classes(inlineLink, props.className)} />
)

export const WelcomeModal = React.memo(
  (props: {
    onAction(): void
    onClose(): void
    state: 'trial' | 'notrial' | 'creator'
    isOpen: boolean
  }) => {
    return (
      <BasicModal
        headline="Automagically look like a pro"
        maxWidth={280}
        onClose={props.onClose}
        isOpen={props.isOpen}
        shadowBackground={false}
        position={Position.BottomLeft}
      >
        <Paragraph>
          Select what to show on stream and we'll position everything for you.
        </Paragraph>
        <Paragraph>
          Questions? <HelpCenter />
        </Paragraph>

        {props.state !== 'creator' && (
          <>
            <Rule />

            <Paragraph>
              Explore and see how it feels. When you're ready to go live, you'll
              need a{'\u00a0'}
              <InlineLink href="#">Lightstream Creator Account.</InlineLink>
              {props.state === 'trial' && (
                <> Get a free 7 day trial and cancel anytime.</>
              )}
            </Paragraph>

            {props.state === 'trial' && (
              <Button
                marginTop={12}
                text="Start your free trial"
                onClick={props.onAction}
              />
            )}
            {props.state === 'notrial' && (
              <Button marginTop={12} text="Upgrade" onClick={props.onAction} />
            )}
          </>
        )}
      </BasicModal>
    )
  },
)

export const NoStudio1Modal = React.memo(
  (props: {
    context: 'going-live' | 'setup'
    onSignup(): void
    onLink(): void
    onClose(): void
    isOpen: boolean
  }) => {
    const headline =
      props.context === 'going-live'
        ? 'Ready to go live? You need a Lightstream Creator subscription.'
        : 'Studio 2 Account Setup'

    return (
      <BasicModal
        isOpen={props.isOpen}
        headline={headline}
        maxWidth={550}
        onClose={props.onClose}
      >
        <Flex direction="row">
          <Box>
            <Title fontWeight={700} text="New Account" />
            <Paragraph>
              Create a new account if you've never used Lightstream before.
            </Paragraph>
            <Button
              text="Create new account"
              onClick={props.onSignup}
              marginTop={6}
            />
          </Box>
          <Box>
            <Title fontWeight={700} text="Link to Your Lightstream Account" />
            <Paragraph>
              Manage your Studio 1 projects, settings and existing subscription.
            </Paragraph>
            <Button
              text="Link to existing account"
              onClick={props.onLink}
              marginTop={6}
            />
          </Box>
        </Flex>

        <Rule />

        <Paragraph>
          Questions? <HelpCenter />
        </Paragraph>
      </BasicModal>
    )
  },
)

export const GoLiveStudio1Trial = React.memo(
  (props: { isOpen: boolean; onAction(): void; onClose(): void }) => {
    return (
      <BasicModal
        headline="Ready to go live? You need a Lightstream Creator subscription."
        maxWidth={350}
        onClose={props.onClose}
        isOpen={props.isOpen}
      >
        <Paragraph>
          Enjoy a free 7-day trial with access to all features. Cancel anytime.
        </Paragraph>
        <Button
          marginTop={12}
          text="Start your free trial"
          onClick={props.onAction}
        />

        <Rule />

        <Paragraph>
          Questions? <HelpCenter />
        </Paragraph>
      </BasicModal>
    )
  },
)

export const GoLiveStudio1NoTrial = React.memo(
  (props: { isOpen: boolean; onAction(): void; onClose(): void }) => {
    return (
      <BasicModal
        headline="Ready to go live? You need a Lightstream Creator subscription."
        maxWidth={350}
        onClose={props.onClose}
        isOpen={props.isOpen}
      >
        <Flex direction="row" align="center">
          <Button text="Upgrade" onClick={props.onAction} />
          <Flex direction="column" paddingLeft={20}>
            <Paragraph style={{ marginBottom: -6, marginTop: -6 }}>
              Questions?
            </Paragraph>
            <HelpCenter />
          </Flex>
        </Flex>
      </BasicModal>
    )
  },
)

export const SetupLinkPlatform = React.memo(
  (props: {
    isOpen: boolean
    onAction(type: DestinationType): () => void
    onClose(): void
  }) => {
    return (
      <BasicModal
        headline="Studio 2 Account Setup"
        maxWidth={550}
        onClose={props.onClose}
        isOpen={props.isOpen}
      >
        <Title text="Link to Your Lightstream Account" />
        <Paragraph>Log in with:</Paragraph>

        <Flex direction="row">
          <div
            onClick={props.onAction(DestinationType.Twitch)}
            style={{ cursor: 'pointer' }}
          >
            <PlatformFilterIcon active={true} platform="twitch" variant={0} />
          </div>
          <div
            style={{ marginLeft: 20, cursor: 'pointer' }}
            onClick={props.onAction(DestinationType.YouTube)}
          >
            <PlatformFilterIcon active={true} platform="youtube" variant={0} />
          </div>
          <div
            style={{ marginLeft: 20, cursor: 'pointer' }}
            onClick={props.onAction(DestinationType.Facebook)}
          >
            <PlatformFilterIcon active={true} platform="facebook" variant={0} />
          </div>
        </Flex>

        <Rule />

        <Paragraph>
          Questions? <HelpCenter />
        </Paragraph>
      </BasicModal>
    )
  },
)

export const SetupNoAccount = React.memo(
  (props: { isOpen: boolean; onAction(): void; onClose(): void }) => {
    return (
      <BasicModal
        headline="Studio 2 Account Setup"
        maxWidth={550}
        onClose={props.onClose}
        isOpen={props.isOpen}
      >
        <Title fontWeight={700} text="Link to Your Lightstream Account" />
        <Paragraph>No Lightstream account found with that login.</Paragraph>

        <Flex direction="row" marginTop={6}>
          <Button text="Try a different login" onClick={props.onAction} />
          <Button
            text="Create new account"
            marginLeft={12}
            onClick={props.onAction}
            appearance="outline"
          />
        </Flex>

        <Rule />

        <Paragraph>
          Questions? <HelpCenter />
        </Paragraph>
      </BasicModal>
    )
  },
)
