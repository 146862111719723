import * as React from 'react'
import Button from '@ui/atoms/buttons/Button'
import TextInput from '@ui/atoms/inputs/TextInput'
import Text from '@ui/atoms/text/Text'
import Box, { Flex } from '@ui/layout/Box'
import { useApp } from '../context/app-context'
import { MediaTileData } from '@ui/atoms/media/Mediatile'
import { UsageType } from 'studio-types/models/resource'
import { Resource } from '../types'
import { CustomOverlay, CustomOverlayData } from '@ui/components/CustomOverlay'
import { MediaContext } from '../context/media-context'
import { WithInfo } from '@ui/atoms/FloatingMenu/FloatingMenu'
import useToaster from '@ui/hooks/useToaster'
import TooltipTypes from '@ui/atoms/FloatingMenu/TooltipTypes'
import { UserContext } from '../context/user-context'

interface MeidaDataProps {
  data: CustomOverlayData[]
  onResourceUpdate(key: string, resource: Partial<Resource>): void
  onResourceCreate(resource: Partial<Resource>): void
  onHotKeyAdded(data: MediaTileData, state: boolean): void
  onResourceDelete(key: string): void
}

const re = new RegExp('^(http|https)://', 'i')

function extractRootDomainNoExt(url: string) {
  var domain = new URL(url).hostname,
    splitArr = domain.split('.'),
    arrLen = splitArr.length

  if (arrLen == 2) {
    domain = splitArr[0]
  } else if (arrLen > 2) {
    domain = splitArr[arrLen - 2]
    //check to see if it's using a Country Code Top Level Domain (ccTLD) (i.e. ".me.uk")
    if (splitArr[arrLen - 2].length == 2 && splitArr[arrLen - 1].length == 2) {
      domain = splitArr[arrLen - 3]
    }
  }
  return domain
}

export const CustomOverlayManager = ({
  data,
  onResourceDelete,
  onResourceUpdate,
  onResourceCreate,
  onHotKeyAdded,
}: MeidaDataProps) => {
  const { projectCommands, track } = useApp()
  const user = React.useContext(UserContext)
  const { setImageOverlay, imageOverlay } = React.useContext(MediaContext)
  const [showOtherFields, setShowOtherFields] = React.useState<Boolean>(false)
  const [toaster, contextHolder] = useToaster({
    maxCount: 1,
  })
  const [newOverlay, setNewOverlay] = React.useState({
    title: 'New Overlay',
    url: '',
  })

  const keyDownHandler = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      console.log('User pressed: ', e.key)

      if (e.key === 'Enter') {
        e.preventDefault()

        // 👇️ call submit function here
        addOverlay()
      }
    },
    [newOverlay?.url, newOverlay?.title],
  )

  const addOverlay = React.useCallback(async () => {

    if (!newOverlay?.url.trim().length) {
      return
    }

    if (!re.test(newOverlay?.url)) {
      toaster.open({
        content:
          'The URL is invalid. Make sure the URL is correct and try again.',
        duration: 4,
        icon: null,
        closable: true,
        style: {
          backgroundColor: 'secondary',
          backgroundColorWeight: 500,
        },
      })
      return
    }
    
    (document.activeElement as HTMLElement).blur()
    const rootDomain = extractRootDomainNoExt(newOverlay?.url)

    try {
      const requestBody = {
        integration: rootDomain,
        url: newOverlay?.url,
      }

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody),
      }

      const overlayResponse = await fetch(
        'api/studio2/overlay-details',
        requestOptions,
      )

      const responseJson = await overlayResponse?.json()

      onResourceCreate({
        ...newOverlay,
        naturalWidth: responseJson?.width || 0,
        naturalHeight: responseJson?.height || 0,
        usageType: UsageType.Overlay,
        type: 'custom',
      })
      setShowOtherFields(false)
      setNewOverlay({ title: 'New Overlay', url: '' })

      
    } catch (err) {}
  }, [newOverlay?.url, newOverlay?.title])

  const onActiveCustomOverlay = React.useCallback(
    (id: string) => {
      track('SetOverlay', { type: 'custom' })
      if (id === imageOverlay) {
        projectCommands.removeCustomOverlay(id)
        setImageOverlay(null)
      } else {
        const overlay = data.find((overlay: MediaTileData) => overlay.id === id)
        projectCommands.addCustomOverlay(id, {
          src: overlay.url,
          width: overlay?.width ?? null,
          height: overlay?.height ?? null,
        })
        setImageOverlay(id)
      }
    },
    [imageOverlay, setImageOverlay, data],
  )

  return (
    <Box height="100%" width="100%" marginTop={5}>
      {data.map((overlay) => (
        <CustomOverlay
          key={overlay.id}
          data={overlay}
          active={imageOverlay ? overlay.id === imageOverlay : null}
          onResourceUpdate={onResourceUpdate}
          onResourceDelete={onResourceDelete}
          onHotKeyAdded={onHotKeyAdded}
          onClick={onActiveCustomOverlay}
        />
      ))}

      <Box>
        <form>
          {(showOtherFields || newOverlay?.url) && (
            <TextInput
              value={newOverlay?.title}
              onChange={(e) =>
                setNewOverlay({ ...newOverlay, title: e.target.value })
              }
              onKeyDown={keyDownHandler}
              label={
                <Text.Label
                  marginTop={10}
                  text={'Name'}
                  color="neutral"
                  fontSize={14}
                  colorWeight={500}
                  fontWeight={300}
                />
              }
              paddingY={16}
              paddingX={14}
              maxLength={200}
              color="neutral"
              colorWeight={500}
            />
          )}
          <TextInput  
            value={newOverlay.url}
            onChange={(e) =>
              setNewOverlay({ ...newOverlay, url: e.target.value })
            }
            onFocus={(e) => {
              setShowOtherFields(true)
            }}
            onKeyDown={keyDownHandler}
            label={
              <Text.Label
                marginTop={10}
                text={
                  showOtherFields || newOverlay?.url
                    ? 'URL'
                    : 'Add a URL overlay'
                }
                color="neutral"
                fontSize={14}
                colorWeight={500}
                fontWeight={300}
              />
            }
            paddingY={16}
            paddingX={14}
            maxLength={200}
            color="neutral"
            colorWeight={500}
          />
        </form>
      </Box>

      {(showOtherFields || newOverlay?.url) && (
        <Flex direction="row" justify="space-between" align="center">
          <Box>
            <WithInfo
              padding='0px'
              lingerMS={150}
              message={
                <TooltipTypes.Default
                  mediaLink={user?.tooltipHelpers?.urloverlay}
                  link="https://golightstream-studio2.kustomer.help/en_us/how-do-i-add-custom-url-overlays-BJZZkc5Jo"
                  linkTitle="Learn more"
                  title="Alerts & Overlays"
                  description={`Engage your audience with external alerts and overlay services. Content will load on top of your other media at the max resolution allowed by your plan: ${user?.resolution?.x} x ${user?.resolution?.y}.`}
                />
              }
              delay={0}
              position="top"
              node={
                <Text.Label
                  icon="LayerInfo"
                  text={`Set overlay to ${user.resolution.y}p`}
                  color="neutral"
                  colorWeight={400}
                  style={{
                    padding: '4px',
                    marginTop: '4px',
                  }}
                  fontSize={10}
                  fontWeight={300}
                />
              }
            />
          </Box>
          <Flex gap={10} align="center">
            <Button
              text="Cancel"
              appearance="text"
              color="neutral"
              colorWeight={0}
              height={33}
              width={55}
              fontSize={14}
              marginTop={8}
              onClick={() => {
                setShowOtherFields(false)
                setNewOverlay({ title: 'New Overlay', url: '' })
              }}
            />
            <Button
              text="Save"
              height={33}
              width={55}
              fontSize={14}
              marginTop={8}
              onClick={addOverlay}
            />
          </Flex>
        </Flex>
      )}
      {contextHolder}
    </Box>
  )
}
