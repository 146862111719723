import React, { useState, useContext } from 'react'
import { Helpers } from '@api.stream/studio-kit'
import { Uploader } from './Uploader'
import { Column, Row } from '@ui/layout/Box'
import MediaTile, { MediaTileData } from '@ui/atoms/media/Mediatile'
import { UsageType } from '../../types/models/resource'
import { Resource, trigger } from '../utils/events'
import { UploadContext } from '../context/upload-context'
import Text from '@ui/atoms/text/Text'
import Accordion from '@ui/atoms/accordion/Accordion'
import { MediaContext } from '../context/media-context'
import { useApp } from '../context/app-context'
import { useProjectProp } from '@ui/hooks/useProjectProp'
import { HotKey } from '@ui/components/HotKeyPanel'
import useMediaData from '@ui/hooks/useMediaData'
const { StudioContext } = Helpers.React

interface MediaData {
  id: string
  type: string
  url: string
  isGlobal: boolean
  isFavorite: boolean
}
const defaultBackground = {
  id: '570d9bb3-5b73-4ec8-ab23-30d222b4d6a5',
  src: ' https://studio.golightstream.com/images/polygons.jpg',
}
const deleteResource = async (key: string) => {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  }
  const response = await fetch(`/api/resources/${key}`, requestOptions)
  return await response.json()
}

const updateResource = async (
  key: string,
  postBody: Partial<MediaTileData>,
) => {
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(postBody),
  }
  const response = await fetch(
    `/api/resources/studio2/resource/${key}`,
    requestOptions,
  )
  return await response.json()
}

export const BackgroundManager = () => {
  const { background, setBackground, remainingVideoLoopTime } =
    useContext(MediaContext)
  const { uppy, setFileType, setUsageType } = useContext(UploadContext)
  const { projectCommands, track, project } = useApp()
  const { studio } = useContext(StudioContext)
  const [selectedContext, setSelecedContext] = useState(null)
  const [hotkeys, setHotKeys] = useProjectProp<HotKey[]>('hotkeys')
  const [getMediaData] = useMediaData()
  const mediaData = getMediaData(['image', 'video'], UsageType.Background)

  React.useEffect(() => {
    if (!background) {
      projectCommands.setBackgroundImage2(defaultBackground.id, {
        src: defaultBackground.src,
      })
    }
  }, [background])

  React.useEffect(() => {
    /** a patch to hide the backward compatible background */
    const root = project.scene.getRoot()
    const background = root.children.find((x) => x.props.id === 'bg')
    let backgroundContainer = background?.children?.find(
      (x) => x.props.id === 'bg-image',
    )
    if (backgroundContainer?.props?.fields?.style?.opacity) {
      studio.Command.updateNode({
        nodeId: backgroundContainer.id,
        props: {
          fields: {
            style: {
              opacity: 0,
            },
          },
        },
      })
    }
  }, [])

  const onUploaderOpen = () => {
    setFileType('media')
    setUsageType(UsageType.Background)
  }

  const TitleLabel = () => (
    <Text.Heading2
      style={{ textTransform: 'capitalize' }}
      marginLeft={8}
      text={UsageType.Background}
    />
  )

  return (
    <Column style={{ overflow: 'hidden' }}>
      <Column grow={1} shrink={1} width="100%">
        <Accordion icon="Panorama" active={true} title={<TitleLabel />}>
          <Row
            style={{
              width: '100%',
              alignItems: 'center',
              display: 'grid',
              gridGap: '5px',
              gridTemplateColumns: 'repeat(auto-fill, minmax(115px, 1fr))',
            }}
          >
            {mediaData?.map((d: MediaTileData) => {
              return (
                <MediaTile
                  key={d.id}
                  data={d}
                  isOpen={selectedContext == d.id}
                  setSelectedContext={setSelecedContext}
                  remaningDuration={
                    background === d.id && remainingVideoLoopTime
                  }
                  active={background === d.id}
                  dimmed={
                    background &&
                    background !== defaultBackground.id &&
                    background !== d.id
                  }
                  showFavorite={true}
                  showContext={true}
                  onFavorite={async (key: string, isFavorite: boolean) => {
                    if (isFavorite) {
                      if (hotkeys && hotkeys.length >= 4) {
                        return
                      }
                    }

                    if (!isFavorite) {
                      const filterdHotKeys = hotkeys?.filter(
                        (h) => h.hotKeyId !== d.id,
                      )
                      setHotKeys([...filterdHotKeys])
                    } else {
                      setHotKeys([
                        ...(hotkeys ?? []),
                        {
                          loop: true,
                          type: d.resourceType,
                          hotKeyId: d.id,
                        },
                      ])
                    }
                  }}
                  onClick={(key: string) => {
                    const media = mediaData.find(
                      (image: MediaData) => image.id === key,
                    )
                    track('SetBackground')
                    if (media.id === background) {
                      setBackground(defaultBackground.id)
                      projectCommands.setBackgroundImage2(
                        defaultBackground.id,
                        {
                          src: defaultBackground.src,
                        },
                      )
                    } else {
                      setBackground(media.id)
                      media.type === 'image'
                        ? projectCommands.setBackgroundImage2(media.id, {
                            src: media.url,
                          })
                        : projectCommands.setBackgroundVideo2(media.id, {
                            src: media.url,
                            loop: true,
                          })
                    }
                  }}
                  onDelete={async (key: string) => {
                    const resource = await deleteResource(key)
                    trigger(Resource.Removed, { resource })
                  }}
                />
              )
            })}
            <Uploader
              usageType={UsageType.Background}
              uppy={uppy}
              onOpen={onUploaderOpen}
            />
          </Row>
        </Accordion>
      </Column>
    </Column>
  )
}
