import React, { useEffect, useMemo, useRef, useState } from 'react'
import { DashboardModal } from '@uppy/react'
import { Resource, trigger } from '../utils/events'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import Button from '@ui/atoms/buttons/Button'
import { UsageType } from '../../types/models/resource'
import './uploader.css'

export const Uploader = (props: {
  usageType: UsageType
  onOpen: () => void
  uppy: any
}) => {
  const { usageType, uppy, onOpen } = props
  const [open, setOpen] = useState(false)

  const openModal = () => {
    onOpen()
    setOpen(true)
  }
  const closeModal = () => {
    uppy.reset();
    setOpen(false)
  }

  return (
    <div>
      <Button
        appearance="outline"
        icon="Plus"
        color="primary"
        style={{
          borderWidth: '1px',
        }}
        onClick={openModal}
        colorWeight={500}
        iconSize={24}
        width="100%"
        height={68}
      />

      {/* <button onClick={openModal}>Upload an image</button> */}
      {/* @ts-ignore */}

      <DashboardModal
        id={usageType}
        uppy={uppy}
        open={open}
        theme="dark"
        onRequestClose={closeModal}
        closeModalOnClickOutside
      />
    </div>
  )
}
