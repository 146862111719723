import { Helpers, SDK } from '@api.stream/studio-kit'
import Button from '@ui/atoms/buttons/Button'
import { Column, Flex, Row } from '@ui/layout/Box'
import ErrorBoundary from '@ui/layout/ErrorBoundary'
import {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { AppContext, StudioContext, useApp } from '../context/app-context'
import Text from '@ui/atoms/text/Text'
import Icon, { SVG } from '@ui/atoms/icons/Icon'
import LoadingDots from '@ui/atoms/animations/LoadingDots'
import { SidebarHeading } from '../host/sidebar/shared'
import { ParticipantCard } from '@ui/components/ParticipantCard'
import { hasPermission, Permission } from '../utils/permissions'
import { getSignalDisplay } from '@ui/atoms/icons/SignalBars'
import useEffectOnce from '@ui/hooks/useEffectOnce'
import { UserType } from '../types'
import { DISPLAY_NAME_KEY } from '@ui/components/CameraSettings'
import useToaster, { ToasterAPI } from '@ui/hooks/useToaster'
import { NormalModal } from '@ui/atoms/FloatingMenu/Modal'
import { AdditionalCameraSettings } from '@ui/components/AdditionalCameraSettings'

const { Room } = Helpers

const border = `1px solid black`

const getUrl = () => window.location.protocol + '//' + window.location.host

const InviteButton = ({ toast }: { toast: ToasterAPI }) => {
  const [guestUrl, setGuestUrl] = useState('')
  const { studio } = useContext(StudioContext)
  const { project } = useApp()

  // Generate project links
  useEffect(() => {
    studio
      .createGuestLink(getUrl() + '/guest', { projectId: project.id })
      .then(setGuestUrl)
  }, [])

  return (
    <Button
      text="Copy guest invite link"
      textTransform="capitalize"
      height={35}
      width="100%"
      onClick={async () => {
        try {
          await navigator.clipboard.writeText(guestUrl)
          toast.open({
            content: 'Copied to clipboard',
          })
          // TODO: Trigger success toast
        } catch (e) {
          console.warn('Failed to write to clipboard')
          toast.open({
            content: 'Failed to copy to clipboard',
          })
          // TODO: Trigger failure toast
        }
      }}
    />
  )
}

export const GreenRoom = () => {
  const app = useContext(AppContext)
  const { room } = useContext(StudioContext)

  const [disposeSettings, setDisposeSettings] = useState(() => () => {})
  const [selectedExternalTrack, setSelectedExternalTrack] =
    useState<SDK.Track>(null)
  const webcamId =
    selectedExternalTrack?.mediaStreamTrack?.getSettings()?.deviceId
  const [toast, contextHolder] = useToaster({
    maxCount: 1,
  })

  return (
    <Column width={312} style={{ borderRight: border, overflow: 'hidden' }}>
      <SidebarHeading
        icon="Users"
        text="Green Room"
        infoSelector="InfoGreenRoom"
      />
      <Column
        padding={16}
        width="100%"
        grow={1}
        shrink={1}
        style={{ overflowY: 'auto' }}
      >
        {app.userType !== UserType.GUEST && (
          <Flex shrink={0} marginBottom={12} width="100%">
            <InviteButton toast={toast} />
          </Flex>
        )}
        {app.userType !== UserType.GUEST && (
          <Flex shrink={0} marginBottom={12} width="100%">
            <Text.Body text="Select guests below to add them to the stage" />
          </Flex>
        )}
        {app.userType === UserType.GUEST && (
          <Flex shrink={0} marginBottom={16} width="100%">
            <Text.Body text="There may be other guests waiting in the Green Room that are not visible. You can use the Guest Chat feature to communicate with them." />
          </Flex>
        )}
        <Flex width="100%">
          <ErrorBoundary>
            <Participants setSelectedExternalTrack={setSelectedExternalTrack} />
            {selectedExternalTrack && (
              <NormalModal
                isOpen={Boolean(webcamId)}
                close={() => {
                  setSelectedExternalTrack(null)
                }}
              >
                <Column padding={20}>
                  <Flex marginBottom={20}>
                    <Text.Heading1 text="Settings" />
                  </Flex>
                  <AdditionalCameraSettings
                    startingTrackId={selectedExternalTrack?.id}
                    isEdit={Boolean(webcamId)}
                    startingWebcamId={webcamId}
                    nameDisabled={
                      !hasPermission(
                        app?.project?.role,
                        Permission.ManageGuests,
                      )
                    }
                    setDispose={(fn) => setDisposeSettings(() => fn)}
                    onComplete={(settings) => {
                      disposeSettings()
                      window.setTimeout(async () => {
                        // Add the screen to the room
                        const participant = room.getParticipant(
                          room?.participantId,
                        )

                        if (participant) {
                          if (
                            hasPermission(
                              app?.project.role,
                              Permission.ManageGuests,
                            )
                          ) {
                            const externalTracks =
                              participant?.meta?.externalTracks || []
                            const isExternalCamExist = externalTracks?.some(
                              (t: string) => t === selectedExternalTrack?.id,
                            )
                            if (!isExternalCamExist) {
                              externalTracks.push(selectedExternalTrack?.id)
                            }
                            /* Setting the participant metadata to the room. */
                            room.setParticipantMetadata(room?.participantId, {
                              ...participant.meta,
                              externalTracks,
                              [selectedExternalTrack?.id]: {
                                isMirrored: settings?.isMirrored,
                                displayName:
                                  settings.displayName ||
                                  (app.userType === UserType.HOST
                                    ? 'Host'
                                    : 'Guest'),
                              },
                            })
                          }
                        }
                        setSelectedExternalTrack(null)
                      })
                    }}
                  />
                </Column>
              </NormalModal>
            )}
          </ErrorBoundary>
        </Flex>
      </Column>

      {contextHolder}
    </Column>
  )
}

export const Participants = ({
  setSelectedExternalTrack,
}: {
  setSelectedExternalTrack: (track: SDK.Track) => void
}) => {
  const { room } = useContext(StudioContext)
  const { userType, projectCommands } = useContext(AppContext)
  const [participants, setParticipants] = useState<SDK.Participant[]>([])
  const [addDisabled, setAddDisabled] = useState(false)
  const [externalTracks, setExternalTracks] = useState<SDK.Track[]>([])

  useEffect(() => {
    if (!room) return

    return room.useTracks((tracks) =>
      setExternalTracks(
        tracks.filter(
          (track) => track?.isExternal === true && track?.type === 'camera',
        ),
      ),
    )
  }, [room])

  // Listen for room participants
  useEffect(() => {
    if (!room) return
    return room.useParticipants((participants) => {
      // An impersonator can see themselves in the webrtc room.
      setParticipants(
        participants.filter(
          (role) =>
            role.role !== SDK.Role.ROLE_IMPERSONATE &&
            (userType === UserType.GUEST ? role.isSelf : true),
        ),
      )

      // Prune non-existent guests from the project
      if (userType === UserType.HOST) {
        projectCommands.pruneParticipants()
      }
    })
  }, [room])

  return (
    <Column width="100%">
      {room ? (
        <Fragment>
          {participants.map((x) => (
            <Flex key={x.id} width="100%">
              <Participant
                participant={x}
                addDisabled={addDisabled}
                setAddDisabled={setAddDisabled}
              />
            </Flex>
          ))}
          {/* Only show additional camera card if active */}

          {userType === UserType.HOST &&
            externalTracks.map((track) => (
              <Flex marginBottom={12} width="100%" key={track?.id}>
                <ParticipantAdditionalCamera
                  onOpenSettings={(track: SDK.Track) => {
                    setSelectedExternalTrack(track)
                  }}
                  participantTrack={room.getTrack(track.id)}
                  addDisabled={addDisabled}
                  setAddDisabled={setAddDisabled}
                />
              </Flex>
            ))}
        </Fragment>
      ) : (
        <Flex align="center" justify="center" width="100%" height={100}>
          <SVG color="primary" colorWeight={400} width={32} svg={LoadingDots} />
        </Flex>
      )}
    </Column>
  )
}

export const Participant = ({
  participant,
  addDisabled,
  setAddDisabled,
}: ParticipantProps) => {
  const { room, studio } = useContext(StudioContext)
  const tracks = participant.trackIds.map(room.getTrack)
  const screenshare = tracks.find((x) => x?.type === 'screen_share')

  return (
    <Flex width="100%" direction="column">
      {/* Always show camera card even if none exists */}
      <Flex marginBottom={12} width="100%">
        <ParticipantCamera
          participant={participant}
          addDisabled={addDisabled}
          setAddDisabled={setAddDisabled}
        />
      </Flex>

      {/* Only show screenshare card if active */}
      {screenshare && (
        <Flex marginBottom={12} width="100%">
          <ParticipantScreenshare
            addDisabled={addDisabled}
            setAddDisabled={setAddDisabled}
            participant={participant}
          />
        </Flex>
      )}
    </Flex>
  )
}

type ParticipantProps = {
  participant: SDK.Participant
  addDisabled: boolean
  setAddDisabled: (disabled: boolean) => void
}

type ParticipantTrackProps = {
  participantTrack: SDK.Track
  addDisabled: boolean
  setAddDisabled: (disabled: boolean) => void
}

export const ParticipantAdditionalCamera = ({
  participantTrack,
  addDisabled,
  setAddDisabled,
  onOpenSettings,
}: ParticipantTrackProps & {
  onOpenSettings: (track: SDK.Track) => void
}) => {
  const app = useContext(AppContext)
  const { room } = useContext(StudioContext)
  const participant = room.getParticipant(participantTrack?.participantId)
  const { project, projectCommands, track } = useApp()
  const [srcObject] = useState(() => new MediaStream([]))
  const [isOnStream, setIsOnStream] = useState(false)
  const [isShowcase, setIsShowcase] = useState(false)
  // Stream output is muted, independent of whether mic track is active
  const [isMutedOnStream, setIsMutedOnStream] = useState(true)
  // Stream output is hidden, independent of whether camera track is active
  const [isHiddenOnStream, setIsHiddenOnStream] = useState(false)
  const ref = useRef<HTMLVideoElement>()

  // const tracks = participant.trackIds.map(room.getTrack)
  const isMirrored = participant?.meta[participantTrack.id]?.isMirrored
  const displayName = participant.meta[participantTrack.id]?.displayName
  //const microphoneTrack = tracks.find((x) => x.type === 'microphone')

  const webcamMuted = Boolean(participantTrack?.isMuted)
  //const microphoneMuted = Boolean(microphoneTrack?.isMuted)

  const { id, connectionQuality, isSelf, isSpeaking, meta } = participant

  const eventData = { guestId: participant.id }

  // Monitor whether the participant has been removed from the stream
  //  from some other means (e.g. dragged off canvas by host)
  useEffectOnce(() => {
    return projectCommands.useParticipantState(
      participantTrack?.id,
      (x) => {
        if (x) {
          setIsOnStream(Boolean(x))
          setIsMutedOnStream(Boolean(x?.isMuted))
          setIsHiddenOnStream(Boolean(x?.isHidden))
        }
      },
      'camera',
    )
  }, [])

  // Monitor the project's showcase to determine whether this
  //  participant/type is active
  useEffect(
    () =>
      projectCommands.useShowcase((showcase) => {
        setIsShowcase(
          showcase?.participantId === participantTrack?.id &&
            showcase.type === 'camera',
        )
      }),
    [],
  )

  useEffect(() => {
    // Replace the tracks on the existing MediaStream
    Room.updateMediaStreamTracks(srcObject, {
      video: participantTrack?.mediaStreamTrack,
    })
  }, [participantTrack?.mediaStreamTrack, isHiddenOnStream])

  useEffect(() => {
    if (ref.current) {
      ref.current.srcObject = srcObject
    }
  }, [ref?.current, srcObject])

  return (
    <ParticipantCard
      isExternal={participantTrack?.isExternal}
      isMuted={isMutedOnStream}
      isHidden={isHiddenOnStream}
      isOnStage={isOnStream}
      isAddDisabled={addDisabled}
      isMirrored={isMirrored}
      hasVideo={participantTrack && !webcamMuted}
      canRename={hasPermission(project.role, Permission.ManageGuests)}
      canKick={true}
      // Muting and hiding is handled through Layout API project updates
      canClose={hasPermission(project.role, Permission.ManageGuests)}
      canHide={hasPermission(project.role, Permission.UpdateProject)}
      canMute={false}
      canAdd={hasPermission(project.role, Permission.UpdateProject)}
      canOpenSettings={hasPermission(project.role, Permission.UpdateProject)}
      signal={getSignalDisplay(connectionQuality)}
      name={displayName || 'Guest'}
      onKick={() => {
        room.removeTrack(participantTrack?.id)

        const meta = participant?.meta
        delete meta[participantTrack?.id]
        const externalTracks = participant?.meta?.externalTracks?.filter(
          (track: string) => {
            track !== participantTrack?.id
          },
        )

        room.setParticipantMetadata(participant?.id, {
          ...meta,
          externalTracks,
        })
      }}
      onOpenSettings={() => {
        onOpenSettings(participantTrack)
      }}
      onNameChange={(name) => {
        if (participant.isSelf) {
          try {
            localStorage.setItem(DISPLAY_NAME_KEY, name)
          } catch (e) {}
        }
        track('SetAdditionalCameraName', eventData)
        room.setParticipantMetadata(participant.id, {
          ...participant.meta,
          [participantTrack?.id]: {
            displayName: name,
          },
        })
      }}
      onMuteChange={(isMuted) => {
        isMuted
          ? track('MuteAdditionalCamera', eventData)
          : track('UnmuteAdditionalCamera', eventData)
        projectCommands.setParticipantMuted(participantTrack?.id, true)
      }}
      onHiddenChange={(isHidden) => {
        isHidden
          ? track('HideAdditionalCamera', eventData)
          : track('ShowAdditionalCamera', eventData)
        projectCommands.setParticipantHidden(participantTrack?.id, isHidden)
      }}
      onAddToStage={(props = {}) => {
        track('AddAdditionalCamera', eventData)
        setAddDisabled(true)
        projectCommands
          .addParticipantTrack(participantTrack?.id, props, 'camera')
          .finally(() => {
            setAddDisabled(false)
          })
      }}
      onRemoveFromStage={() => {
        track('RemoveAdditionalCamera', eventData)
        projectCommands.removeParticipantTrack(participantTrack?.id, 'camera')
      }}
      label="External Camera"
      video={
        <video
          // Mute because audio is only communicated through the compositor
          muted={true}
          ref={ref}
          autoPlay={true}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      }
    />
  )
}

export const ParticipantCamera = ({
  participant,
  addDisabled,
  setAddDisabled,
}: ParticipantProps) => {
  const { room } = useContext(StudioContext)
  const { project, projectCommands, track } = useApp()
  const [srcObject] = useState(() => new MediaStream([]))
  const [isOnStream, setIsOnStream] = useState(false)
  const [isShowcase, setIsShowcase] = useState(false)
  // Stream output is muted, independent of whether mic track is active
  const [isMutedOnStream, setIsMutedOnStream] = useState(false)
  // Stream output is hidden, independent of whether camera track is active
  const [isHiddenOnStream, setIsHiddenOnStream] = useState(false)
  const ref = useRef<HTMLVideoElement>()

  const tracks = participant.trackIds.map(room.getTrack)
  const webcamTrack = tracks.find(
    (x) => x?.type === 'camera' && x?.isExternal === false,
  )

  const isMirrored = participant?.meta?.isMirrored

  const microphoneTrack = tracks.find((x) => x?.type === 'microphone')

  const webcamMuted = Boolean(webcamTrack?.isMuted)
  const microphoneMuted = Boolean(microphoneTrack?.isMuted)

  const { id, displayName, connectionQuality, isSelf, isSpeaking } = participant
  const eventData = { guestId: participant.id }

  // Monitor whether the participant has been removed from the stream
  //  from some other means (e.g. dragged off canvas by host)
  useEffectOnce(() => {
    return projectCommands.useParticipantState(
      id,
      (x) => {
        if (x) {
          setIsOnStream(Boolean(x))
          setIsMutedOnStream(Boolean(x?.isMuted))
          setIsHiddenOnStream(Boolean(x?.isHidden))
        }
      },
      'camera',
    )
  }, [])

  // Monitor the project's showcase to determine whether this
  //  participant/type is active
  useEffect(
    () =>
      projectCommands.useShowcase((showcase) => {
        setIsShowcase(
          showcase?.participantId === id && showcase.type === 'camera',
        )
      }),
    [],
  )

  useEffect(() => {
    // Replace the tracks on the existing MediaStream
    Room.updateMediaStreamTracks(srcObject, {
      video: webcamTrack?.mediaStreamTrack,
      audio: microphoneTrack?.mediaStreamTrack,
    })
  }, [
    webcamTrack?.mediaStreamTrack,
    microphoneTrack?.mediaStreamTrack,
    isHiddenOnStream,
  ])

  useEffectOnce(
    () => {
      ref.current.srcObject = srcObject
    },
    [ref?.current, srcObject],
    ([currentRef]) => Boolean(currentRef),
  )

  return (
    <ParticipantCard
      isMuted={isMutedOnStream}
      isHidden={isHiddenOnStream}
      isOnStage={isOnStream}
      isAddDisabled={addDisabled}
      isMirrored={isMirrored}
      hasVideo={webcamTrack && !webcamMuted}
      canRename={hasPermission(project.role, Permission.ManageGuests)}
      canKick={hasPermission(project.role, Permission.ManageGuests) && !isSelf}
      // Muting and hiding is handled through Layout API project updates
      canClose={hasPermission(project.role, Permission.ManageGuests)}
      canHide={hasPermission(project.role, Permission.UpdateProject)}
      canMute={hasPermission(project.role, Permission.UpdateProject)}
      canAdd={hasPermission(project.role, Permission.UpdateProject)}
      signal={getSignalDisplay(connectionQuality)}
      name={displayName || 'Guest'}
      onKick={() => {
        room.kickParticipant(participant.id)
      }}
      onNameChange={(name) => {
        if (participant.isSelf) {
          try {
            localStorage.setItem(DISPLAY_NAME_KEY, name)
          } catch (e) {}
        }
        track('SetGuestName', eventData)
        room.setParticipantMetadata(participant.id, {
          ...participant.meta,
          displayName: name,
        })
      }}
      onMuteChange={(isMuted) => {
        isMuted
          ? track('MuteGuestCamera', eventData)
          : track('UnmuteGuestCamera', eventData)
        projectCommands.setParticipantMuted(id, isMuted)
      }}
      onHiddenChange={(isHidden) => {
        isHidden
          ? track('HideGuestCamera', eventData)
          : track('ShowGuestCamera', eventData)
        projectCommands.setParticipantHidden(id, isHidden)
      }}
      onAddToStage={(props = {}) => {
        track('AddGuestCamera', eventData)
        setAddDisabled(true)
        projectCommands.addParticipant(id, props, 'camera').finally(() => {
          setAddDisabled(false)
        })
      }}
      onRemoveFromStage={() => {
        track('RemoveGuestCamera', eventData)
        projectCommands.removeParticipant(id, 'camera')
      }}
      video={
        <video
          // Mute because audio is only communicated through the compositor
          muted={true}
          ref={ref}
          autoPlay={true}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      }
    />
  )
}

export const ParticipantScreenshare = ({
  participant,
  addDisabled,
  setAddDisabled,
}: ParticipantProps) => {
  const { room } = useContext(StudioContext)
  const { project, projectCommands, track } = useApp()
  const [srcObject] = useState(() => new MediaStream([]))
  const [isOnStream, setIsOnStream] = useState(false)
  const [isShowcase, setIsShowcase] = useState(false)
  // Stream output is muted, independent of whether mic track is active
  const [isMutedOnStream, setIsMutedOnStream] = useState(false)
  // Stream output is hidden, independent of whether camera track is active
  const [isHiddenOnStream, setIsHiddenOnStream] = useState(false)
  const ref = useRef<HTMLVideoElement>()

  const tracks = participant.trackIds.map(room.getTrack)
  const screenTrack = tracks.find((x) => x.type === 'screen_share')

  const screenMuted = Boolean(screenTrack?.isMuted)

  const { id, displayName, meta, connectionQuality } = participant
  const { screenDisplayName } = meta

  const eventData = { guestId: participant.id }

  // Monitor whether the participant has been removed from the stream
  //  from some other means (e.g. dragged off canvas by host)
  useEffectOnce(() => {
    return projectCommands.useParticipantState(
      id,
      (x) => {
        setIsOnStream(Boolean(x))
        setIsHiddenOnStream(Boolean(x?.isHidden))
      },
      'screen',
    )
  }, [])

  // Monitor the project's showcase to determine whether this
  //  participant/type is active
  useEffect(
    () =>
      projectCommands.useShowcase((showcase) => {
        setIsShowcase(
          showcase?.participantId === id && showcase.type === 'screen',
        )
      }),
    [],
  )

  useEffect(() => {
    // Replace the tracks on the existing MediaStream
    Room.updateMediaStreamTracks(srcObject, {
      video: screenTrack?.mediaStreamTrack,
    })
  }, [screenTrack?.mediaStreamTrack])

  useEffectOnce(
    () => {
      ref.current.srcObject = srcObject
    },
    [ref?.current, srcObject],
    ([currentRef]) => Boolean(currentRef),
  )

  useEffect(() => {
    if (ref.current) {
      ref.current.srcObject = srcObject
    }
  }, [ref?.current, srcObject])

  return (
    <ParticipantCard
      isMuted={isMutedOnStream}
      isHidden={isHiddenOnStream}
      isOnStage={isOnStream}
      isAddDisabled={addDisabled}
      hasVideo={screenTrack && !screenMuted}
      canRename={hasPermission(project.role, Permission.ManageGuests)}
      canKick={false}
      canClose={false}
      // Hiding is not made available for screenshares
      canHide={false}
      // Screenshare currently has no audio to mute
      canMute={false}
      canAdd={hasPermission(project.role, Permission.UpdateProject)}
      signal={getSignalDisplay(connectionQuality)}
      name={screenDisplayName || displayName || 'Guest'}
      onAddToStage={(props = {}) => {
        track('AddGuestScreenshare', eventData)
        setAddDisabled(true)
        projectCommands.addParticipant(id, props, 'screen').finally(() => {
          setAddDisabled(false)
        })
      }}
      onRemoveFromStage={() => {
        track('RemoveGuestScreenshare', eventData)
        projectCommands.removeParticipant(id, 'screen')
      }}
      onNameChange={(name) => {
        track('ChangeGuestScreenshareName', eventData)
        room.setParticipantMetadata(participant?.id, {
          ...participant?.meta,
          screenDisplayName: name,
        })
      }}
      label="Screen Share"
      video={
        <video
          // Mute because audio is only communicated through the compositor
          muted={true}
          ref={ref}
          autoPlay={true}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
          }}
        />
      }
    />
  )
}
