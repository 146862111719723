/**
 * Event + Reducer helpers:
 * https://medium.com/hackernoon/finally-the-typescript-redux-hooks-events-blog-you-were-looking-for-c4663d823b01
 */

import type * as Data from '../types'
import { useEffect } from 'react'
import { Magic, RPCError, RPCErrorCode } from 'magic-sdk'
import { DestinationType } from 'studio-types/models/destination'
import { YouTubeError } from 'studio-types/error-messages/platforms'
import { SDK } from '@api.stream/studio-kit'

export function createMsg<Obj extends { [index: string]: any }>() {
  return function <Key extends keyof Obj>(
    name: Key,
    ...args: Obj[Key] extends undefined ? [] : [Obj[Key]]
  ) {
    return { type: name, payload: args[0] }
  }
}

export function createTrigger<Obj extends { [index: string]: any }>() {
  return function <Key extends keyof Obj>(
    name: Key,
    ...args: Obj[Key] extends undefined ? [] : [Obj[Key]]
  ) {
    let action = { type: name, payload: args[0] } as any
    console.log('Event:', action)
    dispatchers.forEach((x) => x(action))
    subscribers.forEach((x) => x(action))
  }
}

export function createOn<Obj extends { [index: string]: any }>() {
  return function <Key extends keyof Obj>(
    name: Key,
    cb: (...args: Obj[Key] extends undefined ? [] : [Obj[Key]]) => void,
  ) {
    if (typeof cb !== 'function') return

    subscribe((event) => {
      if (event.type !== name) return
      // @ts-ignore
      cb(event.payload)
    })
  }
}

function createSubscribe<Obj extends { [index: string]: any }>() {
  return function <Key extends keyof Obj>(
    cb: (event: Events) => void,
  ): Disposable {
    if (typeof cb !== 'function') return

    const id = ++currentSubId
    subscribers.set(id, cb)

    return () => {
      subscribers.delete(id)
    }
  }
}

export type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: {
    type: Key
    payload: M[Key]
  }
}

type Dispatcher = (event: Events) => void
const dispatchers = [] as Dispatcher[]

let currentSubId = 0
const subscribers = new Map<number, any>()

/**
 * A union of all available events structured as `{ type, payload }`
 */
export type Events = ActionMap<EventMap>[keyof ActionMap<EventMap>]

/**
 * Global event bus - proxies events to each reducer that
 *  has registered with registerDispatcher()
 */
export const trigger = createTrigger<EventMap>()

/**
 * Subscribe to all events. Invoke the returned function to dispose.
 */
export const subscribe = createSubscribe<EventMap>()
type Subscribe = typeof subscribe

/**
 * Subscribe to a specific event. Invoke the returned function to dispose.
 */
export const on = createOn<EventMap>()
type On = typeof on

/**
 * Register a dispatcher (e.g. Reducer 'dispatch')
 *  into the global event bus
 */
export const registerDispatcher = (x: Dispatcher) => dispatchers.push(x)

/**
 * A function that may be invoked to clean up its corresponding functionality.
 */
export type Disposable = () => void

/**
 * React hook for subscribing to all events
 */
export const useEvents = (...args: Parameters<Subscribe>) =>
  useEffect(() => {
    return subscribe(...args)
  }, [])

/**
 * React hook for subscribing to a specific event
 */
export const useEvent = (...args: Parameters<On>) =>
  useEffect(() => {
    return on(...args)
  }, [])

window.trigger = trigger // Debug helper

/**
 * Events
 */

export enum App {
  SocketConnected = 'App/SocketConnected',
  ActiveProjectChanged = 'App/ActiveProjectChanged',
  MagicInitialized = 'App/MagicInitialized',
}

export enum User {
  Ready = 'User/Ready',
  LoggedIn = 'User/LoggedIn',
  CapabilitiesChanged = 'User/CapabilitiesChanged',
  VerifiedEmail = 'User/VerifiedEmail',
  EmailVerificationFailed = 'User/EmailVerificationFailed',
  EmailAuthenticated = 'User/EmailAuthenticated',
  AddDestinationFailed = 'User/AddDestinationFailed',
  UserMergeFailed = 'User/UserMergeFailed',
  UserMergeSucceeded = 'User/UserMergeSucceeded',
  UpdatedBillingAddress = 'User/UpdatedBillingAddress',
}

export enum Camera {
  ModeChanged = 'Camera/ModeChanged',
}

export enum Project {
  Changed = 'Project/Changed',
}

export enum Account {
  Authenticated = 'Account/Authenticated',
}

export enum Resource {
  Added = 'Resource/Added',
  Removed = 'Resource/Removed',
  Updated = 'Resource/Updated',
}

type EventMap = {
  // App
  [App.SocketConnected]: {
    bootstrap: Data.Bootstrap
  }
  [App.ActiveProjectChanged]: {
    projectId: string
  }
  [App.MagicInitialized]: {
    magicInstance: Magic
  }

  // User
  [User.VerifiedEmail]: { email: string }
  [User.EmailVerificationFailed]: { e: RPCError; email: string }
  [User.EmailAuthenticated]: undefined
  [User.CapabilitiesChanged]: {
    capabilities: Data.Capabilities
    role: Data.Role
    subscription: Data.Subscription
  }
  [User.AddDestinationFailed]: {
    error: string
    platform: DestinationType
    errorReason?: YouTubeError
  }
  [User.UserMergeFailed]: { error: string }
  [User.UserMergeSucceeded]: { success: boolean }
  [User.UpdatedBillingAddress]: Data.User['subscription']

  // Project
  [Project.Changed]: { project: SDK.Project }

  // Account
  [Account.Authenticated]: { account: Data.Account }

  // Resource
  [Resource.Added]: { resource: Data.Resource }
  [Resource.Removed]: { resource: Data.Resource }
  [Resource.Updated]: { resource: Data.Resource }

  [Camera.ModeChanged]: { isMirrored: boolean }
}
