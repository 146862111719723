import * as React from 'react'

export function useUpdatedEffect(fn: () => void, inputs: React.DependencyList) {
	const didMountRef = React.useRef(false)

	React.useEffect(() => {
		if (didMountRef.current) {
			fn()
		} else {
			didMountRef.current = true
		}
	}, inputs)
}
