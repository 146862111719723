import Accordion from '@ui/atoms/accordion/Accordion'
import Text from '@ui/atoms/text/Text'
import Box from '@ui/layout/Box'
import * as React from 'react'
import { Overlay } from '../../../components/Overlay'
import { UsageType } from 'studio-types/models/resource'
import { Producer } from '../../../components/Producer'
import { TextBannerManager } from '../../../components/TextBannerManager'
import useToaster from '@ui/hooks/useToaster'
import { MediaTileData } from '@ui/atoms/media/Mediatile'
import { Resource, trigger } from '../../../utils/events'
import { useProjectProp } from '@ui/hooks/useProjectProp'
import { HotKey } from '@ui/components/HotKeyPanel'
import useMediaData from '@ui/hooks/useMediaData'

const onResourceDelete = async (key: string) => {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  }
  const response = await fetch(`/api/resources/${key}`, requestOptions)
  trigger(Resource.Removed, { resource: await response.json() })
}

const onResourceCreate = async (postBody: any) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(postBody),
  }
  const response = await fetch(
    `/api/resources/studio2/resource`,
    requestOptions,
  )
  trigger(Resource.Added, { resource: await response.json() })
  return
}

const onResourceUpdate = async (key: string, postBody: any) => {
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(postBody),
  }
  const response = await fetch(
    `/api/resources/studio2/resource/${key}`,
    requestOptions,
  )
  trigger(Resource.Updated, { resource: await response.json() })
  return
}

export const ProduceShelf = () => {
  const [toaster, contextHolder] = useToaster({ maxCount: 1 })
  const [hotkeys, setHotKeys] = useProjectProp<HotKey[]>('hotkeys')
  const [getMediaData] = useMediaData()
  const onHotKeyAdded = async (d: MediaTileData, state : boolean) => {
    if (state) {
      if (hotkeys && hotkeys.length >= 4) {
        return
      }
    }

    if (!state) {
      const filterdHotKeys = hotkeys?.filter((h) => h.hotKeyId !== d.id)
      setHotKeys([...filterdHotKeys])
    } else {
      setHotKeys([
        ...(hotkeys ?? []),
        {
          type: d.resourceType,
          hotKeyId: d.id,
        },
      ])
    }
  }


  const TitleLabel = ({ text }: { text: string }) => (
    <Text.Heading2
      style={{ textTransform: 'capitalize' }}
      marginLeft={8}
      text={text}
    />
  )
  return (
    <Box width="100%" style={{ overflowX: 'hidden', overflowY: 'auto' }}>
      <Accordion title={<TitleLabel text="Video" />} icon="Film" active={true}>
        <Box marginTop={10} marginBottom={10}>
          <Producer
            onHotKeyAdded={onHotKeyAdded}
            type={UsageType.Intro}
            toaster={toaster}
            data={getMediaData(['video'], UsageType.Intro)}
            onResourceUpdate={onResourceUpdate}
            onResourceDelete={onResourceDelete}
          />
        </Box>
        <Box marginBottom={10}>
          <Producer
            onHotKeyAdded={onHotKeyAdded}
            type={UsageType.Outro}
            toaster={toaster}
            data={getMediaData(['video'], UsageType.Outro)}
            onResourceUpdate={onResourceUpdate}
            onResourceDelete={onResourceDelete}
          />
        </Box>
        <Box marginBottom={20}>
          <Producer
            onHotKeyAdded={onHotKeyAdded}
            type={UsageType.Intermission}
            toaster={toaster}
            data={getMediaData(['video'], UsageType.Intermission)}
            onResourceUpdate={onResourceUpdate}
            onResourceDelete={onResourceDelete}
          />
        </Box>
      </Accordion>

      <Accordion
        title={<TitleLabel text="Text Banner" />}
        icon="Text"
        active={true}
      >
        <Box marginBottom={10}>
          <TextBannerManager />
        </Box>
      </Accordion>

      <Accordion
        title={<TitleLabel text={UsageType.Overlay} />}
        icon="AlertAndOverlays"
        active={true}
      >
        <Box marginBottom={10}>
          <Overlay
            onResourceCreate={onResourceCreate}
            onHotKeyAdded={onHotKeyAdded}
            type={UsageType.Overlay}
            data={getMediaData(['image', 'custom'], UsageType.Overlay)}
            onResourceUpdate={onResourceUpdate}
            onResourceDelete={onResourceDelete}
          />
        </Box>
      </Accordion>
      {contextHolder}
    </Box>
  )
}
