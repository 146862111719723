import * as React from 'react'
import chroma from 'chroma-js'

import { Slider } from './Slider'
import { RGBAInput } from './RGBAInput'
import TextInput from '../inputs/TextInput'
import { useUpdatedEffect } from '../../hooks/useUpdatedEffect'
import {
  colorBackgroundFillStyle,
  colorBackgroundStyle,
  hueSliderStyle,
  opacitySliderStyle,
  pickerContainerStyle,
  sliderCursorStyle,
  spectrumCursorStyle,
  spectrumLumStyle,
  spectrumSatStyle,
  spectrumStyle,
} from './Picker'
import { Flex } from '../../layout/Box'
import Text from '../text/Text'

// Quick re-implementation of lodashes 'debounce'.
const debounce = <T extends (...args: any[]) => void>(
  fn: T,
  timeout: number,
) => {
  let timer: NodeJS.Timer = null
  let call: Parameters<T> = null

  return (...args: Parameters<T>) => {
    call = args

    if (timer) {
      return
    }
    timer = setTimeout(() => {
      timer = null
      fn(...call)
    }, timeout)
  }
}

const HSV_MULT = 360

export interface IColorPickerProps {
  value: string
  onChange(value: string): void
}

// tslint:disable max-func-body-length
export const ColorPicker = React.memo((props: IColorPickerProps) => {
  const [cursorDown, setCursorDown] = React.useState(false)
  const [hue, setHue] = React.useState(0)
  const [opacity, setOpacity] = React.useState(1)
  const [s, setS] = React.useState(1)
  const [v, setV] = React.useState(1)
  const [colors, setColors] = React.useState({
    rgb: 'rgb(255, 0, 0)',
    rgba: 'rgba(255, 0, 0, 1)',
    hue: 'rgb(255, 0, 0)',
    hex: 'FF0000',
  })
  const [uncontrolledHex, setUncontrolledHex] = React.useState(colors.hex)
  const change = React.useMemo(
    () => debounce(props.onChange, 200),
    [props.onChange],
  )

  const setColorCode = React.useCallback((value: string) => {
    const adjustedValue: string = value === 'transparent' ? '#FFFFFF00' : value

    try {
      const hsv = chroma(adjustedValue).hsv()
      setOpacity(chroma(adjustedValue).alpha())
      setHue((hsv[0] || 0) / HSV_MULT)
      setS(hsv[1])
      setV(hsv[2])
    } catch (err) {
      return
    }
  }, [])

  useUpdatedEffect(() => {
    const rgb: string = chroma(hue * HSV_MULT, s, v, 'hsv').css('rgb' as any)
    const base = chroma(rgb).alpha(opacity)
    const hex = (
      opacity !== 1 ? base.hex('rgba') : base.hex('rgb')
    ).toUpperCase() // If full opacity, ignore it

    setColors({
      rgb: chroma(hue * HSV_MULT, s, v, 'hsv').css('rgb' as any),
      rgba: base.css('rgba' as any),
      hex,
      hue: chroma(hue * HSV_MULT, 1, 1, 'hsv').css('rgb' as any),
    })
    setUncontrolledHex(hex)
  }, [hue, s, v, opacity])

  React.useEffect(() => {
    change(colors.hex)
  }, [change, opacity, colors.hex, colors.rgba])

  const setColor = React.useCallback((data: { x: number; y: number }) => {
    setS(data.x)
    setV(1 - data.y)
  }, [])

  const setHueValue = React.useCallback((data: { y: number }) => {
    setHue(data.y)
  }, [])

  const setOpacityValue = React.useCallback((data: { y: number }) => {
    setOpacity(+data.y.toFixed(2))
  }, [])

  React.useEffect(() => {
    setColorCode(props.value)
  }, [])

  React.useEffect(() => {
    const fn = () => {
      setCursorDown(false)
    }

    if (cursorDown) {
      document.addEventListener('pointerup', fn)
    }

    return () => {
      document.removeEventListener('pointerup', fn)
    }
  }, [cursorDown])

  const down = React.useCallback(() => {
    setCursorDown(true)
  }, [])

  return (
    <div className={pickerContainerStyle} onPointerDown={down}>
      <div className={colorBackgroundStyle}>
        <div
          className={colorBackgroundFillStyle}
          style={{ backgroundColor: colors.rgba }}
        />
      </div>

      <Flex direction="row">
        <div
          className={spectrumStyle}
          style={{ backgroundColor: colors.hue, flex: 1 }}
        >
          <div className={spectrumSatStyle}>
            <div className={spectrumLumStyle}>
              <Slider
                thumbClass={spectrumCursorStyle}
                y={(1 - v) * 100}
                x={s * 100}
                onUpdate={setColor}
              />
            </div>
          </div>
        </div>

        <div className={hueSliderStyle}>
          <Slider
            thumbClass={sliderCursorStyle}
            y={hue * 100}
            onUpdate={setHueValue}
          />
        </div>

        <div className={opacitySliderStyle}>
          <div
            className={colorBackgroundFillStyle}
            style={{
              backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0), ${colors.rgb})`,
            }}
          >
            <Slider
              thumbClass={sliderCursorStyle}
              y={opacity * 100}
              onUpdate={setOpacityValue}
            />
          </div>
        </div>
      </Flex>
      <Flex
        direction="row"
        style={{ paddingTop: 10 }}
        justify="center"
        align="center"
      >
        <Flex basis="33%" style={{ paddingRight: 8 }} direction="column">
          <Text.Label text="HEX" style={{ marginBottom: 8 }} />
          <TextInput
            autoFocus={false}
            onBlur={(e) => {
              setColorCode(e.target.value)
            }}
            selectOnFocus={true}
            value={uncontrolledHex}
            fontSize={14}
            paddingY={14}
            paddingX={8}
            width="100%"
            onChange={(e) => setUncontrolledHex(e.target.value)}
          />
        </Flex>
        <Flex basis="66%" style={{ paddingLeft: 8 }} direction="column">
          <Text.Label text="RGBA" style={{ marginBottom: 8 }} />
          <RGBAInput value={colors.rgba} onChange={setColorCode} />
        </Flex>
      </Flex>
    </div>
  )
})
