import Box, { Column, Flex } from '@ui/layout/Box'
import { Avatar, LetterAvatar } from '../icons/Avatar'
import Text from '../text/Text'
import * as Colors from '@ui/helpers/colors'
export const MediaTileContext = ({ data , type , index }: { data: any , type? : string, index? : number}) => {
  return (
    <Box padding={5} marginBottom={5}>
      <Flex>
        <Text.Body
          color="neutral"
          colorWeight={400}
          marginLeft={5}
          fontSize={10}
          fontWeight={500}
          style={{
            textTransform: 'uppercase',
            marginBottom: '1px',
          }}
          text={data.themeName ?? 'UPLOAD'}
        />
        {type && (
          <Text.Body
            color="neutral"
            colorWeight={400}
            marginLeft={5}
            fontSize={10}
            fontWeight={600}
            style={{
              textTransform: 'uppercase',
              marginBottom: '1px',
              margin: '0 0 0 auto',
            }}
            text={`SHIFT + ${index}`}
          />
        )}
      </Flex>
      <Box>
        <Text.Heading3
          fontWeight={600}
          fontSize={16}
          bold
          marginLeft={5}
          style={{
            whiteSpace: 'nowrap',
            width: '200px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          text={data?.title}
        />
      </Box>
      <Flex align="center" marginTop={20} marginLeft={5}>
        {!data.authorAvatar ? (
          <LetterAvatar
            name={data.authorName}
            size={30}
            background={Colors['primary'](500)}
          />
        ) : (
          <Avatar src={data.authorAvatar} size={30} />
        )}

        <Column marginLeft={10}>
          <Text.Body
            fontSize={14}
            bold
            fontWeight={600}
            text={data?.authorName}
          />
          {data.isGlobal && !type ? (
            <Text.Link
              marginTop={4}
              style={{
                color: Colors['primary'](500),
                textDecoration: 'none',
              }}
              fontSize={10}
              href={data.authorWebsite}
              text="Visit website"
            />
          ) : (
            <Text.Label
              marginTop={4}
              color="neutral"
              colorWeight={400}
              fontSize={10}
              fontWeight={500}
              text={`Uploaded ${data?.uploadedAt}`}
            />
          )}
        </Column>
      </Flex>
    </Box>
  )
}
