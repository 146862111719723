// TODO: Add errors reasons for other platforms

export const enum YouTubeError {
  /**
   * User needs to enable live streaming in dashboard
   */
  LiveStreamingNotEnabled = 'liveStreamingNotEnabled',
  InsufficientPermissions = 'insufficientLivePermissions',
  /**
   * Youtube has blocked the user from going live
   */
  LivePermissionBlocked = 'livePermissionBlocked',
  /**
   * Live broadcast passed an invalid description field
   */
  InvalidDescription = 'invalidDescription',
  /**
   * Live broadcast passed an invalid title field
   */
  InvalidTitle = 'invalidTitle',
  Null = 'null',
}
