import Box from '@ui/layout/Box'
import React, { useMemo, useState } from 'react'
import { style, classes } from 'typestyle'
import Icon, { IconName } from '../icons/Icon'
import { TextProps } from '../text/Text'
import * as Color from '../../helpers/colors'

interface AccordionProps {
  title: React.ReactNode
  children: React.ReactNode
  onToggle?: (isOpen: boolean) => void
  active?: boolean
  openicon?: IconName
  closeicon?: IconName
  icon?: IconName
  chevronSize?: string | number
  iconHeight?: string | number
  iconWidth?: string | number
}

interface AccordionStyleProps {
  backgroundColor?: TextProps['color']
  backgroundColorWeight?: TextProps['colorWeight']
}

const AccordionItem = style({
  width: '100%',
})

const AccordionTitle = style({
  alignItems: 'center',
  display: 'flex',
  cursor: 'pointer',
  padding: '10px 10px 10px 0px',
  marginTop: '10px',
  height: '40px',
  marginLeft: '14px',
})

const AccodianChevron = style({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '36px',
  height: '36px',
  borderRadius: '8px',
  flex: 'none',
  order: 0,
})

const chevronBackground = ({
  backgroundColor,
  backgroundColorWeight,
}: AccordionStyleProps) =>
  style({
    ...(backgroundColor && {
      backgroundColor: Color[backgroundColor](backgroundColorWeight),
    }),
  })

const Accordion = ({
  children,
  onToggle,
  title,
  active,
  openicon = 'ChevronRight',
  closeicon = 'ChevronDown',
  backgroundColor = 'neutral',
  backgroundColorWeight = 900,
  icon,
  iconHeight = 30,
  iconWidth = 30,
  chevronSize = 24,
}: AccordionProps & AccordionStyleProps) => {
  const [isActive, setIsActive] = useState<boolean>(active)
  const [backgroundWeight, setBackgroundWeight] = useState<number>(
    backgroundColorWeight,
  )

  const AccordionChevronBackground = useMemo(
    () =>
      chevronBackground({
        backgroundColor,
        backgroundColorWeight: backgroundWeight,
      }),
    [backgroundWeight],
  )

  return (
    <Box className={classes(AccordionItem)}>
      <Box
        className={AccordionTitle}
        onMouseEnter={() => setBackgroundWeight(800)}
        onMouseLeave={() => setBackgroundWeight(backgroundColorWeight)}
        onClick={() => {
          setIsActive(!isActive)
          if (onToggle) onToggle(!isActive)
        }}
      >
        {icon && (
          <Icon
            name={icon}
            {...(iconHeight && {
              height: iconHeight,
            })}
            {...(iconWidth && {
              width: iconWidth,
            })}
          />
        )}

        <Box style={{ width: '100%' }}>{title}</Box>
        <div className={classes(AccodianChevron, AccordionChevronBackground)}>
          {isActive ? (
            <Icon name={closeicon} height={chevronSize} width={chevronSize} />
          ) : (
            <Icon name={openicon} height={chevronSize} width={chevronSize} />
          )}
        </div>
      </Box>

      {isActive && <div style={{ padding: '8px 16px' }}>{children}</div>}
    </Box>
  )
}

export default Accordion
