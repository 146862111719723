import * as React from 'react'
import { ChatMessageProps } from '@ui/atoms/chat-message/ChatMessage'
import {
  getPlatformName,
  IPlatformVariation,
  PlatformFilter,
} from '@ui/atoms/chat-message/PlatformFilter'
import Icon from '@ui/atoms/icons/Icon'
import Text from '@ui/atoms/text/Text'
import { Chat } from '@ui/components/Chat'
import { ChatInput } from '@ui/components/ChatInput'
import { Row } from '@ui/layout/Box'
import { usePrimaryHost } from '../../../utils/is-primary-host'
import { SidebarHeading } from '../shared'
import {
  chatComingSoon,
  IPlatformMessage,
  ISentMessage,
  PlatformChatContext,
} from '../../../context/platform-chat-context'
import { OnStreamMessage } from '@ui/atoms/chat-message/OnStreamMessage'
import { AppContext } from '../../../../src/context/app-context'
import { UserType } from '../../../../src/types'

function groupMessagesBySender(
  messages: (IPlatformMessage | ISentMessage)[],
  currentParticipant: string,
  destinations: IPlatformVariation[],
): ChatMessageProps[] {
  const mapped: ChatMessageProps[] = []

  for (const msg of messages) {
    const recentMessage = mapped[mapped.length - 1]

    if (msg.type === 'message') {
      const destination = destinations.find(
        (i) =>
          i.platform === msg.value.platform &&
          i.username.toLowerCase() === msg.value.platformUserId.toLowerCase(),
      )

      if (
        destination &&
        recentMessage &&
        recentMessage.isSender === false &&
        recentMessage.target[0].type === 'destination' &&
        recentMessage.target[0].id === destination.id &&
        // TODO: Refactor to use platformId instead of platformUsername
        recentMessage.displayName === msg.value.user.platformUsername
      ) {
        recentMessage.messages.push(msg.value.message)
      } else {
        // If destination is missing, we have chat for an unlinked destination.
        if (destination) {
          mapped.push({
            id: msg.value.metadata.id,
            userId: destination.id,
            avatar: msg.value.user.avatar,
            displayName: msg.value.user.platformUsername,
            isSender:
              msg.value.user.platformUsername.toLowerCase() ===
              currentParticipant.toLowerCase(),
            messages: [msg.value.message],
            target: [
              {
                type: 'destination',
                id: destination.id,
                username: msg.value.platformUserId,
                platform: msg.value.platform as any,
                variant: destination?.variant ?? 0,
              },
            ],
            timestamp: new Date(msg.value.ts),
          })
        }
      }
    } else {
      const bothEveryone =
        msg.value.toDestinations.length === 0 &&
        recentMessage?.target[0].type === 'everyone'
      const recentHasMatching =
        recentMessage &&
        msg.value.toDestinations.length &&
        msg.value.toDestinations.every((to) =>
          recentMessage.target.some(
            (f) => f.type === 'destination' && f.id === to.id,
          ),
        )
      const currentHasMatching =
        recentMessage &&
        recentMessage.target.every(
          (to) =>
            to.type === 'destination' &&
            msg.value.toDestinations.some((f) => f.id === to.id),
        )
      const recentMatches =
        bothEveryone || (currentHasMatching && recentHasMatching)

      if (recentMatches && recentMessage.isSender === true) {
        recentMessage.messages.push(msg.value.message)
      } else {
        mapped.push({
          id: msg.value.id,
          avatar: '',
          userId: 'user',
          displayName: 'User',
          isSender: true,
          messages: [msg.value.message],
          target: msg.value.toDestinations.length
            ? msg.value.toDestinations.map((i) => ({
                type: 'destination',
                id: i.id,
                platform: i.platform as any,
                username: i.username,
                variant: i.variant,
              }))
            : [{ type: 'everyone' }],
          timestamp: new Date(),
        })
      }
    }
  }

  return mapped
}

export const PlatformChatShelf = React.memo(() => {
  const { userType } = React.useContext(AppContext)
  const { messages, send, destinations, enabled } =
    React.useContext(PlatformChatContext)
  const CHAT_COMING_SOON = chatComingSoon()
  const isComingSoon = destinations.every((i) =>
    CHAT_COMING_SOON.includes(i.platform),
  )
  const primary = usePrimaryHost()
  const [filter, setFilter] = React.useState<IPlatformVariation[] | null>(null)
  const [sendFilter, setSendFilter] = React.useState<
    IPlatformVariation[] | null
  >(null)

  const msgs = React.useMemo(() => {
    return groupMessagesBySender(messages, 'guest', destinations)
  }, [messages, destinations])

  // Filter all messages to the selected destinations.
  const filtered = React.useMemo(() => {
    if (filter === null) {
      return msgs
    }

    return msgs.filter((i) => {
      if (i.target[0].type === 'everyone') {
        return true
      }

      return i.target.some(
        (i) =>
          i.type === 'destination' &&
          !!filter.find(
            (filter) =>
              filter.platform === i.platform &&
              filter.username.toLowerCase() === i.username.toLowerCase(),
          ),
      )
    })
  }, [filter, msgs])

  // Identify any platforms where we have more than one connected.
  const duplicatedPlatforms = React.useMemo(() => {
    const uniqPlatforms = new Set([...destinations.map((i) => i.platform)])

    return Array.from(uniqPlatforms).filter(
      (platform) =>
        destinations.filter((i) => i.platform === platform).length > 1,
    )
  }, [destinations])

  const hasMultipleWritableDestinations =
    destinations.filter(
      (r) => r.canSend && !CHAT_COMING_SOON.includes(r.platform),
    ).length >= 1 && destinations.length > 1

  return (
    <>
      <SidebarHeading icon="ChatBubbles" text="Platform Chat" />
      {destinations.length > 1 && (
        <Row paddingX={25} paddingTop={8}>
          <Icon name="Filter" width={24} />
          <div style={{ marginLeft: 10 }}>
            <PlatformFilter
              destinations={destinations}
              destinationsComingSoon={CHAT_COMING_SOON}
              value={filter}
              onChange={setFilter}
            />
          </div>
        </Row>
      )}

      {!enabled && !isComingSoon && (
        <div style={{ width: '100%' }}>
          <Text.Caption1
            textAlign="center"
            marginTop={50}
            colorWeight={500}
            text="Once you go live, chat will display here."
          />
        </div>
      )}
      {!enabled && isComingSoon && (
        <div style={{ width: '100%' }}>
          <Text.Caption1
            textAlign="center"
            marginTop={50}
            colorWeight={500}
            text={`Support for ${getPlatformName(
              destinations[0].platform,
            )} chat is coming soon`}
          />
        </div>
      )}
      <Chat
        type="platform"
        messages={filtered}
        usePlatformHeading={true}
        duplicatedPlatforms={duplicatedPlatforms}
        onTargetClick={(v) => {
          if (v.type === 'destination') {
            setFilter([v])
          }
        }}
      />
      {userType !== UserType.GUEST && <OnStreamMessage type="platform" />}
      {hasMultipleWritableDestinations && (
        <Row
          paddingX={16}
          align="center"
          style={{ borderTop: '1px solid #000', paddingTop: 8, width: '100%' }}
        >
          <Text.Caption1
            fontWeight={700}
            text="To:"
            style={{ marginRight: 16 }}
          />
          <PlatformFilter
            destinations={destinations.filter(
              (r) => r.canSend && !CHAT_COMING_SOON.includes(r.platform),
            )}
            value={sendFilter}
            onChange={setSendFilter}
          />
        </Row>
      )}
      {!!destinations.filter(
        (r) => r.canSend && !CHAT_COMING_SOON.includes(r.platform),
      ).length && (
        <ChatInput onSend={(message) => send(sendFilter ?? [], message)} />
      )}
    </>
  )
})
