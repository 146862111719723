import { HotKey } from '@ui/components/HotKeyPanel'
import { useProjectProp } from '@ui/hooks/useProjectProp'
import Box from '@ui/layout/Box'
import { IconButton } from '../buttons/IconButton'
import { WithInfo } from '../FloatingMenu/FloatingMenu'
import TooltipTypes from '../FloatingMenu/TooltipTypes'
import Icon from '../icons/Icon'

interface FavoriteButtonIconProps {
  iconWidth?: number
  iconHeight?: number
  iconSize?: number
}

interface FavoriteButtonProps {
  className?: string
  onFavorite: (id: string, state: boolean) => void
  dimmed?: boolean
  id: string
  isFavorite: boolean
}

const FavoriteButton = ({
  className,
  id,
  isFavorite,
  onFavorite,
  dimmed,
  iconWidth,
  iconHeight,
  iconSize,
}: FavoriteButtonProps & FavoriteButtonIconProps) => {
  const [hotkeys] = useProjectProp<HotKey[]>('hotkeys')
  return (
    <>
      {(!hotkeys || hotkeys?.length < 4 ||
      hotkeys?.findIndex((h) => h.hotKeyId === id) > -1) ? (
        <IconButton
          style={{ opacity: dimmed ? 0.5 : 1 }}
          className={className}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            onFavorite?.(id, !isFavorite)
          }}
          height={iconHeight}
          appearance="text"
          icon={isFavorite ? 'StarFilled' : 'Star'}
          iconColor="neutral"
          iconColorWeight={200}
          iconSize={iconSize}
          width={iconWidth}
        />
      ) : (
        <WithInfo
          message={
            <TooltipTypes.Default
              title="Your Favorites are full"
              description="Remove a Favorite to make room for a new one."
            />
          }
          position="left"
          node={
            <IconButton
              style={{ opacity: dimmed ? 0.5 : 1 }}
              className={className}
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
              }}
              height={iconHeight}
              appearance="text"
              icon={isFavorite ? 'StarFilled' : 'Star'}
              iconColor="neutral"
              iconColorWeight={200}
              iconSize={iconSize}
              marginLeft={4}
              width={iconWidth}
            />
          }
        />
      )}
    </>
  )
}

export default FavoriteButton
