import * as React from 'react'
import { style } from 'typestyle'
import Icon, { IconName } from '../../../ui/atoms/icons/Icon'
import Text from '../../../ui/atoms/text/Text'
import { Row } from '../../../ui/layout/Box'
import * as Colors from '../../../ui/helpers/colors'

const sidebarRowStyle = style({
  padding: '16px',
  width: '100%',
  borderBottom: `1px solid ${Colors.neutral(1000)}`,
})

export interface ISidebarHeadingProps {
  icon: IconName
  text: string
  infoSelector?: string
}

export const SidebarHeading = (props: ISidebarHeadingProps) => {
  return (
    <Row className={sidebarRowStyle}>
      <Icon width={24} name={props.icon} />
      <Text.Heading2 marginLeft={14} text={props.text} />
      {props.infoSelector && (
        <div
          className={`InfoSpace ${props.infoSelector}`}
          style={{ marginLeft: 8 }}
        ></div>
      )}
    </Row>
  )
}
