import { style } from 'typestyle'
import * as Color from '../../helpers/colors';
export const sliderCursorStyle = style({
	borderRadius: 25,
	height: 16,
	width: 16,
	border: '1px solid white',
	background: Color.neutral(800),
	cursor: 'pointer',
	position: 'absolute',
	top: 0,
	left: 0,
	marginTop: -8,
	marginLeft: -2,
})

export const spectrumCursorStyle = style({
	borderRadius: 12,
	height: 12,
	width: 12,
	border: '1px solid white',
	cursor: 'pointer',
	position: 'absolute',
	top: 0,
	left: 0,
	marginLeft: -6,
	marginTop: -6,
	boxShadow: '0 0 2px 0px rgba(0, 0, 0, 0.24)',
})

export const opacitySliderStyle = style({
	width: 12,
	height: 150,
	marginLeft: 12,
	backgroundSize: '100%',
	position: 'relative',
  background: `url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeAQMAAAAB/jzhAAAABlBMVEXh4eG0tLSN+vM/AAAAFUlEQVR4XmNgYPz/h0rE/38MDNQhAHTjO5gQMYlZAAAAAElFTkSuQmCC")`,
})

export const colorBackgroundStyle = style({
	marginBottom: 5,
	overflow: 'hidden',
	height: 20,
	position: 'relative',
	zIndex: 0,
  background: `url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeAQMAAAAB/jzhAAAABlBMVEXh4eG0tLSN+vM/AAAAFUlEQVR4XmNgYPz/h0rE/38MDNQhAHTjO5gQMYlZAAAAAElFTkSuQmCC")`,
  backgroundSize: '20px',
})

export const colorBackgroundFillStyle = style({
	position: 'absolute',
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
})

export const spectrumSatStyle = style({
	backgroundImage: 'linear-gradient(to right, white, rgba(204, 154, 129, 0))',
  position: 'absolute',
  inset: 0,
})

export const spectrumLumStyle = style({
	backgroundImage: 'linear-gradient(to top, black, rgba(204, 154, 129, 0))',
  position: 'absolute',
  inset: 0,
})

export const spectrumStyle = style({
	height: 150,
	position: 'relative',
	cursor: 'pointer',
})

export const hueSliderStyle = style({
  width: '12px',
  height: '150px',
  marginLeft: '12px',
  position: 'relative',
  backgroundImage: 'linear-gradient(to bottom, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%)',
})

export const pickerContainerStyle = style({
  padding: 10,
  userSelect: 'none',
})

export const sliderStyle = style({
  width: '100%',
  height: '100%',
})