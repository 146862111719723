import * as React from 'react'
import Accordion from '@ui/atoms/accordion/Accordion'
import { ColorInput } from '@ui/atoms/inputs/ColorInput'
import Text from '@ui/atoms/text/Text'
import Box from '@ui/layout/Box'
import { BackgroundManager } from '../../../components/BackgroundManager'
import { BannerStyleManger } from '../../../components/BannerStyleManager'
import { useProjectProp } from '@ui/hooks/useProjectProp'
import { LogoManager } from '../../../components/LogoManager'
import { LogoStyleManager } from '../../../components/LogoStyleManager'

const TitleLabel = ({ text }: { text: string }) => (
  <Text.Heading2
    style={{ textTransform: 'capitalize' }}
    marginLeft={8}
    text={text}
  />
)

export const DesignShelf = () => {
  const [color, setColor] = useProjectProp<string>('primaryColor')
  const [logoPosition, setLogoPosition] = useProjectProp<string>('logoPosition')

  if (!logoPosition) {
    setLogoPosition('top-right')
  }

  return (
    <Box width="100%" style={{ overflowX: 'hidden', overflowY: 'auto' }}>
      <Accordion
        title={<TitleLabel text="Primary Color" />}
        icon="PaintRoller"
        active={true}
      >
        <Box marginBottom={20} position="relative" style={{ zIndex: 4 }}>
          {/* We're always outputting RGB since HEX w/ alpha breaks color conversion in studio kit */}
          <ColorInput
            value={color}
            defaultColor="#F39508"
            outputFormat="rgba"
            onChange={setColor}
          />
        </Box>
      </Accordion>

      <Accordion title={<TitleLabel text="Logo" />} icon="Logo" active={true}>
        <Box marginTop={0} marginBottom={20}>
          <LogoManager />
        </Box>
        <Box marginTop={0} marginBottom={20}>
          <LogoStyleManager
            position={logoPosition}
            setLogoPosition={setLogoPosition}
          />
        </Box>
      </Accordion>

      <Accordion
        title={<TitleLabel text="Banner Style" />}
        icon="Text"
        active={true}
      >
        <Box marginBottom={20}>
          <BannerStyleManger color={color} />
        </Box>
      </Accordion>
      <BackgroundManager />
    </Box>
  )
}
