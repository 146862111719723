import React, { useContext, useEffect } from 'react'
import { Helpers } from '@api.stream/studio-kit'
import { Uploader } from './Uploader'
import Box, { Column, Flex, Row } from '@ui/layout/Box'
import MediaTile, { MediaTileData } from '@ui/atoms/media/Mediatile'
import { UsageType } from '../../types/models/resource'
import Text from '@ui/atoms/text/Text'
import LoadingDots from '@ui/atoms/animations/LoadingDots'
import { UploadContext } from '../context/upload-context'
import { MediaContext } from '../context/media-context'
import Icon, { SVG } from '@ui/atoms/icons/Icon'
import { WithInfo } from '@ui/atoms/FloatingMenu/FloatingMenu'
import { ToasterAPI } from '@ui/hooks/useToaster'
import { Resource } from '../types'
import { useApp } from '../context/app-context'
const { StudioContext } = Helpers.React

interface MeidaDataProps {
  type: UsageType
  toaster: ToasterAPI
  data: MediaTileData[]
  onResourceUpdate(key: string, resource: Partial<Resource>): void
  onHotKeyAdded(data : MediaTileData,state : boolean): void
  onResourceDelete(key: string): void
}

export const Producer = ({
  type,
  toaster,
  data,
  onResourceDelete,
  onResourceUpdate,
  onHotKeyAdded,
}: MeidaDataProps) => {
  const {
    setVideoOverlay,
    videoOverlay,
    videoLoop,
    setVideoLoop,
    remainingOverlayTime,
    setRemainingOverlayTime,
  } = useContext(MediaContext)

  const { uppy, setUsageType, setFileType } = useContext(UploadContext)
  const { room } = useContext(StudioContext)
  const { projectCommands, track } = useApp()
  const [selectedContext, setSelectedContext] = React.useState(null)
  const onUploaderOpen = () => {
    setFileType('video')
    setUsageType(type)
  }

  useEffect(() => {
    if (videoOverlay === null) {
      toaster.destroy()
    }
  }, [videoOverlay])

  if (!room) {
    return (
      <Flex align="center" justify="center" width="100%" height={100}>
        <SVG color="primary" colorWeight={400} width={32} svg={LoadingDots} />
      </Flex>
    )
  }

  return (
    <Column width="100%" grow={1} shrink={1}>
      <Flex width="100%" align="center" marginBottom={10}>
        <Text.Label
          style={{
            width: '100%',
            textTransform: 'uppercase',
            color: '#BABABA',
            fontSize: '12px',
          }}
          text={type}
        />
        <Box
          tag="span"
          style={{
            cursor: 'pointer',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={() =>
            setVideoLoop({ ...videoLoop, [type]: !videoLoop[type] })
          }
        >
          <WithInfo
            message="Loop video"
            position="left"
            node={
              <Icon
                width={24}
                height={24}
                name={'Loop'}
                color={videoLoop[type] ? 'primary' : 'neutral'}
                colorWeight={videoLoop[type] ? 400 : 300}
              />
            }
          />
        </Box>
      </Flex>

      <Row
        style={{
          width: '100%',
          alignItems: 'center',
          display: 'grid',
          gridGap: '5px',
          gridTemplateColumns: 'repeat(auto-fill, minmax(115px, 1fr))',
        }}
      >
        {data &&
          data?.map((d: MediaTileData) => {
            return (
              <MediaTile
                key={d.id}
                data={d}
                remaningDuration={videoOverlay === d.id && remainingOverlayTime}
                isOpen={selectedContext === d.id}
                setSelectedContext={setSelectedContext}
                active={videoOverlay === d.id}
                dimmed={videoOverlay && videoOverlay !== d.id}
                showContext={true}
                showFavorite={true}
                onFavorite={async (key: string, state: boolean) => {
                  onHotKeyAdded(d,state)
                }}
                onClick={(key: string) => {
                  track('SetOverlay', { type: 'video' })
                  if (key === videoOverlay) {
                    setVideoOverlay(null)
                    projectCommands.removeVideoOverlay2(key)
                    toaster.close(key)
                  } else {
                    setVideoOverlay(key)
                    const media = data.find(
                      (image: MediaTileData) => image.id === key,
                    )
                    setRemainingOverlayTime({id : media.id , time : Math.floor(media.duration)})
                    projectCommands.addVideoOverlay2(key, {
                      src: media.url,
                      loop : videoLoop[type],
                    })
                    toaster.open({
                      content:
                        'Hey, We have muted your mic while the video clip is playing',
                      key: key,
                      duration: null,
                      icon: 'MicrophoneOff',
                    })
                  }
                }}
                onDelete={async (key: string) => {
                  onResourceDelete(key)
                }}
              />
            )
          })}
        <Uploader usageType={type} uppy={uppy} onOpen={onUploaderOpen} />
      </Row>
    </Column>
  )
}
