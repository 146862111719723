interface MagicLog {
  message: MagicLogMessage
}

interface MagicLogMessage {
    state: string
    email?: string
    reason?: string
}

/**
 * It sends a POST request to the server with the body of the request being the MagicLog object
 * @param {MagicLog} body - MagicLog
 */
export const sendMagicLogs = async (body: MagicLog) => {
  await fetch(`https://studio.sandbox.horse/api/auth/logs`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
}

/**
 * It takes a string, finds all the characters that are not at the beginning or end of the string, and
 * replaces them with an asterisk
 * @param {string} email - The email address to hide.
 */
export const hideEmail = function (email:string) {
  const regex = /(?<=.)[^@\n](?=[^@\n]*?[^@\n]@)|(?:(?<=@.)|(?!^)\G(?=[^@\n]*$)).(?=.*[^@\n]\.)/gm;
  const result = email.replace(regex, "*")
  return result;
}
