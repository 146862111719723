import { ChatAggregator } from './ChatAggregator'
import { YouTubeParser } from '@streamjar/chatparser'
import { BaseChat } from './BaseChat'

export class YoutubeChat extends BaseChat {
  private timer: NodeJS.Timer
  private reconnectTimer: NodeJS.Timer
  private chatId: string
  private accessToken: string
  private lastToken: string
  private ignoredMessages: string[] = []
  private parser = new YouTubeParser({
    bots: [],
    developers: [],
  })
  private ignoreMessagesBefore = new Date()

  constructor(
    private readonly aggregator: ChatAggregator,
    private username: string,
    private platformId: string,
    private destinationId: string,
    accessToken?: string,
  ) {
    super()
    this.accessToken = accessToken
  }

  public setToken(token: string): void {
    // We can't trust updates, this is stale _somewhere_ from StudioKit
    this.accessToken = token
  }

  /**
   * Fetch the ID of the chat we should poll.
   */
  private async getChatId(retry = false): Promise<string | null> {
    return fetch(
      `https://www.googleapis.com/youtube/v3/liveBroadcasts?broadcastStatus=active&part=snippet`,
      {
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
        },
      },
    )
      .then(async (res) => {
        const response = await res.json()

        // If we've not refreshed the token before, we should try.
        if (res.status === 401 && !retry) {
          await this.refresh()
          return this.getChatId(true)
        }

        if (response.error) {
          return null
        }

        if (!response.items.length) {
          return null
        }

        return response.items[0].snippet.liveChatId
      })
      .catch((err) => {
        console.warn('Unable to lookup livestream', err)

        return null
      })
  }

  public async connect(): Promise<void> {
    if (this.chatId) {
      return
    }

    const chatId = await this.getChatId()

    if (!chatId) {
      this.handleDisconnect(25000)

      return
    }

    this.chatId = chatId
    this.getChat(chatId)
    this.aggregator.emit('health-changed')
    this.timer = setTimeout(() => {
      this.getChat(chatId)
    }, 30000)
  }

  public disconnect() {
    clearTimeout(this.timer)
    clearTimeout(this.reconnectTimer)

    this.timer = null
    this.reconnectTimer = null
    this.chatId = null
    this.aggregator.emit('health-changed')
  }

  private handleDisconnect(retry: number = 1000) {
    this.disconnect()

    this.reconnectTimer = setTimeout(() => {
      this.connect()
    }, retry)
  }

  private getChat(chatId: string) {
    const token = this.lastToken ? `&pageToken=${this.lastToken}` : ''

    return fetch(
      `https://www.googleapis.com/youtube/v3/liveChat/messages?liveChatId=${chatId}${token}&part=snippet,authorDetails&maxResults=2000`,
      {
        headers: {
          Authorization: `Bearer ${this.accessToken},`,
        },
      },
    )
      .then()
      .then(async (res) => {
        const response = await res.json()

        if (response.nextPageToken) {
          this.lastToken = response.nextPageToken
        }

        if (response.offlineAt || res.status !== 200) {
          this.handleDisconnect(25000)
        } else {
          this.timer = setTimeout(() => {
            this.getChat(chatId)
          }, 30000)
        }

        for (const msg of response.items) {
          if (msg.kind !== 'youtube#liveChatMessage') {
            continue
          }

          if (this.ignoredMessages.includes(msg.id)) {
            continue
          }

          const time = new Date(msg.snippet.publishedAt)

          if (time < this.ignoreMessagesBefore) {
            continue
          }

          this.ignoreMessagesBefore = time

          this.parser
            .parseMessage(msg, {
              platformUserId: this.username,
              platformId: '',
            })
            .then((message) => {
              this.aggregator.emit('message', {
                destination: 'youtube',
                message,
              })
            })
            .catch((err) => {
              console.warn('Unable to parse youtube message', err)
            })
        }
      })
  }

  public sendMessage(message: string) {
    if (!this.chatId) {
      return
    }

    return fetch(
      `https://www.googleapis.com/youtube/v3/liveChat/messages?part=snippet`,
      {
        method: 'POST',
        body: JSON.stringify({
          snippet: {
            type: 'textMessageEvent',
            liveChatId: this.chatId,
            textMessageDetails: {
              messageText: message,
            },
          },
        }),
        headers: {
          Authorization: `Bearer ${this.accessToken},`,
        },
      },
    )
      .then((res) => res.json())
      .then((res) => {
        this.ignoredMessages.push(res.id)
      })
      .catch(() => {
        /* */
      })
  }

  public canChat(): boolean {
    return !!this.chatId
  }

  private async refresh(): Promise<void> {
    await fetch(
      `/api/studio2/destinations/${this.destinationId}/refresh?projectId=${this.aggregator.projectId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then(async (res) => {
      if (res.status === 200) {
        const response = await res.json()
        this.accessToken = response.payload.metadata.props.accessToken
      }
    })
  }
}
