/**
 * Denotes how a resource is used in studio 2
 */
export enum UsageType {
  Background = 'background',
  Overlay = 'overlay',
  Logo = 'logo',
  Intro = 'intro',
  Outro = 'outro',
  Intermission = 'intermission'
}
