import * as React from 'react'
import { sliderStyle } from './Picker'

export interface IcolorSliderProps {
  thumbClass: string

  x?: number
  y?: number

  onUpdate(data: { x?: number; y?: number }): void
}

export const Slider = React.memo((props: IcolorSliderProps) => {
  const [down, setDown] = React.useState(false)
  const { thumbClass } = props
  const ref = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    const up = () => {
      setDown(false)
    }
    const move = (event: React.MouseEvent<HTMLDivElement> | MouseEvent) => {
      if (down && ref.current) {
        const height = ref.current.offsetHeight
        const width = ref.current.offsetWidth

        const x = Math.max(
          0,
          Math.min(
            event.pageX -
              ref.current.getBoundingClientRect().left -
              window.pageXOffset,
            width,
          ),
        )
        const y = Math.max(
          0,
          Math.min(
            event.pageY -
              ref.current.getBoundingClientRect().top -
              window.pageYOffset,
            height,
          ),
        )

        props.onUpdate({
          x: x / width,
          y: y / height,
        })
      }
    }

    window.addEventListener('mouseup', up)
    window.addEventListener('mousedown', move)
    window.addEventListener('mousemove', move)

    return () => {
      window.removeEventListener('mouseup', up)
      window.removeEventListener('mousedown', move)
      window.removeEventListener('mousemove', move)
    }
  }, [down, ref])

  const onDown = React.useCallback(() => {
    setDown(true)
  }, [])

  return (
    <div className={sliderStyle} onMouseDown={onDown} ref={ref}>
      <div
        className={thumbClass}
        style={{
          top: props.y ? `${props.y}%` : undefined,
          left: props.x ? `${props.x}%` : undefined,
        }}
      />
    </div>
  )
})
