import { Column, Row } from '@ui/layout/Box'
import { ToasterAPI, useToaster } from '@ui/hooks/useToaster'
import { useCallback, useEffect, useState } from 'react'
import {
  usePermissions,
  PermissionErrorType,
} from '../context/permission-context'
import { style } from 'typestyle'
import Text from '@ui/atoms/text/Text'
import Button from '@ui/atoms/buttons/Button'
import Bowser from 'bowser'

const browser = Bowser.getParser(window.navigator.userAgent)

export const PermissionAlerter = () => {
  const Permission = usePermissions()
  const [activeToast, setActiveToast] = useState<string | number>()
  const [toaster, contextHolder] = useToaster({
    className: () =>
      style({
        // Add margin to avoid overlap with browser permissions prompt
        marginTop: 160,
        width: 480,
      }),
  })

  const clearToast = useCallback(() => {
    if (activeToast) toaster.close(activeToast)
  }, [activeToast, toaster])

  const showToast = useCallback(
    (...params: Parameters<ToasterAPI['open']>) => {
      const options = params[0]
      clearToast()
      setActiveToast(options.key)

      toaster.open({
        placement: 'top',
        duration: null,
        style: {
          backgroundColor: 'warning',
          backgroundColorWeight: 600,
        },
        icon: null,
        closable: true,
        ...options,
      })
    },
    [activeToast, setActiveToast, toaster],
  )

  useEffect(() => {
    clearToast()
    const error = Permission.camera.error || Permission.microphone.error
    if (!error) return

    let onlyDevice = null
    if (!Permission.camera.error) {
      onlyDevice = 'Microphone'
    } else if (!Permission.microphone.error) {
      onlyDevice = 'Camera'
    }

    if (error.type === PermissionErrorType.UserPermissionDenied) {
      // User denied permission
      showToast({
        key: PermissionErrorType.UserPermissionDenied,
        content: (
          <Column width="100%" style={{ textAlign: 'left' }} paddingY={8}>
            <Row>
              <Text.Body text={`Permission required`} bold={true} />
              {onlyDevice && (
                <Text.Body
                  marginLeft={6}
                  text={`(${onlyDevice})`}
                  opacity={0.8}
                />
              )}
            </Row>
            <Text.Caption1
              text="Browser permission is required to add a microphone and camera."
              marginTop={8}
            />
            <Text.Caption1 text="Update your browser settings to allow Lightstream permission to access." />
            <TryAgain
              onClick={() => {
                clearToast()
                Permission.request.camera()
                Permission.request.microphone()
              }}
            />
          </Column>
        ),
      })
    } else if (error.type === PermissionErrorType.SystemPermissionDenied) {
      showToast({
        key: PermissionErrorType.SystemPermissionDenied,
        content: (
          <Column width="100%" style={{ textAlign: 'left' }} paddingY={8}>
            <Row>
              <Text.Body text={`Cannot reach device`} bold={true} />
              {onlyDevice && (
                <Text.Body
                  marginLeft={6}
                  text={`(${onlyDevice})`}
                  opacity={0.8}
                />
              )}
            </Row>
            {browser.getBrowserName() === 'Firefox' ? (
              <Text.Caption1
                text="Ensure no other applications are using your webcam and update your system privacy settings to enable device access for this browser."
                marginTop={8}
              />
            ) : (
              <Text.Caption1
                text="Check your system privacy settings to enable device access for this browser."
                marginTop={8}
              />
            )}
            <TryAgain
              onClick={() => {
                clearToast()
                Permission.request.camera()
                Permission.request.microphone()
              }}
            />
          </Column>
        ),
      })
      // Browser doesn't have access to devices
    } else if (error.type === PermissionErrorType.CouldNotStartVideoSource) {
      // Most likely when other apps or tabs are using the cam/mic (mostly windows)
      showToast({
        key: PermissionErrorType.CouldNotStartVideoSource,
        content: (
          <Column width="100%" style={{ textAlign: 'left' }} paddingY={8}>
            <Row>
              <Text.Body text={`Device in use`} bold={true} />
              {onlyDevice && (
                <Text.Body
                  marginLeft={6}
                  text={`(${onlyDevice})`}
                  opacity={0.8}
                />
              )}
            </Row>
            <Text.Caption1
              text="Another application or browser tab might already be using your webcam.
                Please turn off other cameras before proceeding."
              marginTop={8}
            />
            <TryAgain
              onClick={() => {
                clearToast()
                Permission.request.camera()
                Permission.request.microphone()
              }}
            />
          </Column>
        ),
      })
    } else {
    }
  }, [
    Permission.camera.error,
    Permission.microphone.error,
    showToast,
    clearToast,
  ])

  // useEffect(() => {
  //   clearToast()
  // }, [Permission.camera.status, Permission.microphone.status, clearToast])

  return <>{contextHolder}</>
}

const TryAgain = (props: { onClick: () => void }) => (
  <Button
    marginTop={12}
    text="Try again"
    appearance="outline"
    color="neutral"
    colorWeight={0}
    fontSize={13}
    onClick={() => {
      if (browser.getBrowserName() === 'Safari') {
        // If on Safari, rechecking permissions results in glitches so just refresh the page
        window.location.reload()
      } else {
        props.onClick()
      }
    }}
  />
)
