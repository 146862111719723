import { OnStreamChatMessage } from '@ui/atoms/chat-message/OnStreamMessage'
import React, { useState } from 'react'
import { useApp } from './app-context'

type Props = {
  children: JSX.Element[] | JSX.Element
}

export interface videoLoop {
  intro: boolean
  outro: boolean
  intermission: boolean
  background?: boolean
  overlay?: boolean
  logo?: boolean
  custom?: boolean
}

export interface RemaningTime {
  id: string
  time: number
}

type MediaContext = {
  videoOverlay: string
  setVideoOverlay: (videoOverlay: string) => void
  background: string
  setBackground: (background: string) => void
  imageOverlay: string
  setImageOverlay: (imageOverlay: string) => void
  videoLoop: videoLoop
  setVideoLoop: (loop: videoLoop) => void
  remainingOverlayTime: RemaningTime
  setRemainingOverlayTime: (data: RemaningTime) => void
  remainingVideoLoopTime: RemaningTime
  setRemainingVideoLoopTime: (data: RemaningTime) => void
  logo: string
  setLogo: (logo: string) => void
  activeBanner: string
  setActiveBanner: (activeBanner: string) => void
  activeChatOverlay: OnStreamChatMessage
  setActiveChatOverlay: (ativeChatOverlay: OnStreamChatMessage) => void
}

function isImage(url: string) {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url)
}

const MediaContext = React.createContext<MediaContext>(null)

const MediaProvider = ({ children }: Props) => {
  const { projectCommands } = useApp()
  const [videoOverlay, setVideoOverlay] = React.useState<string | null>(
    (projectCommands.getVideoOverlay2() as string) || null,
  )
  const [background, setBackground] = React.useState<string | null>(
    (projectCommands.getBackgroundMedia2() as string) || null,
  )
  const [imageOverlay, setImageOverlay] = React.useState<string | null>(
    (projectCommands.getImageOverlay2() as string) ||
      (projectCommands.getCustomOverlay() as string) ||
      null,
  )
  const [logo, setLogo] = React.useState<string | null>(
    (projectCommands.getLogo() as string) || null,
  )
  const [activeBanner, setActiveBanner] = React.useState<string | null>(
    (projectCommands.getActiveBanner() as string) || null,
  )
  const [videoLoop, setVideoLoop] = React.useState<videoLoop>({
    intro: false,
    outro: false,
    intermission: false,
  })
  const [remainingOverlayTime, setRemainingOverlayTime] =
    useState<RemaningTime>(null)
  const [remainingVideoLoopTime, setRemainingVideoLoopTime] =
    useState<RemaningTime>(null)
  const [activeChatOverlay, setActiveChatOverlay] = useState<OnStreamChatMessage>(
    (projectCommands.getChatOverlay()) || null,
  )

  return (
    <MediaContext.Provider
      value={{
        activeChatOverlay: activeChatOverlay,
        setActiveChatOverlay: setActiveChatOverlay,
        videoLoop: videoLoop,
        setVideoLoop,
        videoOverlay: videoOverlay,
        setVideoOverlay,
        background: background,
        setBackground,
        imageOverlay: imageOverlay,
        setImageOverlay,
        remainingOverlayTime,
        setRemainingOverlayTime,
        remainingVideoLoopTime,
        setRemainingVideoLoopTime,
        logo: logo,
        setLogo,
        activeBanner: activeBanner,
        setActiveBanner,
      }}
    >
      {children}
    </MediaContext.Provider>
  )
}

export { MediaContext, MediaProvider }
