import * as React from 'react'
import { style } from 'typestyle'
import { IconButton } from '../atoms/buttons/IconButton'
import Text from '../atoms/text/Text'
import * as Color from '../helpers/colors'

export interface IChatInputProps {
  fontSize?: number
  maxLength?: number

  onSend(message: string): void
}

const chatInputContainer = style({
  padding: 15,
  borderRadius: 4,
  position: 'relative',
  marginTop: 15,
  border: '1px solid transparent',
})

const sendButtonContainer = style({
  position: 'absolute',
  right: 6,
  bottom: 6,
})

const transparentTextInput = style({
  color: '#FFF',
  background: 'transparent',
  padding: 0,
  overflow: 'hidden',
  outline: 'none',
  verticalAlign: 'top',
  width: '100%',
  fontSize: 14,
  resize: 'none',
  paddingRight: 35,
  maxHeight: 600,
  $nest: {
    '&::placeholder': {
      color: 'rgba(255, 255, 255, 0.5)',
      fontWeight: 500,
    },
  },
})

export const ChatInput: React.FC<IChatInputProps> = React.memo(
  (props: IChatInputProps) => {
    const [focused, setFocus] = React.useState(false)
    const [message, setMessage] = React.useState('')
    const [rows, setRows] = React.useState(1)
    const inputRef = React.useRef<HTMLTextAreaElement>(null)

    const onSend = React.useCallback(() => {
      const msg = message.trim()

      if (msg.length >= 1 && msg.length <= 200) {
        props.onSend(msg)
        setMessage('')
      }
    }, [props.onSend, message])

    // Catch the user pressing enter and take that as them hitting submit.
    const onKeyDown = React.useCallback(
      (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter') {
          e.preventDefault()

          onSend()

          return false
        }
      },
      [props.onSend, message, onSend],
    )

    // Automatically expand the input when required.
    const onChange = React.useCallback(
      (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(e.target.value)

        // Calculate the number of rows needed to display the text
        e.target.rows = 1
        const rows = Math.floor(e.target.scrollHeight / props.fontSize)
        e.target.rows = rows
        setRows(rows)
      },
      [props.onSend, message, props.fontSize],
    )

    const disabled = message.length < 1 || message.length > 200
    const error = message.length > 200

    return (
      <div style={{ width: '100%', padding: 16, paddingTop: 0 }}>
        <div
          className={chatInputContainer}
          onClick={() => inputRef.current?.select()}
          style={{
            backgroundColor: Color.neutral(800),
            borderColor: error
              ? Color.secondary(500)
              : focused
              ? Color.primary(500)
              : Color.neutral(600),
          }}
        >
          <textarea
            style={{ fontSize: props.fontSize }}
            rows={rows}
            ref={inputRef}
            className={transparentTextInput}
            value={message}
            onClick={(e) => e.stopPropagation()}
            onFocus={() => {
              setFocus(true)
            }}
            onBlur={() => {
              setFocus(false)
            }}
            placeholder="Type a message"
            onChange={onChange}
            onKeyDown={onKeyDown}
          />

          <div className={sendButtonContainer}>
            <IconButton
              icon="Send"
              disabled={disabled}
              onClick={onSend}
              appearance={disabled ? 'text' : 'solid'}
              color={disabled ? 'neutral' : 'primary'}
              colorWeight={disabled ? 400 : 600}
            />
          </div>
        </div>
        <Text.Caption1
          fontWeight={400}
          marginTop={5}
          style={{ marginRight: 2 }}
          textAlign="right"
          color={error ? 'secondary' : 'neutral'}
          colorWeight={error ? 500 : 600}
          text={`${message.length} / ${props.maxLength}`}
        />
      </div>
    )
  },
)

ChatInput.defaultProps = {
  maxLength: 200,
  fontSize: 14,
}
