import React, { useState, useEffect } from 'react'
import { Modal } from '../FloatingMenu/FloatingMenu'
import { Flex } from '../../layout/Box'
import { style } from 'typestyle'
import * as Colors from '../../helpers/colors'
import { subscribeToEscape } from '../../helpers/escape-context'

const modalBackground = style({
  background: Colors.color(Colors.neutral(900)).fade(0.9).toString(),
})

const modalBackgroundLight = style({
  background: Colors.color(Colors.neutral(900)).fade(0.3).toString(),
})

const modalContainer = style({
  background: Colors.neutral(900),
  borderRadius: '16px',
  padding: '20px',
})

export const NormalModal = (props: {
  isOpen: boolean
  close: () => void
  children?: React.ReactNode
  darkBackground?: boolean
  width?: string | number
  height?: string | number
}) => {
  const darkBackground = props.darkBackground ?? true
  useEffect(() => {
    if (props.isOpen) {
      return subscribeToEscape(() => {
        props.close()
      })
    }
  }, [props.isOpen])
  return (
    <Modal
      top="0%"
      isOpen={props.isOpen}
      onClose={() => {
        props.close()
      }}
    >
      {/* outer-most is just blurred background */}
      <Flex
        onClick={(e) => {
          e.stopPropagation()
          props.close()
        }}
        align="center"
        justify="center"
        height="100vh"
        width="100vw"
        className={darkBackground ? modalBackground : modalBackgroundLight}
      >
        <Flex
          direction="column"
          className={modalContainer}
          width={props.width}
          height={props.height}
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          {props.children}
        </Flex>
      </Flex>
    </Modal>
  )
}

const darkModalContainer = style({
  background: Colors.neutral(900),
  padding: '16px',
  borderRadius: '6px',
  border: `1px solid ${Colors.neutral(800)}`,
})

export const DarkModal = (props: {
  isOpen: boolean
  close: () => void
  children?: React.ReactNode
}) => {
  useEffect(() => {
    return subscribeToEscape(() => {
      props.close()
    })
  }, [props.isOpen])
  return (
    <Modal
      top="0%"
      isOpen={props.isOpen}
      onClose={() => {
        props.close()
      }}
    >
      {/* outer-most is just blurred background */}
      <Flex
        onClick={(e) => {
          e.stopPropagation()
          props.close()
        }}
        align="center"
        justify="center"
        height="100vh"
        width="100vw"
        className={modalBackground}
      >
        <Flex
          direction="column"
          align="center"
          className={darkModalContainer}
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          {props.children}
        </Flex>
      </Flex>
    </Modal>
  )
}
