import React, { useLayoutEffect, RefObject, DependencyList } from 'react'

type ObserverCallback = (entries: DOMRectReadOnly[]) => void

const getRect = (x: HTMLElement) => x.getBoundingClientRect() as DOMRectReadOnly

export const useResizeObserver = (
  els: HTMLElement[],
  callback: ObserverCallback,
  triggers: DependencyList = [],
) => {
  useLayoutEffect(() => {
    const invalidRef = els.some((x) => !x)
    if (invalidRef) return

    // TODO: Use a singleton resize observer for performance
    const resizeObserver = new (window as any).ResizeObserver(() => {
      // Note: Not using entries because bounding rect is proving to be
      //  more reliable
      callback(els.map(getRect))
    })

    els.forEach((el) => resizeObserver.observe(el))

    return () => {
      resizeObserver.disconnect()
    }
  }, [...els, ...triggers])
}

export default useResizeObserver
