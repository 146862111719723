import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Helpers, SDK } from '@api.stream/studio-kit'
import { GreenRoom } from '../components/GreenRoom'
import { ControlPanel } from '@ui/components/ControlPanel'
import { layouts } from '../utils/scene-layouts'
import { TopBar } from '../components/TopBar'
import Box, { Column, Flex, Row } from '@ui/layout/Box'
import { CameraSettings } from '@ui/components/CameraSettings'
import { NormalModal } from '@ui/atoms/FloatingMenu/Modal'
import { MediaProvider } from '../context/media-context'
import { UploadProvider } from '../context/upload-context'
import { AppContext } from '../context/app-context'
import { Sidebar } from '../host/sidebar/Sidebar'
import Text from '@ui/atoms/text/Text'
import SceneLayoutPanel from '@ui/components/SceneLayoutPanel'
import * as Sentry from '@sentry/react'
import useEffectOnce from '@ui/hooks/useEffectOnce'
import { hasPermission, Permission, permissions } from '../utils/permissions'
import { PlatformChatProvider } from '../context/platform-chat-context'
import { Welcome } from './Welcome'
import { UserType } from '../types'
import { style, media } from 'typestyle'
import { PermissionAlerter } from './Permissions'
import { HotKeyManager } from './HotKeyManager'
import useElementFromRef from '@ui/hooks/useElementFromRef'
import useResizeObserver from '@ui/hooks/useResizeObserver'
import { AdditionalCameraSettings } from '@ui/components/AdditionalCameraSettings'

const { useDevices, StudioContext } = Helpers.React

const canvasContainer = style(
  {
    padding: 20,
    flex: 1,
    overflow: 'hidden',
  },
  media(
    { minHeight: 0, maxHeight: 900 },
    {
      height: '100%',
      width: '100%',
    },
  ),
)

const canvasWrapper = style(
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    aspectRatio: '16 / 9',
  },
  media(
    { minHeight: 0, maxHeight: 900 },
    {
      height: '100%',
      width: '100%',
      overflowX: 'hidden',
    },
  ),
)

const canvasRenderer = style(
  {
    width: '100%',
    height: '100%',
    maxWidth: 1280,
    maxHeight: 720,
    aspectRatio: '16 / 9',
  },
  media(
    { minHeight: 0, maxHeight: 900 },
    {
      overflowX: 'hidden',
    },
  ),
)

export const Project = () => {
  const app = useContext(AppContext)
  const { project, projectCommands, track } = app
  const { studio, room, setMicrophoneId, setWebcamId, webcamId } =
    useContext(StudioContext)
  const [speakerId, setSpeakerId] = useState('default')
  const renderContainer = useRef()
  const devices = useDevices()
  const [settingsOpen, setSettingsOpen] = useState(false)
  const [additionalSettingsOpen, setAdditionalSettingsOpen] = useState(false)
  const [disposeSettings, setDisposeSettings] = useState(() => () => {})
  const [controlRef, controlEl] = useElementFromRef<HTMLElement>()
  const [containerWidth, setContainerWidth] = useState(0)
  useResizeObserver([controlEl], () => {
    setContainerWidth(controlEl.scrollWidth)
  })
  // TODO: Read from `project` instead of managing local state
  //  Depends on upgrade to React Context in Studio Kit
  const [localLayout, setLocalLayout] = useState(project.props.layout)

  const [isLive, setIsLive] = useState(project.isLive)

  // Set Sentry context
  useEffectOnce(
    () => {
      Sentry.setContext('studio-kit', {
        version: studio.version,
      })
    },
    [studio],
    ([studio]) => Boolean(studio),
  )

  // Record studio-kit events as breadcrumbs to Sentry
  useEffectOnce(
    () => {
      if (!studio) return
      return studio.subscribe((event, payload) => {
        Sentry.addBreadcrumb({
          category: 'studio-kit.event',
          message: event,
          data: payload,
          level: 'info',
        })
      })
    },
    [studio],
    ([studio]) => Boolean(studio),
  )

  // Listen for project events
  useEffect(() => {
    return studio.subscribe((event, payload) => {
      if (event === 'ProjectChanged' && payload.project.id === project.id) {
        setIsLive(payload.project.isLive)
      }
    })
  }, [])

  // Set up audio sink
  useEffect(() => {
    let speaker = devices.speakers.find((x) => x.deviceId === speakerId)
    if (!speaker) {
      return setSpeakerId(devices.speakers[0]?.deviceId)
    }

    // TODO: Uncomment once this method is available
    // room.setAudioOutput(speaker.deviceId)
  }, [speakerId, devices])

  useEffectOnce(
    () => {
      studio.render({
        containerEl: renderContainer.current,
        projectId: project.id,
        dragAndDrop: hasPermission(project.role, Permission.UpdateProject),
      })
    },
    [renderContainer.current],
    ([container]) => Boolean(container),
  )

  return (
    <PlatformChatProvider isLive={isLive}>
      <PermissionAlerter />
      <UploadProvider>
        <MediaProvider>
          <Column height="100%">
            <TopBar />
            <NormalModal
              isOpen={settingsOpen}
              close={() => {
                setSettingsOpen(false)
              }}
            >
              <Column padding={20}>
                <Flex marginBottom={20}>
                  <Text.Heading1 text="Settings" />
                </Flex>
                <CameraSettings
                  nameDisabled={
                    !hasPermission(project.role, Permission.ManageGuests)
                  }
                  setDispose={(fn) => setDisposeSettings(() => fn)}
                  onComplete={(settings) => {
                    disposeSettings()
                    window.setTimeout(() => {
                      setMicrophoneId(settings.microphoneId)
                      setWebcamId(settings.webcamId)
                      setSpeakerId(settings.speakerId)
                      setSettingsOpen(false)
                      const participant = room.getParticipant(
                        room?.participantId,
                      )

                      if (participant) {
                        if (
                          hasPermission(project.role, Permission.ManageGuests)
                        ) {
                          /* Setting the participant metadata to the room. */
            
                          return room.setParticipantMetadata(
                            room?.participantId,
                            {
                              ...participant.meta,
                              isMirrored: settings?.isMirrored,
                              displayName:
                                settings.displayName ||
                                (app.userType === UserType.HOST
                                  ? 'Host'
                                  : 'Guest'),
                            },
                          )
                        }

                        if (
                          hasPermission(
                            participant?.role,
                            Permission.ManageSelf,
                          )
                        ) {
                          /* Setting the local participant metadata. */
                          return room.setLocalParticipantMetadata(
                            room?.participantId,
                            {
                              ...participant.meta,
                              isMirrored: settings?.isMirrored,
                            },
                          )
                        }
                      }
                    })
                  }}
                />
              </Column>
            </NormalModal>
            <NormalModal
              isOpen={additionalSettingsOpen}
              close={() => {
                setAdditionalSettingsOpen(false)
              }}
            >
              <Column padding={20}>
                <Flex marginBottom={20}>
                  <Text.Heading1 text="Settings" />
                </Flex>
                <AdditionalCameraSettings
                  startingWebcamId={webcamId}
                  nameDisabled={
                    !hasPermission(project.role, Permission.ManageGuests)
                  }
                  setDispose={(fn) => setDisposeSettings(() => fn)}
                  onComplete={(settings) => {
                    disposeSettings()
                    window.setTimeout(async () => {
                      setAdditionalSettingsOpen(false)
                      if (settings.webcamId) {
                        // Add the screen to the room
                        const track = await room.addCamera({
                          deviceId: settings?.webcamId,
                        })

                        const participant = room.getParticipant(
                          room?.participantId,
                        )

                        if (participant) {
                          if (
                            hasPermission(project.role, Permission.ManageGuests)
                          ) {
                            /* Setting the participant metadata to the room. */
                            const externalTracks = participant?.meta?.externalTracks || [];
                            const isExternalCamExist = externalTracks?.some(
                              (t: string) => t === track?.id,
                            )
                            if (!isExternalCamExist) {
                              externalTracks.push(track?.id)
                            }
                            return room.setParticipantMetadata(
                              room?.participantId,
                              {
                                ...participant.meta,
                                externalTracks,
                                [track?.id]: {
                                  isMirrored: settings?.isMirrored,
                                  displayName:
                                    settings.displayName ||
                                    (app.userType === UserType.HOST
                                      ? 'Host'
                                      : 'Guest'),
                                },
                              },
                            )
                          }
                        }
                      }
                    })
                  }}
                />
              </Column>
            </NormalModal>
            <Row
              align="stretch"
              width="100%"
              grow={1}
              shrink={1}
              overflow="hidden"
              style={{ zIndex: 1 }}
            >
              <GreenRoom />
              <div className={canvasContainer}>
                <div className={canvasWrapper}>
                  <div ref={renderContainer} className={canvasRenderer}></div>
                  <Row
                    forwardedRef={controlRef}
                    maxWidth={1280}
                    width="100%"
                    justify="space-between"
                    style={{
                      flexWrap: 'wrap-reverse',
                      display: containerWidth >= 1150 ? 'flex' : 'block',
                    }}
                  >
                    <Row
                      {...(containerWidth < 1150 && {
                        maxWidth: 1280,
                        width: '100%',
                      })}
                      justify="space-between"
                      style={{
                        flexWrap: 'wrap-reverse',
                      }}
                    >
                      {app.userType !== UserType.ADMIN && (
                        <Flex
                          marginTop={20}
                          marginRight={15}
                          justify="flex-start"
                        >
                          <ControlPanel
                            handleSettingsClicked={() => {
                              setSettingsOpen(true)
                            }}
                            handleAdditionalSettingsClicked={() => {
                              setAdditionalSettingsOpen(true)
                            }}
                          />
                        </Flex>
                      )}
                      {app.userType !== UserType.GUEST && (
                        <Flex marginTop={20} justify="flex-end">
                          <HotKeyManager />
                        </Flex>
                      )}
                    </Row>
                    {app.userType !== UserType.GUEST && (
                      <Flex
                        marginTop={containerWidth >= 1150 ? 20 : 25}
                        justify="flex-end"
                      >
                        <SceneLayoutPanel
                          activeLayout={localLayout}
                          onChange={(layoutName) => {
                            const {
                              layout,
                              props,
                            }: {
                              layout: Helpers.ScenelessProject.LayoutName
                              props: Helpers.ScenelessProject.LayoutProps
                            } = layouts[layoutName]

                            track('SetLayout', { value: layoutName })
                            projectCommands.setLayout(layout, props)

                            // Store our custom layout configuration by name
                            studio.Command.updateProjectProps({
                              projectId: project.id,
                              props: {
                                layout: layoutName,
                              },
                            })
                            // TODO: Remove
                            setLocalLayout(layoutName)
                          }}
                        />
                      </Flex>
                    )}
                  </Row>
                </div>
              </div>

              <Sidebar />
            </Row>
          </Column>
        </MediaProvider>
      </UploadProvider>
      <Welcome />
    </PlatformChatProvider>
  )
}
