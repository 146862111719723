import produce from 'immer'
import React, { createContext, useContext, useReducer, useEffect } from 'react'
import * as Types from '../types'
import {
  Events,
  User,
  App,
  Account,
  Resource,
  trigger,
  registerDispatcher,
} from '../utils/events'
import { AppContext } from './app-context'
import { RPCError } from 'magic-sdk'
import * as Sentry from '@sentry/react'
import { identifyUser } from '../utils/analytics'
import { closeAuthWindow } from '../utils/auth'
import useEffectOnce from '@ui/hooks/useEffectOnce'
import every from 'lodash/every'
import { hideEmail, sendMagicLogs } from '../utils/magiclink-log'

const initialState = {
  loggedIn: false,
  emailVerified: false,
} as Types.User

/**
 * @param token Magic links DID token
 * @param haveSession Set to true if we are already logged in with session, otherwise set to false
 */
const authWithToken = (token: string, haveSession: boolean) =>
  fetch(`/api/auth/magic${haveSession ? '' : '?toEnterApp=true'}`, {
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
    method: 'POST',
    credentials: 'same-origin',
  })

export const UserContext = createContext<Types.User>(initialState)
UserContext.displayName = 'User'

const checkPlanForRes = (plan: string) => {
  if (plan?.startsWith('creator_720_30')) {
    return {
      resolution: {
        x: 1280,
        y: 720,
        framerate: 30,
      },
    }
  } else if (plan?.startsWith('creator_720_60')) {
    return {
      resolution: {
        x: 1280,
        y: 720,
        framerate: 60,
      },
    }
  } else if (plan?.startsWith('creator_1080_30')) {
    return {
      resolution: {
        x: 1920,
        y: 1080,
        framerate: 30,
      },
    }
  } else {
    return {
      resolution: {
        x: 1280,
        y: 720,
        framerate: 30,
      },
    }
  }
}

export const UserProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [user, dispatch] = useReducer(reducer, initialState)
  const app = useContext(AppContext)
  window.user = user // Debug helper

  useEffect(() => {
    registerDispatcher(dispatch)
  }, [])

  useEffectOnce(
    () => {
      if (!user.id) return
      Sentry.setUser({ id: user.id })
      identifyUser(user, app)
    },
    [user.id, app.kustomerApiKey, app.segment],
    every,
  )

  useEffect(() => {
    if (!app.visitedFromMagicLink || !app.magicInstance) return
    sendMagicLogs({
      message: {
        state: 'email-link-visited',
        email: hideEmail(app?.magicLinkEmail),
      },
    })
    app.magicInstance.auth
      .loginWithCredential(app.magicLinkCredentials)
      .then(() => trigger(User.VerifiedEmail, { email: app.magicLinkEmail }))
      .catch((e) => {
        trigger(User.EmailVerificationFailed, { e, email: app.magicLinkEmail })
      })
  }, [app.visitedFromMagicLink, app.magicInstance])

  useEffect(() => {
    if (!app.magicInstance) return
    // There is no validation to run
    if (!app.visitedFromMagicLink) return
    // Email has not yet been verified
    if (!user.emailVerified) return

    app.magicInstance.user.getIdToken().then(async (token) => {
      // Log in to Lightstream
      const response = await authWithToken(token, user.loggedIn)
      // check if response has error
      trigger(User.EmailAuthenticated)
      // Reconnect socket to get the bootstrap data
      app.reconnectSocket()
    })
  }, [app.magicInstance, app.visitedFromMagicLink, user.emailVerified])

  useEffect(() => {
    if (!app.magicInstance) return
    if (!app.loaded || (user.loggedIn && user.emailVerified)) return

    // If the user is logged in with magic link, but not with Lightstream,
    //  then they'll be unable to receive another login link.
    // Log the user out of magic and reload.
    app.magicInstance.user.isLoggedIn().then((magicLoggedIn) => {
      if (magicLoggedIn) {
        app.magicInstance.user.logout().then(() => {
          window.location.reload()
        })
      }
    })
  }, [app.magicInstance, app.loaded, user.loggedIn, user.emailVerified])

  return (
    <UserContext.Provider
      value={
        user
          ? {
              ...user,
            }
          : null
      }
    >
      {children}
    </UserContext.Provider>
  )
}

/**
 * User Reducer
 */

const reducer = produce((user: Types.User, event: Events): Types.User => {
  console.log('User Reducer received:', event)
  const { type, payload } = event

  switch (type) {
    case App.SocketConnected: {
      const { bootstrap } = payload
      const plan = checkPlanForRes(bootstrap?.user?.capabilities?.role)
      if (bootstrap.user) {
        Object.assign(user, {
          ...bootstrap.user,
          ...plan,
          loggedIn: true,
        })
      }
      break
    }

    case User.VerifiedEmail: {
      user.emailVerified = true
      sendMagicLogs({
        message: {
          state: 'email-verified',
          email: hideEmail(payload?.email),
        },
      })
      break
    }

    case User.EmailVerificationFailed: {
      user.emailVerified = false
      sendMagicLogs({
        message: {
          state: 'email-verification-failed',
          reason: payload?.e?.rawMessage || payload?.e?.message,
          email: hideEmail(payload?.email),
        },
      })
      break
    }

    case User.UserMergeSucceeded: {
      closeAuthWindow()
      // @ts-ignore
      window.location = `${window.location.origin}/v2`
      window.location.reload()
      break
    }

    case User.CapabilitiesChanged: {
      const { capabilities, role, subscription } = payload
      user.capabilities = capabilities
      user.roles = [...user.roles, role]
      // const plan = checkPlanForRes(user?.capabilities?.role)
      // user = { ...user, ...plan }
      user.subscription = subscription
      break
    }

    case User.UpdatedBillingAddress: {
      user.subscription = event.payload
      break
    }
    case Account.Authenticated: {
      const { account } = payload
      user.accounts = [
        ...(user.accounts?.filter((a) => a.id !== account.id) ?? []),
        account,
      ]
      break
    }
    case Resource.Added: {
      const { resource } = payload
      user.resources = [...user.resources, resource]
      break
    }
    case Resource.Removed: {
      const { resource } = payload
      user.resources = user.resources.filter((r) => r.id !== resource.id)
      break
    }
    case Resource.Updated: {
      const { resource } = payload
      user.resources = user.resources.map((r) =>
        r.id === resource.id ? resource : r,
      )
      break
    }
  }

  return user
})

export const useUser = () => useContext(UserContext)

// Note: This does not work as expected.
//  Until an understanding is reached -
//  Instead call `useContext(UserContext)` directly
// export const useUser = () => useContext(UserContext)
