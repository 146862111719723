import * as React from 'react';
import classNames from 'classnames';
import Box from '@ui/layout/Box';
import Icon, { IconName } from '@ui/atoms/icons/Icon'
import { TextProps } from '@ui/atoms/text/Text';
import * as Colors from '@ui/helpers/colors'
import Text from '@ui/atoms/text/Text';
import {
    toasterWrapper,
    toasterContent,
} from '@ui/atoms/toaster/ToasterStyle'
interface styleProps {
    backgroundColor?: TextProps['color']
    backgroundColorWeight?: TextProps['colorWeight'],
    textColor?: TextProps['color']
    textColorWeight?: TextProps['colorWeight']
}
export interface ToastConfig {
    content?: React.ReactNode;
    duration?: number | null;
    closeIcon?: IconName;
    icon?: IconName;
    closable?: boolean;
    className?: string;
    style?: styleProps;
    /** @private Internal usage. Do not override in your code */
    props?: React.HTMLAttributes<HTMLDivElement> & Record<string, any>;

    onClose?: VoidFunction;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export interface ToastProps extends Omit<ToastConfig, 'onClose'> {
    prefixCls: string;
    className?: string;
    style?: styleProps;
    eventKey: React.Key;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    onToastClose?: (key: React.Key) => void;
}

const Notify = React.forwardRef<HTMLDivElement, ToastProps>((props, ref) => {
    const {
        prefixCls,
        style = {
            backgroundColor: 'primary',
            backgroundColorWeight: 900,
        },
        duration = 4.5,
        eventKey,
        content,
        closable,
        closeIcon = 'Close',
        icon = 'Checkmark',
        props: divProps,

        onClick,
        onToastClose,
    } = props;
    const [hovering, setHovering] = React.useState(false);

    // ======================== Close =========================
    const onInternalClose = () => {
        onToastClose(eventKey);
    };

    // ======================== Effect ========================
    React.useEffect(() => {
        if (!hovering && duration > 0) {
            const timeout = setTimeout(() => {
                onInternalClose();
            }, duration * 1000);

            return () => {
                clearTimeout(timeout);
            };
        }
    }, [duration, hovering]);

    // ======================== Render ========================
    const toastPrefixCls = `${prefixCls}`;

    const toasterBackground = {
        backgroundColor: Colors[style.backgroundColor](style.backgroundColorWeight),
    }

    const toasterBorder = {
        border: `1px solid ${Colors[style.backgroundColor](style.backgroundColorWeight)}`,
    }

    return (
        <div
            {...divProps}
            ref={ref}
            className={classNames(toastPrefixCls, toasterWrapper, {
                [`${toastPrefixCls}-closable`]: closable,
            })}
            style={toasterBorder}
            onMouseEnter={() => {
                setHovering(true);
            }}
            onMouseLeave={() => {
                setHovering(false);
            }}
            onClick={onClick}
        >
            {/* Content */}
            <Box className={toasterContent} style={toasterBackground}>
                {/* Front Icon */}
                {typeof content === 'string' ? (
                    <>
                        {icon && (
                            <Box className={`${toastPrefixCls}-icon`}>
                                <Icon name={icon} height={20} width={20} />
                            </Box>
                        )
                        }
                        <Box className={`${toastPrefixCls}-content`} >
                            <Text.Body
                                color={style.textColor}
                                colorWeight={style.textColorWeight}
                                text={content}
                            />
                        </Box>
                    </>
                ) : (
                    <Box className={`${toastPrefixCls}-content`} >{content}</Box>
                )}


                {/* Close Icon */}
                {closable && (
                    <a
                        tabIndex={0}
                        className={`${toastPrefixCls}-close`}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onInternalClose();
                        }}
                    >
                        <Icon name={closeIcon} height={20} width={20} />
                    </a>
                )}
            </Box>
        </div>
    );
});

export default Notify;