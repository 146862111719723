import { useContext, useEffect, useState } from 'react'
import { IconButton } from '@ui/atoms/buttons/IconButton'
import { SDK } from '@api.stream/studio-kit'
import { WithInfo } from '@ui/atoms/FloatingMenu/FloatingMenu'
import { Row } from '@ui/layout/Box'
import { AppContext, StudioContext } from '../../src/context/app-context'
import { UserType } from '../../src/types'
import {
  PermissionStatus,
  usePermissions,
} from '../../src/context/permission-context'
import useEffectOnce from '@ui/hooks/useEffectOnce'
import { Camera, trigger } from '../../src/utils/events'
import * as Color from '@ui/helpers/colors'
import { margin } from 'csx'

export type ControlPanelProps = {
  handleSettingsClicked: () => void
  handleAdditionalSettingsClicked: () => void
}
export const ControlPanel = ({
  handleSettingsClicked,
  handleAdditionalSettingsClicked,
}: ControlPanelProps) => {
  const { userType, projectCommands, isMirrored } = useContext(AppContext)
  const { room } = useContext(StudioContext)
  const canScreenshare = Boolean(navigator.mediaDevices.getDisplayMedia)
  const Permission = usePermissions()

  // Local state
  const [webcamEnabled, setWebcamEnabled] = useState(true)
  const [microphoneEnabled, setMicrophoneEnabled] = useState(true)

  // External state
  const [participant, setParticipant] = useState<SDK.Participant>()
  const [screenTrack, setScreenTrack] = useState<SDK.Track>()
  const [webcamTrack, setWebcamTrack] = useState<SDK.Track>()
  const [microphoneTrack, setMicrophoneTrack] = useState<SDK.Track>()

  const isSharingScreen = Boolean(screenTrack)
  const webcamMuted = Boolean(webcamTrack?.isMuted)
  const microphoneMuted = Boolean(microphoneTrack?.isMuted)

  // Listen for self as participant
  useEffectOnce(
    () => {
      // TODO: Send mute request if either webcam or mic is disabled
      // TODO: This should get sent along with the "join" request
      return room.useParticipant(room.participantId, setParticipant)
    },
    [room],
    ([room]) => Boolean(room),
  )

  useEffect(() => {
    if (!room) return
    // Update local state
    setWebcamEnabled(!webcamMuted)
  }, [webcamMuted])

  useEffect(() => {
    if (!room) return
    // Update local state
    setMicrophoneEnabled(!microphoneMuted)
  }, [microphoneMuted])

  // Listen for room participants
  useEffect(() => {
    if (!room) return
    return room.useParticipant(room.participantId, setParticipant)
  }, [room])

  useEffect(() => {
    if (!participant) return
    const screenshareId = participant.trackIds.find(
      (x) => room.getTrack(x)?.type === 'screen_share',
    )
    const microphoneId = participant.trackIds.find(
      (x) => room.getTrack(x)?.type === 'microphone',
    )
    const webcamId = participant.trackIds.find(
      (x) => room.getTrack(x)?.type === 'camera',
    )
    if (!screenshareId) {
      // TODO: Move this to a central file for managing project state
      projectCommands.removeParticipant(room.participantId, 'screen')
    }
    setScreenTrack(room.getTrack(screenshareId))
    setMicrophoneTrack(room.getTrack(microphoneId))
    setWebcamTrack(room.getTrack(webcamId))
  }, [participant?.trackIds])

  const microphoneActive =
    microphoneEnabled &&
    Permission.microphone.status === PermissionStatus.Allowed

  const webcamActive =
    webcamEnabled && Permission.camera.status === PermissionStatus.Allowed

  return (
    <Row>
      <WithInfo
        message={
          microphoneActive ? 'Turn off microphone' : 'Turn on microphone'
        }
        node={
          <IconButton
            height={48}
            width={48}
            iconSize={24}
            icon={microphoneActive ? 'Microphone' : 'MicrophoneOff'}
            color={microphoneActive ? 'neutral' : 'secondary'}
            colorWeight={microphoneActive ? 800 : 400}
            onClick={() => {
              if (Permission.microphone.status !== PermissionStatus.Allowed) {
                Permission.request.microphone()
              }
              setMicrophoneEnabled(!microphoneActive)
              room.setMicrophoneEnabled(!microphoneActive).catch((e) => {
                console.warn(e)
              })
            }}
          />
        }
      />
      <WithInfo
        marginLeft={8}
        message={webcamActive ? 'Turn off webcam' : 'Turn on webcam'}
        node={
          <IconButton
            height={48}
            width={48}
            iconSize={24}
            icon={webcamActive ? 'Video' : 'VideoOff'}
            color={webcamActive ? 'neutral' : 'secondary'}
            colorWeight={webcamActive ? 800 : 400}
            onClick={() => {
              if (Permission.camera.status !== PermissionStatus.Allowed) {
                Permission.request.camera()
              }
              setWebcamEnabled(!webcamActive)
              room.setCameraEnabled(!webcamActive).catch((e) => {
                console.warn(e)
              })
            }}
          />
        }
      />
      <WithInfo
        message="Settings"
        marginLeft={8}
        node={
          <IconButton
            height={48}
            width={48}
            iconSize={24}
            icon="Gear"
            color="neutral"
            colorWeight={800}
            onClick={() => {
              handleSettingsClicked()
            }}
          />
        }
      />

      <span
        style={{
          width: 1,
          height: 48,
          border: `1px solid ${Color.neutral(800)}`,
          marginLeft: 14,
          marginRight: 14,
        }}
      />

      {canScreenshare && (
        <WithInfo
          message={isSharingScreen ? 'Stop sharing screen' : 'Share screen'}
          node={
            <IconButton
              height={48}
              width={48}
              iconSize={24}
              icon={isSharingScreen ? 'Tv' : 'TvPlus'}
              color={isSharingScreen ? 'primary' : 'neutral'}
              colorWeight={isSharingScreen ? 500 : 800}
              onClick={() => {
                if (!isSharingScreen) {
                  // Add the screen to the room
                  room.addScreen()
                } else {
                  // Remove the screen from the room
                  // TODO: Create room.removeScreen() helper
                  room
                    .getParticipant(room.participantId)
                    .trackIds.forEach((x) => {
                      if (room.getTrack(x).type === 'screen_share') {
                        room.removeTrack(x)
                      }
                    })
                }
              }}
            />
          }
        />
      )}

      {userType === UserType.HOST && (
        <WithInfo
          marginLeft={8}
          message={'Add another camera'}
          node={
            <IconButton
              height={48}
              width={48}
              iconSize={24}
              icon="CameraAdd"
              color="neutral"
              colorWeight={800}
              onClick={async () => {
                handleAdditionalSettingsClicked()
              }}
            />
          }
        />
      )}
      {userType !== UserType.HOST && (
        <WithInfo
          message="Exit"
          marginLeft={8}
          node={
            <IconButton
              height={48}
              width={48}
              iconSize={24}
              icon="ArrowRightBracket"
              color="neutral"
              colorWeight={800}
              iconColor="secondary"
              iconColorWeight={400}
              onClick={() => {
                document.location.reload()
              }}
            />
          }
        />
      )}
    </Row>
  )
}
