import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react'
import produce from 'immer'
import { Helpers } from '@api.stream/studio-kit'
import {
  Events,
  registerDispatcher,
  User,
  App,
  Project,
  subscribe,
  trigger,
  Camera,
} from '../utils/events'
import { useWebsocket } from '../utils/websocket'
import * as Types from '../types'
import * as Sentry from '@sentry/react'
import useEffectOnce from '@ui/hooks/useEffectOnce'
import every from 'lodash/every'
import * as Magic from 'magic-sdk'
import { hideEmail, sendMagicLogs } from '../utils/magiclink-log'

const { StudioContext } = Helpers.React

const getInitialState = () => {
  const currentLocation = new URL(window.location.toString())
  const magicLinkCredentials =
    currentLocation.searchParams.get('magic_credential')
  const magicLinkEmail = currentLocation.searchParams.get('email')
  const commandToken = currentLocation.searchParams.get('commandToken')
  const visitedFromMagicLink = Boolean(magicLinkCredentials)

  let entryUrl = `${window.location.origin}${window.location.pathname}`

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop: any) => searchParams.get(prop),
  }) as any
  const guestToken = params.at

  if (!guestToken) {
    // Strip credentials from the URL
    window.history.replaceState({}, '', entryUrl)
  }

  return {
    userType: Types.UserType.GUEST,
    loaded: false,
    visitedFromMagicLink,
    magicLinkCredentials,
    magicLinkEmail,
    entryUrl,
    guestToken,
    commandToken,
    isLive: false,
    isMirrored: localStorage.getItem('isMirrored') === 'true' ? true : false,
  } as Partial<Types.App>
}

const initialState = getInitialState()

export const AppContext = React.createContext<Types.App>(
  initialState as Types.App,
)
AppContext.displayName = 'App'

type AppProps = {
  children: React.ReactChild
} & Partial<Types.App>

export const AppProvider = ({ children, ...presets }: AppProps) => {
  const { studio } = useStudio()
  const [socket, reconnectSocket] = useWebsocket()
  const [app, dispatch] = useReducer(reducer, {
    ...(initialState as Types.App),
    ...presets,
    socket,
    reconnectSocket,
    track: (...args) => track(...args),
    // TODO: Uncomment with multiple project support
    // activeProjectId: null,
  })

  const track = useCallback(
    (event, data = {}) => {
      if (window.analytics) {
        // Note: Unsure why the dependency is not updating in callback.
        //  Opt to use the global reference to ensure latest value.
        if (window.app.project?.broadcastId) {
          // Capture broadcast update if live
          // Note: This assumes that every event coming through will be
          //  related to the broadcast output. If this changes then
          //  we should revisit our approach here.
          window.analytics.track('UpdateBroadcast', {
            event,
            data,
            broadcastId: window.app.project.broadcastId,
            date: Date.now(),
          })
        }
        // Capture specific event
        window.analytics.track(event, {
          ...data,
          broadcastId: window.app.project?.broadcastId,
          date: Date.now(),
        })
      }
    },
    [app.project],
  )

  // Debug helper / used for external state lookup (e.g. on Sentry error)
  window.app = app
  window.project = app.project

  // Set app context on Sentry events
  useEffect(() => {
    Sentry.setContext('app', {
      ...app,
    })
  }, [app])

  // Re-emit relevant StudioKit events into central dispatcher
  useEffectOnce(
    () => {
      return studio.subscribe((event, payload) => {
        switch (event) {
          /**
           * TODO: Wire up when supporting multiple projects.
           *  Currently there is no good way to fetch the state of
           *  a new active project from studio-kit
           */
          // case 'ActiveProjectChanged': {
          //   return dispatch({
          //     type: App.ActiveProjectChanged,
          //     payload: {
          //       projectId: payload.projectId,
          //       isLive: project.isLive
          //     },
          //   })
          // }
          case 'ProjectChanged': {
            return trigger(Project.Changed, {
              project: payload.project,
            })
          }
        }
      })
    },
    [studio],
    every,
  )

  // Record application events as breadcrumbs to Sentry
  useEffect(() => {
    return subscribe(({ payload, type }) => {
      Sentry.addBreadcrumb({
        category: 'application.event',
        message: type,
        data: payload,
        level: 'info',
      })
    })
  }, [])

  // Modify sentry events before sending out
  useEffect(() => {
    Sentry.configureScope((scope) => {
      scope.addEventProcessor((event) => {
        // Attach parameters that were unavailable at time of `Sentry.init()`
        return {
          ...event,
          environment: window.app.env,
        }
      })
    })
  }, [])

  useEffect(() => {
    if (!app.magicLink?.apiKey || app.magicInstance) return
    trigger(App.MagicInitialized, {
      magicInstance: new Magic.Magic(app.magicLink.apiKey, {
        testMode: false,
      }),
    })
  }, [app.magicLink?.apiKey, app.magicInstance])

  useEffect(() => {
    registerDispatcher(dispatch)
  }, [])

  useEffect(() => {
    document.body.setAttribute('data-env', app.env)
  }, [app.env])

  useEffect(() => {
    localStorage.setItem('isMirrored', String(app?.isMirrored))
  }, [app.isMirrored])
  return (
    <AppContext.Provider
      value={{
        ...app,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

/**
 * App Reducer
 */

const reducer = produce((app: Types.App, event: Events) => {
  console.log('App Reducer received:', event)
  const { type, payload } = event

  switch (type) {
    case App.SocketConnected: {
      const { bootstrap } = payload

      if (!app.visitedFromMagicLink) {
        // App is not loaded until socket reconnects after verifying magic link
        app.loaded = true
      }
      if (bootstrap) {
        Object.assign(app, {
          ...bootstrap.app,
        })
      }
      break
    }
    case User.EmailAuthenticated: {
      app.visitedFromMagicLink = false
      sendMagicLogs({
        message: {
          state: 'email-authenticated',
          email: hideEmail(app?.magicLinkEmail),
        },
      })
      break
    }
    case Project.Changed: {
      app.project = payload.project
      app.projectCommands = !app.projectCommands
        ? Helpers.ScenelessProject.commands(app.project)
        : app.projectCommands
      break
    }
    case Camera.ModeChanged: {
      app.isMirrored = payload.isMirrored
      break
    }
    case App.MagicInitialized: {
      app.magicInstance = payload.magicInstance
      break
    }
  }
})

export { StudioContext }

export const useApp = () => useContext(AppContext)
export const useStudio = () => useContext(StudioContext)
