import React, { useState, useEffect } from 'react'
import * as Color from '../../helpers/colors'
import { classes, style } from 'typestyle'
import Box, { Flex, Row } from '../../layout/Box'
import Text, { CustomText } from '../text/Text'
import useElementFromRef from '../../hooks/useElementFromRef'
import useResizeObserver from '../../hooks/useResizeObserver'

export type Props = {
  value?: string
  defaultValue?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onClick?: React.MouseEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>
  appearance?: 'solid' | 'outline' | 'text'
  disabled?: boolean
  invalid?: boolean
  multiline?: boolean
  placeholder?: string
  uppercase?: boolean
  width?: React.CSSProperties['width']
  height?: React.CSSProperties['height']
  name?: string
  color?: Color.Type
  colorWeight?: number
  textColor?: Color.Type
  textColorWeight?: number
  maxLength?: number
  hideCharacterCount?: boolean
  minHeight?: number
  maxHeight?: number
  marginLeft?: number
  marginTop?: number
  autoFocus?: boolean
  autoHeight?: boolean
  selectOnFocus?: boolean
  required?: boolean
  readOnly?: boolean
  autoComplete?: 'on' | 'off' | string
  fontSize?: number
  paddingX?: number
  paddingY?: number
  type?: 'text' | 'email' | 'password' | 'search' | 'tel' | 'url'
  label?: JSX.Element
  // Avoid
  style?: React.CSSProperties
}

/** Styles */

const important = (x: string) => `${x} !important`

export const inputStyle = style({
  borderStyle: `solid`,
  borderWidth: 1,
  borderRadius: 4,
  padding: `0 .9em`,
  lineHeight: 1,
  fontWeight: 300,
  fontSize: 'inherit',
  outline: 'none',
  userSelect: 'none',
  width: '100%',
  maxWidth: 'none',
  resize: 'none',
  textOverflow: 'ellipsis',
  $nest: {
    '&::placeholder': {
      fontWeight: 300,
      fontStyle: 'italic',
    },
  },
})

const hideEmpty = style({
  $nest: {
    '&:empty': {
      display: important('none'),
    },
  },
})
export const modify = ({
  appearance,
  disabled,
  invalid,
  multiline,
  color,
  colorWeight,
  textColor,
  textColorWeight,
}: Props) => {
  const colors = {
    box: Color[color](colorWeight),
    text: Color[textColor](textColorWeight),
  }

  return style({
    borderColor: colors.box,
    backgroundColor: colors.box,
    color: colors.text,
    ...(disabled && {
      pointerEvents: 'none',
      opacity: 0.5,
    }),
    ...(appearance === 'outline' && {
      backgroundColor: 'transparent',
    }),
    ...(appearance === 'text' && {
      padding: '0 0.3em',
      borderColor: 'transparent',
      backgroundColor: 'transparent',
    }),
    ...(invalid && {
      borderColor: important(Color.secondary(500)),
    }),
    ...(multiline && {
      padding: '0.8em 0.9em',
    }),
    $nest: {
      ...(!disabled && {
        '&:hover': {
          borderColor: colors.box,
          ...(appearance === 'solid' && {
            backgroundColor: colors.box,
          }),
          ...(invalid && {
            borderColor: important(Color.secondary(500)),
          }),
        },
        '&:focus, &:focus-within': {
          borderColor: Color.primary(500),
          ...(invalid && {
            borderColor: important(Color.secondary(500)),
          }),
        },
      }),
    },
  })
}

const clamp = (value: number, min: number, max: number): number => {
  return Math.max(min, Math.min(max, value))
}

const TextInput = ({
  value,
  onChange,
  onFocus,
  onBlur,
  onClick,
  onKeyUp,
  onKeyDown,
  label,
  type = 'text',
  name,
  appearance = 'outline',
  width,
  height = '100%',
  disabled = false,
  invalid = false,
  uppercase = false,
  selectOnFocus = false,
  multiline = false,
  autoHeight = false,
  required = false,
  hideCharacterCount = false,
  readOnly = false,
  minHeight = 30,
  maxHeight = 600,
  marginLeft,
  marginTop,
  fontSize,
  placeholder = '',
  color = 'neutral',
  colorWeight = 800,
  textColor = 'neutral',
  textColorWeight = 0,
  paddingX,
  paddingY,
  maxLength,
  autoFocus,
  autoComplete,
  defaultValue,
  style = {},
}: Props) => {
  const [startValue] = useState(value || defaultValue)
  const [numChars, setNumChars] = useState(startValue ? startValue.length : 0)

  const colors = {
    box: Color[color](colorWeight),
    text: Color[textColor](textColorWeight),
  }

  const _onFocus: Props['onFocus'] = (e) => {
    if (selectOnFocus) {
      ;(e.target as HTMLInputElement).select()
    }
    if (onFocus) onFocus(e)
  }

  const _onChange: Props['onChange'] = (e) => {
    updateHeight()
    setNumChars(e.target.value.length)
    if (typeof defaultValue !== 'undefined' && !onChange) return
    onChange(e)
  }

  const [currentHeight, setCurrentHeight] = useState(height)
  const [ref, el] = useElementFromRef<HTMLTextAreaElement>()
  const [hiddenRef, hiddenEl] = useElementFromRef<HTMLTextAreaElement>()

  const updateHeight = () => {
    if (!autoHeight || !multiline || !el || !hiddenEl) return
    hiddenEl.value = el.value

    // 4 is a buffer to account for unknown diff between scrollHeight and true height
    const newHeight = clamp(hiddenEl.scrollHeight + 4, minHeight, maxHeight)
    const previousHeight = typeof currentHeight === 'number' ? currentHeight : 0

    // Prevent jitter due to inconsistencies in scroll height
    if (Math.abs(newHeight - previousHeight) < 4) {
      return
    }

    setCurrentHeight(newHeight)

    if (newHeight > previousHeight) {
      el.scrollTo({ top: el.offsetHeight })
    }
  }

  useResizeObserver([hiddenEl], () => {
    updateHeight()
  })

  useEffect(() => {
    updateHeight()
  }, [])

  const props = {
    className: classes(
      inputStyle,
      modify({
        appearance,
        disabled,
        invalid,
        multiline,
        color,
        colorWeight,
        textColor,
        textColorWeight,
      }),
    ),
    ...{
      type,
      name,
      value,
      placeholder,
      disabled,
      required,
      maxLength,
      autoFocus,
      autoComplete,
      readOnly,
      onChange: _onChange,
      onFocus: _onFocus,
      onKeyUp,
      onKeyDown,
      onBlur,
      onClick,
    },
    ...(defaultValue && { defaultValue }),
    style: {
      width,
      height,
      minWidth: width || 'fit-content',
      marginLeft,
      marginTop,
      fontSize,
      textTransform: uppercase ? 'uppercase' : null,
      ...(paddingX && {
        paddingLeft: paddingX,
        paddingRight: paddingX,
      }),
      ...(paddingY && {
        paddingTop: paddingY,
        paddingBottom: paddingY,
      }),
      ...style,
    } as React.CSSProperties,
  }

  return (
    <Box height="auto" width={width || 'auto'}>
      <Row
        className={hideEmpty}
        justify="space-between"
        fontSize={13}
        marginBottom={8}
        color={colors.box}
      >
        {label}
        {maxLength && !hideCharacterCount && (
          <Flex align="center" push="right">
            <CustomText text={numChars} />
            {' / '}
            <CustomText text={maxLength} />
          </Flex>
        )}
      </Row>
      {multiline ? (
        <>
          <textarea
            {...(props as React.TextareaHTMLAttributes<HTMLTextAreaElement>)}
            ref={hiddenRef}
            style={{
              ...props.style,
              position: 'absolute',
              pointerEvents: 'none',
              visibility: 'hidden',
              overflow: 'hidden',
              height: 0,
            }}
          />
          <textarea
            {...(props as React.TextareaHTMLAttributes<HTMLTextAreaElement>)}
            ref={ref}
            style={{
              ...props.style,
              height: currentHeight,
            }}
          />
        </>
      ) : (
        <input {...(props as React.InputHTMLAttributes<HTMLInputElement>)} />
      )}
    </Box>
  )
}

export { TextInput }
export default TextInput
