import { init, Helpers } from '@api.stream/studio-kit'
import { IconName } from '@ui/atoms/icons/Icon'

export const DEFAULT_LAYOUT: LayoutName = 'Contain'

export type LayoutName = keyof typeof layouts

export type LayoutDeclaration = {
  label: string
  icon: IconName
  iconBorder: boolean
  layout: Helpers.ScenelessProject.LayoutName
  props: Helpers.ScenelessProject.LayoutProps
}

// Custom layout configurations
export const layouts = {
  Contain: {
    label: 'Contain',
    icon: 'LayoutContain',
    iconBorder: true,
    layout: 'Grid',
    props: {
      cover: false,
    },
  } as LayoutDeclaration,
  Cover: {
    label: 'Cover',
    icon: 'LayoutCover',
    iconBorder: false,
    layout: 'Grid',
    props: {
      cover: true,
    },
  } as LayoutDeclaration,
  Spotlight: {
    label: 'Spotlight',
    icon: 'LayoutSpotlight',
    iconBorder: true,
    layout: 'Presentation',
    props: {
      cover: false,
      justifyViewers: 'center',
      barPosition: 'side',
      barWidth: 0.2,
      reverse: true,
    },
  } as LayoutDeclaration,
  Theater: {
    label: 'Theater',
    icon: 'LayoutTheater',
    iconBorder: true,
    layout: 'Presentation',
    props: {
      cover: false,
      justifyViewers: 'center',
      barPosition: 'bottom',
      barWidth: 0.2,
    },
  } as LayoutDeclaration,
  PiP: {
    label: 'Picture-in-Picture',
    icon: 'LayoutPiP',
    iconBorder: true,
    layout: 'Presentation',
    props: {
      cover: true,
      justifyViewers: 'flex-start',
      barPosition: 'bottom',
      barWidth: 0.2,
    },
  } as LayoutDeclaration,
}
