import Box, { Flex } from '@ui/layout/Box'
import React, { useCallback, useContext } from 'react'
import { style } from 'typestyle'
import { MediaContext } from '../../../src/context/media-context'
import { WithInfo } from '../FloatingMenu/FloatingMenu'
import Icon from '../icons/Icon'
import { iconStyles, IPlatformFilterIconProps } from './PlatformFilter'
import Text from '../text/Text'
import { AppContext } from '../../../src/context/app-context'
import * as Colors from '../../helpers/colors'
import { LetterAvatar } from '../icons/Avatar'
import { IMessageSegment } from '@streamjar/chatparser'
import { ChatMessageBubbleSegment } from './ChatMessageBubble'

export interface OnStreamChatMessage {
  // Unique identifier for the chat message.
  id?: string

  // Display name of the chat messages
  username?: string

  message?: IMessageSegment[]

  metadata? : {[prop:string] : any}
}

const OnStreamMessageContainer = style({
  padding: '0px 16px 16px',
  width: '100%',
  gap: 10,
})

const OnStreamMessageWrapper = style({
  backgroundColor: Colors.neutral(800),
  borderRadius: '4px',
  marginTop: '15px',
  padding: '15px',
  position: 'relative',
  border: '1px solid transparent',
  $nest: {
    '&:hover': {
      border: `1px solid ${Colors.primary(600)}`,
    },
  },
})

const PlatformButton = (props: Omit<IPlatformFilterIconProps, 'active'>) => {
  const platformStyle =
    iconStyles[props?.platform]?.[
      props?.variant % iconStyles[props?.platform]?.length
    ]

  const activeStyle = {
    backgroundColor: platformStyle?.background,
    color: platformStyle?.iconColor,
  }

  const platformButtonStyle = style({
    display: 'flex',
    flexDirection: 'row',
    padding: '4px 8px 4px 4px',
    borderRadius: 4,
    marginBottom: 5,
    marginLeft: 5,
    alignItems: 'center',
    borderBottomLeftRadius: '0px !important',
    position: 'relative',
    border: 'none',
    cursor: 'pointer',
    $nest: {
      '&::after': {
        content: `''`,
        width: '15px',
        height: '15px',
        backgroundColor: Colors.neutral(800),
        position: 'absolute',
        bottom: '-15px',
        left: 0,
        borderRadius: '15px 0 0 0',
      },
      '&::before': {
        content: `''`,
        width: '15px',
        height: '15px',
        backgroundColor: activeStyle?.backgroundColor || Colors.neutral(0),
        position: 'absolute',
        bottom: '-15px',
        left: 0,
      },
    },
  })

  return (
    <button
      className={platformButtonStyle}
      style={activeStyle}
      onClick={props.onClick}
    >
      {platformStyle?.icon && <Icon width={18} name={platformStyle.icon} />}
      <Text.Caption1 marginLeft={4} fontWeight={700} text={props.username} />
    </button>
  )
}

const chatStyle = style({
  display: 'inline-flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  lineHeight: 1.2,
  zIndex: 2,
  verticalAlign: 'middle',
  position: 'relative',
  gap:"5px"
})


export const OnStreamMessage = ({ type }: { type: 'platform' | 'guest' }) => {
  const { projectCommands } = useContext(AppContext)
  const { activeChatOverlay, setActiveChatOverlay } = useContext(MediaContext)
  const onRemoveMessage = useCallback((id: string) => {
    projectCommands.removeChatOverlay(id)
    setActiveChatOverlay(null)
  }, [])
  const { metadata } = activeChatOverlay || {};

  return (
    <>
      {metadata?.chatType === type ? (
        <Box
          style={{ cursor: 'pointer' }}
          className={OnStreamMessageContainer}
          onClick={() => onRemoveMessage(activeChatOverlay?.id)}
        >
          {activeChatOverlay?.message && (
            <Box className={OnStreamMessageWrapper}>
              <Flex align="center">
                <Box>
                  {metadata?.avatar ? (
                    <span>
                      <img
                        src={metadata?.avatar}
                        alt={`${activeChatOverlay?.username}'s Avatar`}
                        style={{
                          width: 37,
                          height: 37,
                          borderRadius: '50%',
                          margin: '2px 7.5px 0px',
                        }}
                      />
                    </span>
                  ) : (
                    <Box
                      style={{
                        margin: '2px 7.5px 0px',
                      }}
                    >
                      <LetterAvatar
                        size={37}
                        name={activeChatOverlay?.username}
                        background={Colors.primary(500)}
                      />
                    </Box>
                  )}
                </Box>
                <Box width="100%">
                  {activeChatOverlay?.username && (
                    <PlatformButton
                      platform={metadata?.platform}
                      username={activeChatOverlay?.username}
                      variant={metadata?.variant}
                    />
                  )}
                  <Flex
                    align="center"
                    padding={5}
                    marginLeft={15}
                    lineHeight={1.2}
                  >
                    <Box style={{ width: '90%' }} className={chatStyle}>
                      {activeChatOverlay?.message?.map((part, index) => (
                        <ChatMessageBubbleSegment key={index} {...part} />
                      ))}
                    </Box>
                    <WithInfo
                      position="left"
                      message="Remove message from stream"
                      node={
                        <Box
                          style={{ cursor: 'pointer' }}
                          height={24}
                          width={24}
                          marginLeft={5}
                        >
                          <Icon name="CommentDeSelect" height={24} width={24} />
                        </Box>
                      }
                    />
                  </Flex>
                </Box>
              </Flex>
            </Box>
          )}
        </Box>
      ) : null}
    </>
  )
}
