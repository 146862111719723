import { FacebookChat } from './FacebookChat'
import EventEmitter from 'events'
import { BaseChat } from './BaseChat'
import { YoutubeChat } from './YoutubeChat'
import { TwitchChat } from './TwitchChat'
import { SDK } from '@api.stream/studio-kit'

function getChat(
  agg: ChatAggregator,
  destinationId: string,
  platform: string,
  username: string,
  platformId: string,
  token?: string,
): BaseChat {
  if (platform === 'twitch') {
    return new TwitchChat(agg, username, token)
  }

  if (platform === 'facebook') {
    return new FacebookChat(agg, username, platformId, token)
  }

  if (platform === 'youtube') {
    return new YoutubeChat(agg, username, platformId, destinationId, token)
  }

  throw new Error('Unsupported destination.')
}

export class ChatAggregator extends EventEmitter {
  constructor(
    public readonly studio: SDK.Studio,
    public readonly projectId: string,
  ) {
    super()
  }

  private chats: Map<string, BaseChat> = new Map()

  public sendMessage(
    destinations: { id: string; platform: string; username: string }[],
    message: string,
  ): void {
    const destinationPlatforms = destinations.length
      ? destinations
          .map((p) => this.chats.get(p.id))
          .filter((i) => !!i)
      : Array.from(this.chats.values())

    for (const destination of destinationPlatforms) {
      destination.sendMessage(message)
    }
  }

  public trackDestinations(
    destinations: {
      id: string,
      platform: string
      username: string
      platformId: string
      token?: string
    }[],
  ): void {
    const existingDestinationIds = Array.from(this.chats.keys())

    for (const destination of destinations) {
      if (existingDestinationIds.includes(destination.id)) {
        const chat = this.chats.get(destination.id);

        chat.setToken(destination.token);
      } else {
        const chat = getChat(
          this,
          destination.id,
          destination.platform,
          destination.username,
          destination.platformId,
          destination.token,
        )
        chat.connect()
        this.chats.set(destination.id, chat)
      }
    }

    for (const destinationId of existingDestinationIds) {
      // Check if the destination is no-longer tracked.
      if (!destinations.some((destination) => destination.id === destinationId)) {
        const destinationChat = this.chats.get(destinationId)
        destinationChat.disconnect()
        this.chats.delete(destinationId)
      }
    }
  }

  public getWritable(): string[] {
    return Array.from(this.chats.keys()).filter(id => this.chats.get(id).canChat());
  }
}
