import * as React from 'react'
import { Chat } from '@ui/components/Chat'
import { ChatInput } from '@ui/components/ChatInput'
import { SidebarHeading } from '../shared'
import { GuestChatContext } from '../../../context/guest-chat-context'
import LoadingDots from '@ui/atoms/animations/LoadingDots'
import { Flex } from '@ui/layout/Box'
import { SVG } from '@ui/atoms/icons/Icon'
import { style } from 'typestyle'
import { AppContext } from '../../../context/app-context'
import { UserType } from '../../../types'
import { OnStreamMessage } from '@ui/atoms/chat-message/OnStreamMessage'

const overlayStyle = style({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
})

export const GuestChatShelf = React.memo(() => {
  const { messages, canSend, setViewingChat, send } =
    React.useContext(GuestChatContext)
  const { userType } = React.useContext(AppContext)
  // Mark the shelf as open to track unread state.
  React.useEffect(() => {
    setViewingChat(true)

    return () => {
      setViewingChat(false)
    }
  }, [])

  return (
    <>
      <SidebarHeading icon="ChatPrivate" text="Guest Chat" />
      <Chat messages={messages} type="guest" />
      {!canSend && (
        <div className={overlayStyle}>
          <Flex align="center" justify="center" width="100%" height="100%">
            <SVG
              color="primary"
              colorWeight={400}
              width={32}
              svg={LoadingDots}
            />
          </Flex>
        </div>
      )}
      {userType !== UserType.GUEST && <OnStreamMessage type="guest" />}
      {canSend && <ChatInput onSend={send} />}
    </>
  )
})
