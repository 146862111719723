export abstract class BaseChat {
  public abstract sendMessage(message: string): void
  public abstract connect(): void
  public abstract disconnect(): void
  public abstract setToken(token: string): void

  public canChat(): boolean {
    return false;
  }
}
