import { UsageType } from './../../types/models/resource';
import { Resource } from '../../src/types';
import { assertReturnStatement } from '@babel/types';

export const toMediaType = (r: Resource,type : UsageType) => {
  return (r.usageType ?? type) === type
}

export const toMediaItem = (r: Resource, predicate :  string[]) => {
  return predicate?.some(p => r.resourceType === p);
}

export const range = (start: number, stop: number, step: number) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step)

export const tryGetItem = (key: string) => {
  try {
    return localStorage.getItem(key)
  } catch (e) {
    return null
  }
}