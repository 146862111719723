import * as Color from '../../helpers/colors'
import { SVGWrapper } from './Icon'
import { SDK } from '@api.stream/studio-kit'

export type SignalDisplay = {
  quality?: 0 | 1 | 2 | 3 | 4
  color?: 'primary' | 'secondary'
  colorWeight?: number
}

export type SignalProps = {
  size?: number
  emptyColorWeight?: number
} & SignalDisplay

export const getSignalDisplay = (
  quality: SDK.ConnectionQuality,
): SignalDisplay => {
  switch (quality) {
    case 'excellent':
      return {
        quality: 4,
        color: 'primary',
        colorWeight: 500,
      }
    case 'good':
      return {
        quality: 3,
        color: 'primary',
        colorWeight: 500,
      }
    case 'poor':
      return {
        quality: 2,
        color: 'secondary',
        colorWeight: 500,
      }
    case 'unknown':
      return {
        quality: 0,
      }
  }
}

export const SignalBars = ({
  quality = 0,
  color = 'primary',
  colorWeight = 500,
  emptyColorWeight = 400,
  size,
}: SignalProps) => {
  const colors = {
    active: Color[color](colorWeight),
    empty: Color.neutral(emptyColorWeight),
  }

  return (
    <SVGWrapper width={size} height={size}>
      <svg style={{ flex: '0 0 100%' }} viewBox="0 0 15 12">
        <rect
          width="2.5"
          height="12"
          rx="1.25"
          transform="matrix(-1 0 0 1 14.5 0)"
          fill={quality >= 4 ? colors.active : colors.empty}
        />
        <rect
          width="2.5"
          height="8.57143"
          rx="1.25"
          transform="matrix(-1 0 0 1 10.5 3.42857)"
          fill={quality >= 3 ? colors.active : colors.empty}
        />
        <rect
          width="2.5"
          height="6"
          rx="1.25"
          transform="matrix(-1 0 0 1 6.5 6)"
          fill={quality >= 2 ? colors.active : colors.empty}
        />
        <rect
          width="2.5"
          height="3.42857"
          rx="1.25"
          transform="matrix(-1 0 0 1 2.5 8.57143)"
          fill={quality >= 1 ? colors.active : colors.empty}
        />
      </svg>
    </SVGWrapper>
  )
}
