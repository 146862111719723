import React, { useState, useContext, useEffect } from 'react'
import { Uploader } from './Uploader'
import { Column, Row } from '@ui/layout/Box'
import MediaTile, { MediaTileData } from '@ui/atoms/media/Mediatile'
import { UsageType } from '../../types/models/resource'
import { Resource, trigger } from '../utils/events'
import { UploadContext } from '../context/upload-context'
import { useApp } from '../context/app-context'
import { useProjectProp } from '@ui/hooks/useProjectProp'
import { HotKey } from '@ui/components/HotKeyPanel'
import { MediaContext } from '../context/media-context'
import useMediaData from '@ui/hooks/useMediaData'

const deleteResource = async (key: string) => {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  }
  const response = await fetch(`/api/resources/${key}`, requestOptions)
  return await response.json()
}

const updateResource = async (
  key: string,
  postBody: Partial<MediaTileData>,
) => {
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(postBody),
  }
  const response = await fetch(
    `/api/resources/studio2/resource/${key}`,
    requestOptions,
  )
  return await response.json()
}

export const LogoManager = () => {
  const { uppy, setFileType, setUsageType } = useContext(UploadContext)
  const { logo, setLogo } = useContext(MediaContext)
  const [hotkeys, setHotKeys] = useProjectProp<HotKey[]>('hotkeys')
  const { projectCommands, track } = useApp()
  const [selectedContext, setSelectedContext] = useState(null)
  const [getMediaData] = useMediaData()
  const mediaData = getMediaData(['image', 'video'], UsageType.Logo);
  const onUploaderOpen = () => {
    setFileType('image')
    setUsageType(UsageType.Logo)
  }

  return (
    <Column style={{ overflow: 'hidden' }}>
      <Column grow={1} shrink={1} width="100%">
        <Row
          style={{
            width: '100%',
            alignItems: 'center',
            display: 'grid',
            gridGap: '5px',
            gridTemplateColumns: 'repeat(auto-fill, minmax(115px, 1fr))',
          }}
        >
          {mediaData?.map((d: MediaTileData) => {
            return (
              <MediaTile
                resizeMode="contain"
                key={d.id}
                data={d}
                active={logo === d.id}
                dimmed={logo && logo !== d.id}
                isOpen={selectedContext === d.id}
                setSelectedContext={setSelectedContext}
                showFavorite={true}
                showContext={true}
                onFavorite={async (key: string, state: boolean) => {
                  if (state) {
                    if (hotkeys && hotkeys.length >= 4) {
                      return
                    }
                  }

                  if (!state) {
                    const filterdHotKeys = hotkeys?.filter(
                      (h) => h.hotKeyId !== d.id,
                    )
                    setHotKeys([...filterdHotKeys])
                  } else {
                    setHotKeys([
                      ...(hotkeys ?? []),
                      {
                        type: d.resourceType,
                        hotKeyId: d.id,
                      },
                    ])
                  }
                }}
                onClick={(key: string) => {
                  const media = mediaData.find(
                    (image: MediaTileData) => image.id === key,
                  )
                  track('SetLogo')
                  if (media.id === logo) {
                    setLogo(null)
                    projectCommands.removeLogo(media.id)
                  } else {
                    setLogo(media.id)
                    projectCommands.addLogo(media.id, {
                      src: media.url,
                    })
                  }
                }}
                onDelete={async (key: string) => {
                  const resource = await deleteResource(key)
                  trigger(Resource.Removed, { resource })
                }}
              />
            )
          })}
          <Uploader
            usageType={UsageType.Logo}
            uppy={uppy}
            onOpen={onUploaderOpen}
          />
        </Row>
      </Column>
    </Column>
  )
}
