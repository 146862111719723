import { DestinationType } from 'studio-types/models/destination'
let authWindow: Window
export const loadAuthWindow = (payload: {
  provider: DestinationType
  projectId: string
}) => {
  const { provider, projectId } = payload
  const url = `/api/auth/${provider}?studio2Destination=true&projectId=${projectId}`
  if (authWindow) {
    authWindow.close()
  }
  authWindow = window.open(url)
}

export const loadMergeAuthWindow = (payload: { provider: DestinationType }) => {
  const { provider } = payload
  const url = `/api/auth/${provider}?merge=true`
  if (authWindow) {
    authWindow.close()
  }
  authWindow = window.open(url)
}

export const closeAuthWindow = () => {
  if (authWindow) {
    authWindow.close()
  }
}
