import * as Color from '@ui/helpers/colors'
import { IconName } from '@ui/atoms/icons/Icon'

interface IHotKeyFooter {
  [key: string]: {
    icon?: IconName
    loop?: IconName
  }
}

export const HotKeyType = {
  Active: {
    border: `1px solid ${Color.primary(600)}`,
    color: Color.primary(500),
  },
  Filled: {
    border: `1px solid ${Color.neutral(600)}`,
    color: Color.neutral(700),
  },
  Empty: {
    border: `1px dashed ${Color.neutral(600)}`,
    color: Color.neutral(500),
  },
}

export const HotKeyItems = {
  1: 'Shift+Digit1',
  2: 'Shift+Digit2',
  3: 'Shift+Digit3',
  4: 'Shift+Digit4',
} as const

export const HotKeyFooter = {
  video: {
    loop: 'Loop' as IconName,
  },
  overlay: {
    icon: 'AlertAndOverlays' as IconName,
  },
  background: {
    icon: 'Image' as IconName,
  },
  banner: {
    icon: 'Text' as IconName,
  },
  custom: {
    icon: 'AlertAndOverlays' as IconName,
  },
  logo: {
    icon: 'Logo' as IconName,
  },
} as IHotKeyFooter

export const getShortcut = (index: number) => {
  return HotKeyItems[index as keyof typeof HotKeyItems]
}

export type Shortcuts = typeof HotKeyItems[keyof typeof HotKeyItems]
