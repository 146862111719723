import React, { useEffect, useState } from 'react'
import { Account, App, Resource, trigger, User } from './events'
import { DestinationType } from 'studio-types/models/destination'
import { YouTubeError } from 'studio-types/error-messages/platforms'

const host = 'studio.stream.horse' || window.location.hostname
const url = `wss://${host}/api/live?zone=sceneless`

type SocketReturn = [WebSocket, () => void]

export const useWebsocket = () => {
  const [ws, setWs] = useState<WebSocket>(() => new WebSocket(url))

  useEffect(() => {
    if (!ws) return
    ws.addEventListener('error', (evt) => {
      console.error(evt)
    })
    ws.onopen = (evt) => {
      console.log('socket opened!', evt)
      return true
    }
    ws.onmessage = async (evt) => {
      const data = JSON.parse(evt.data)
      const { payload } = data
      switch (data.id) {
        case 'ResourceUpdated': {
          return trigger(Resource.Updated, payload)
        }
        case 'socket.connected': {
          console.log({ bootstrap: payload.bootstrap })

          return trigger(App.SocketConnected, {
            bootstrap: payload.bootstrap,
          })
        }
        case 'SetUserCapabilities': {
          // Handle changes in user subscription
          const { capabilities, subscription, role } = payload

          return trigger(User.CapabilitiesChanged, {
            capabilities,
            role,
            subscription,
          })
        }
        case 'AccountAuthenticated': {
          return trigger(Account.Authenticated, {
            account: payload,
          })
        }
        case 'UserMergeSucceeded': {
          return trigger(User.UserMergeSucceeded, { success: true })
        }
        case 'UserMergeFailed': {
          const { error }: { error: string } = payload
          return trigger(User.UserMergeFailed, { error })
        }
        case 'AddDestinationFailed': {
          const {
            error,
            platform,
            errorReason,
          }: {
            error: string
            platform: DestinationType
            errorReason: YouTubeError
          } = payload
          return trigger(User.AddDestinationFailed, {
            error,
            platform,
            errorReason,
          })
        }
      }
    }
    ws.onclose = (evt) => {
      console.log('closing ws', evt)
      return true
    }

    return () => ws.close()
  }, [ws])

  return [ws, () => setWs(new WebSocket(url))] as SocketReturn
}
