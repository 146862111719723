import * as React from 'react'
import chroma from 'chroma-js'
import { style } from 'typestyle'
import { Flex } from '../../layout/Box'
import * as Color from '../../helpers/colors'
const fakeInput = style({
  border: `1px solid ${Color.neutral(800)}`,
  borderRadius: 4,
  padding: '0px 8px',
  width: '100%',
})

const missingInputStyle = style({
  padding: '14px 6px 14px 0px',
  width: '100%',
  background: 'transparent',
  border: 'transparent',
  outline: 'none',
  color: '#FFF',
  flex: '1',
  '-moz-appearance': 'textfield',
  $nest: {
    '&::-webkit-inner-spin-button,  &::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
    },
  },
})

const rightBorderStyle = style({
  width: '0px',
  marginRight: '6px',
  height: '15px',
  borderRight: `1px solid ${Color.neutral(600)}`,
})

interface ITransparentInputProps {
  value: number
  id: number
  max: number
  onFocus(): void
  onBlur(): void
  onChange(id: number, value: number): void
}

export const TransparentInput = React.memo((props: ITransparentInputProps) => {
  const [val, setVal] = React.useState<string | number>(props.value)
  const [focused, setFocused] = React.useState(false)

  React.useEffect(() => {
    if (!focused) {
      setVal(props.value)
    }
  }, [props.value, focused])

  const onFocus = React.useCallback(() => {
    setFocused(true)
    props.onFocus()
  }, [props.onFocus])

  const onBlur = React.useCallback(() => {
    setFocused(false)
    props.onBlur()
  }, [props.onBlur])

  const onChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setVal(event.currentTarget.value)
      props.onChange(props.id, +event.currentTarget.value)
    },
    [props.onChange],
  )

  return (
    <input
      className={missingInputStyle}
      value={val}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={onChange}
      type="number"
      max={props.max}
      min={0}
    />
  )
})

// tslint:disable max-func-body-length
export const RGBAInput = React.memo(
  (props: { value: string; onChange(value: string): void }) => {
    const [values, setValues] = React.useState<
      [number, number, number, number]
    >([255, 255, 255, 255])
    const [focus, setFocus] = React.useState(false)

    React.useEffect(() => {
      const col = chroma(props.value)
      const rgb = col.rgba(true)

      setValues([rgb[0], rgb[1], rgb[2], rgb[3] * 100])
    }, [props.value])

    const onFocus = React.useCallback(() => {
      setFocus(true)
    }, [])

    const onBlur = React.useCallback(() => {
      setFocus(false)
    }, [])

    const setVal = React.useCallback(
      (id: number, value: number) => {
        const col: [number, number, number, number] = [...values]
        col[id] = value

        setValues(col)
        props.onChange(
          chroma
            .rgb(col[0], col[1], col[2])
            .alpha(col[3] / 100)
            .css('rgba' as any),
        )
      },
      [values, props.onChange],
    )

    return (
      <Flex
        direction="row"
        align="center"
        className={fakeInput}
        style={{ borderColor: focus ? Color.primary(500) : Color.neutral(800) }}
      >
        <div style={{ flex: 1 }}>
          <TransparentInput
            id={0}
            onFocus={onFocus}
            onBlur={onBlur}
            value={values[0]}
            onChange={setVal}
            max={255}
          />
        </div>

        <div className={rightBorderStyle} />

        <div style={{ flex: 1 }}>
          <TransparentInput
            id={1}
            onFocus={onFocus}
            onBlur={onBlur}
            value={values[1]}
            onChange={setVal}
            max={255}
          />
        </div>

        <div className={rightBorderStyle} />

        <div style={{ flex: 1 }}>
          <TransparentInput
            id={2}
            onFocus={onFocus}
            onBlur={onBlur}
            value={values[2]}
            onChange={setVal}
            max={255}
          />
        </div>

        <div className={rightBorderStyle} />

        <Flex
          direction="row"
          align="center"
          justify="center"
          style={{ flex: 1 }}
        >
          <TransparentInput
            id={3}
            onFocus={onFocus}
            onBlur={onBlur}
            value={values[3]}
            onChange={setVal}
            max={100}
          />
        </Flex>

        <span style={{ paddingBottom: 1 }}>%</span>
      </Flex>
    )
  },
)
