import * as React from 'react'
import { classes, style } from 'typestyle'
import chroma from 'chroma-js'
import * as Color from '../../helpers/colors'
import { Flex } from '../../layout/Box'
import { ColorPicker } from '../color-picker/ColorPicker'
import { WithDropdown } from '../FloatingMenu/FloatingMenu'
import { color } from 'csx'
import TextInput from './TextInput'

const VALID_HEX = /^#(?:[0-9a-fA-F]{3,4}){1,2}$/

const colorInputContainer = style({})
const block = style({
  width: 38,
  height: 38,
  padding: 0,
  border: 0,
  outline: 'none',
  cursor: 'pointer',
})

const transparentBlock = style({
  backgroundImage: `url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeAQMAAAAB/jzhAAAABlBMVEXh4eG0tLSN+vM/AAAAFUlEQVR4XmNgYPz/h0rE/38MDNQhAHTjO5gQMYlZAAAAAElFTkSuQmCC")`,
  backgroundSize: '20px',
  overflow: 'hidden',
  borderRadius: 8,
  position: 'relative',
  $nest: {
    '&:after': {
      content: '""',
      position: 'absolute',
      inset: 0,
      borderRadius: 8,
    },
  },
})

const pickerBackdrop = style({
  maxWidth: 300,
  background: Color.neutral(900),
})

export interface IColorInputProps {
  value: string
  defaultColor: string
  outputFormat: 'hex' | 'rgba'
  onChange(value: string): void
}

function transformForOutput(
  value: string,
  outputFormat: 'hex' | 'rgba',
): string {
  // Note: chromajs supports hex with alpha but csx doesn't. So we'll convert with that.
  const color = chroma(value)

  if (outputFormat === 'hex') {
    return color.hex('auto')
  }

  const [r, g, b, a] = color.rgba()

  return `rgba(${r}, ${g}, ${b}, ${a})`
}

// Visually we should be dealing with hex.
function toHex(input: string, defaultColor: string) {
  try {
    return chroma(input || defaultColor).hex('auto')
  } catch (e) {
    return defaultColor
  }
}

export const ColorInput = React.memo((props: IColorInputProps) => {
  const [value, setValue] = React.useState(
    toHex(props.value, props.defaultColor),
  )
  const [localValue, setLocalValue] = React.useState(
    toHex(props.value, props.defaultColor),
  )
  const [isOpen, setIsOpen] = React.useState(false)
  const invalid: boolean = !VALID_HEX.test(localValue)

  React.useEffect(() => {
    setValue(toHex(props.value, props.defaultColor))
  }, [props.value])

  const change = React.useCallback(
    (value: string) => {
      props.onChange(transformForOutput(value, props.outputFormat))
      setValue(value)
      setLocalValue(value)
    },
    [props.onChange],
  )

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setLocalValue(event.target.value.toUpperCase())

      // Only consider the change if it's valid.
      if (VALID_HEX.test(event.target.value)) {
        change(event.target.value)
      }
    },
    [change],
  )

  const input = (
    <div
      className={classes(
        block,
        transparentBlock,
        style({
          $nest: {
            '&:after': {
              content: '""',
              position: 'absolute',
              border: `1px solid ${color(transformForOutput(value, 'rgba'))
                .fade(1)
                .lighten('12%')}`,
            },
          },
        }),
      )}
    >
      <div
        className={block}
        style={{
          backgroundColor: value,
        }}
      />
    </div>
  )

  return (
    <Flex direction="row" align="center" className={colorInputContainer}>
      <WithDropdown
        isOpen={isOpen}
        onClick={() => {
          setIsOpen(!isOpen)
        }}
        onClose={() => {
          setIsOpen(false)
        }}
        node={input}
      >
        <div className={pickerBackdrop}>
          <ColorPicker value={value} onChange={change} />
        </div>
      </WithDropdown>
      <TextInput
        value={localValue}
        paddingY={8}
        paddingX={16}
        marginLeft={5}
        invalid={invalid}
        fontSize={18}
        height={40}
        onChange={handleChange}
        width={134}
        style={{
          borderRadius: 8,
        }}
        onBlur={() => {
          setLocalValue(value.toUpperCase())
        }}
      />
    </Flex>
  )
})
