import * as React from 'react'
import { style } from 'typestyle'
import * as Colors from '../helpers/colors'
import Box, { Flex } from '../layout/Box'
import Button from '../atoms/buttons/Button'
import { IconButton } from '../atoms/buttons/IconButton'
import { WithDropdown } from '../atoms/FloatingMenu/FloatingMenu'
import Icon from '../atoms/icons/Icon'
import TextInput from '../atoms/inputs/TextInput'
import Text from '../atoms/text/Text'
import SDK from '@api.stream/studio-kit'
import { StudioContext } from '../../src/context/app-context'
import { useProjectProp } from '@ui/hooks/useProjectProp'
import { HotKey } from './HotKeyPanel'
import FavoriteButton from '@ui/atoms/media/FavoriteButton'

export type Banner = SDK.Helpers.ScenelessProject.Banner
export type BannerSource = SDK.Helpers.ScenelessProject.BannerSource

export interface ITextBannerProps {
  active: boolean | null
  isFavorite?: boolean
  bannerId: string
  onClick(id: string): void
  onEdit(banner: Banner): void
  onDelete(id: string): void
  onHotKeyAdded(bannerId: string, isFavorite: boolean): void
}

export const bannerIcons = style({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  paddingRight: 8,
})

const bannerStyle = style({
  backgroundColor: Colors.neutral(800),
  padding: 20,
  borderRadius: 8,
  width: '100%',
  margin: '0 0 8px',
  cursor: 'pointer',
  display: 'block',
  border: '1px solid transparent',
  outline: 'none',
  textAlign: 'left',
  color: Colors.neutral(300),
  position: 'relative',
  $nest: {
    '&:hover': {
      color: Colors.neutral(0),
      backgroundColor: Colors.neutral(700),
      $nest: {
        '& .iconButton': {
          visibility: 'visible',
        },
      },
    },
  },
})

export const backdrop = style({
  backgroundColor: Colors.neutral(800),
  padding: 8,
  borderRadius: 4,
})

const menuItemStyle = style({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: 8,
  borderRadius: 4,
  background: 'transparent',
  color: Colors.neutral(300),
  width: '100%',
  border: 'none',
  $nest: {
    '&:hover': {
      color: Colors.neutral(0),
      backgroundColor: Colors.neutral(700),
    },
  },
})

export const TextBanner = React.memo((props: ITextBannerProps) => {
  const { studio } = React.useContext(StudioContext)
  const [banner, setBanner] = React.useState<Banner>(
    studio.compositor.getSource(props.bannerId),
  )

  const [dropdownOpen, setDropdownOpen] = React.useState(false)
  const [edit, setEdit] = React.useState(false)
  const [editValue, setEditValue] = React.useState('')
  const style: React.CSSProperties = {
    opacity:
      !dropdownOpen && props.active !== null ? (props.active ? 1 : 0.3) : null,
    borderColor: props.active ? Colors.primary(500) : null,
  }

  React.useEffect(() => {
    return studio.compositor.useSource(props.bannerId, (x: Banner) => {
      setBanner({ ...x })
    })
  }, [props.bannerId])

  const onDelete = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()

      props.onDelete(banner.id)
    },
    [props.onDelete, banner.id],
  )

  // Swap to the edit state.
  const onEdit = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()

      setEditValue(banner.props.bodyText)
      setEdit(true)
      setDropdownOpen(false)
    },
    [banner.props.bodyText],
  )

  // Save the banner
  const onSave = React.useCallback(() => {
    if (editValue.trim() === '') {
      setEdit(false)
      return
    }

    props.onEdit({
      id: banner.id,
      props: {
        ...banner.props,
        bodyText: editValue,
      },
    })
    setEdit(false)
  }, [banner, props.onEdit, editValue])

  if (edit) {
    return (
      <>
        <Box marginBottom={8}>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              onSave()
            }}
          >
            <TextInput
              defaultValue={banner.props.bodyText}
              label={
                <Text.Label
                  text="Edit"
                  color="neutral"
                  fontSize={14}
                  colorWeight={500}
                  fontWeight={300}
                />
              }
              autoFocus={true}
              selectOnFocus={true}
              paddingY={16}
              paddingX={14}
              maxLength={200}
              color="neutral"
              colorWeight={500}
              value={editValue}
              onChange={(e) => setEditValue(e.target.value)}
            />
          </form>
        </Box>
        <Flex direction="row" justify="flex-end" marginBottom={10}>
          <Button
            text="Cancel"
            appearance="text"
            height={33}
            width={68}
            fontSize={14}
            onClick={() => {
              setEdit(false)
            }}
          />
          <Button
            text="Save"
            height={33}
            width={55}
            fontSize={14}
            marginLeft={8}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation()
              onSave()
            }}
          />
        </Flex>
      </>
    )
  }

  return (
    <button
      className={bannerStyle}
      style={style}
      onClick={() => props.onClick(banner.id)}
    >
      <Text.Body colorWeight={300} text={banner.props.bodyText} />

      <Box className={bannerIcons}>
        <Flex direction="row" justify="flex-end">
          <FavoriteButton
            id={props?.bannerId}
            className="iconButton"
            isFavorite={props?.isFavorite}
            onFavorite={(id, isFavorite) => props.onHotKeyAdded(id, isFavorite)}
            iconWidth={24}
            iconHeight={24}
            iconSize={20}
          />
          <WithDropdown
            isOpen={dropdownOpen}
            position="bottom"
            align="end"
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.stopPropagation()
              setDropdownOpen((v) => !v)
            }}
            onClose={() => {
              setDropdownOpen(false)
            }}
            width={140}
            node={
              <IconButton
                className="iconButton"
                height={24}
                appearance="text"
                icon="TripleDots"
                iconColor="neutral"
                iconSize={20}
                marginLeft={4}
                width={24}
              />
            }
          >
            <Box className={backdrop}>
              <button className={menuItemStyle} onClick={onEdit}>
                <Icon width={24} height={24} name="Pencil" />
                <Text.Body marginLeft={5} text="Edit" />
              </button>

              <button className={menuItemStyle} onClick={onDelete}>
                <Icon width={24} height={24} name="Trash" />
                <Text.Body marginLeft={5} text="Delete" />
              </button>
            </Box>
          </WithDropdown>
        </Flex>
      </Box>
    </button>
  )
})
