import * as React from 'react'
import { MediaContext } from '../../src/context/media-context'
import { style } from 'typestyle'
import {
  ChatMessage,
  ChatMessageProps,
  ChatTarget,
  PlatformChatMessage,
} from '../atoms/chat-message/ChatMessage'
import * as Color from '../helpers/colors'


export type ChatProps = {
  messages: ChatMessageProps[]
  duplicatedPlatforms?: string[]
  usePlatformHeading?: boolean
  onTargetClick?(target: ChatTarget): void
  type?: 'platform' | 'guest'
}

const box = style({
  backgroundColor: Color.neutral(900),
  flexDirection: 'column',
  display: 'flex',
  flex: 1,
  padding: 16,
  paddingBottom: 8,
  width: '100%',
})

const scrollContainer = style({
  flexGrow: '1',
  overflowY: 'scroll',
  height: 100,
})

function getChatKey(msg: ChatMessageProps): string {
  if (msg.target[0].type === 'destination') {
    return `${+msg.timestamp}-${msg.target[0].id}-${msg.displayName}`
  }

  return `${+msg.timestamp}-${msg.displayName}-${msg.id}`
}

export const Chat = React.memo((props: ChatProps) => {
  const scrollRef = React.useRef<HTMLDivElement>(null)
  const anchor = React.useRef<HTMLDivElement>(null)
  const [messages, setMessages] = React.useState<ChatMessageProps[]>([])
  const isPinned = React.useRef(true)
  const { setActiveBanner, activeChatOverlay } = React.useContext(MediaContext)

  React.useEffect(() => {
    if (activeChatOverlay) {
      setActiveBanner(null)
    }
  }, [activeChatOverlay, setActiveBanner])

  React.useEffect(() => {
    const scrollBottomDifference =
      scrollRef.current.scrollHeight -
      scrollRef.current.clientHeight -
      scrollRef.current.scrollTop

    // Check if we're close enough to the bottom
    isPinned.current = Math.abs(scrollBottomDifference) < 10
    setMessages(props.messages)
  }, [props.messages])

  React.useEffect(() => {
    if (isPinned.current) {
      anchor.current.scrollIntoView()
    }
  }, [messages])

  return (
    <div className={box}>
      <div className={scrollContainer} ref={scrollRef}>
        {messages.map((msg) => (
          <React.Fragment key={getChatKey(msg)}>
            {!props.usePlatformHeading && <ChatMessage {...msg} chatType={props?.type} />}
            {props.usePlatformHeading && (
              <PlatformChatMessage
                chatType={props?.type}
                duplicatedPlatforms={props.duplicatedPlatforms}
                onTargetClick={props.onTargetClick}
                {...msg}
              />
            )}
          </React.Fragment>
        ))}
        <div ref={anchor} />
      </div>
    </div>
  )
})
