import {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import TextInput from '../atoms/inputs/TextInput'
import Text from '../atoms/text/Text'
import Box, { Column, Flex, Row } from '../layout/Box'
import { Helpers } from '@api.stream/studio-kit'
import CustomSelect from '../atoms/inputs/CustomSelect'
import * as Color from '../helpers/colors'
import Button from '../atoms/buttons/Button'
import { AppContext, StudioContext } from '../../src/context/app-context'
import LoadingDots from '../atoms/animations/LoadingDots'
import Icon, { SVG } from '../atoms/icons/Icon'
import { WithInfo } from '@ui/atoms/FloatingMenu/FloatingMenu'
import useTimeout from '@ui/hooks/useTimeout'
import {
  PermissionStatus,
  usePermissions,
} from '../../src/context/permission-context'
import Toggle from '@ui/atoms/toggles/Toggle'
import { tryGetItem } from '@ui/helpers/common'

const { Room } = Helpers
const { useDevices } = Helpers.React

export const DISPLAY_NAME_KEY = '__LS_displayName'
export const MIRROR_STATE_KEY = '__LS_isMirrored'


export type CameraSettingsProps = {
  nameDisabled?: boolean
  buttonText?: string
  setDispose?: (fn: () => void) => void
  onComplete: (settings: {
    webcamId: string
    microphoneId: string
    speakerId: string
    displayName: string
    isMirrored: boolean
  }) => void
}
export const CameraSettings = ({
  nameDisabled = false,
  buttonText = 'Save',
  onComplete = () => {},
  setDispose = () => {},
}: CameraSettingsProps) => {
  const app = useContext(AppContext)
  const {
    webcamId: startingWebcamId,
    microphoneId: startingMicrophoneId,
    room,
  } = useContext(StudioContext)
  const Permission = usePermissions()
  const participant = room?.getParticipant(room.participantId)
  const [webcamId, setWebcamId] = useState<string>(startingWebcamId)
  const [microphoneId, setMicrophoneId] = useState<string>(startingMicrophoneId)
  const [isMirrored, setIsMirrored] = useState(
    Boolean(participant?.meta?.isMirrored ?? tryGetItem(MIRROR_STATE_KEY)),
  )
  const [speakerId, setSpeakerId] = useState<string>()
  const [displayName, setDisplayName] = useState(
    participant?.displayName ?? tryGetItem(DISPLAY_NAME_KEY),
  )
  const [srcObject] = useState(new MediaStream([]))
  const videoRef = useRef<HTMLVideoElement>()
  const devices = useDevices()
  const [audioEl] = useState(() => document.createElement('audio'))
  const [hasAudioOutputSupport, setHasAudioOutputSupport] = useState(false)

  const [showWaitingMessage, setShowWaitingMessage] = useState(false)

  const initialTrack = useMemo(
    () =>
      participant?.trackIds
        .map((x) => room.getTrack(x))
        .find((x) => x.type === 'camera' && !x.isExternal)?.mediaStreamTrack,
    [],
  )

  useEffect(() => {
    Permission.request.camera()
    Permission.request.microphone()
  }, [])

  // Ignore the first set of MediaStream if we can pull an active from the room
  const [ignoringFirst, setIgnoringFirst] = useState(
    Boolean(initialTrack?.enabled && initialTrack?.readyState === 'live'),
  )
  const [loading, setLoading] = useState(!ignoringFirst)

  useEffect(() => {
    setDispose(() => {
      srcObject?.getTracks().forEach((x) => x.stop())
    })
  }, [])

  // Show waiting message after an arbitrary timeout
  //  This gives getUserMedia time to make a request to the browser
  //  before we make any assumptions about existing permissions
  useTimeout(() => setShowWaitingMessage(true), 1000)

  // Check for audio output support
  useEffect(() => {
    // TODO: Uncomment this code when Livekit's "switchActiveDevice"
    //  is proven to work for audio output
    // @ts-ignore
    // setHasAudioOutputSupport(Boolean(audioEl.setSinkId))
  }, [])

  useEffect(() => {
    // Ignore the first run if a track was pulled from the room
    if (ignoringFirst || Permission.camera.status === PermissionStatus.Failed) {
      return setIgnoringFirst(false)
    }

    // Get a new MediaStream when webcamId changes
    Room.getUserMedia({
      video: {
        deviceId: webcamId,
      },
    })
      .then((mediaStream) => {
        srcObject?.getTracks().forEach((x) => x.stop())
        mediaStream.getTracks().forEach((x) => {
          x.addEventListener(
            'ended',
            () => {
              // If a track ends prematurely, it's likely permission was revoked
              Permission.request.camera()
              Permission.request.microphone()
            },
            { once: true },
          )
        })
        setLoading(true)
        // Replace the tracks on the existing MediaStream
        Room.updateMediaStreamTracks(srcObject, {
          video: mediaStream.getVideoTracks()[0],
        })
        if (videoRef.current && srcObject.getTracks().length > 0) {
          videoRef.current.srcObject = srcObject
          videoRef.current.play().catch((e) => {
            document.addEventListener(
              'click',
              () => {
                videoRef.current?.play().catch((e) => console.warn(e))
              },
              { once: true },
            )
          })
          videoRef.current.onplaying = () => setLoading(false)
        }
      })
      .catch((e) => {
        console.warn(e)
      })
  }, [webcamId, Permission.camera.status])

  useLayoutEffect(() => {
    if (initialTrack && videoRef.current) {
      videoRef.current.srcObject = new MediaStream([initialTrack])
      videoRef.current.play().catch((e) => {
        document.addEventListener(
          'click',
          () => {
            videoRef.current?.play().catch((e) => console.warn(e))
          },
          { once: true },
        )
      })
    }
  }, [])

  useEffect(() => {
    const webcamDoesNotExist =
      webcamId && !devices.webcams.find((x) => x.deviceId === webcamId)
    const defaultWebcam =
      devices.webcams.find((x) => x.deviceId === 'default') ||
      devices.webcams[0]
    if ((!webcamId || webcamDoesNotExist) && defaultWebcam) {
      setWebcamId(defaultWebcam.deviceId)
    }

    const microphoneDoesNotExist =
      microphoneId &&
      !devices.microphones.find((x) => x.deviceId === microphoneId)
    const defaultMicrophone =
      devices.microphones.find((x) => x.deviceId === 'default') ||
      devices.microphones[0]
    if ((!microphoneId || microphoneDoesNotExist) && defaultMicrophone) {
      setMicrophoneId(defaultMicrophone.deviceId)
    }

    const speakerDoesNotExist =
      speakerId && !devices.speakers.find((x) => x.deviceId === speakerId)
    const defaultSpeaker =
      devices.speakers.find((x) => x.deviceId === 'default') ||
      devices.speakers[0]
    if ((!speakerId || speakerDoesNotExist) && defaultSpeaker) {
      setSpeakerId(defaultSpeaker.deviceId)
    }
  }, [devices])

  const submit = () => {
    try {
      localStorage.setItem(DISPLAY_NAME_KEY, displayName)
    } catch (e) {}
    onComplete({
      webcamId,
      microphoneId,
      speakerId,
      isMirrored,
      displayName,
    })
  }

  return (
    <Flex direction="column">
      <form
        onSubmit={(e) => {
          e.preventDefault()
          submit()
        }}
      >
        {/* Content */}
        <Flex direction="row">
          {/* Camera Preview */}
          <Flex
            width={680}
            height={380}
            style={{
              borderRadius: 6,
              overflow: 'hidden',
              position: 'relative',
              background: Color.neutral(1000),
            }}
          >
            <Flex
              align="center"
              justify="center"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                opacity: 1,
                transition: '200ms ease all',
              }}
            >
              {/* Requesting permissions */}
              {Permission.camera.status === PermissionStatus.Unknown &&
                showWaitingMessage && (
                  <Column gap={8} style={{}}>
                    <Text.Heading2 text="Waiting for permission..." />
                    <Text.Body text="Lightstream Studio requires access to your camera and microphone." />
                  </Column>
                )}
              {/* Loading camera */}
              {Permission.camera.status === PermissionStatus.Allowed &&
                !showWaitingMessage && (
                  <SVG
                    color="primary"
                    colorWeight={400}
                    width={40}
                    svg={LoadingDots}
                  />
                )}
              {/* Loading camera */}
              {Permission.camera.status === PermissionStatus.Failed && (
                <Column align="center">
                  <Icon name="VideoOff" width={80} height={80} />
                  <Text.Custom
                    text="Camera unavailable"
                    fontSize={18}
                    marginTop={12}
                  />
                </Column>
              )}
            </Flex>
            {Permission.camera.status === PermissionStatus.Allowed && (
              <video
                ref={videoRef}
                muted={true}
                autoPlay={true}
                style={{
                  opacity: loading ? 0 : 1,
                  objectFit: 'cover',
                  width: '100%',
                  height: '100%',
                  position: 'relative',
                  transition: '200ms opacity ease',
                  ...(Boolean(isMirrored) && { transform: 'scaleX(-1)' }),
                }}
              />
            )}
          </Flex>
          {/* Fields */}
          <Flex direction="column" marginLeft={20} width={430}>
            <Flex direction="column" width="100%">
              <Text.Label text="Display Name" />
              <TextInput
                placeholder="Enter name..."
                marginTop={8}
                disabled={nameDisabled}
                width="100%"
                height={34}
                defaultValue={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
                autoFocus={true}
              />
            </Flex>
            <Flex direction="column" marginTop={20} width="100%">
              <Flex align="center" height={18}>
                <Text.Label text="Camera" />
                {Permission.camera.status === PermissionStatus.Failed && (
                  <WithInfo
                    message="Device inaccessible"
                    delay={0}
                    node={
                      <Icon
                        marginLeft={4}
                        width={18}
                        height={18}
                        name="CircleExclamation"
                        color="secondary"
                        colorWeight={400}
                      />
                    }
                  />
                )}
              </Flex>
              <CustomSelect
                placeholder="Select camera..."
                marginTop={6}
                disabled={Permission.camera.status !== PermissionStatus.Allowed}
                value={
                  Permission.camera.status !== PermissionStatus.Allowed
                    ? null
                    : webcamId
                }
                onChange={(value) => setWebcamId(value)}
                options={devices.webcams.map((x) => {
                  const tracks = room?.getTracks()

                  const inUseTrack = tracks?.find(
                    (t) =>
                      t?.mediaStreamTrack?.getSettings()?.deviceId ===
                      x?.deviceId,
                  )

                  return {
                    value: x.deviceId,
                    label: x.label,
                    disabled: inUseTrack?.isExternal,
                  }
                })}
              />
            </Flex>
            <Flex
              justify="space-between"
              align="center"
              marginTop={20}
              gap={10}
            >
              <Toggle
                onChange={(value) => {
                  app.track('ToggleCamera', { value })
                  setIsMirrored(value)
                }}
                value={isMirrored}
              />
              <Text.Heading3 text="Mirror Camera" colorWeight={300} />
            </Flex>
            <Flex direction="column" marginTop={20} width="100%">
              <Flex align="center" height={18}>
                <Text.Label text="Microphone" />
                {Permission.microphone.status === PermissionStatus.Failed && (
                  <WithInfo
                    message="Device inaccessible"
                    delay={0}
                    node={
                      <Icon
                        marginLeft={4}
                        width={18}
                        height={18}
                        name="CircleExclamation"
                        color="secondary"
                        colorWeight={400}
                      />
                    }
                  />
                )}
              </Flex>
              <CustomSelect
                placeholder="Select microphone..."
                marginTop={6}
                disabled={
                  Permission.microphone.status !== PermissionStatus.Allowed
                }
                value={
                  Permission.microphone.status !== PermissionStatus.Allowed
                    ? null
                    : microphoneId
                }
                onChange={(value) => setMicrophoneId(value)}
                options={devices.microphones.map((x) => ({
                  value: x.deviceId,
                  label: x.label,
                }))}
              />
            </Flex>
            {hasAudioOutputSupport && (
              <Flex direction="column" marginTop={20} width="100%">
                <Text.Label text="Speaker" />
                <CustomSelect
                  placeholder="Select speaker..."
                  marginTop={8}
                  value={speakerId}
                  onChange={(value) => setSpeakerId(value)}
                  options={devices.speakers.map((x) => ({
                    value: x.deviceId,
                    label: x.label,
                  }))}
                />
              </Flex>
            )}
            <Flex push="right" marginTop={20}>
              <Button color="primary" text={buttonText} />
            </Flex>
          </Flex>
        </Flex>
      </form>
    </Flex>
  )
}
