import * as Types from '../types'

const hasCreatorSubscription = (user: Types.User) => {
  return Boolean(
    user.subscription &&
      user.subscription.status !== 'cancelled' &&
      (user.subscription.planId.toLowerCase().startsWith('creator') ||
        user.subscription.planId.toLowerCase().startsWith('pro')),
  )
}

const isVip = (user: Types.User) => {
  return user.roles.includes('vip')
}

export const userCanStream = (user: Types.User) => {
  return isVip(user) || hasCreatorSubscription(user)
}
