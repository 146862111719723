import * as React from 'react'
import Box, { Flex, Row } from '../../ui/layout/Box'
import Text from '@ui/atoms/text/Text'
import { WithDropdown } from '@ui/atoms/FloatingMenu/FloatingMenu'
import * as Colors from '@ui/helpers/colors'
import { style } from 'typestyle'
import Icon from '@ui/atoms/icons/Icon'
import { useApp } from '../context/app-context'

const LogoPositions = [
  {
    label: 'Top Left',
    value: 'top-left',
  },
  {
    label: 'Top Right',
    value: 'top-right',
  },
  {
    label: 'Bottom Left',
    value: 'bottom-left',
  },
  {
    label: 'Bottom Right',
    value: 'bottom-right',
  },
]

const withDropdownClass = style({
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'row',
  cursor: 'pointer',
  zIndex : '99999 !important'
})

const backdrop = style({
  backgroundColor: Colors.neutral(800),
  padding: 8,
  borderRadius: 4,
  backfaceVisibility: 'hidden'
})

const menuItemStyle = style({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '160px',
  height: '40px',
  gap: '2px',
  borderRadius: 4,
  background: 'transparent',
  color: Colors.neutral(300),
  border: 'none',
  $nest: {
    '&:hover': {
      color: Colors.neutral(0),
      backgroundColor: Colors.neutral(700),
    },
  },
})

const logoSelectChevron = style({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '8px',
  flex: 'none',
  order: 0,
  margin: '0 auto',
})

const selectedItemStyle = style({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '10px 14px',
  borderRadius: 4,
  color: Colors.neutral(0),
  backgroundColor: Colors.neutral(700),
  border: `1px solid ${Colors.neutral(900)}`,
  width: '180px',
  height: '40px',
  gap: '2px',
})
// todo: color should be pulled from state, not passed in.
export const LogoStyleManager = React.memo(
  (props: { position: string; setLogoPosition: any }) => {
    const { track } = useApp()
    const setPosition = React.useCallback(
      (position: string) => {
        track('SetLogoPosition', { value: position })
        props.setLogoPosition(position)
      },
      [props.setLogoPosition],
    )
    const selectedPos = LogoPositions.find(
      (p) => p.value === (props.position || 'top-right'),
    )
    const [isOpen, setIsOpen] = React.useState(false)
    return (
      <Box>
        <Flex direction="row" align="center">
          <Flex grow={1} marginLeft={5}>
            Position
          </Flex>
          <Box position="relative" style={{ zIndex: 3 }}>
            <Row align="stretch">
              <WithDropdown
                className={withDropdownClass}
                isOpen={isOpen}
                onClick={() => {
                  setIsOpen(!isOpen)
                }}
                onClose={() => {
                  setIsOpen(false)
                }}
                node={
                  <Box className={selectedItemStyle}>
                    <Text.Body
                      text={selectedPos.label}
                      width={120}
                      fontWeight={400}
                      fontSize={14}
                    />
                    <div className={logoSelectChevron}>
                      {!isOpen ? (
                        <Icon name="ChevronUp" height={14} width={14} />
                      ) : (
                        <Icon name="ChevronDown" height={14} width={14} />
                      )}
                    </div>
                  </Box>
                }
              >
                <Box className={backdrop}>
                  {LogoPositions.map((d) => {
                    return (
                      <Box key={d.label}>
                        <button
                          className={menuItemStyle}
                          onClick={(e) => {
                            setPosition(d.value)
                            setIsOpen(false)
                          }}
                        >
                          <Text.Body
                            marginLeft={5}
                            text={d.label}
                            fontWeight={400}
                            fontSize={14}
                          />
                        </button>
                      </Box>
                    )
                  })}
                </Box>
              </WithDropdown>
            </Row>
          </Box>
        </Flex>
      </Box>
    )
  },
)
