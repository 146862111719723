import * as React from 'react'
import { style } from 'typestyle'
import {
  IMessageSegment,
  SegmentEmoticonType,
  SegmentType,
} from '@streamjar/chatparser'
import * as Color from '../../helpers/colors'
import classNames from 'classnames'

export type ChatMessageBubbleProps = {
  // Whether the chat message was sent by the current user.
  isSender: boolean

  isSelected?: boolean
  // Chat message to display
  message: IMessageSegment[]
}

const chatStyle = style({
  borderRadius: 12,
  backgroundColor: Color.neutral(800),
  border: '1px solid transparent',
  display: 'inline-flex',
  padding: '10.5px 14.5px',
  flexDirection: 'row',
  gap: '1ch',
  flexWrap: 'wrap',
  maxWidth: '68%',
  lineHeight: 1.2,
  zIndex: 2,
  verticalAlign: 'middle',
  position: 'relative',
})



const senderStyle = style({
  backgroundColor: Color.primary(500),
  textAlign : "left"
})

function fixLink(link: string): string {
  if (!link.startsWith('http:') && !link.startsWith('https:')) {
    return `https://${link}`
  }

  return link
}

export const ChatMessageBubbleSegment = React.memo(
  (
    props: IMessageSegment 
  ) => {
    if (props.type === SegmentType.Emoticon) {
      if (props.data.type === SegmentEmoticonType.DIRECT) {
        return (
          <img src={props.data.url} style={{ height: 16 }} alt={props.text} />
        )
      }
    }

    if (props.type === SegmentType.Link) {
      return (
        <a
          style={{ color: '#FFF', wordBreak: 'break-all' }}
          target="_blank"
          rel="noreferrer"
          href={fixLink(props.data.url)}
        >
          {props.text}
        </a>
      )
    }

    return (
      <span>
        {props.text}
      </span>
    )
  },
)

export const ChatMessageBubble = (
  props: ChatMessageBubbleProps 
) => {
  const selectedStyle = style({
    border: `1px solid ${props.isSender ? Color.neutral(0) : Color.primary(700)}`,
  })
  return (
    <div
      className={classNames(
        chatStyle,
        props.isSender && senderStyle,
        props.isSelected && selectedStyle,
      )}
    >
      {props.message.map((part, index) => (
        <ChatMessageBubbleSegment
          key={index}
          {...part}
        />
      ))}
    </div>
  )
}
