import React, { useCallback, useContext, useRef } from 'react'
import Bowser from 'bowser'
import {
  MediaPermissionsError,
  MediaPermissionsErrorType,
  requestMediaPermissions,
} from 'mic-check'
import { useLocalStorage } from '@ui/hooks/useLocalStorage'

const browser = Bowser.getParser(window.navigator.userAgent)

export type PermissionErrorType = MediaPermissionsErrorType
export const PermissionErrorType = MediaPermissionsErrorType

export enum PermissionStatus {
  Unknown,
  Allowed,
  Failed,
}

export type PermissionContext = {
  request: {
    camera: () => void
    microphone: () => void
  }
  camera: {
    status: PermissionStatus
    error: MediaPermissionsError | null
  }
  microphone: {
    status: PermissionStatus
    error: MediaPermissionsError | null
  }
}

export const PermissionContext = React.createContext<PermissionContext>({
  request: {
    camera() {},
    microphone() {},
  },
  camera: {
    status: PermissionStatus.Unknown,
    error: null,
  },
  microphone: {
    status: PermissionStatus.Unknown,
    error: null,
  },
})

export const PermissionProvider = React.memo(
  (props: React.PropsWithChildren<{}>) => {
    const [cameraPermissions, setCameraPermissions] = React.useState(
      PermissionStatus.Unknown,
    )
    const [cameraError, setCameraError] = React.useState(null)
    const [microphonePermissions, setMicrophonePermissions] = React.useState(
      PermissionStatus.Unknown,
    )
    const [microphoneError, setMicrophoneError] = React.useState(null)

    const requestCamera = useCallback(() => {
      return requestMediaPermissions({ video: true })
        .then(() => {
          setCameraPermissions(PermissionStatus.Allowed)
          setCameraError(null)
        })
        .catch((error: MediaPermissionsError) => {
          console.warn('Failed to access camera:', error)
          setCameraPermissions(PermissionStatus.Failed)
          setCameraError(error)
        })
    }, [setCameraPermissions, setCameraError])

    const requestMicrophone = useCallback(() => {
      return requestMediaPermissions({ audio: true })
        .then(() => {
          setMicrophonePermissions(PermissionStatus.Allowed)
          setMicrophoneError(null)
        })
        .catch((error: MediaPermissionsError) => {
          console.warn('Failed to access microphone:', error)
          setMicrophonePermissions(PermissionStatus.Failed)
          setMicrophoneError(error)
        })
    }, [setCameraPermissions, setCameraError])

    return (
      <PermissionContext.Provider
        value={{
          request: {
            camera: requestCamera,
            microphone: requestMicrophone,
          },
          camera: {
            status: cameraPermissions,
            error: cameraError,
          },
          microphone: {
            status: microphonePermissions,
            error: microphoneError,
          },
        }}
      >
        {props.children}
      </PermissionContext.Provider>
    )
  },
)

export const usePermissions = () => useContext(PermissionContext)
