import * as React from 'react'
import { IMessage, IMessageSegment, SegmentType } from '@streamjar/chatparser'
import { IPlatformVariation } from '@ui/atoms/chat-message/PlatformFilter'
import { Helpers, SDK } from '@api.stream/studio-kit'
import { LiveApiModel } from '@api.stream/sdk'
import { usePrimaryHost } from '../utils/is-primary-host'
import { ChatAggregator } from '../utils/chat-aggregator/ChatAggregator'
import { DestinationProps } from '../../types/models/destination'
import { useApp } from './app-context'

const { StudioContext } = Helpers.React

export interface IPlatformMessage {
  type: 'message'
  value: IMessage
}

export interface ISentMessage {
  type: 'send'
  value: {
    id: string
    message: IMessageSegment[]
    toDestinations: IPlatformVariation[]
    ts: Date
  }
}

export interface IPlatformChatContext {
  // All configured destinations
  destinations: (IPlatformVariation & { canSend: boolean })[]

  // Chat History for all platforms
  messages: (IPlatformMessage | ISentMessage)[]

  // Whether platform chat is enabled.
  enabled: boolean

  // Whether platform chat has any writable platforms connected.
  isUsable: boolean

  // Send a message to a platform
  send(destinations: IPlatformVariation[], message: string): void
}

export const PlatformChatContext = React.createContext<IPlatformChatContext>({
  messages: [],
  enabled: false,
  isUsable: false,
  send() {},
  destinations: [],
})

export const chatComingSoon = (): string[] => {
  return []
}

const SUPPORTED = ['facebook', 'twitchtv', 'youtube']

export const PlatformChatProvider = React.memo(
  (props: React.PropsWithChildren<{ isLive: boolean }>) => {
    const { project } = useApp()
    const { studio, room } = React.useContext(StudioContext)
    const CHAT_COMING_SOON = chatComingSoon()
    const isPrimaryHost = usePrimaryHost()
    const aggregator = React.useRef<ChatAggregator | null>(null)
    const [messages, setMessages] = React.useState<
      (IPlatformMessage | ISentMessage)[]
    >([])
    const [configuredDestinations, setConfiguredDestinations] = React.useState<
      (SDK.Destination & { props: DestinationProps })[]
      // @ts-ignore
    >(project.destinations)

    const [writableDestinations, setWritableDestinations] = React.useState([])

    React.useEffect(() => {
      return project.subscribe((event, payload) => {
        if (event === 'DestinationAdded') {
          const destination: SDK.Destination = payload.destination
          // @ts-ignore
          setConfiguredDestinations((d) => [...d, destination])
        } else if (event === 'DestinationChanged') {
          setConfiguredDestinations((dests) =>
            dests.map((d) => {
              if (d.id !== payload.destinationId) return d
              return payload.destination
            }),
          )
        } else if (event === 'DestinationRemoved') {
          setConfiguredDestinations((dests) =>
            dests.filter((d) => d.id !== payload.destinationId),
          )
        }
      })
    }, [])

    const shouldSubscribe = isPrimaryHost && props.isLive

    const destinations = React.useMemo(() => {
      if (!configuredDestinations) {
        return []
      }

      // Only display valid destinations
      const supported = configuredDestinations.filter(
        (destination) =>
          SUPPORTED.includes(destination.props['type']) && destination.enabled,
      )

      const destinations: (IPlatformVariation & {
        token: string
        platformId: string
      } & IPlatformChatContext['destinations'][number])[] = []

      for (const destination of supported) {
        const platform =
          destination.props['type'] === 'twitchtv'
            ? 'twitch'
            : destination.props['type']

        destinations.push({
          id: destination.id,
          canSend: writableDestinations.includes(destination.id),
          platform: platform as any,
          platformId: destination.props.settings?.streamDestination
            ? destination.props.settings?.streamDestination?.id
            : destination.props['providerId'],
          username:
            destination.props.settings?.streamDestination?.name ??
            destination.props['username'],
          variant: destinations.filter((i) => i.platform === platform).length,
          token:
            destination.props.settings?.streamDestination?.accessToken ??
            destination.props['accessToken'],
        })
      }

      return destinations
    }, [configuredDestinations, writableDestinations])

    const sendMessage = React.useCallback(
      (destinations: IPlatformVariation[], message: string) => {
        const payload: ISentMessage['value'] = {
          id: String(Math.random()),
          ts: new Date(),
          toDestinations: destinations,
          message: [{ type: SegmentType.Text, text: message }],
        }

        // If we're not the primary host, forward the chat message on.
        if (!isPrimaryHost) {
          room?.sendData({
            type: 'Studio2PlatformChatSend',
            destinations,
            message,
          })

          return
        }

        // Forward to platforms
        aggregator.current.sendMessage(destinations, message)

        // Relay the sent message
        room?.sendData({
          type: 'Studio2PlatformChatMessage',
          messageType: 'send',
          message: payload,
        })

        setMessages((msgs) => {
          return [...msgs, { type: 'send', value: payload }]
        })
      },
      [room, isPrimaryHost],
    )

    // Setup the chat aggregation
    React.useEffect(() => {
      if (!aggregator.current) {
        aggregator.current = new ChatAggregator(studio, project.id)
      }

      if (isPrimaryHost) {
        const fn = ({ message }: { message: IMessage }) => {
          room?.sendData({
            type: 'Studio2PlatformChatMessage',
            messageType: 'message',
            message,
          })
          setMessages((msgs) => [...msgs, { type: 'message', value: message }])
        }
        const healthFn = () => {
          room?.sendData({
            type: 'Studio2PlatformChatWritable',
            data: aggregator.current.getWritable(),
          })
          setWritableDestinations(aggregator.current.getWritable())
        }

        aggregator.current.on('message', fn)
        aggregator.current.on('health-changed', healthFn)

        return () => {
          aggregator.current.off('message', fn)
          aggregator.current.off('health-changed', fn)
        }
      }
      return () => {
        /* */
      }
    }, [isPrimaryHost, room])

    // If we're not the primary host, try and load existing chat history.
    React.useEffect(() => {
      if (room && !isPrimaryHost) {
        room.sendData({ type: 'Studio2PlatformChatRequestHistory' })
      }
    }, [!!room, isPrimaryHost])

    // Setup the chat connection (or the hooks to handle remote chat)
    React.useEffect(() => {
      if (shouldSubscribe) {
        aggregator.current.trackDestinations(
          destinations.filter((p) => !CHAT_COMING_SOON.includes(p.platform)),
        )
      } else {
        aggregator.current.trackDestinations([])
      }

      return room?.onData((event, senderId) => {
        // A chat message has been received
        if (event.type === 'Studio2PlatformChatMessage') {
          setMessages((msgs) => [
            ...msgs,
            { type: event.messageType, value: event.message },
          ])
        }

        // A request for chat history has been received (only when we're the primary host)
        // we also forward along any writable platforms if configured.
        if (
          event.type === 'Studio2PlatformChatRequestHistory' &&
          isPrimaryHost
        ) {
          setTimeout(() => {
            room.sendData({ type: 'Studio2PlatformChatHistory', messages }, [
              senderId,
            ])

            const sender = room.getParticipants().find((p) => p.id === senderId)
            if (sender.role === LiveApiModel.Role.ROLE_HOST) {
              room?.sendData(
                {
                  type: 'Studio2PlatformChatWritable',
                  data: aggregator.current.getWritable(),
                },
                [senderId],
              )
            }
          }, 100)
        }

        // Chat history has been received.
        if (event.type === 'Studio2PlatformChatHistory') {
          setMessages(event.messages)
        }

        // A request to send a chat message (only applies to non-primary hosts)
        if (event.type === 'Studio2PlatformChatSend' && isPrimaryHost) {
          const sender = room.getParticipants().find((p) => p.id === senderId)

          if (sender.role === LiveApiModel.Role.ROLE_HOST) {
            sendMessage(event.destinations, event.message)
          }
        }

        // An update around the platforms we can write to
        if (event.type === 'Studio2PlatformChatWritable' && !isPrimaryHost) {
          const self = room
            .getParticipants()
            .find((p) => p.id === room.participantId)

          if (self.role === LiveApiModel.Role.ROLE_HOST) {
            setWritableDestinations(event.data)
          }
        }
      })
    }, [
      shouldSubscribe,
      isPrimaryHost,
      destinations,
      messages,
      room,
      sendMessage,
    ])

    // Context Value
    const value = React.useMemo(
      () => ({
        messages,
        send: sendMessage,
        enabled: props.isLive,
        destinations,
        isUsable: !!destinations.length,
      }),
      [messages, sendMessage, destinations, props.isLive],
    )

    return (
      <PlatformChatContext.Provider value={value}>
        {props.children}
      </PlatformChatContext.Provider>
    )
  },
)
