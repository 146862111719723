import { classes, style } from 'typestyle'
import * as Colors from '../../helpers/colors'
import { Row } from '../../layout/Box'
import { WithInfo } from '../FloatingMenu/FloatingMenu'
import * as React from 'react'
import Text from '../text/Text'
import { Icon } from '../icons/Icon'

export function getPlatformName(platform: string): string {
  switch (platform) {
    case 'twitch':
        return 'Twitch'
    case 'facebook':
        return 'Facebook'
    case 'youtube':
        return 'YouTube'
    default:
        return platform
  }
}


export const iconStyles = {
  twitch: [
    {
      icon: 'Twitch',
      background: Colors.twitch.toHexString(),
      iconColor: Colors.neutral(0),
    },
    {
      icon: 'TwitchDuo',
      background: Colors.neutral(0),
      iconColor: Colors.twitch.toHexString(),
    },
    {
      icon: 'TwitchDuo',
      background: Colors.neutral(300),
      iconColor: Colors.neutral(900),
    },
    {
      icon: 'Twitch',
      background: Colors.neutral(700),
      iconColor: Colors.white.toHexString(),
    },
  ],
  youtube: [
    {
      icon: 'YouTube',
      background: Colors.youtube.toHexString(),
      iconColor: Colors.neutral(0),
    },
    {
      icon: 'YouTubeDuo',
      background: Colors.neutral(0),
      iconColor: Colors.youtube.toHexString(),
    },
    {
      icon: 'YouTubeDuo',
      background: Colors.neutral(300),
      iconColor: Colors.youtube.toHexString(),
    },
    {
      icon: 'YouTube',
      background: Colors.neutral(700),
      iconColor: Colors.neutral(0),
    },
  ],
  facebook: [
    {
      icon: 'Facebook',
      background: Colors.facebook.toHexString(),
      iconColor: Colors.neutral(0),
    },
    {
      icon: 'Facebook',
      background: Colors.neutral(0),
      iconColor: Colors.facebook.toHexString(),
    },
    {
      icon: 'Facebook',
      background: Colors.neutral(300),
      iconColor: Colors.facebook.toHexString(),
    },
    {
      icon: 'Facebook',
      background: Colors.neutral(700),
      iconColor: Colors.neutral(0),
    },
  ],
} as const

const iconStyle = style({
  cursor: 'pointer',
  border: '1px solid transparent',
  width: 32,
  height: 32,
  borderRadius: '50%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 24,
  margin: 2,
})

export interface IPlatformFilterIconProps {
  platform: keyof typeof iconStyles
  variant: number
  active?: boolean
  username?: string
  size?: number
  iconSize?: number
  comingSoonMessage?: string;
  onClick?(): void
}

const platformButtonStyle = style({
  display: 'flex',
  flexDirection: 'row',
  padding: 4,
  borderRadius: 4,
  alignItems: 'center',
  borderBottomLeftRadius: '0px !important',
  position: 'relative',
  border: 'none',
  cursor: 'pointer',
  $nest: {
    '&::after': {
      content: `''`,
      width: 10,
      height: 10,
      position: 'absolute',
      left: 0,
      bottom: -10,
      zIndex: 0,
      backgroundColor: 'inherit',
    },
  },
})

export const PlatformButton = (
  props: Omit<IPlatformFilterIconProps, 'active'>,
) => {
  const platformStyle =
    iconStyles[props.platform]?.[
      props.variant % iconStyles[props.platform]?.length
    ]

  const activeStyle = {
    backgroundColor: platformStyle.background,
    color: platformStyle.iconColor,
  }

  return (
    <button
      className={platformButtonStyle}
      style={activeStyle}
      onClick={props.onClick}
    >
      <Icon width={18} name={platformStyle.icon} />{' '}
      <Text.Caption1 marginLeft={4} fontWeight={700} text={props.username} />
    </button>
  )
}

PlatformButton.defaultProps = {
  variant: 0,
}

export const PlatformFilterIcon = (props: IPlatformFilterIconProps) => {
  const platformStyle = iconStyles[props.platform]

  const activeStyle = {
    backgroundColor:
      platformStyle[props.variant % platformStyle.length].background,
    borderColor: platformStyle[props.variant % platformStyle.length].background,
    color: platformStyle[props.variant % platformStyle.length].iconColor,
    width: props.size,
    height: props.size,
    fontSize: props.iconSize,
  }

  const inactiveStyle = {
    backgroundColor: 'transparent',
    borderColor: platformStyle[props.variant % platformStyle.length].background,
    color: platformStyle[props.variant % platformStyle.length].iconColor,
    width: props.size,
    height: props.size,
    fontSize: props.iconSize,
  }

  const active = props.active && !props.comingSoonMessage
  const style = active ? activeStyle : inactiveStyle
  const className = active ? classes(iconStyle) : classes(iconStyle)
  return (
    <WithInfo
      message={props.comingSoonMessage ?? props.username}
      position="top"
      node={
        <button className={className} style={style} onClick={!props.comingSoonMessage ? props.onClick : () => { }}>
          <span style={{ opacity: active ? 1 : 0.3 }}>
            <Icon
              name={platformStyle[props.variant % platformStyle.length].icon}
            />
          </span>
        </button>
      }
    />
  )
}

export interface IPlatformVariation {
  id: string
  platform: keyof typeof iconStyles
  variant: number
  username: string
}
export interface IPlatformFilterProps {
  value: IPlatformVariation[] | null
  destinations: IPlatformVariation[]
  destinationsComingSoon?: string[]
  onChange(value: IPlatformVariation[] | null): void
}

export const PlatformFilter = (props: IPlatformFilterProps) => {
  const [value, setValue] = React.useState<IPlatformVariation[] | null>(
    props.value,
  )

  React.useEffect(() => {
    setValue(props.value)
  }, [props.value])

  const changeValue = React.useCallback(
    (value: IPlatformVariation[] | null) => {
      // if all destinations are selected, use null.
      if (
        value !== null &&
        props.destinations.every((val) => !!value.find((v) => v.id === val.id))
      ) {
        props.onChange(null)
        setValue(null)

        return
      }

      props.onChange(value)
      setValue(value)
    },
    [props.onChange, props.destinations],
  )

  const onToggle = React.useCallback(
    (newValue: IPlatformVariation) => {
      // All are enabled, we should disable this platform.
      if (value === null) {
        changeValue(props.destinations.filter((i) => i.id !== newValue.id))

        return
      }

      const included = value.find((i) => i.id === newValue.id)

      // This is already enabled, disable if we has multiple enabled.
      if (included) {
        if (value.length > 1) {
          changeValue(value.filter((i) => i !== included))

          return
        }

        return
      }

      // Enable
      changeValue([...value, newValue])
    },
    [value],
  )

  const toggleAll = React.useCallback(() => {
    changeValue(null)
  }, [])

  const allSelected =
    value === null ||
    props.destinations.every((plat) => {
      return value.some((v) => plat.id === v.id)
    })

  return (
    <Row>
      {props.destinations.map(
        (i) =>
          (
            <div style={{ position: 'relative' }} key={i.id}>
              <PlatformFilterIcon
                active={
                  value === null ||
                  !!value.find(
                    (v) =>
                      i.platform === v.platform && i.username === v.username,
                  )
                }
                platform={i.platform as any}
                variant={i.variant}
                username={i.username}
                comingSoonMessage={
                  props.destinationsComingSoon?.includes(i.platform)
                    ? `Support for ${getPlatformName(
                        i.platform,
                      )} chat is coming soon`
                    : undefined
                }
                onClick={() => onToggle(i)}
              />
              {props.destinationsComingSoon?.includes(i.platform) && (
                <div
                  style={{
                    position: 'absolute',
                    bottom: -2,
                    right: -2,
                    pointerEvents: 'none',
                  }}
                >
                  <Icon
                    width={18}
                    height={18}
                    colorWeight={200}
                    color="neutral"
                    name="CircleExclamation"
                  />
                </div>
              )}
            </div>
          )!,
      )}

      <button
        className={allButtonStyle}
        style={{ opacity: allSelected ? 0.3 : 1 }}
        disabled={allSelected}
        onClick={toggleAll}
      >
        All
      </button>
    </Row>
  )
}

const allButtonStyle = style({
  background: 'transparent',
  outline: 'none',
  border: 'none',
  fontWeight: 500,
  fontSize: 10,
  padding: '4px 16px',
  textTransform: 'uppercase',
  color: Colors.neutral(300),
  cursor: 'pointer',
  letterSpacing: '0.08em',
  $nest: {
    '&:hover': {
      color: Colors.neutral(0),
    },
  },
})
