import * as React from 'react'
import Button from '@ui/atoms/buttons/Button'
import TextInput from '@ui/atoms/inputs/TextInput'
import Text from '@ui/atoms/text/Text'
import Box, { Flex } from '@ui/layout/Box'
import { Banner, TextBanner } from '../../ui/components/TextBanner'
import { StudioContext, useApp } from '../context/app-context'
import { useProjectProp } from '@ui/hooks/useProjectProp'
import { HotKey } from '@ui/components/HotKeyPanel'
import { MediaContext } from '../context/media-context'

export const TextBannerManager = () => {
  const { studio } = React.useContext(StudioContext)
  const { activeBanner , setActiveBanner , setActiveChatOverlay } = React.useContext(MediaContext);
  const { projectCommands, track } = useApp()
  const [hotkeys, setHotKeys] = useProjectProp<HotKey[]>('hotkeys')
  const [banners, setBanners] = React.useState<Banner[]>(
    studio.compositor.getSources('Banner'),
  )

  const [newBanner, setNewBanner] = React.useState('')

  React.useEffect(() => studio.compositor.useSources('Banner', setBanners), [])
  // TODO: useActiveBanners

  // Update the state for a banner.
  const onUpdateBanner = React.useCallback((banner: Banner) => {
    projectCommands.editBanner(banner.id, banner.props)
  }, [])

  // Delete a banner
  const onDeleteBanner = React.useCallback(
    (id: string) => {
      if (activeBanner === id) {
        setActiveBanner(null)
      }

      projectCommands.removeBanner(id)
    },
    [activeBanner],
  )

  // Handle creating a new banner.
  const addBanner = React.useCallback(() => {
    if (!newBanner.trim().length) {
      return
    }

    projectCommands.addBanner({
      bodyText: newBanner,
      isFavorite: false,
    })
    setNewBanner('')
  }, [newBanner])

  // Handle the active banner being changed.
  const onActiveBanner = React.useCallback(
    (id: string) => {
      track('SetBanner')
      setActiveBanner(id === activeBanner ? null : id)
      projectCommands.setActiveBanner(id === activeBanner ? null : id)
      setActiveChatOverlay(null)
    },
    [activeBanner],
  )

  const onHotKeyAdded = React.useCallback(
    (bannerId: string, isFavorite: boolean) => {
      if (isFavorite) {
        if (hotkeys && hotkeys?.length >= 4) {
          return
        }
      }

      if (!isFavorite) {
        const filterdHotKeys = hotkeys?.filter((h) => h.hotKeyId !== bannerId)
        setHotKeys([...filterdHotKeys])
      } else {
        setHotKeys([
          ...(hotkeys ?? []),
          {
            type: 'banner',
            hotKeyId: bannerId,
          },
        ])
      }
    },
    [hotkeys],
  )

  return (
    <Box>
      {banners.map((banner) => (
        <TextBanner
          key={banner.id}
          active={activeBanner ? banner.id === activeBanner : null}
          isFavorite={hotkeys?.some((hotkey) => hotkey.hotKeyId === banner.id)}
          bannerId={banner.id}
          onEdit={onUpdateBanner}
          onDelete={onDeleteBanner}
          onHotKeyAdded={onHotKeyAdded}
          onClick={onActiveBanner}
        />
      ))}

      <Box marginTop={10}>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            addBanner()
          }}
        >
          <TextInput
            value={newBanner}
            onChange={(e) => setNewBanner(e.target.value)}
            label={
              <Text.Label
                text={banners.length ? 'Add another' : 'Add a banner'}
                color="neutral"
                fontSize={14}
                colorWeight={500}
                fontWeight={300}
              />
            }
            paddingY={16}
            paddingX={14}
            maxLength={200}
            color="neutral"
            colorWeight={500}
          />
        </form>
      </Box>

      {!!newBanner.trim().length && (
        <Flex direction="row" justify="flex-end">
          <Button
            text="Save"
            height={33}
            width={55}
            fontSize={14}
            marginTop={8}
            onClick={addBanner}
          />
        </Flex>
      )}
    </Box>
  )
}
