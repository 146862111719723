import { cssRaw, keyframes, style, types } from 'typestyle'

export const Placement = {
    topleft: 'topLeft',
    topright: 'topRight',
    bottomleft: 'bottomLeft',
    bottomright: 'bottomRight',
    bottomcenter: 'bottom',
    topcenter: 'top',
}

export const toasterContainer = style({
    position: "fixed",
    overflow: "hidden",
    zIndex: 999999999999,
    textAlign: "right",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
})


export const getContainerPosition = (position: string) => {
    switch (position) {
        case Placement.topleft:
            return {
                top: 10,
                left: 10
            }
        case Placement.topright:
            return {
                top: 10,
                right: 10
            }
        case Placement.topcenter:
            return {
                top: 10,
                left: '50%',
                transform: 'translateX(-50%)'
            }
        case Placement.bottomleft:
            return {
                bottom: 10,
                left: 10
            }
        case Placement.bottomright:
            return {
                bottom: 10,
                right: 10
            }
        case Placement.bottomcenter:
            return {
                bottom: 10,
                left: '50%',
                transform: 'translateX(-50%)'
            }
        default:
            return {
                bottom: 10,
                right: 10
            }
    }
}

cssRaw(`
    .lightstream-toaster-close {
        cursor: pointer;
        display:flex;
    }
    .lightstream-toaster-icon {
        margin-left: -10px;
        margin-right: -8px;
    }
`)

export const toasterContent = style({
    display: "flex",
    fontFamily: 'Arial',
    flexDirection: "row",
    alignItems: "center",
    padding: '5px 15px',
    whiteSpace: "pre-line",
    height: "100%",
    gap: "15px",
    background: "#26AD80",
    borderRadius: "6px",
    flex: "none",
    order: 0,
    flexGrow: 0,
})

const colorAnimationName = keyframes({
    from: {
        opacity: 0,
        transform: "translate3d(0, 100%, 0)"
    },
    to: {
        opacity: 1,
        transform: "none"
    }
})

export const toasterWrapper = style({
    animationName: colorAnimationName,
    animationDuration: "500ms",
    animationFillMode: "both",
    borderRadius: "6px",
    boxSizing: 'border-box',
    display: "flex",
    height: "100%",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "3px",
    gap: "10px"
})

