import React, { useCallback, useContext } from 'react'
import * as Colors from '../../ui/helpers/colors'
import { Column, Flex, Row } from '../../ui/layout/Box'
import { style } from 'typestyle'
import { DestinationButton } from './DestinationDrawer'
import UserDropdown from '@ui/components/UserDropdown'
import Button from '@ui/atoms/buttons/Button'
import Text from '@ui/atoms/text/Text'
import { UserContext } from '../context/user-context'
import { AppContext, useApp } from '../context/app-context'
import { loadMergeAuthWindow } from '../utils/auth'
import { DestinationType } from 'studio-types/models/destination'
import * as Types from '../types'
import { Icon, IconName } from '../../ui/atoms/icons/Icon'
import { IconButton } from '@ui/atoms/buttons/IconButton'
import { CommandButton } from './CommandButton'
import { useReferralHero } from '../utils/analytics'

const topBarClass = style({
  width: '100%',
  height: 70,
  backgroundColor: Colors.neutral(900),
  borderBottom: '1px solid #000',
})

const colorForRole = {
  [Types.Studio2Role.Inactive]: Colors.neutral(300),
  [Types.Studio2Role.Gamer]: Colors.twitch,
  [Types.Studio2Role.Creator]: Colors.primary(400),
  [Types.Studio2Role.Vip]: Colors.neutral(300),
}

const RoleBadge = () => {
  const user = useContext(UserContext)

  return (
    <Flex
      paddingX={8}
      paddingY={3}
      align="center"
      justify="center"
      style={{
        border: `1px solid ${colorForRole[user.role]}`,
        borderRadius: '30px',
      }}
    >
      <Text.Custom
        text={user.role.toUpperCase()}
        style={{ color: colorForRole[user.role] }}
        fontSize={10}
        fontWeight={900}
      />
    </Flex>
  )
}

const linkStyle = style({
  cursor: 'pointer',
  borderRadius: 4,
  color: Colors.neutral(300),
  $nest: {
    '&:hover': {
      background: Colors.neutral(700),
      color: Colors.neutral(0),
    },
    '&:active': {
      background: 'none',
      color: Colors.neutral(0),
    },
  },
})

type ListItemProps = {
  href?: string
  onClick?: () => void
  icon: IconName
  text: string
  newTab?: boolean
}

const ListItem: React.FC<ListItemProps> = (props) => {
  const newTab = props.newTab ?? true
  return (
    <DropdownItem href={props.href} onClick={props.onClick} newTab={newTab}>
      <Row align="center">
        <Flex
          align="center"
          justify="center"
          marginRight={5}
          height={24}
          width={24}
        >
          <Icon name={props.icon} height={24} width={24} />
        </Flex>
        <Text.Body
          text={props.text}
          style={{ textDecoration: 'none' }}
          fontWeight={400}
        />
      </Row>
    </DropdownItem>
  )
}

const DropdownItem: React.FC<{
  href?: string
  newTab?: boolean
  direction?: 'column' | 'row'
  onClick?: React.MouseEventHandler
}> = ({ href, newTab = true, direction = 'row', children, onClick }) => {
  if (href) {
    return (
      <a
        href={href}
        onClick={onClick}
        style={{ textDecoration: 'none' }}
        {...(newTab && { target: '_blank' })}
      >
        <Flex
          direction={direction}
          align="flex-start"
          width={230}
          paddingX={8}
          paddingY={4}
          className={linkStyle}
        >
          {children}
        </Flex>
      </a>
    )
  }
  return (
    <Flex
      direction={direction}
      align="flex-start"
      width={230}
      paddingX={8}
      paddingY={4}
      className={linkStyle}
      {...(Boolean(onClick) && { onClick })}
    >
      {children}
    </Flex>
  )
}

const accountUrl = `${window.location.origin}/welcome?account=true`

const UserHeader = () => {
  const user = useContext(UserContext)

  return (
    <DropdownItem direction="column" newTab={false} href={accountUrl}>
      <Flex direction="column" width="100%">
        <Row paddingBottom={4} width="100%">
          <Text.Heading3
            color="neutral"
            colorWeight={0}
            fontWeight={900}
            text={user.displayName}
            ellipsis={true}
          />
        </Row>
        <Row>
          <RoleBadge />
        </Row>
      </Flex>
    </DropdownItem>
  )
}

const GoLiveTag = () => {
  return (
    <Flex alignSelf="center" align="center" justify="center">
      <IconButton
        width={68}
        height={30}
        iconMargin={3}
        iconSize={11}
        appearance="solid"
        disableOnActive={true}
        disableOnHover={true}
        fontSize={14}
        style={{
          borderRadius: 15,
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          right: '10px',
          gap: 2,
        }}
        color={'secondary'}
        colorWeight={600}
        icon="Circle"
        text="LIVE"
      />
    </Flex>
  )
}

export const TopBar = () => {
  const { userType, project, referralHero, magicInstance } = useContext(AppContext)
  const user = useContext(UserContext)

  const logOut = useCallback(async () => {
    // Clear the session and reload
    if (magicInstance) await magicInstance.user.logout()
    await fetch('/api/users/logout', {
      method: 'POST',
    })
    location.reload()
  }, [magicInstance])

  return (
    <Flex
      className={topBarClass}
      align="stretch"
      justify={
        userType !== Types.UserType.GUEST ? 'space-between' : 'flex-start'
      }
      style={{
        borderBottom: `1px solid black`,
        position: 'relative',
        zIndex: 2,
      }}
    >
      <Flex margin={12}>
        {userType !== Types.UserType.GUEST ? (
          <UserDropdown>
            <Column
              padding={8}
              style={{
                background: Colors.neutral(800),
                borderRadius: 8,
              }}
            >
              <Row marginBottom={8}>
                <UserHeader />
              </Row>
              <ListItem
                icon="AccountSettings"
                text="Account Settings"
                newTab={false}
                href={accountUrl}
              />
              <ListItem
                icon="Studio1"
                text="Launch Studio 1"
                href={`${window.location.origin}/welcome?intent=studio1`}
                newTab={false}
              />
              {referralHero && (
                <ListItem
                  icon="Coin"
                  text="Referral Code"
                  onClick={() => {
                    useReferralHero.then((RH) => {
                      if (RH.optin_data) {
                        const sharingScreen = RH.generate.sharing_screen()
                        RH.generate.popup(sharingScreen)
                      } else {
                        RH.config.callbacks.subscriberLoaded = function () {
                          const sharingScreen = RH.generate.sharing_screen()
                          RH.generate.popup(sharingScreen)
                        }
                        RH.identify(
                          {
                            email: user.email,
                            extra_field: user.id,
                            upsert: true,
                          },
                          true,
                        )
                      }
                    })
                  }}
                />
              )}
              <Flex height={16} width={16} />
              <ListItem
                icon="Book"
                text="Help Center"
                href="https://kb.golightstream.com"
              />
              <ListItem
                icon="Discord"
                text="Discord Community"
                href="https://discord.gg/lightstream"
              />
              <ListItem
                icon="FacebookCircle"
                text="Facebook Group"
                href="https://www.facebook.com/golightstream"
              />
              <Flex height={48} width={16} />
              <DropdownItem onClick={logOut}>
                <Row align="center">
                  <Flex
                    marginRight={5}
                    align="center"
                    justify="center"
                    height={24}
                    width={24}
                  >
                    <Icon name="ArrowRightBracket" height={20} width={20} />
                  </Flex>
                  <Text.Body text="Log Out" fontWeight={400} />
                </Row>
              </DropdownItem>
            </Column>
          </UserDropdown>
        ) : (
          <IconButton
            icon="Lightstream"
            iconColor="primary"
            iconColorWeight={500}
            color="neutral"
            colorWeight={800}
            height={48}
            width={48}
            iconSize={27}
            href={`${window.location.origin}/v2`}
          />
        )}

        {userType === Types.UserType.ADMIN && <CommandButton />}
      </Flex>
      {userType !== Types.UserType.GUEST ? (
        <DestinationButton />
      ) : (
        <React.Fragment>{project.isLive && <GoLiveTag />}</React.Fragment>
      )}
    </Flex>
  )
}
