import * as React from 'react'
import { style } from 'typestyle'
import * as Colors from '../helpers/colors'
import Box, { Flex } from '../layout/Box'
import Button from '../atoms/buttons/Button'
import { IconButton } from '../atoms/buttons/IconButton'
import { WithDropdown } from '../atoms/FloatingMenu/FloatingMenu'
import Icon from '../atoms/icons/Icon'
import TextInput from '../atoms/inputs/TextInput'
import Text from '../atoms/text/Text'
import { StudioContext, useApp } from '../../src/context/app-context'
import { UserContext } from 'src/context/user-context'
import FavoriteButton from '@ui/atoms/media/FavoriteButton'

export interface CustomOverlayData {
  id?: string
  isFavorite?: boolean
  title?: string
  url?: string
  usageType?: string
  resourceType?: string
  height?: number
  width?: number
}

export interface CustomOverlayProps {
  active?: boolean | null
  data: CustomOverlayData
  onClick?(id: string): void
  onResourceUpdate?(id: string, data: CustomOverlayData): void
  onResourceDelete?(id: string): void
  onHotKeyAdded?(data: CustomOverlayData, isFavorite: boolean): void
}

export const OverlayIcons = style({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  paddingRight: 8,
})

const OverlayStyle = style({
  backgroundColor: Colors.neutral(800),
  padding: 20,
  borderRadius: 8,
  width: '100%',
  margin: '0 0 8px',
  marginTop: '10px',
  cursor: 'pointer',
  display: 'block',
  border: '1px solid transparent',
  outline: 'none',
  textAlign: 'left',
  color: Colors.neutral(300),
  position: 'relative',
  $nest: {
    '&:hover': {
      color: Colors.neutral(0),
      backgroundColor: Colors.neutral(700),
      $nest: {
        '& .iconButton': {
          visibility: 'visible',
        },
      },
    },
  },
})

export const backdrop = style({
  backgroundColor: Colors.neutral(800),
  padding: 8,
  borderRadius: 4,
})

const menuItemStyle = style({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: 8,
  borderRadius: 4,
  background: 'transparent',
  color: Colors.neutral(300),
  width: '100%',
  border: 'none',
  $nest: {
    '&:hover': {
      color: Colors.neutral(0),
      backgroundColor: Colors.neutral(700),
    },
  },
})

export const CustomOverlay = React.memo((props: CustomOverlayProps) => {
  const [dropdownOpen, setDropdownOpen] = React.useState(false)
  const [edit, setEdit] = React.useState(false)
  const [editValue, setEditValue] = React.useState('')
  const style: React.CSSProperties = {
    opacity:
      !dropdownOpen && props.active !== null ? (props.active ? 1 : 0.3) : null,
    borderColor: props.active ? Colors.primary(500) : null,
  }

  const onDelete = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      props.onResourceDelete(props.data.id)
    },
    [props.onResourceDelete],
  )

  //Swap to the edit state.
  const onEdit = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      setEditValue(props?.data?.title)
      setEdit(true)
      setDropdownOpen(false)
    },
    [props.data.title],
  )

  const onSave = React.useCallback(() => {
    if (editValue.trim() === '') {
      setEdit(false)
      return
    }

    props.onResourceUpdate(props.data.id, {
      ...props.data,
      title: editValue,
    })

    setEdit(false)
  }, [props.data, props.onResourceUpdate, editValue])

  if (edit) {
    return (
      <>
        <Box>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              onSave()
            }}
          >
            <TextInput
              defaultValue={props.data.title}
              label={
                <Text.Label
                  marginTop={10}
                  text="Edit"
                  color="neutral"
                  fontSize={14}
                  colorWeight={500}
                  fontWeight={300}
                />
              }
              autoFocus={true}
              selectOnFocus={true}
              paddingY={16}
              paddingX={14}
              maxLength={200}
              color="neutral"
              colorWeight={500}
              value={editValue}
              onChange={(e) => setEditValue(e.target.value)}
            />

            <TextInput
              defaultValue={props?.data?.url}
              disabled={true}
              label={
                <Text.Label
                  marginTop={10}
                  text={'URL'}
                  color="neutral"
                  fontSize={14}
                  colorWeight={500}
                  fontWeight={300}
                />
              }
              paddingY={16}
              paddingX={14}
              maxLength={200}
              color="neutral"
              colorWeight={500}
            />
          </form>
        </Box>
        <Flex direction="row" justify="flex-end" marginTop={8}>
          <Button
            text="Cancel"
            appearance="text"
            color="neutral"
            colorWeight={0}
            height={33}
            width={68}
            fontSize={14}
            onClick={() => {
              setEdit(false)
            }}
          />
          <Button
            text="Save"
            height={33}
            width={55}
            fontSize={14}
            marginLeft={8}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation()
              onSave()
            }}
          />
        </Flex>
      </>
    )
  }

  return (
    <button
      className={OverlayStyle}
      style={style}
      onClick={() => props.onClick(props.data.id)}
    >
      <Text.Body colorWeight={300} fontWeight={700} text={props?.data.title} />
      <Text.Body
        colorWeight={300}
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
        text={props?.data.url}
      />

      <Box className={OverlayIcons}>
        <Flex direction="row" justify="flex-end">
          <FavoriteButton
            id={props?.data?.id}
            className="iconButton"
            isFavorite={props?.data?.isFavorite}
            onFavorite={(id, isFavorite) =>
              props.onHotKeyAdded(
                { id, resourceType: props?.data.resourceType },
                isFavorite,
              )
            }
            iconWidth={24}
            iconHeight={24}
            iconSize={20}
          />
          <WithDropdown
            isOpen={dropdownOpen}
            position="bottom"
            align="end"
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.stopPropagation()
              setDropdownOpen((v) => !v)
            }}
            onClose={() => {
              setDropdownOpen(false)
            }}
            width={140}
            node={
              <IconButton
                className="iconButton"
                height={24}
                appearance="text"
                icon="TripleDots"
                iconColor="neutral"
                iconSize={20}
                marginLeft={4}
                width={24}
              />
            }
          >
            <Box className={backdrop}>
              <button className={menuItemStyle} onClick={onEdit}>
                <Icon width={24} height={24} name="Pencil" />
                <Text.Body marginLeft={5} text="Edit" />
              </button>

              <button className={menuItemStyle} onClick={onDelete}>
                <Icon width={24} height={24} name="Trash" />
                <Text.Body marginLeft={5} text="Delete" />
              </button>
            </Box>
          </WithDropdown>
        </Flex>
      </Box>
    </button>
  )
})
