import React, { useState, useEffect, ReactElement } from 'react'
import * as Color from '../../helpers/colors'

import { stylesheet, style, classes } from 'typestyle'
import Icon from '../icons/Icon'
import Select from './Select'
import useElementFromRef from '../../hooks/useElementFromRef'
import useEventListener from '../../hooks/useEventListener'
import useResizeObserver from '../../hooks/useResizeObserver'
import { WithDropdown } from '../FloatingMenu/FloatingMenu'
import { Flex } from '../../layout/Box'
import isArray from 'lodash/isArray'
import Text from '../text/Text'

const important = (x: string) => `${x} !important`

const styles = stylesheet({
  dropdown: {
    borderRadius: 4,
    cursor: 'default',
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    $nest: {
      '& svg': {
        color: 'white',
        opacity: 0.8,
      },
      '&:hover svg': {
        opacity: 1,
      },
      '&:hover': {
        opacity: 1,
      },
    },
  },
  option: {
    cursor: 'default',
    color: Color.white.toString(),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
})

const DefaultOptionNode = (
  props: DropdownOptionProps<any> & { view: 'list' | 'label' | 'main' },
) => {
  const colorWeight = props.view === 'label' ? 500 : props.disabled ? 700 : 0

  return (
    <Flex paddingX={12} paddingY={8} align="center">
      <Text.Body
        text={props.label || props.value}
        color="neutral"
        colorWeight={colorWeight}
        fontSize={props.view === 'label' ? 11 : undefined}
        selectable={false}
      />
    </Flex>
  )
}

type DropdownOptionProps<T> = {
  value: T
  disabled?: boolean
  label?: string
  // Any additional fields are passed to the OptionNode component
  [prop: string]: unknown
}
type Options<T> = Array<DropdownOptionProps<T>>
type OptionGroups<T> = { [label: string]: Options<T> }
type DropdownProps<T> = {
  onChange: (val: T) => void
  options: Options<T> | OptionGroups<T>
  value: T
  width?: number
  height?: string | number
  marginLeft?: number
  marginTop?: number
  disabled?: boolean
  invalid?: boolean
  appearance?: 'solid' | 'outline'
  color?: Color.Type
  colorWeight?: number
  dropdownColor?: Color.Type
  dropdownColorWeight?: number
  placeholder?: string
  className?: string
  OptionNode?: (
    props: DropdownOptionProps<T> & {
      view: 'main' | 'list' | 'label'
      group?: string
    },
  ) => ReactElement
}
export const CustomSelect = <T extends string | number>(
  props: DropdownProps<T>,
) => {
  const {
    options,
    value,
    width,
    marginLeft,
    marginTop,
    className,
    color = 'neutral',
    colorWeight = 800,
    dropdownColor,
    dropdownColorWeight = 400,
    disabled = false,
    invalid = false,
    appearance = 'solid',
    placeholder = 'Select an option...',
    onChange,
    OptionNode = DefaultOptionNode,
  } = props
  const allOptions = isArray(options)
    ? options
    : Object.entries(options).flatMap(([group, val]) =>
        val.map((x) => ({ ...x, group })),
      )
  const [activeOption, setActiveOption] = useState(
    allOptions.find((x) => x.value === value),
  )
  const [hoveredValue, setHoveredValue] = useState(null)
  const [dropdownRectWidth, setDropdownRectWidth] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [inputRef, inputEl] = useElementFromRef<HTMLSelectElement>()
  const [dropdownRef, dropdownEl] = useElementFromRef<HTMLDivElement>()
  const [isFocused, setIsFocused] = useState(false)
  const height = props.height || 48

  const colors = {
    base: Color[color](colorWeight),
    error: Color.secondary(400),
  }

  useResizeObserver([dropdownEl], ([rect]) => {
    setDropdownRectWidth(rect.width)
  })
  useEffect(() => {
    setActiveOption(allOptions.find((x) => x.value === value))
  }, [value, options])
  useEffect(() => {
    setHoveredValue(null)
  }, [value])

  useEventListener(
    inputEl,
    'focus',
    () => {
      setIsFocused(true)
      setIsOpen(true)
    },
    {},
    [],
  )

  useEventListener(
    inputEl,
    'blur',
    () => {
      setTimeout(() => {
        setIsFocused(false)
        setIsOpen(false)
      }, 200)
    },
    {},
    [],
  )
  // Indicate to the parent that the dropdown believes it should close
  useEventListener(
    document.body,
    'keydown',
    (e) => {
      if (e.key === 'Enter') {
        setIsOpen(!isOpen)
      }
    },
    {},
    [isFocused],
  )

  const renderOptionSet = (options: Options<T>) =>
    options.map(({ value: optionValue, disabled, ...optionProps }, i) => {
      const style =
        (optionValue === value && !hoveredValue) ||
        (hoveredValue === optionValue && !disabled)
          ? {
              background: Color.primary(700),
              color: Color.white.toString(),
            }
          : { color: Color.white.fade(disabled ? 0.4 : 0.8).toString() }
      return (
        <Flex
          width="100%"
          className={styles.option}
          style={style}
          key={optionValue}
          onMouseEnter={() => {
            if (disabled) return
            setHoveredValue(optionValue)
          }}
          onClick={() => {
            if (disabled) return
            onChange(optionValue)
            inputEl.blur()
            setIsFocused(false)
            setIsOpen(false)
          }}
        >
          <OptionNode
            {...optionProps}
            value={optionValue}
            disabled={disabled}
            view="list"
          />
        </Flex>
      )
    })

  const renderOptionGroups = (optionGroups: OptionGroups<T>) =>
    Object.entries(optionGroups).map(([k, val]) => (
      <>
        {!!val.length && (
          <OptionNode key={k} label={k} value={null} view="label" />
        )}
        {renderOptionSet(val)}
      </>
    ))

  const renderOptions = (options: Options<T> | OptionGroups<T>) => {
    if (isArray(options)) {
      return renderOptionSet(options)
    } else {
      return renderOptionGroups(options)
    }
  }

  return (
    <Flex
      className={classes(
        style({
          border: `1px solid ${colors.base}`,
          backgroundColor: colors.base,
          ...(disabled && {
            pointerEvents: 'none',
            opacity: 0.5,
          }),
          ...(appearance === 'outline' && {
            backgroundColor: Color.transparent.toString(),
          }),
          ...(invalid && {
            borderColor: important(colors.error),
          }),
          $nest: {
            ...(!disabled && {
              '&:hover': {
                ...(appearance === 'solid' && {
                  backgroundColor: colors.base,
                }),
                ...(invalid && {
                  borderColor: important(colors.error),
                }),
              },
              '&:focus, &:focus-within': {
                border: `1px solid ${Color.primary(500)}`,
                ...(invalid && {
                  borderColor: important(colors.error),
                }),
              },
            }),
          },
        }),
        styles.dropdown,
        className,
      )}
      width={width || '100%'}
      style={{
        height: `${height}px`,
        marginTop,
        marginLeft,
        padding: 0,
        ...(isFocused ? { borderColor: Color.primary(500) } : {}),
      }}
    >
      <WithDropdown
        onClick={() => {
          setIsFocused(true)
          setIsOpen(true)
        }}
        onClose={() => {
          setIsOpen(false)
        }}
        isOpen={isOpen}
        disabled={disabled}
        containerWidth="100%"
        containerHeight="100%"
        node={
          <div
            ref={dropdownRef}
            onClick={() => {
              window.setTimeout(() => {
                if (isOpen) setIsOpen(false)
              })
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            {/* Active selection */}
            <Flex width="88%" className={styles.option}>
              {activeOption ? (
                <OptionNode {...activeOption} view="main" />
              ) : (
                <Flex paddingX={12}>
                  <Text.Body selectable={false} text={placeholder} />
                </Flex>
              )}
            </Flex>
            <div
              style={{
                opacity: isOpen ? 1 : 0.7,
                position: 'absolute',
                right: '0.9em',
              }}
            >
              <Icon
                name="DropdownChevron"
                width={14}
                height={10}
                colorWeight={0}
                color="neutral"
              />
            </div>
          </div>
        }
      >
        {/* Options list */}
        <Flex
          direction="column"
          width={width || dropdownRectWidth || '100%'}
          maxHeight={350}
          className={classes(styles.dropdown)}
          paddingY={3}
          style={{
            overflowY: 'auto',
            ...(appearance === 'solid' && {
              background: colors.base,
            }),
            ...(appearance === 'outline' && {
              background: Color.neutral(900),
              border: `1px solid ${colors.base}`,
            }),
            ...(dropdownColor && {
              background:
                Color[dropdownColor || 'neutral'](dropdownColorWeight),
            }),
            fontSize: dropdownEl ? getComputedStyle(dropdownEl).fontSize : 14,
          }}
          onMouseLeave={() => {
            setHoveredValue(value)
          }}
        >
          {renderOptions(options)}
        </Flex>
      </WithDropdown>
      {/* Offscreen select element */}
      <div
        style={{
          opacity: 0,
          top: -10000,
          left: -10000,
          position: 'absolute',
          pointerEvents: 'none',
        }}
      >
        <Select
          {...props}
          value={String(props.value)}
          options={allOptions}
          inputRef={inputRef}
          onChange={(e) => {
            setHoveredValue(null)
            const nextValue = e.target.value
            props.onChange(
              (typeof value === 'string'
                ? String(nextValue)
                : Number(nextValue)) as any,
            )
          }}
        />
      </div>
    </Flex>
  )
}

export default CustomSelect
