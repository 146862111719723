import { keyframes, style } from 'typestyle'
import { WithInfo } from '../../ui/atoms/FloatingMenu/FloatingMenu'
import Icon from '../../ui/atoms/icons/Icon'
import Text from '../../ui/atoms/text/Text'

const animation = keyframes({
  '0%': { filter: 'hue-rotate(0deg)' },
  '100%': { filter: 'hue-rotate(360deg)' },
})

const impersonationClass = style({
  backgroundImage: 'radial-gradient(circle at 75% 75%, #26ad80, #303030)',
  animation: `${animation} 30s steps(90, end)`,
  color: '#FFF',
  borderRadius: 8,
  display: 'flex',
  alignItems: 'center',
  padding: 12,
  marginLeft: 15,
})

export const CommandButton = () => {
  return (
    <WithInfo
      message="You are currently impersonating another users studio2 project"
      position="right"
      node={
        <div className={impersonationClass}>
          <Icon name="CircleExclamation" width={24} height={24} />
          <Text.Heading3
            text="Impersonating"
            textTransform="uppercase"
            fontWeight={600}
            marginLeft={8}
            fontSize={13}
          />
        </div>
      }
    />
  )
}
