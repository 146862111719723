import * as React from 'react'
import Box, { Flex } from '../../ui/layout/Box'
import {
  VideoBannerStyle,
  VideoBannerButton,
} from '@ui/components/VideoBannerStyle'
import Text from '@ui/atoms/text/Text'
import Toggle from '@ui/atoms/toggles/Toggle'
import { useProjectProp } from '@ui/hooks/useProjectProp'
import { useApp } from '../context/app-context'

// todo: color should be pulled from state, not passed in.
export const BannerStyleManger = React.memo((props: { color: string }) => {
  const { track } = useApp()
  const [showNameBanners, setShowNameBanners] =
    useProjectProp<boolean>('showNameBanners')
  const [style, setStyle] = useProjectProp<VideoBannerStyle>('bannerStyle')

  const onSelect = React.useCallback((type: VideoBannerStyle) => {
    track('SetBannerStyle', { value: type })
    setStyle(type)
  }, [])

  return (
    <Box>
      <Flex direction="row" gap={4}>
        <Flex grow={1}>
          <VideoBannerButton
            active={style === VideoBannerStyle.DEFAULT}
            color={props.color}
            type={VideoBannerStyle.DEFAULT}
            onSelect={onSelect}
          />
        </Flex>
        <Flex grow={1}>
          <VideoBannerButton
            active={style === VideoBannerStyle.MINIMAL}
            color={props.color}
            type={VideoBannerStyle.MINIMAL}
            onSelect={onSelect}
          />
        </Flex>
        <Flex grow={1}>
          <VideoBannerButton
            active={style === VideoBannerStyle.BUBBLE}
            color={props.color}
            type={VideoBannerStyle.BUBBLE}
            onSelect={onSelect}
          />
        </Flex>
      </Flex>

      <Flex justify="space-between" marginY={20}>
        <Text.Heading3 text="Participant Names" colorWeight={300} />
        <Toggle
          onChange={(value) => {
            track('ToggleNameBanners', { value })
            setShowNameBanners(value)
          }}
          value={showNameBanners}
        />
      </Flex>
    </Box>
  )
})
