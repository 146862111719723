import Box, {  Flex } from '../../layout/Box'
import React, { useState, useEffect, useCallback, CSSProperties } from 'react'
import { style } from 'typestyle'
import Icon, { SVG } from '../icons/Icon'
import classNames from 'classnames'
import { WithDropdown } from '../FloatingMenu/FloatingMenu'
import * as Color from '../../helpers/colors'
import LoadingDots from '../animations/LoadingDots'
import * as Colors from '@ui/helpers/colors'
import Text, { TextProps } from '../text/Text'
import { MediaTileContext } from './MediatileContext'
import { RemaningTime } from '../../../src/context/media-context'
import FavoriteButton from './FavoriteButton'
export interface SnapShot {
  small: string
  medium: string
  large: string
}
export interface MediaTileData {
  url: string
  id: string
  type: string
  title: string
  authorName: string
  authorWebsite: string
  authorAvatar?: string
  resourceType?: string
  usageType?: string
  themeName: string
  uploadedAt: string
  isFavorite: boolean
  isGlobal: boolean
  snapshot: SnapShot
  duration: number
  width?: number
  height?: number
}

interface StyleProps {
  borderColor?: TextProps['color']
  borderRadius?: CSSProperties['borderRadius']
  borderColorWeight?: TextProps['colorWeight']
}

export interface MediaTileProps {
  data: MediaTileData
  favorite?: boolean
  key?: string
  playOnClick?: boolean
  showFavorite?: boolean
  showDuration?: boolean
  showContext?: boolean
  hideControls?: boolean
  active?: boolean
  dimmed?: boolean
  altTag?: string
  resizeMode?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down'
  remaningDuration?: RemaningTime
  isOpen?: boolean
  setSelectedContext?: (id: string) => void
  onFavorite?: (id: string, state: boolean) => void
  onClick?: (key: string) => void
  onDelete?: (key: string) => void
  onInputChange?: (id: string, value: string) => void
}

export const backdrop = style({
  cursor: 'default',
  backgroundColor: Colors.neutral(800),
  padding: 8,
  borderRadius: 4,
  backfaceVisibility: 'hidden',
})

const horizontalRow = style({
  minWidth: '200px',
  height: '0px',
  border: '1px solid #000000',

  /* Inside auto layout */

  flex: 'none',
  order: 1,
  flexGrow: 0,
})
const menuItemStyle = style({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: 8,
  borderRadius: 4,
  background: 'transparent',
  color: Colors.neutral(300),
  width: '100%',
  border: 'none',
  $nest: {
    '&:hover': {
      color: Colors.neutral(0),
      backgroundColor: Colors.neutral(700),
    },
  },
})

const MediaTileStar = style({
  cursor: 'pointer',
  marginLeft: '4px',
})

const MediaTileLayer = style({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: '28px',
  height: '20px',
  background: 'rgba(20, 20, 20, 0.7)',
  borderRadius: '8px',
  fontSize: '10px',
})

const MediaTileMore = style({
  cursor: 'pointer',
  marginLeft: 'auto',
})

const MediaTileChildren = style({
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  bottom: 0,
  padding: '2px',
  maxWidth: '120px',
  width: '100%',
})

const MediaTileContainer = style({
  position: 'relative',
  cursor: 'pointer',
  border: '1px solid transparent',
  borderRadius: '6px',
  $nest: {
    '&:hover': {
      border: '1px solid white',
    },
  },
})

/**
 *
 * @returns {JSX.Element}
 */
const LoadingSpinner = (): JSX.Element => {
  return (
    <Flex align="center" justify="center" height={68} width={120}>
      <SVG color="primary" colorWeight={400} width={32} svg={LoadingDots} />
    </Flex>
  )
}

const secondsToMintues = (s: number) => {
  return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s
}

const MediaTile: React.FC<MediaTileProps & StyleProps> = ({
  data,
  borderColor = 'primary',
  borderColorWeight = 600,
  borderRadius = '6px',
  showFavorite = true,
  showDuration = true,
  showContext = true,
  hideControls = false,
  active = false,
  remaningDuration = null,
  altTag,
  dimmed = false,
  onInputChange,
  onFavorite,
  onClick,
  onDelete,
  setSelectedContext,
  isOpen,
  resizeMode = 'cover',
}) => {
  const MediaTileActive = {
    border: `1px solid ${Color[borderColor](borderColorWeight)}`,
    borderRadius: borderRadius,
    cursor: 'pointer',
  }

  const [loading, setLoading] = useState(true)
  const nodeRef = React.useRef(null)
  const [remainingTime, setRemainingTime] = useState<number>(0)


  useEffect(() => {
    if (remaningDuration) {
      setRemainingTime(remaningDuration.time)
    } else {
      setRemainingTime(Math.floor(data.duration))
    }
  }, [remaningDuration])


  const onDeleteItem = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()

      onDelete(data.id)
    },
    [onDelete, data.id],
  )

  /** @returns {JSX.Element} */
  const GetResource = (): JSX.Element => {
    return (
      <Box
        style={active && MediaTileActive}
        onClick={() => onClick(data?.id)}
        className={MediaTileContainer}
      >
        {!hideControls && !loading && (
          <Box className={MediaTileChildren}>
            {showDuration && data.duration > 0 && (
              <Box
                style={{ opacity: dimmed ? 0.5 : 1, fontSize: 10 }}
                className={classNames(MediaTileLayer)}
              >
                {remainingTime
                  ? secondsToMintues(remainingTime)
                  : secondsToMintues(Math.floor(data.duration))}
              </Box>
            )}
            {showFavorite && (
              <FavoriteButton
                id={data?.id}
                dimmed={dimmed}
                className={MediaTileStar}
                isFavorite={data?.isFavorite}
                onFavorite={onFavorite}
                iconWidth={16}
                iconHeight={16}
                iconSize={16}
              />
            )}
            {showContext && (
              <Box style={{ marginLeft: 'auto' }}>
                <WithDropdown
                  isOpen={isOpen}
                  position="bottom"
                  align="end"
                  onClick={(e) => {
                    e.stopPropagation()
                    setSelectedContext(!isOpen ? data.id : null)
                  }}
                  onClose={() => {
                    setSelectedContext(null)
                  }}
                  node={
                    <Box
                      className={classNames(MediaTileLayer, MediaTileMore)}
                      style={{ opacity: dimmed ? 0.5 : 1 }}
                    >
                      <Icon name="TripleDots" height={4} />
                    </Box>
                  }
                >
                  <Box
                    className={backdrop}
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                  >
                    <MediaTileContext data={data} />
                    {!data.isGlobal && (
                      <>
                        <hr className={horizontalRow}></hr>
                        <button
                          className={menuItemStyle}
                          onClick={onDeleteItem}
                        >
                          <Icon width={24} height={24} name="Trash" />
                          <Text.Body marginLeft={5} text="Delete" />
                        </button>
                      </>
                    )}
                  </Box>
                </WithDropdown>
              </Box>
            )}
          </Box>
        )}
        {loading && <LoadingSpinner />}
        <MediaElement
          style={{
            opacity: dimmed ? 0.5 : 1,
            pointerEvents: 'none',
            objectFit: resizeMode,
          }}
          loading={loading}
        />
      </Box>
    )
  }

  const MediaElement = useCallback(
    ({ style, loading }: { style: CSSProperties; loading: boolean }) => {
      return (
        <>
          <img
            key={data.id}
            ref={nodeRef}
            src={data?.snapshot?.medium}
            style={{
              display: loading ? 'none' : 'block',
              borderRadius: '6px',
              width: '100%',
              maxWidth: '120px',
              height: '68px',

              objectFit: 'cover',
              ...style,
            }}
            onLoad={() => {
              setLoading(false)
            }}
          />
        </>
      )
    },
    [data?.snapshot?.small],
  )

  const resource = GetResource()
  return <>{resource}</>
}

export default MediaTile
