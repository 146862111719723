import type {
  Subscription, 
  Capabilities,
  Role,
} from 'studio-types/models/subscriptions'
import { Destination } from 'studio-types/models/destination'
import { UsageType } from 'studio-types/models/resource'
import { SnapShot } from '@ui/atoms/media/Mediatile'
import { SDK, Helpers } from '@api.stream/studio-kit'
import type { Magic } from 'magic-sdk'

export const enum Studio2Role {
  Vip = 'vip',
  Inactive = 'inactive',
  Creator = 'creator',
  Gamer = 'gamer',
}
export const enum UserType {
  HOST,
  GUEST,
  ADMIN,
}

export type { Subscription, Capabilities, Role }

export type App = {
  userType: UserType
  /**
   * Socket has connected
   */
  loaded: boolean
  /**
   * User has entered from a magic link URL
   */
  visitedFromMagicLink: boolean
  magicLinkCredentials: string
  magicLinkEmail?: string
  commandToken?: string
  entryUrl: string
  guestToken?: string
  env: 'local' | 'stage' | 'prod'
  sdkEnv: 'stage' | 'prod'
  socket: WebSocket
  reconnectSocket: () => void
  kustomerApiKey: string
  segment: {
    enabled: boolean
    apiKey: string
  }
  referralHero: {
    enabled: boolean
    apiKey: string
    uuid: string
    newUserChargebeeCoupon: string
  }
  magicLink: {
    apiKey: string
  }
  magicInstance: Magic
  NPS: {
    enabled: boolean
  }
  /**
   * Track event to third-party integrations
   */
  track: (event: string, data?: { [prop: string]: any }) => void
  // TODO: Wire up when supporting multiple projects
  activeProjectId: string
  project: SDK.Project
  projectCommands: Helpers.ScenelessProject.Commands
  isMirrored?: boolean
}

/**
 * Resource from mongodb for Studio 2
 */
export type Resource = {
  title: string
  /** ex: 'image/png' */
  type: string
  length: number
  isGlobal: boolean
  naturalWidth: number
  naturalHeight: number
  /** 'image' | 'video' */
  resourceType: string
  resourceUrl: string
  user?: string
  updatedAt: string
  createdAt: string
  isFavorite: boolean
  snapshot: SnapShot
  authorName: string
  authorAvatar?: string
  themeName: string
  authorWebsite: string
  duration: number
  id: string
  usageType?: UsageType
}

/**
 * Simplified Account from mongodb for Studio 2
 */
export type Account = {
  id: string
  createdAt: string
  displayName: string
  isAuthenticated: boolean
  isRestricted: boolean
  providerId: string
  owner: string
  type: 'magic' | 'twitchtv' | 'youtube' | 'facebook'
  typePretty: 'Magic' | 'Twitch' | 'YouTube' | 'Facebook'
}

export type Bootstrap = {
  user: Partial<User>
  app: Partial<App>
}

type Resolution = {
  x: number
  y: number
  framerate: number
}

type TooltipHelpers = {
  hotkey : string
  urloverlay : string
}

export type User = {
  id: string
  email: string
  createdAt: string
  displayName: string
  subscription: Subscription
  /** deprecating. use User.role instead. keeping roles in here until we know it is not referenced at all in studio 2. */
  roles: Role[]
  /** use this one. only describes if creator/gamer/vip/inactive. */
  role: Studio2Role
  accounts: Account[]
  resources: Resource[]
  globalResources: Resource[]
  capabilities: Capabilities
  destinations: Destination[]
  emailVerified: boolean
  sdkToken: string
  loggedIn: boolean
  hasDoneTrial: boolean
  kustomerToken: string
  resolution: Resolution
  tooltipHelpers :  TooltipHelpers
}
