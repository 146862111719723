const escapeListeners: Array<(e?: KeyboardEvent) => void> = []

export const subscribeToEscape = (listener: (e?: KeyboardEvent) => void) => {
  escapeListeners.unshift(listener)
  return () => {
    escapeListeners.shift()
  }
}

document.body.addEventListener(
  'keydown',
  (e) => {
    if (e.key === 'Escape') {
      escapeListeners.length && escapeListeners[0](e)
    }
  })

