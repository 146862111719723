import React, { useContext, useState, useEffect, useRef, useMemo } from 'react'
import { SDK, Helpers } from '@api.stream/studio-kit'
import { WithDropdown, Modal } from '../../ui/atoms/FloatingMenu/FloatingMenu'
import Box, { Flex, Row } from '../../ui/layout/Box'
import Text, { CustomText } from '../../ui/atoms/text/Text'
import * as Colors from '../../ui/helpers/colors'
import { Avatar } from '../../ui/atoms/icons/Avatar'
import { IconName } from '../../ui/atoms/icons/Icon'
import { useEvent, User } from '../utils/events'
import {
  NoStudio1Modal,
  SetupLinkPlatform,
  GoLiveStudio1Trial,
  GoLiveStudio1NoTrial,
} from '../../ui/components/modals/modals'
import {
  DestinationProps,
  CustomDestinationProps,
  TwitchDestinationProps,
  FacebookDestinationProps,
  YouTubeDestinationProps,
  Destination,
  DestinationType,
  YouTubePrivacy,
  FacebookPrivacy,
} from 'studio-types/models/destination'
import { style } from 'typestyle'
import Toggle from '../../ui/atoms/toggles/Toggle'
import Checkbox from '../../ui/atoms/toggles/Checkbox'
import Icon from '../../ui/atoms/icons/Icon'
import Button, { ButtonProps } from '../../ui/atoms/buttons/Button'
import useEventListener from '../../ui/hooks/useEventListener'
import {
  loadAuthWindow,
  closeAuthWindow,
  loadMergeAuthWindow,
} from '../utils/auth'
import TextInput from '../../ui/atoms/inputs/TextInput'
import { subscribeToEscape } from '../../ui/helpers/escape-context'
import { UserContext } from '../context/user-context'
import { NormalModal, DarkModal } from '../../ui/atoms/FloatingMenu/Modal'
import useToaster from '@ui/hooks/useToaster'
import { YouTubeError } from 'studio-types/error-messages/platforms'
import CustomSelect from '@ui/atoms/inputs/CustomSelect'
import { userCanStream } from '../utils/subscription'
import { UserType } from '../types'
import { ToastConfig } from '@ui/atoms/toaster/Toast'
import { AppContext, useApp } from '../context/app-context'
import { IconButton } from '@ui/atoms/buttons/IconButton'

const { StudioContext } = Helpers.React

const withDropdownClass = style({
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'row',
  cursor: 'pointer',
})

const getButtonProps = (
  isLoading: boolean,
  isLive: boolean,
  isHost: boolean,
): { text: string; color: ButtonProps['color']; disabled?: boolean } => {
  if (!isLoading && !isLive) {
    return {
      text: 'Go Live',
      disabled: !isHost,
      color: 'primary',
    }
  }
  if (!isLoading && isLive) {
    return {
      text: 'Stop Broadcast',
      color: 'secondary',
    }
  }
  if (isLoading && !isLive) {
    return {
      text: 'Starting Broadcast',
      color: 'neutral',
    }
  }
  if (isLoading && isLive) {
    return {
      text: 'Stopping Broadcast',
      color: 'neutral',
    }
  }
}

const validRoles = [
  'vip',
  'creator_720_30',
  'creator_720_60',
  'creator_1080_30',
  'pro_720_30',
]

export const DestinationButton = () => {
  const [toaster, contextHolder] = useToaster()
  const { studio, room } = useContext(StudioContext)
  const app = useContext(AppContext)
  const { project, projectCommands } = app
  const [destinations, setDestinations] = useState<
    (SDK.Destination & { props: DestinationProps })[]
    // @ts-ignore
  >(project.destinations)
  const [isOpen, setIsOpen] = useState(false)
  const [isAddDestinationOpen, setIsAddDestinationOpen] = useState(false)
  const [isLive, setIsLive] = useState(project.isLive)
  const [isLoading, setIsLoading] = useState(false)
  const user = useContext(UserContext)
  const [showNoStudio1Modal, setShowNoStudio1Modal] = useState(false)
  const [showSetupLinkPlatform, setShowSetupLinkPlatform] = useState(false)
  const [showGoLiveStudio1Trial, setShowGoLiveStudio1Trial] = useState(false)
  const [showGoLiveStudio1NoTrial, setShowGoLiveStudio1NoTrial] =
    useState(false)

  useEvent(User.UserMergeFailed, (payload: { error: string }) => {
    closeAuthWindow()
    const errorMessage = `Could not merge Lightstream accounts: ${payload.error}`
    toaster.open({
      key: User.UserMergeFailed,
      content: errorMessage,
      placement: 'topRight',
      duration: 10,
      style: {
        backgroundColor: 'secondary',
        backgroundColorWeight: 500,
      },
      closable: true,
    })
  })

  useEvent(
    User.AddDestinationFailed,
    (payload: {
      error: string
      platform: DestinationType
      errorReason?: YouTubeError
    }) => {
      closeAuthWindow()
      if (
        payload.platform === DestinationType.YouTube &&
        payload.errorReason !== YouTubeError.Null
      ) {
        switch (payload.errorReason) {
          // TODO: Add other cases
          case YouTubeError.LiveStreamingNotEnabled: {
            const errorMessage = `Failed to add destination: ${payload.error}`
            toaster.open({
              key: User.AddDestinationFailed,
              content: (
                <Flex direction="column" style={{ lineHeight: 1.2 }}>
                  <CustomText text={errorMessage} />
                  <Button
                    appearance="text"
                    text="Click here to learn how to fix it."
                    href="https://help.golightstream.com/en/article/i-receive-an-error-when-trying-to-connect-or-while-going-live-with-my-youtube-account-1km1q0z/"
                    color="neutral"
                    colorWeight={0}
                  />
                </Flex>
              ),
              placement: 'top',
              duration: 15,
              style: {
                backgroundColor: 'secondary',
                backgroundColorWeight: 500,
              },
              closable: true,
            })
            return
          }
          default:
            break
        }
      }
      const errorMessage = `Failed to add destination: ${payload.error}`
      toaster.open({
        key: User.AddDestinationFailed,
        content: errorMessage,
        placement: 'top',
        duration: 10,
        style: {
          backgroundColor: 'secondary',
          backgroundColorWeight: 500,
        },
        closable: true,
      })
    },
  )

  const enum toastMsgs {
    DESTINATION_ADDED = 'Destination added',
    DESTINATION_REMOVED = 'Destination removed',
    DESTINATION_ENABLED = 'Destination enabled',
    DESTINATION_DISABLED = 'Destination disabled',
    BRODCAST_STARTED = 'Broadcast started',
    BRODCAST_STOPPED = 'Broadcast stopped',
    BRODCAST_FAILED = 'The broadcast failed to start',
  }

  const showToaster = (content: string, key: string, options?: ToastConfig) => {
    toaster.open({
      content,
      key,
      ...options,
    })
  }

  // Listen for project events
  useEffect(() => {
    return studio.subscribe((event, payload) => {
      if (event === 'ProjectChanged' && payload.project.id === project.id) {
        setIsLive(payload.project.isLive)
      }
    })
  }, [])

  // Listen for project events
  useEffect(() => {
    return project.subscribe((event, payload) => {
      if (event === 'BroadcastStarted') {
        setIsLoading(false)
        showToaster(toastMsgs.BRODCAST_STARTED, 'BST')
      } else if (event === 'BroadcastStopped') {
        setIsLoading(false)
        showToaster(toastMsgs.BRODCAST_STOPPED, 'BSS')
      }
    })
  }, [])

  useEffect(() => {
    return project.subscribe((event, payload) => {
      if (event === 'DestinationAdded') {
        const destination: SDK.Destination = payload.destination
        // @ts-ignore
        setDestinations([...destinations, destination])
        showToaster(toastMsgs.DESTINATION_ADDED, 'DA')
      } else if (event === 'DestinationChanged') {
        const dests = destinations.map((d) => {
          if (d.id !== payload.destination.id) return d
          return payload.destination
        })
        setDestinations(dests)
      } else if (event === 'DestinationRemoved') {
        const dests = destinations.filter((d) => d.id !== payload.destinationId)
        setDestinations(dests)
        showToaster(toastMsgs.DESTINATION_REMOVED, 'DR')
      }
    })
  }, [project, destinations])

  const style = {
    cursor: 'pointer',
    height: '100%',
  }

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    setIsOpen(!isOpen)
  }

  if (!project) return <></>

  const buttonProps = getButtonProps(
    isLoading,
    isLive,
    app.userType === UserType.HOST,
  )

  return (
    <>
      <AddDestinationModal
        isOpen={isAddDestinationOpen}
        close={() => {
          setIsAddDestinationOpen(false)
        }}
      />
      {destinations.length > 0 ? (
        <Row align="stretch">
          <Flex align="center">
            <div
              className="InfoSpace InfoDestinations"
              style={{ marginRight: 4 }}
            />
          </Flex>
          <WithDropdown
            className={withDropdownClass}
            onClose={() => {
              setIsOpen(false)
            }}
            align="start"
            isOpen={isOpen}
            margin={0}
            node={
              <Flex
                paddingLeft={8}
                paddingRight={8}
                align="center"
                style={style}
                onClick={onClick}
              >
                <Flex marginRight={15}>
                  <CustomText
                    text="Destinations"
                    color="neutral"
                    colorWeight={200}
                    fontSize={16}
                    fontWeight={700}
                    cursor="pointer"
                  />
                </Flex>
                <Flex align="center" wrap={false}>
                  {destinations
                    .filter((d) => d.props.auth)
                    .slice(0, 2)
                    .map((d) => {
                      const data = destinationData[d.props.type]
                      if (!d.props.auth) return <></>
                      return (
                        <Flex marginRight={-6} key={d.id}>
                          <Avatar
                            icon={data.logo}
                            size={32}
                            iconHeight={20}
                            iconWidth={20}
                            background={data.background}
                            borderColor={Colors.neutral(900)}
                          />
                        </Flex>
                      )
                    })}
                  <Flex>
                    <Avatar
                      icon={'Plus'}
                      size={32}
                      iconHeight={20}
                      iconWidth={20}
                      background={Colors.neutral(800)}
                      borderColor={Colors.neutral(900)}
                    />
                  </Flex>
                </Flex>
              </Flex>
            }
          >
            <DestinationDrawer
              close={() => {
                setIsOpen(false)
              }}
              openAddDestination={() => {
                setIsAddDestinationOpen(true)
              }}
              destinations={destinations}
            />
          </WithDropdown>

          {!isLoading && isLive && (
            <Flex alignSelf="center" justify="center" marginLeft={10}>
              <IconButton
                width={68}
                height={30}
                iconMargin={3}
                iconSize={11}
                appearance="solid"
                disableOnActive={true}
                disableOnHover={true}
                fontSize={14}
                style={{
                  borderRadius: 15,
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 2,
                }}
                color={'secondary'}
                colorWeight={600}
                icon="Circle"
                text="LIVE"
              />
            </Flex>
          )}

          <Flex margin={12}>
            <Button
              text={buttonProps.text}
              color={buttonProps.color}
              colorWeight={600}
              height={48}
              disabled={isLoading || buttonProps.disabled}
              onClick={async () => {
                if (userCanStream(user)) {
                  // Able to stream due to role
                  setIsLoading(true)
                  if (isLive) {
                    studio.Command.stopBroadcast({
                      projectId: project.id,
                    })
                  } else {
                    try {
                      const result = await fetch(
                        `/api/studio2/prepare-broadcast`,
                        {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          body: JSON.stringify({
                            projectId: project.id,
                            metadata: {
                              participants: projectCommands
                                .getParticipants()
                                .map((x) => x.props.sourceProps),
                              layout: project.props.layout,
                            },
                          }),
                        },
                      )
                      if (!result.ok) {
                        setIsLoading(false)
                        showToaster(toastMsgs.BRODCAST_FAILED, 'BF', {
                          icon: null,
                          style: {
                            backgroundColor: 'secondary',
                            backgroundColorWeight: 600,
                          },
                        })
                      }
                      console.info('Prepare broadcast', { result })
                    } catch (e) {
                      console.warn(e)
                      setIsLoading(false)
                      showToaster(toastMsgs.BRODCAST_FAILED, 'BF', {
                        icon: null,
                        style: {
                          backgroundColor: 'secondary',
                          backgroundColorWeight: 600,
                        },
                      })
                    }
                  }
                } else {
                  // Cannot stream. Show modal instead.
                  const hasStudio1Accounts = Boolean(
                    user.accounts.filter((a) => a.type !== 'magic').length,
                  )
                  const needsUpgrade =
                    user.subscription &&
                    user.subscription.status !== 'cancelled'
                  if (needsUpgrade) {
                    // show upgrade modal
                    setShowGoLiveStudio1NoTrial(true)
                    return
                  }
                  if (!hasStudio1Accounts) {
                    // Show noStudio1Modal
                    setShowNoStudio1Modal(true)
                    return
                  } else {
                    // has a studio 1 account
                    if (!user.hasDoneTrial) {
                      // show start trial modal
                      setShowGoLiveStudio1Trial(true)
                      return
                    } else {
                      // show signup modal
                      setShowGoLiveStudio1NoTrial(true)
                      return
                    }
                  }
                }
              }}
            />
          </Flex>
          <NoStudio1Modal
            isOpen={showNoStudio1Modal}
            context="going-live"
            onLink={() => setShowSetupLinkPlatform(true)}
            onClose={() => setShowNoStudio1Modal(false)}
            onSignup={() => {
              // @ts-ignore
              window.location = `${window.location.origin}/welcome?account=true`
            }}
          />
          <SetupLinkPlatform
            isOpen={showSetupLinkPlatform}
            onAction={(type) => () => {
              loadMergeAuthWindow({ provider: type })
            }}
            onClose={() => setShowSetupLinkPlatform(false)}
          />
          <GoLiveStudio1NoTrial
            isOpen={showGoLiveStudio1NoTrial}
            onAction={() => {
              // @ts-ignore
              window.location = `${window.location.origin}/welcome?account=true`
            }}
            onClose={() => setShowGoLiveStudio1NoTrial(false)}
          />
          <GoLiveStudio1Trial
            isOpen={showGoLiveStudio1Trial}
            onAction={() => {
              // @ts-ignore
              window.location = `${window.location.origin}/welcome?account=true`
            }}
            onClose={() => setShowGoLiveStudio1Trial(false)}
          />
        </Row>
      ) : (
        <Flex margin={12} align="center">
          <div className="InfoSpace" style={{ marginRight: 12 }}></div>
          <Button
            disabled={app.userType !== UserType.HOST}
            text="Connect Destination"
            height={48}
            onClick={() => {
              setIsAddDestinationOpen(true)
            }}
          />
        </Flex>
      )}
      {contextHolder}
    </>
  )
}

const DestinationDrawer = (props: {
  destinations: (SDK.Destination & { props: DestinationProps })[]
  openAddDestination: () => void
  close: () => void
}) => {
  const ref = useRef<HTMLDivElement>()

  const style: React.CSSProperties = {
    backgroundColor: Colors.neutral(900),
    borderBottomRightRadius: '4px',
    borderBottomLeftRadius: '4px',
  }
  const { destinations, close } = props

  useEffect(() => {
    return subscribeToEscape(() => {
      props.close()
    })
  }, [])

  return (
    <Flex
      direction="column"
      align="center"
      style={style}
      paddingLeft={12}
      paddingRight={12}
      paddingBottom={12}
      forwardedRef={ref}
    >
      {destinations.map((d, i) => (
        <DestinationDrawerItem
          key={d.id}
          destination={d}
          isLastItem={destinations.length - 1 === i}
        />
      ))}
      <Button
        text="Add Destination"
        style={{ alignSelf: 'stretch' }}
        width={244}
        onClick={() => {
          props.openAddDestination()
        }}
      />
    </Flex>
  )
}

const prettyType = (type: DestinationType) => {
  switch (type) {
    case DestinationType.Twitch:
      return 'Twitch'
    case DestinationType.Facebook:
      return 'Facebook'
    case DestinationType.YouTube:
      return 'YouTube'
    case DestinationType.Custom:
    default:
      return 'RTMP'
  }
}

const notLastClass = style({
  borderBottom: `1px solid ${Colors.neutral(1000)}`,
})

type DestinationTypeData = {
  name: DestinationType
  typePretty: string
  auth: boolean
  logo?: IconName
  background?: string
  settings?: {
    title?: object
    privacy?: object
    description?: object
  }
}

const destinationData: { [key in DestinationType]: DestinationTypeData } = {
  [DestinationType.Custom]: {
    name: DestinationType.Custom,
    typePretty: 'RTMP Destination',
    auth: false,
  },
  [DestinationType.Facebook]: {
    name: DestinationType.Facebook,
    typePretty: 'Facebook',
    auth: true,
    logo: 'Facebook',
    background: Colors.facebook.toString(),
  },
  [DestinationType.Twitch]: {
    name: DestinationType.Twitch,
    typePretty: 'Twitch',
    auth: true,
    logo: 'Twitch',
    background: Colors.twitch.toString(),
  },
  [DestinationType.YouTube]: {
    name: DestinationType.YouTube,
    typePretty: 'YouTube',
    auth: true,
    logo: 'YouTube',
    background: Colors.youtube.toString(),
  },
}

const AddDestinationModal = (props: { isOpen: boolean; close: () => void }) => {
  const { project } = useApp()
  const [rtmpOpen, setRtmpOpen] = useState(false)
  useEffect(() => {
    return project.subscribe((event, payload) => {
      if (event === 'DestinationAdded') {
        props.close()
        closeAuthWindow()
      }
    })
  })
  return (
    <NormalModal close={props.close} isOpen={props.isOpen} width={900}>
      <Flex marginBottom={8} alignSelf="stretch">
        <CustomText
          text="Add Destination"
          fontSize={24}
          fontWeight={700}
          color="neutral"
          colorWeight={0}
        />
      </Flex>
      {/* add list of items */}
      <Flex wrap={true} justify="space-between">
        {Object.values(destinationData).map((data) => {
          return (
            <Flex
              key={data.name}
              style={{
                background: Colors.neutral(800),
                cursor: 'pointer',
              }}
              height={140}
              marginBottom={12}
              width={278}
              align="center"
              justify="center"
              onClick={(e) => {
                if (data.auth) {
                  loadAuthWindow({ provider: data.name, projectId: project.id })
                } else {
                  setRtmpOpen(true)
                }
              }}
            >
              {Boolean(data.logo) && (
                <Flex marginRight={20}>
                  <Avatar
                    size={60}
                    iconHeight={40}
                    iconWidth={40}
                    icon={data.logo}
                    background={data.background}
                  />
                </Flex>
              )}
              <CustomText
                text={data.typePretty}
                fontSize={22}
                fontWeight={700}
                color="neutral"
                colorWeight={0}
                cursor="pointer"
              />
              <RTMPSettings
                isOpen={rtmpOpen}
                close={() => {
                  setRtmpOpen(false)
                }}
              />
            </Flex>
          )
        })}
      </Flex>
    </NormalModal>
  )
}

type AuthSettingsProps = {
  destination: SDK.Destination & { props: DestinationProps }
  save: () => void
  isOpen: boolean
  close: () => void
  children: React.ReactNode
}

const AuthSettingsModal = (props: AuthSettingsProps) => {
  const { studio } = useContext(StudioContext)
  const { project } = useApp()
  const { destination } = props
  const meta = destination.props
  const [isDisconnectOpen, setIsDisconnectOpen] = useState(false)
  if (!meta.auth) return <></>
  const { settings } = meta
  const data = destinationData[meta.type]
  const src = destination.props.auth ? destination.props.logo : ''

  return (
    <NormalModal close={props.close} isOpen={props.isOpen} width={900}>
      <Flex marginBottom={20}>
        <CustomText
          text="Edit Destination"
          fontSize={24}
          fontWeight={700}
          color="neutral"
          colorWeight={0}
        />
      </Flex>
      <Flex
        direction="column"
        align="stretch"
        style={{
          backgroundColor: Colors.neutral(800),
          borderRadius: '6px',
        }}
        alignSelf="stretch"
        padding={16}
        marginBottom={20}
      >
        <Flex direction="row" align="center" marginBottom={35}>
          {Boolean(data.logo) ? (
            <Flex marginRight={-9}>
              <Avatar
                icon={data.logo}
                size={60}
                iconHeight={40}
                iconWidth={40}
                background={data.background}
              />
            </Flex>
          ) : (
            <Flex marginRight={-9}>
              <Flex width={60} />
            </Flex>
          )}
          {Boolean(src) ? (
            <Flex marginRight={16}>
              <Avatar src={src} size={60} borderColor={Colors.neutral(800)} />
            </Flex>
          ) : (
            <Flex marginRight={16}>
              <Flex width={60} />
            </Flex>
          )}
          <Flex direction="column" align="flex-start">
            <CustomText
              cursor="pointer"
              fontSize={14}
              fontWeight={500}
              color="neutral"
              colorWeight={200}
              text={data.typePretty}
            />
            <div style={{ width: '140px' }}>
              <CustomText
                cursor="pointer"
                ellipsis={true}
                fontSize={18}
                fontWeight={700}
                color="neutral"
                colorWeight={0}
                text={meta.username}
              />
            </div>
          </Flex>
        </Flex>

        <form
          style={{
            width: '100%',
            height: '100%',
          }}
          onSubmit={(e) => {
            e.preventDefault()
            props.save()
          }}
        >
          {props.children}
        </form>
      </Flex>
      <Flex alignSelf="stretch" direction="row" justify="space-between">
        <Flex>
          <Button
            text="Cancel"
            appearance="outline"
            onClick={() => props.close()}
            style={{ marginRight: '12px' }}
          />
          <Button
            text="Disconnect"
            appearance="outline"
            onClick={() => {
              setIsDisconnectOpen(true)
            }}
          />
          {isDisconnectOpen && (
            <DarkModal
              isOpen={isDisconnectOpen}
              close={() => setIsDisconnectOpen(false)}
            >
              <Flex marginBottom={10}>
                <CustomText
                  text="Are you sure you want to remove this destination from your account?"
                  color="neutral"
                  colorWeight={0}
                  fontSize={14}
                  fontWeight={500}
                  textAlign="center"
                />
              </Flex>
              <Flex marginBottom={20}>
                <CustomText
                  text="This action will remove this connection from your destination selection list."
                  color="neutral"
                  colorWeight={400}
                  fontSize={12}
                  fontWeight={400}
                  textAlign="center"
                />
              </Flex>
              <Flex direction="row">
                <Flex marginRight={10}>
                  <Button
                    appearance="solid"
                    color="neutral"
                    colorWeight={1000}
                    fontSize={16}
                    text="No"
                    onClick={() => setIsDisconnectOpen(false)}
                  />
                </Flex>
                <Flex>
                  <Button
                    appearance="solid"
                    fontSize={16}
                    text="Yes"
                    onClick={() => {
                      studio.Command.removeDestination({
                        projectId: project.id,
                        destinationId: destination.id,
                      })
                    }}
                  />
                </Flex>
              </Flex>
            </DarkModal>
          )}
        </Flex>
        <Flex>
          <Button text="Save" appearance="solid" onClick={props.save} />
        </Flex>
      </Flex>
    </NormalModal>
  )
}

const InputField = (props: {
  title: string
  value: string
  setValue: (value: string) => void
  setIsValid: (value: boolean) => void
  isValid: boolean
  maxLength: number
  required: boolean
  multiline: boolean
  placeholder?: string
}) => {
  const {
    value,
    setValue,
    setIsValid,
    maxLength,
    isValid,
    title,
    required,
    multiline,
    placeholder,
  } = props
  return (
    <>
      <Flex
        direction="column"
        align="stretch"
        alignSelf="stretch"
        marginBottom={15}
      >
        <TextInput
          label={<Text.Label text={title} color="neutral" />}
          maxLength={maxLength}
          appearance="outline"
          multiline={multiline}
          style={{ alignSelf: 'stretch', justifySelf: 'stretch' }}
          defaultValue={value}
          color="neutral"
          colorWeight={600}
          height={multiline ? 85 : 34}
          placeholder={placeholder}
          onChange={(e) => {
            setValue(e.target.value)
            if (
              (required ? value.length <= 0 : false) ||
              value.length > maxLength
            ) {
              setIsValid(false)
            } else {
              setIsValid(true)
            }
          }}
          invalid={!isValid}
        />
      </Flex>
    </>
  )
}

const SyncDestinationsField = (props: {
  syncDestinations: boolean
  setSyncDestinations: (value: boolean) => void
}) => {
  const { syncDestinations, setSyncDestinations } = props
  return (
    <Flex direction="row" align="center" justify="flex-start">
      <Flex marginRight={12}>
        <Checkbox
          width={18}
          height={18}
          checked={syncDestinations}
          onChange={setSyncDestinations}
          text="Apply metadata to all destinations"
        />
      </Flex>
    </Flex>
  )
}

const TwitchSettings = (props: {
  destination: SDK.Destination & { props: TwitchDestinationProps }
  isOpen: boolean
  close: () => void
}) => {
  const { studio } = useContext(StudioContext)
  const { project } = useApp()
  const { destination } = props
  const [title, setTitle] = useState(
    destination.props.settings?.title ?? 'My Stream',
  )
  const [storedTitle, setStoredTitle] = useState(
    destination.props.settings?.title ?? 'My Stream',
  )
  const titleMax = 100
  const [isTitleValid, setIsTitleValid] = useState(true)
  const [syncDestinations, setSyncDestinations] = useState(false)
  useEffect(() => {
    return project.subscribe((event, payload) => {
      if (event === 'DestinationChanged') {
        const d: SDK.Destination & { props: DestinationProps } =
          payload.destination
        if (d.id === destination.id) {
          const props = d.props as TwitchDestinationProps
          setTitle(props.settings.title)
          setStoredTitle(props.settings.title)
        }
      }
    })
  }, [])
  const save = async () => {
    if (syncDestinations) {
      return saveAll()
    }
    const destinationProps: TwitchDestinationProps = {
      ...destination.props,
      settings: {
        title,
      },
    }

    if (isTitleValid) {
      await studio.Command.updateDestinationProps({
        projectId: project.id,
        destinationId: destination.id,
        props: destinationProps,
      })
      props.close()
    }
  }

  const saveAll = async () => {
    if (isTitleValid) {
      await Promise.all(
        project.destinations
          .filter((d) => d.props.auth)
          .map(async (d) => {
            const props = d.props as DestinationProps & { auth: true }
            await studio.Command.updateDestinationProps({
              destinationId: d.id,
              projectId: project.id,
              props: {
                ...d.props,
                settings: {
                  ...d.props.settings,
                  title,
                },
              },
            })
            return props
          }),
      )
      props.close()
    }
  }

  if (destination.props.type !== DestinationType.Twitch) {
    return <></>
  }
  return (
    <AuthSettingsModal
      destination={destination}
      isOpen={props.isOpen}
      close={() => {
        setTitle(storedTitle)
        props.close()
      }}
      save={save}
    >
      <Flex alignSelf="stretch" align="stretch" direction="column">
        <InputField
          title={`Title`}
          value={title}
          setValue={setTitle}
          isValid={isTitleValid}
          setIsValid={setIsTitleValid}
          multiline={false}
          required={true}
          maxLength={titleMax}
        />
        <SyncDestinationsField
          syncDestinations={syncDestinations}
          setSyncDestinations={setSyncDestinations}
        />
      </Flex>
    </AuthSettingsModal>
  )
}

const YouTubeSettings = (props: {
  destination: SDK.Destination & { props: YouTubeDestinationProps }
  isOpen: boolean
  close: () => void
}) => {
  const { studio } = useContext(StudioContext)
  const { project } = useApp()
  const { destination } = props
  const [title, setTitle] = useState(
    destination.props.settings?.title ?? 'My Stream',
  )
  const [storedTitle, setStoredTitle] = useState(
    destination.props.settings?.title ?? 'My Stream',
  )
  const titleMax = 100
  const [isTitleValid, setIsTitleValid] = useState(true)
  const [description, setDescription] = useState(
    destination.props.settings?.description ?? '',
  )
  const [storedDescription, setStoredDescription] = useState(
    destination.props.settings?.description ?? '',
  )
  const [isDescriptionValid, setIsDescriptionValid] = useState(true)
  const descriptionMax = 1500
  const [privacy, setPrivacy] = useState(
    destination.props.settings?.privacy ?? YouTubePrivacy.Public,
  )
  const [storedPrivacy, setStoredPrivacy] = useState(
    destination.props.settings?.privacy ?? YouTubePrivacy.Public,
  )
  const [syncDestinations, setSyncDestinations] = useState(false)
  useEffect(() => {
    return project.subscribe((event, payload) => {
      if (event === 'DestinationChanged') {
        const d: SDK.Destination & { props: DestinationProps } =
          payload.destination
        if (d.id === destination.id) {
          const props = d.props as YouTubeDestinationProps
          setTitle(props.settings.title)
          setStoredTitle(props.settings.title)
          setIsTitleValid(true)
          setDescription(props.settings.description)
          setStoredDescription(props.settings.description)
          setIsDescriptionValid(true)
          setPrivacy(props.settings.privacy)
          setStoredPrivacy(props.settings.privacy)
        }
      }
    })
  })

  const privacyOptions = [
    {
      value: YouTubePrivacy.Public,
      label: 'Public',
    },
    {
      value: YouTubePrivacy.Unlisted,
      label: 'Unlisted',
    },
    {
      value: YouTubePrivacy.Private,
      label: 'Private',
    },
  ]

  const saveAll = async () => {
    if (isTitleValid && isDescriptionValid) {
      await Promise.all(
        project.destinations
          .filter((d) => d.props.auth)
          .map(async (d) => {
            const props = d.props as DestinationProps & { auth: true }
            if (props.type === DestinationType.Twitch) {
              await studio.Command.updateDestinationProps({
                destinationId: d.id,
                projectId: project.id,
                props: {
                  ...d.props,
                  settings: {
                    ...d.props.settings,
                    title,
                  },
                },
              })
            } else if (props.type === DestinationType.Facebook) {
              await studio.Command.updateDestinationProps({
                destinationId: d.id,
                projectId: project.id,
                props: {
                  ...d.props,
                  settings: {
                    ...d.props.settings,
                    title,
                    description,
                  },
                },
              })
            } else if (props.type === DestinationType.YouTube) {
              await studio.Command.updateDestinationProps({
                destinationId: d.id,
                projectId: project.id,
                props: {
                  ...d.props,
                  settings: {
                    ...d.props.settings,
                    title,
                    description,
                    privacy,
                  },
                },
              })
            }
            return props
          }),
      )
      props.close()
    }
  }
  const save = async () => {
    if (syncDestinations) {
      return saveAll()
    }
    const destinationProps: YouTubeDestinationProps = {
      ...destination.props,
      settings: {
        title,
        description,
        privacy,
      },
    }

    if (isTitleValid && isDescriptionValid) {
      await studio.Command.updateDestinationProps({
        projectId: project.id,
        destinationId: destination.id,
        props: destinationProps,
      })
      props.close()
    }
  }

  if (destination.props.type !== DestinationType.YouTube) {
    return <></>
  }
  return (
    <AuthSettingsModal
      destination={destination}
      isOpen={props.isOpen}
      close={() => {
        setTitle(storedTitle)
        setDescription(storedDescription)
        setPrivacy(storedPrivacy)
        props.close()
      }}
      save={save}
    >
      <Flex alignSelf="stretch" align="stretch" direction="column">
        <InputField
          title={`Title`}
          value={title}
          setValue={setTitle}
          isValid={isTitleValid}
          setIsValid={setIsTitleValid}
          multiline={false}
          required={true}
          maxLength={titleMax}
          placeholder="Add a title here..."
        />
        <InputField
          title={`Description`}
          value={description}
          setValue={setDescription}
          isValid={isDescriptionValid}
          setIsValid={setIsDescriptionValid}
          multiline={true}
          required={false}
          maxLength={descriptionMax}
          placeholder="Add a description here..."
        />
        <Flex marginBottom={12}>
          <Text.Label text="Privacy" color="neutral" />
        </Flex>
        <Flex
          direction="column"
          align="stretch"
          alignSelf="stretch"
          marginBottom={15}
        >
          <CustomSelect
            appearance="outline"
            placeholder="Select privacy..."
            color="neutral"
            colorWeight={600}
            dropdownColor="neutral"
            dropdownColorWeight={700}
            value={privacy}
            onChange={(value) => setPrivacy(value)}
            options={privacyOptions}
          />
        </Flex>
        <SyncDestinationsField
          syncDestinations={syncDestinations}
          setSyncDestinations={setSyncDestinations}
        />
      </Flex>
    </AuthSettingsModal>
  )
}

const FacebookSettings = (props: {
  destination: SDK.Destination & { props: FacebookDestinationProps }
  isOpen: boolean
  close: () => void
}) => {
  const { studio } = useContext(StudioContext)
  const { project } = useApp()
  const { destination } = props
  const [title, setTitle] = useState(
    destination.props.settings?.title ?? 'My Stream',
  )
  const [loadingStreamDestinations, setLoadingStreamDestinations] =
    React.useState(true)
  const [streamDestination, setStreamDestination] = useState(
    destination.props.settings?.streamDestination ?? null,
  )
  const [storedTitle, setStoredTitle] = useState(
    destination.props.settings?.title ?? 'My Stream',
  )
  const titleMax = 100
  const [isTitleValid, setIsTitleValid] = useState(true)
  const [description, setDescription] = useState(
    destination.props.settings?.description ?? '',
  )
  const [storedDescription, setStoredDescription] = useState(
    destination.props.settings?.description ?? '',
  )
  const [isDescriptionValid, setIsDescriptionValid] = useState(true)
  const descriptionMax = 1500
  const [privacy, setPrivacy] = useState(
    destination.props.settings?.privacy ?? FacebookPrivacy.Everyone,
  )
  const [storedPrivacy, setStoredPrivacy] = useState(
    destination.props.settings?.privacy ?? FacebookPrivacy.Everyone,
  )
  const [storedStreamDestination, setStoredStreamDestination] = useState(
    destination.props.settings?.streamDestination ?? null,
  )

  const [availableDestinations, setAvailableDestinations] = useState<
    { type: 'page' | 'group' | 'event'; id: string }[]
  >([])
  const disabledDestinations = useMemo(() => {
    return project.destinations
      .filter((d) => d.props.type === DestinationType.Facebook)
      .filter((d) => d.id !== destination.id)
      .map((i) => i.props.settings?.streamDestination?.id ?? null)
  }, [project.destinations, destination.id])

  const pageOptions = useMemo(() => {
    return {
      Timeline: [
        {
          value: null,
          label: destination.props.username,
          disabled: disabledDestinations.includes(null),
        },
      ],
      Pages: availableDestinations
        .filter((i: any) => i.type === 'page')
        .map((i: any) => ({
          value: i.id,
          label: i.name,
          disabled: disabledDestinations.includes(i.id),
        })),
      Groups: availableDestinations
        .filter((i: any) => i.type === 'group')
        .map((i: any) => ({
          value: i.id,
          label: i.name,
          disabled: disabledDestinations.includes(i.id),
        })),
      Events: availableDestinations
        .filter((i: any) => i.type === 'event')
        .map((i: any) => ({
          value: i.id,
          label: i.name,
          disabled: disabledDestinations.includes(i.id),
        })),
    }
  }, [availableDestinations, disabledDestinations])

  useEffect(() => {
    fetch(
      `/api/studio2/destinations/${destination.id}/streamDestinations?projectId=${project.id}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.statusCode !== 200) {
          return
        }
        setLoadingStreamDestinations(false)
        setAvailableDestinations(res.payload)
      })
  }, [destination.id, project.id, destination.props.username])

  const [syncDestinations, setSyncDestinations] = useState(false)
  useEffect(() => {
    return project.subscribe((event, payload) => {
      if (event === 'DestinationChanged') {
        const d: SDK.Destination & { props: DestinationProps } =
          payload.destination
        if (d.id === destination.id) {
          const props = d.props as FacebookDestinationProps
          setTitle(props.settings.title)
          setStoredTitle(props.settings.title)
          setIsTitleValid(true)
          setDescription(props.settings.description)
          setStreamDestination(props.settings.streamDestination ?? null)
          setStoredStreamDestination(props.settings.streamDestination ?? null)
          setStoredDescription(props.settings.description)
          setIsDescriptionValid(true)
          setPrivacy(props.settings.privacy)
          setStoredPrivacy(props.settings.privacy)
        }
      }
    })
  })

  const privacyOptions = [
    {
      value: FacebookPrivacy.Everyone,
      label: 'Everyone',
    },
    {
      value: FacebookPrivacy.Friends,
      label: 'Friends',
    },
    {
      value: FacebookPrivacy.FriendsOfFriends,
      label: 'Friends of friends',
    },
    {
      value: FacebookPrivacy.Self,
      label: 'Self',
    },
  ]

  const saveAll = async () => {
    if (isTitleValid && isDescriptionValid) {
      await Promise.all(
        project.destinations
          .filter((d) => d.props.auth)
          .map(async (d) => {
            const props = d.props as DestinationProps & { auth: true }
            if (props.type === DestinationType.Twitch) {
              await studio.Command.updateDestinationProps({
                destinationId: d.id,
                projectId: project.id,
                props: {
                  ...d.props,
                  settings: {
                    ...d.props.settings,
                    title,
                  },
                },
              })
            } else if (props.type === DestinationType.YouTube) {
              await studio.Command.updateDestinationProps({
                destinationId: d.id,
                projectId: project.id,
                props: {
                  ...d.props,
                  settings: {
                    ...d.props.settings,
                    title,
                    description,
                  },
                },
              })
            } else if (props.type === DestinationType.Facebook) {
              await studio.Command.updateDestinationProps({
                destinationId: d.id,
                projectId: project.id,
                props: {
                  ...d.props,
                  settings: {
                    ...d.props.settings,
                    title,
                    description,
                    privacy,

                    // We should only sync streamDestination for the current destination
                    streamDestination:
                      destination.id === d.id
                        ? streamDestination
                        : d.props.settings.streamDestination,
                  },
                },
              })
            }
            return props
          }),
      )
      props.close()
    }
  }

  const save = async () => {
    if (syncDestinations) {
      return saveAll()
    }
    const destinationProps: FacebookDestinationProps = {
      ...destination.props,
      settings: {
        title,
        description,
        privacy,
        streamDestination,
      },
    }

    if (isTitleValid && isDescriptionValid) {
      await studio.Command.updateDestinationProps({
        projectId: project.id,
        destinationId: destination.id,
        props: destinationProps,
      })
      props.close()
    }
  }

  if (destination.props.type !== DestinationType.Facebook) {
    return <></>
  }
  return (
    <AuthSettingsModal
      destination={destination}
      isOpen={props.isOpen}
      close={() => {
        setTitle(storedTitle)
        setDescription(storedDescription)
        setPrivacy(storedPrivacy)
        setStreamDestination(storedStreamDestination)

        props.close()
      }}
      save={save}
    >
      <Flex alignSelf="stretch" align="stretch" direction="column">
        <Flex
          direction="column"
          align="stretch"
          alignSelf="stretch"
          marginBottom={15}
        >
          <Text.Label text="Go live on" />
          <CustomSelect
            marginTop={12}
            appearance="outline"
            disabled={loadingStreamDestinations}
            options={pageOptions}
            value={streamDestination?.id ?? null}
            color="neutral"
            colorWeight={300}
            height={34}
            onChange={(e) => {
              const res = availableDestinations.find((i) => i.id === e)
              setStreamDestination(res!)
            }}
          />
        </Flex>
        <InputField
          title={`Title`}
          value={title}
          setValue={setTitle}
          isValid={isTitleValid}
          setIsValid={setIsTitleValid}
          multiline={false}
          required={true}
          maxLength={titleMax}
          placeholder="Add a title here..."
        />
        <InputField
          title={`Description`}
          value={description}
          setValue={setDescription}
          isValid={isDescriptionValid}
          setIsValid={setIsDescriptionValid}
          multiline={true}
          required={false}
          maxLength={descriptionMax}
          placeholder="Add a description here..."
        />
        <Flex marginBottom={12}>
          <Text.Label text="Privacy" color="neutral" />
        </Flex>
        <Flex
          direction="column"
          align="stretch"
          alignSelf="stretch"
          marginBottom={15}
        >
          <CustomSelect
            appearance="outline"
            placeholder="Select privacy..."
            color="neutral"
            colorWeight={600}
            dropdownColor="neutral"
            dropdownColorWeight={700}
            value={privacy}
            onChange={(value) => setPrivacy(value)}
            options={privacyOptions}
          />
        </Flex>
        <SyncDestinationsField
          syncDestinations={syncDestinations}
          setSyncDestinations={setSyncDestinations}
        />
      </Flex>
    </AuthSettingsModal>
  )
}

const DestinationDrawerItem = (props: {
  destination: SDK.Destination & { props: DestinationProps }
  isLastItem: boolean
}) => {
  const { destination, isLastItem } = props
  const { studio } = useContext(StudioContext)
  const { project } = useApp()
  const [settingsOpen, setSettingsOpen] = useState(false)
  const [enabled, setEnabled] = useState(destination.enabled)
  const src = destination.props.auth ? destination.props.logo : ''
  const data = destinationData[destination.props.type]
  const typePretty = prettyType(
    destination.props.type || DestinationType.Custom,
  )

  const userName = destination.props.username
    ? destination.props.username
    : 'Custom Destination'
  const secondaryName =
    destination.props.settings?.streamDestination?.name ?? null
  return (
    <Flex
      align="center"
      paddingTop={12}
      paddingBottom={12}
      paddingRight={8}
      className={isLastItem ? '' : notLastClass}
      onClick={() => {
        setSettingsOpen(true)
      }}
    >
      <Flex marginRight={-3}>
        {Boolean(data.logo) ? (
          <Avatar
            icon={data.logo}
            size={32}
            iconHeight={20}
            iconWidth={20}
            background={data.background}
          />
        ) : (
          <Flex width={32} />
        )}
      </Flex>
      {Boolean(src) ? (
        <Avatar src={src} size={32} borderColor={Colors.neutral(900)} />
      ) : (
        <Flex width={32} />
      )}
      <Flex direction="column" align="flex-start" marginLeft={8}>
        <CustomText
          cursor="pointer"
          fontSize={12}
          fontWeight={700}
          color="neutral"
          colorWeight={200}
          text={typePretty}
        />
        <div style={{ width: '140px' }}>
          <CustomText
            cursor="pointer"
            ellipsis={true}
            fontSize={14}
            fontWeight={700}
            color="neutral"
            colorWeight={0}
            text={userName}
          />
          <CustomText
            cursor="pointer"
            ellipsis={true}
            fontSize={10}
            fontWeight={700}
            color="neutral"
            colorWeight={400}
            text={secondaryName}
          />
        </div>
      </Flex>
      <Toggle
        value={enabled}
        onChange={async (value) => {
          await studio.Command.setDestinationEnabled({
            projectId: project.id,
            destinationId: destination.id,
            enabled: value,
          })
          setEnabled(value)
        }}
      />
      <Icon name="ChevronRight" height={24} width={24} />
      <Settings
        close={() => {
          setSettingsOpen(false)
        }}
        isOpen={settingsOpen}
        destination={destination}
      />
    </Flex>
  )
}

const Settings = (
  props: Pick<AuthSettingsProps, 'destination' | 'isOpen' | 'close'>,
) => {
  if (props.destination.props.type === DestinationType.Twitch) {
    return (
      <TwitchSettings
        {...{}}
        /* @ts-ignore */
        destination={props.destination}
        isOpen={props.isOpen}
        close={props.close}
      />
    )
  } else if (props.destination.props.type === DestinationType.YouTube) {
    return (
      <YouTubeSettings
        {...{}}
        /* @ts-ignore */
        destination={props.destination}
        isOpen={props.isOpen}
        close={props.close}
      />
    )
  } else if (props.destination.props.type === DestinationType.Facebook) {
    return (
      <FacebookSettings
        {...{}}
        /* @ts-ignore */
        destination={props.destination}
        isOpen={props.isOpen}
        close={props.close}
      />
    )
  } else if (props.destination.props.type === DestinationType.Custom) {
    return (
      <RTMPSettings
        {...{}}
        /* @ts-ignore */
        destination={props.destination}
        isOpen={props.isOpen}
        close={props.close}
      />
    )
  } else {
    return <></>
  }
}

// Clean an RTMP url if valid, otherwise return the same value
const cleanRtmpURL = (url: string) => {
  try {
    const cleanUrl = new URL(url)
    return cleanUrl.href
  } catch (e) {
    return url
  }
}

const RTMPSettings = (props: {
  close: () => void
  isOpen: boolean
  destination?: SDK.Destination & { props: CustomDestinationProps }
}) => {
  const { studio } = useContext(StudioContext)
  const { project } = useApp()
  const { destination } = props
  const [url, setUrl] = useState(destination?.address.rtmpPush.url ?? '')
  const [key, setKey] = useState(destination?.address.rtmpPush.key ?? '')
  const [username, setUsername] = useState(destination?.props.username ?? '')
  const [usernameValid, setUsernameValid] = useState(true)
  const [urlValid, setUrlValid] = useState(true)
  const [isDisconnectOpen, setIsDisconnectOpen] = useState(false)
  const re = /^(rtmp|rtmps):\/\/.+/i

  const nameMax = 100
  const save = props.destination
    ? async () => {
        await studio.Command.updateDestination({
          projectId: project.id,
          destinationId: destination.id,
          rtmpUrl: cleanRtmpURL(url),
          rtmpKey: key,
        })

        await studio.Command.updateDestinationProps({
          projectId: project.id,
          destinationId: destination.id,
          props: {
            ...destination.props,
            username,
          },
        })

        props.close()
      }
    : async () => {
        await studio.Command.addDestination({
          projectId: project.id,
          rtmpUrl: cleanRtmpURL(url),
          rtmpKey: key,
          enabled: true,
          props: {
            auth: false,
            type: DestinationType.Custom,
            username,
          },
        })
        props.close()
      }
  return (
    <NormalModal
      isOpen={props.isOpen}
      close={props.close}
      darkBackground={Boolean(destination)}
      width={900}
    >
      <Flex marginBottom={8} alignSelf="stretch">
        <CustomText
          text="Add Destination"
          fontSize={24}
          fontWeight={700}
          color="neutral"
          colorWeight={0}
        />
      </Flex>
      <Flex
        direction="column"
        align="stretch"
        style={{
          backgroundColor: Colors.neutral(800),
          borderRadius: '6px',
        }}
        alignSelf="stretch"
        padding={16}
        marginBottom={20}
      >
        <form
          style={{
            width: '100%',
            height: '100%',
          }}
          onSubmit={(e) => {
            e.preventDefault()
            save()
            props.close()
          }}
        >
          <Flex
            direction="column"
            align="stretch"
            alignSelf="stretch"
            marginBottom={15}
          >
            <TextInput
              label={<Text.Label text="Name" color="neutral" />}
              appearance="outline"
              style={{ alignSelf: 'stretch', justifySelf: 'stretch' }}
              defaultValue={username}
              color="neutral"
              placeholder="Add a name here..."
              colorWeight={600}
              height={34}
              onChange={(e) => {
                const value = e.target.value
                setUsername(value)
                if (value.length <= 0 || value.length > nameMax) {
                  setUsernameValid(false)
                } else {
                  setUsernameValid(true)
                }
              }}
              invalid={!usernameValid}
            />
          </Flex>
          <Flex
            direction="column"
            align="stretch"
            alignSelf="stretch"
            marginBottom={15}
          >
            <TextInput
              label={<Text.Label text="Stream Key" color="neutral" />}
              appearance="outline"
              style={{ alignSelf: 'stretch', justifySelf: 'stretch' }}
              defaultValue={key}
              color="neutral"
              placeholder="Add a key here..."
              colorWeight={600}
              height={34}
              onChange={(e) => {
                const value = e.target.value
                setKey(value)
              }}
            />
          </Flex>
          <Flex
            direction="column"
            align="stretch"
            alignSelf="stretch"
            marginBottom={15}
          >
            <TextInput
              label={<Text.Label text="Ingest URL" color="neutral" />}
              appearance="outline"
              style={{ alignSelf: 'stretch', justifySelf: 'stretch' }}
              defaultValue={url}
              color="neutral"
              placeholder="Add a URL here..."
              colorWeight={600}
              height={34}
              onChange={(e) => {
                const value = e.target.value
                setUrl(value)
                if (re.test(value)) {
                  setUrlValid(true)
                } else {
                  setUrlValid(false)
                }
              }}
              invalid={!urlValid}
            />
          </Flex>
        </form>
      </Flex>
      <Flex direction="row" justify="space-between" alignSelf="stretch">
        <Flex>
          <Flex marginRight={12}>
            <Button
              appearance="outline"
              onClick={() => props.close()}
              text={'Cancel'}
            />
          </Flex>
          {Boolean(props.destination) && (
            <>
              <Button
                appearance="outline"
                onClick={() => setIsDisconnectOpen(true)}
                text={'Disconnect'}
              />
              {isDisconnectOpen && (
                <DarkModal
                  isOpen={isDisconnectOpen}
                  close={() => setIsDisconnectOpen(false)}
                >
                  <Flex marginBottom={10}>
                    <CustomText
                      text="Are you sure you want to remove this destination from your account?"
                      color="neutral"
                      colorWeight={0}
                      fontSize={14}
                      fontWeight={500}
                      textAlign="center"
                    />
                  </Flex>
                  <Flex marginBottom={20}>
                    <CustomText
                      text="This action will remove this connection from your destination selection list."
                      color="neutral"
                      colorWeight={400}
                      fontSize={12}
                      fontWeight={400}
                      textAlign="center"
                    />
                  </Flex>
                  <Flex direction="row">
                    <Flex marginRight={10}>
                      <Button
                        appearance="solid"
                        color="neutral"
                        colorWeight={1000}
                        fontSize={16}
                        text="No"
                        onClick={() => setIsDisconnectOpen(false)}
                      />
                    </Flex>
                    <Flex>
                      <Button
                        appearance="solid"
                        fontSize={16}
                        text="Yes"
                        onClick={() => {
                          studio.Command.removeDestination({
                            projectId: project.id,
                            destinationId: destination.id,
                          })
                        }}
                      />
                    </Flex>
                  </Flex>
                </DarkModal>
              )}
            </>
          )}
        </Flex>
        <Button
          appearance="solid"
          onClick={() => {
            if (usernameValid && urlValid && username.length && re.test(url)) {
              save()
              props.close()
            }
          }}
          disabled={!usernameValid || !urlValid}
          text="Save"
        />
      </Flex>
    </NormalModal>
  )
}
