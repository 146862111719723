import { WithInfo } from '@ui/atoms/FloatingMenu/FloatingMenu'
import { SVG } from '@ui/atoms/icons/Icon'
import { Flex, Row } from '@ui/layout/Box'
import { style } from 'typestyle'
import {
  LayoutDeclaration,
  LayoutName,
  layouts,
} from '../../src/utils/scene-layouts'
import * as Icons from '../atoms/icons/IconMap'
import * as Color from '../helpers/colors'

type UserDopdownProps = {
  activeLayout: LayoutName
  onChange: (layout: LayoutName) => void
}

export const SceneLayoutPanel = ({
  activeLayout,
  onChange = () => {},
}: UserDopdownProps) => {
  return (
    <Row>
      <div className="InfoSpace InfoLayout" style={{ marginRight: 8 }} />
      {Object.entries(layouts).map(
        ([name, val]: [LayoutName, LayoutDeclaration], i) => {
          const isActive = activeLayout === name
          return (
            <WithInfo
              key={name}
              delay={0}
              message={val.label}
              node={
                <Flex
                  className={style({
                    cursor: 'pointer',
                    borderRadius: 4,
                    color: isActive ? Color.primary(500) : Color.neutral(700),
                    ...(val.iconBorder && {
                      boxShadow: `0 0 0 2px ${
                        isActive ? Color.primary(500) : Color.neutral(700)
                      } inset`,
                    }),
                    $nest: {
                      '&:hover': {
                        color: isActive
                          ? Color.primary(500)
                          : Color.neutral(500),
                        boxShadow: `0 0 0 2px ${
                          isActive ? Color.primary(500) : Color.neutral(500)
                        } inset`,
                      },
                    },
                  })}
                  marginLeft={i > 0 ? 8 : 0}
                  onClick={() => {
                    onChange(name)
                  }}
                >
                  <SVG height={48} width={90} svg={Icons[val.icon]} />
                </Flex>
              }
            />
          )
        },
      )}
    </Row>
  )
}

export default SceneLayoutPanel
