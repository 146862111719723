export const enum DestinationType {
  Twitch = 'twitchtv',
  Facebook = 'facebook',
  YouTube = 'youtube',
  Custom = 'custom',
}

interface BaseDestinationProps {
  type: DestinationType
  /** Denotes whether or not oauth was used to link the destination or if it was custom/fake integration */
  auth: boolean
  /**
   * user id.
   * This will be a mongoose ObjectId
   * However, we do not want to import mongoose into studio-2
   */
}

interface AuthDestinationProps extends BaseDestinationProps {
  type:
    | DestinationType.Facebook
    | DestinationType.Twitch
    | DestinationType.YouTube
  auth: true
  providerId: string
  username: string
  displayName: string
  /**
   * avatar URL
   */
  logo: string
  dashboardUrl: string
  accessToken: string
  refreshToken: string
}

export const enum FacebookPrivacy {
  Everyone = 'EVERYONE',
  FriendsOfFriends = 'FRIENDS_OF_FRIENDS',
  Friends = 'ALL_FRIENDS',
  Self = 'SELF',
}

export interface FacebookDestinationProps extends AuthDestinationProps {
  type: DestinationType.Facebook
  settings: {
    title: string
    /** default 'EVERYONE' */
    privacy: FacebookPrivacy
    description: string

    // The event/page/group id, if unset, assume default providerId/accessToken.
    streamDestination?: { type: 'group' | 'page' | 'event'; id: string }
  }
}

export interface TwitchDestinationProps extends AuthDestinationProps {
  type: DestinationType.Twitch
  settings: {
    title: string
  }
}

export const enum YouTubePrivacy {
  Public = 'public',
  Private = 'private',
  Unlisted = 'unlisted',
}

export interface YouTubeDestinationProps extends AuthDestinationProps {
  type: DestinationType.YouTube
  settings: {
    title: string
    /** default 'public' */
    privacy: YouTubePrivacy
    description: string
  }
}

export interface CustomDestinationProps extends BaseDestinationProps {
  auth: false
  /** Add more Destination types for other "fake" (non-auth integrations) */
  type: DestinationType.Custom
  username?: string
}

export type DestinationProps =
  | TwitchDestinationProps
  | YouTubeDestinationProps
  | FacebookDestinationProps
  | CustomDestinationProps

export interface Destination {
  id?: string
  rtmpUrl?: string
  rtmpKey?: string
  address?: object
  enabled?: boolean
  props: DestinationProps
}
